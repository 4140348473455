import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default function StripeCard({ urlGlobal, getHeadersToSend, total, textosShow, onSuccess, onError, onCancel, clientSecret }) {
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });
    setProcessing(false);
    if (payload.error) {
      onError(payload.error.message);
    }
    else {
      console.log(payload.paymentIntent);
      if (payload.paymentIntent.status !== "succeeded") {
        onCancel(payload.paymentIntent.cancellation_reason);
      }
      else {
        onSuccess(payload.paymentIntent.id);
      }
    }
  };
  
  return (<form 
        id="payment-form"
        onSubmit={handleSubmit}>
        <div style={{
          backgroundColor: '#ffffff',
          padding: '10px',
          borderRadius: '10px'
        }}>
          <CardElement
            id="card-element"
            onChange={handleChange}
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  }
                },
                invalid: {
                  color: '#9e2146',
                }
              },
              }}>
          </CardElement>
        </div>
        {
          error && (
            <p>
              {error}
            </p>
          )
        }
        <br/>
        <button
          disabled={processing || disabled}
          id="submit"
          style={{
            backgroundColor: '#000000',
            color: '#ffffff',
            height: '55px',
            width: '100%',
            borderRadius: '10px',
            fontSize: '18px',
            lineHeight: '55px'
          }}>
          <span id="button-text">
            {
              processing ? (
                textosShow.procesando || "Procesando..."
              ) : (
                textosShow.pagarconstripe || "Pagar con Stripe"
              )
            }
          </span>
        </button>
      </form>
  );
};