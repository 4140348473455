import React, { useEffect, useRef, useState } from 'react';

import { Card, Col, Container, Row } from 'reactstrap';

import noneMembershipIcon from '../../img/membership/none.png';
import bronzeMembershipIcon from '../../img/membership/bronze.png';
import goldMembershipIcon from '../../img/membership/gold.png';
import proMembershipIcon from '../../img/membership/pro.png';
import diamondMembershipIcon from '../../img/membership/diamond.png';
import rubyMembershipIcon from '../../img/membership/ruby.png';
import crownMembershipIcon from '../../img/membership/crown.png';

import MembershipUtil from '../utils/membership';

export default function MembershipCard({membership, textosShow, defaultData}) {

  const getMembershipIcon = () => {
    if (!membership) {
        return noneMembershipIcon;
    }
    
    switch (membership["MembershipLevel"]) {
      case 0:
        return noneMembershipIcon;

      case 1:
        return bronzeMembershipIcon;

      case 2:
        return goldMembershipIcon;

      case 3:
        return proMembershipIcon;

      case 4:
        return diamondMembershipIcon;

      case 5:
        return rubyMembershipIcon;
      
      case 6:
        return crownMembershipIcon;

      default:
        return noneMembershipIcon;
    }
}

  return (
      <>
        <Card className='membership-card-bg'>
            <div className='membership-card-bg-2'>
              <div className='align-items-center membership-card'>
                <div xs="12" className="text-center text-md-center d-flex-center d-flex-ver">
                  <img className='membership-icon' src={getMembershipIcon()}/>
                  <div className='membership-title line-camp-2'>
                    {`${textosShow[membership["MembershipSlug"]] || membership["MembershipName"]} ${textosShow.membership || 'Membership'}`}
                  </div>
                </div>
                <div xs="12" className='text-center'>
                  <div className='membership-description line-camp-1'>
                    <span>{ textosShow.need || 'Need'} </span>
                    <span className='membership-point'>{`${Number(membership["MembershipPoint"]).toFixed(0)}`}</span>
                    <span> { textosShow.points || 'Points'}</span>
                  </div>
                  <div className='membership-description'>
                    <span>{ textosShow.gemexchangerate || 'Gem exchange rate'} </span>
                    <span className='membership-gem-discount'>{`${(membership["GemDiscount"] * 100).toFixed(0)}%`}</span>
                    <span> { textosShow.less || 'less'}</span>
                  </div>
                  <div className='membership-description'>
                    <span>{ textosShow.freeticketgamecost || 'Free ticket game cost less than'} </span>
                    <span className='membership-free-ticket'>{` ${defaultData.moneda.signo}${membership["FreeLimitPrice"]}`}</span>
                  </div>
                  { membership['MembershipLevel'] > 0 &&
                    <div className='membership-description'>
                      <span>{ textosShow.duelcashblogaccess || 'DuelCash blog access'} </span>
                    </div>
                  }
                  
                </div>
              </div>
            </div>
        </Card>
      </>
  )
}
