import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Card, Col, Container, Row } from 'reactstrap';
import gemIcon from '../../img/gem.png';
import completed from '../../img/completed.png';

import {FacebookShareButton, TwitterShareButton, WhatsappShareButton, TelegramShareButton,
  FacebookIcon, TwitterIcon, WhatsappIcon, TelegramIcon} from "react-share";  

const shareComponents = {
  Facebook: FacebookShareButton,
  Twitter:  TwitterShareButton,
  Whatsapp: WhatsappShareButton,
  Telegram: TelegramShareButton
};

const shareIcons = {
  Facebook: FacebookIcon,
  Twitter: TwitterIcon,
  Whatsapp: WhatsappIcon,
  Telegram: TelegramIcon
};

export default function SocialCard({ item, textosShow, urlGlobal, getDataUser, getHeadersToSend, getLanActual }) {

  function SocialImg(props) {
    const ShareIcon = shareIcons[props.shareType];
    return <ShareIcon size={120} round={true}></ShareIcon>
  }

  function ShareButton(props) {
    const ShareIcon = shareIcons[props.shareType];
  
    return (
      <button
        onClick={() => onShareClick(props.shareType)}>
          <ShareIcon size={48} round={true}></ShareIcon>
      </button>
      );
  }

  const [socialShareCnt, setSocialShareCnt] = useState(0);
  const [isCompleted, setCompleted] = useState(false);

  useEffect(() => {
    getSocialShareCnt();
  }, [item]);

  useEffect(() => {
    if (item && item.share_target >= 0) {
      setCompleted(item.share_target <= socialShareCnt);
    }
  }, [item, socialShareCnt]);

  const getProgressPercent = useCallback(() => {

    if (!item || item.share_target <= 0) {
      return 0;
    } else {
      return Math.floor(socialShareCnt / item.share_target * 100);
    }
  }, [socialShareCnt, item]);

  const onShareDone = (socialMediaName) => {
    storeSocialTargetLog(socialMediaName);
  }

  const onOpenShareWindow = (socialMediaName) => {

    var shareWindow;

    switch(socialMediaName) {
      case 'Twitter':
        shareWindow = window.open("https://twitter.com/intent/tweet?url=https://www.duelcash.com", '_blank', "popup");
        break;

      case 'Telegram':
        shareWindow = window.open("https://telegram.me/share/url?url=https://www.duelcash.com", '_blank', "popup");
        break;

      case 'Whatsapp':
        shareWindow = window.open("https://web.whatsapp.com/send?text=https://www.duelcash.com", '_blank', "popup");
        break;
    }

    var timer = setInterval(function() { 
      if(shareWindow && shareWindow.closed) {
          clearInterval(timer);
          onShareDone(socialMediaName);
      }
    }, 1000);

    
  }

  const onShareClick = (socialMediaName) => {

    switch(socialMediaName) {
      case 'Facebook':
        window.FB.ui({
          display: 'popup',
          method: 'share',
          href: 'https://www.duelcash.com',
        }, function(response){
          if (response) {
            console.log('==============facebook==============', response);
            onShareDone(socialMediaName);
          }
        });
        break;
      
      case 'Twitter':
      case 'Telegram':
      case 'Whatsapp':
        onOpenShareWindow(socialMediaName);
        break;

        
    }
  }

  const getSocialShareCnt = () => {
    let myHeaders = getHeadersToSend();
    let lan = getLanActual();
    
    fetch(urlGlobal + "api/social", {
      method: "POST",
      body: JSON.stringify({
        action: "getSocialSharedCnt",
        social_target_id: item.id
      }),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        setSocialShareCnt(result.social_shared_cnt);
      }
    });
  }

  const storeSocialTargetLog = () => {
    let myHeaders = getHeadersToSend();
    let lan = getLanActual();
    
    fetch(urlGlobal + "api/social", {
      method: "POST",
      body: JSON.stringify({
        action: "storeSocialTargetLog",
        social_target_id: item.id
      }),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        getDataUser();
        getSocialShareCnt();
      }
    });
  }

  return (
    <>
      <Card className='objective-card'>
        <div className='objective-img-container'>
          <div className='img-bg'></div>
          {/* <img className='social-icon' src={facebookIcon}/> */}
          <div className='social-icon'>
            <SocialImg shareType={item.social_media_name}/>
          </div>
          
        </div>
        <div className='description line-camp-2'>
          <span>
            {textosShow.please_share || 'Please share '} {item.share_target} {textosShow.times_via || 'times via'} {item.social_media_name}
          </span>
        </div>
        <div className='objective-bar-container'>
          <div className='objective-bar'>
            <div className='objective-progress' style={{ width: `${getProgressPercent()}%` }}>
            </div>
            <div className='objective-fr progress-text d-flex-center'>
              {socialShareCnt} / {item ? item.share_target : 0}
            </div>
          </div>
        </div>
        <div className='objective-dont-miss line-camp-1'>
          {textosShow.dont_miss_deal || "Don't miss the deal !!!"}
        </div>
        { isCompleted ?
          <div>
            <img style={{ width: "150px", transform: 'rotate(22.5deg)', marginTop: '-1px', marginLeft: '52px' }} src={completed} />
          </div>
          :
          <div className='soc-container'>
            <div className='soc'>
              <ShareButton
                shareType={item.social_media_name}
              />
            </div>
          </div>
        }
        <button className='btn-objective'>
          <img
            src={gemIcon} />
          <span className='objective-rewards'>
            {item.gemas} {textosShow.gemas || 'Gems'}
          </span>
        </button>
      </Card>
    </>
  )
}