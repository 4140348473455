import $ from 'jquery';
import React, { Component } from 'react';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import SmokeCenter from '../components/SmokeCenter';
import SalasDesktop from "../components/SalasDesktop";
import SalasMobile from '../components/SalasMobile';
import SmokeBox from '../components/SmokeBox';
import moveRemainingTime from '../utils/moveRemaingTime';

class Generaljackpots extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      salaSel: null,
      showDepositNow: false,
      showDepositFree: false,
      referralContests: [],
      generaljackpots: [],
      bonusgamespage: {},
      showSubscribeFree: false
    };
  };

  componentDidMount() {
    this.getReferralContests();
    this.getGeneralJackpots();
    this.getBonusGamePage();
    this.timerJackpots = setInterval(() => {
      this.moveTimeReferralContests();
      this.moveTimeGeneraljackpots();
    }, 1000);
    window.scrollTo(0, 0);
  };
  
  componentWillUnmount() {
    this.timerJackpots = null;
    this.setState = () => {
      return;
    };
  };

  getReferralContests() {
    let clase = this;
    fetch(clase.props.urlGlobal + "api/competenciaReferido", {
      method: "POST",
      body: JSON.stringify({
        action: "getCompetenciareferido",
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.setState({
          referralContests: result.competencias
        });
      }
    });
  };

  getGeneralJackpots() {
    let clase = this;
    fetch(clase.props.urlGlobal + "api/generaljackpot", {
      method: "POST",
      body: JSON.stringify({
        action: "getGeneraljackpot",
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.default && result.default.maintenance === true && clase.props.textosShow){
        alert(clase.props.textosShow['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        clase.setState({
          generaljackpots: result.generaljackpots
        });
      }
    });
  };

  moveTimeReferralContests() {
    let referralContests = this.state.referralContests;
    if (referralContests) {
      for (let i = 0; i < referralContests.length; i++) {
        referralContests[i].tiempo = moveRemainingTime(
          referralContests[i].tiempo, 
          ({dias, horas}) => dias === 0 && horas < 6
        );
      }
    }
    this.setState({
      referralContests: referralContests
    });
  };

  moveTimeGeneraljackpots() {
    let generaljackpots = this.state.generaljackpots;
    if (generaljackpots) {
      for (let i = 0; i < generaljackpots.length; i++) {
        generaljackpots[i].tiempo = moveRemainingTime(
          generaljackpots[i].tiempo, 
          ({dias, horas}) => dias === 0 && horas < 6
        );
        generaljackpots[i].tiempoToStart = moveRemainingTime(
          generaljackpots[i].tiempoToStart, 
          ({dias, horas}) => dias === 0 && horas < 6
        );
      }
    }
    this.setState({
      generaljackpots: generaljackpots
    });
  };

  onClickSignUp(generaljackpot) {
    if (generaljackpot !== null && !generaljackpot.type) {
      this.props.history.replace("/referral-contest/" + generaljackpot.id);
    } else if(generaljackpot !== null && generaljackpot.type){
      if (generaljackpot.costo === 0) {
        let data = {
          action: "getGamePlayed",
          lan: this.props.getLanActual(),
          type: 'generaljackpot'
        }
        let clase = this;

        fetch(clase.props.urlGlobal + "api/user", {
          method: "POST",
          body: JSON.stringify(data),
          headers: clase.props.getHeadersToSend()
        }).then(function(response) {
          return response.json();
        }).then(function(result) {
            if (result.success) {
              /*if (result.played > 5){
                clase.showSubscribeMoreNow(generaljackpot.id);
                return;
              }
              if (!result.allowFreeroll) {
                clase.showSubscribeNow(generaljackpot.id);
                return;
              }*/
              if(generaljackpot.registered) {
                clase.props.history.replace("/general-jackpot/" + generaljackpot.id);
              } else {
                clase.showAceptGeneralJackpot(generaljackpot);
              }                
            }
        });           
      }
      else {
        if(generaljackpot.registered) {
          this.props.history.replace("/general-jackpot/" + generaljackpot.id);
        } else {
          this.showAceptGeneralJackpot(generaljackpot);
        }   
      }
    }
  }

  showSubscribeNow(id) {
    $(".mt_block.step_5").fadeOut(0, function() {
      $(".mt_block.step_4").fadeOut(0, function() {
        $("#subscribe_now_" + id).fadeIn(0);
      });
    });
    this.setState({
      showSubscribeFree: id,
    });
  };

  showSubscribeMoreNow(id) {
    $(".mt_block.step_5").fadeOut(0, function() {
      $(".mt_block.step_4").fadeOut(0, function() {
        $("#subscribe_now_more_" + id).fadeIn(0);
      });
    });
    this.setState({
      showSubscribeFreeMore: id,
    });
  };  

  showAceptGeneralJackpot(generaljackpot) {
    if (this.props.loggedIn) {
      if (generaljackpot !== null && !generaljackpot.type) {
        this.props.referralContestModalElement.current.setState({
          referralContest: generaljackpot
        });
        $(".mt_block.step_4").fadeOut(0);
        $(".mt_block.step_5").fadeOut(0);
        $("#referral_contest_modal_overlay").fadeIn("fast", function () {
          $("#referral_contest_modal").fadeIn("fast", function () {
            document.body.style.overflow = "hidden";
          });
        });
      } else if (generaljackpot !== null && generaljackpot.type) {
        if (generaljackpot.costo > this.props.userData.money) {
          this.showDepositNow(generaljackpot.id);
        } else {
          this.props.generaljackpotModalElement.current.setState({
            generaljackpot: generaljackpot
          });
          $(".mt_block.step_4").fadeOut(0);
          $(".mt_block.step_5").fadeOut(0);
          $("#generaljackpot_modal_overlay").fadeIn("fast", function () {
            $("#generaljackpot_modal").fadeIn("fast", function () {
              document.body.style.overflow = "hidden";
            });
          });
        }
      }
      else {
        alert(this.props.textosShow.estamodalidadnoestaaundisponible || "Esta modalidad no esta aún disponible");
      }
    }
    else {
      alert(this.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta");
    }
  };

  getBonusGamePage() {
    let clase = this;
    clase.setState({
      loading: true
    });
    fetch(clase.props.urlGlobal + "api/page", {
      method: "POST",
      body: JSON.stringify({
        action: "getPage",
        page: "bonusgames",
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.setState({
          bonusgamespage: {
            titulo: result.page.titulo,
            texto: result.page.texto
          }
        });
      }
    });
  };
  
  showFaqModal() {
    $("#faq_modal_overlay").fadeIn("fast", function() {
      $("#faq_modal").fadeIn("fast", function() {
        document.body.style.overflow = "hidden";
      });
    });
  };
    
  showDepositNow(id) {
    $(".mt_block.step_4").fadeOut(0, function() {
      $(".mt_block.step_5").fadeOut(0, function() {
        
      });
    });
    $("#deposit_now_" + id).fadeIn(0);
    this.setState({
      showDepositNow: id
    });
  };
  
  showDepositFree(id) {
    $(".mt_block.step_5").fadeOut(0, function() {
      $(".mt_block.step_4").fadeOut(0, function() {
        $("#deposit_free_" + id).fadeIn(0);
      });
    });
    this.setState({
      showDepositFree: id
    });
  };

  render() {
    return(
      <div>
        <div 
          className='content' 
          id='tournaments'>
            <div className='wrapper'>
              <SmokeCenter></SmokeCenter>
              <div className='page_content'>
                <div className='c_center'>
                  <div className='c_top'>
                    <div className='c_bottom'>
                      <Eagle></Eagle>
                      <Leaves></Leaves>
                      <div className='game_image'>
                        <SmokeBox />
                      </div>
                      <h4>
                      {this.state.bonusgamespage.titulo || this.props.textosShow.bonusgames || 'Bonus Games'}
                      </h4>
                      {
                        this.state.bonusgamespage.texto ? (
                          <div dangerouslySetInnerHTML={{__html: this.state.bonusgamespage.texto}} />
                        ) : (null)
                      }
                      <div className='faq_help'>
                        <button 
                          className='faq_info'
                          onClick={this.showFaqModal.bind(this)}>
                           FAQ
                        </button>
                      </div>
                      <div className='page_block'>
                              <div className='table_list d-none d-xl-block'>
                                <SalasDesktop 
                                  gameComponent={this} 
                                  textosShow={this.props.textosShow} 
                                  defaultData={this.props.defaultData} 
                                  referralContests={this.state.referralContests}
                                  generaljackpots={this.state.generaljackpots}
                                  onPlayGeneralJackpotClick={this.onClickSignUp} />
                              </div>
                              <div className='grid_list d-block d-xl-none'>
                                <SalasMobile
                                  gameComponent={this} 
                                  textosShow={this.props.textosShow} 
                                  defaultData={this.props.defaultData} 
                                  showDepositNow={this.state.showDepositNow}
                                  showDepositFree={this.state.showDepositFree}
                                  referralContests={this.state.referralContests}
                                  generaljackpots={this.state.generaljackpots}
                                  onPlayGeneralJackpotClick={this.onClickSignUp} />
                              </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  };
};

export default Generaljackpots;
