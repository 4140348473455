import React, { useEffect, useRef, useState } from 'react';

import ReactTooltip from 'react-tooltip';
import MembershipUtil from '../utils/membership';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import membershipClockIcon from '../../img/membership/clock.png';

export default function MembershipProgress({ membership, nextMembership, textosShow, urlGlobal, getHeadersToSend}) {

    const [progressBarHover, setProgressBarHover] = useState(false);
    const [expirationBarHover, setExpirationBarHover] = useState(false);
    const [membershipTimer, setMembershipTimer] = useState();


    useEffect(() => {
      getMembershipTimer();
    }, []);

    useEffect(() => {
      const timer = setInterval(() => {
        moveTimeMembership();
      }, 1000);

      return () => {
        clearInterval(timer);
      }
    }, [membershipTimer]);

    const getMembershipTimer = () => {
      fetch(urlGlobal + "api/user", {
        method: "POST",
        body: JSON.stringify({
          action: "getMembershipTimer",
        }),
        headers: getHeadersToSend()
      }).then(function(response) {
        return response.json();
      }).then(function(result) {
        if (result.success) {
          setMembershipTimer(result.membershipTimer);
        }
      });
    }

    const moveTimeMembership = () => {

      if (membershipTimer) {
        let dias = membershipTimer.dias;
        let horas = membershipTimer.horas;
        let minutos = membershipTimer.minutos;
        let segundos = membershipTimer.segundos;
        segundos--;

        if (segundos < 0) {
          if (minutos > 0) {
            segundos = 59;
            minutos--;
          }
          else {
            segundos = 0;
          }
        }
        if (minutos < 0) {
          if (horas > 0) {
            minutos = 59;
            horas--;
          }
          else {
            minutos = 0;
          }
        }
        if (horas < 0) {
          if (dias > 0) {
            horas = 23;
            dias--;
          }
          else {
            horas = 0;
          }
        }
        if (dias < 0) {
          dias = 0;
        }

        const tttt = {
          dias: dias,
          horas: horas,
          minutos: minutos,
          segundos: segundos
        };

        setMembershipTimer(tttt);
      }
    };

    
    const getMembershipPointsText = () => {
      if (!progressBarHover) {
        var strPoints = membership.membershipPoints + 'pts';
        if (nextMembership) {
          strPoints += ` - ${(nextMembership.membershipPoints)}pts`;
        }

        return strPoints;
      } else {
        return MembershipUtil.getMembershipProgressText(membership, nextMembership);
      }
    }

    const getStackPointsText = () => {
      var strStackPoints = 'Stack Points: ' + membership.stackPoints + 'pts';
      return strStackPoints;
    }

    const getMembershipTimerText = () => {
      if (expirationBarHover) {
        return `${membership['getLevelAt']} - ${membership['expirationAt']}`;
      } else {
        return `${membership['currentDiff']} / ${membership['expirationDuration']} days`;
      }
    }


    const getTimerPercent = () => {
      if (membership['currentDiff'] && membership['expirationDuration']) {
        return (membership['currentDiff'] / membership['expirationDuration']) * 100;
      } else {
        return 0;
      }
    }

    return (
      <div className='d-flex-center d-flex-ver'>
        { membership && 
          <div className='membership-progress-bg d-flex-center'>
            <div className='membership-proress-frame d-flex-center'>
              <div className='d-flex-center'>
                <div className='d-flex-ver d-flex-center'>
                  {!nextMembership &&
                    <div
                      className='membership-point'>
                      {getStackPointsText()}
                    </div>
                  }
                  <img
                    className='membership-image'
                    data-for="current-membership"
                    data-tip
                    src={MembershipUtil.getMembershipIcon(membership)} />
                  <ReactTooltip id={'current-membership'}>
                    <span className="mb-5 membership-title">
                      {`${textosShow[membership['membershipSlug']] || membership['membershipName']} ${textosShow.membership || 'Membership'}`}
                    </span>
                  </ReactTooltip>                  
                  <div className='membership-name'>{`${textosShow[membership['membershipSlug']] || membership['membershipName']} ${textosShow.membership || 'Membership'}`}</div>
                </div>
                {nextMembership &&
                  <div className='d-flex-ver d-flex-center'>
                    <div
                        className='membership-point'>
                        {getMembershipPointsText()}
                    </div>
                    <div className='membership-bar'
                      onMouseEnter={() => setProgressBarHover(true)}
                      onMouseLeave={() => setProgressBarHover(false)}>
                      <div className='membership-progress' style={{ width: MembershipUtil.getMembershipProgressWidth(membership, nextMembership) }}>
                      </div>
                      <div
                        className='membership-fr'>
                      </div>
                    </div>
                    <div className='d-flex-center'>
                      {nextMembership &&
                        <div
                          onMouseEnter={() => setExpirationBarHover(true)}
                          onMouseLeave={() => setExpirationBarHover(false)}
                          className='membership-expiration'>
                          <img
                            className='membership-clock-image'
                            src={membershipClockIcon} />

                          <span>
                            {getMembershipTimerText()}
                          </span>
                        </div>
                      }
                    </div>
                  </div>}
                {nextMembership &&
                  <div className='d-flex-ver d-flex-center'>
                    <img
                      className='membership-next-image'
                      data-for="next-membership"
                      data-tip
                      src={nextMembership ? MembershipUtil.getMembershipIcon(nextMembership) : ''} />
                    <ReactTooltip id={'next-membership'}>
                      <span className="mb-5 membership-title">
                        {`${textosShow[nextMembership["membershipSlug"]] || nextMembership["membershipName"]} ${textosShow.membership || 'Membership'}`}
                      </span>
                    </ReactTooltip>
                    <div className='membership-name'>
                      {`${textosShow[nextMembership["membershipSlug"]] || nextMembership["membershipName"]} ${textosShow.membership || 'Membership'}`}
                    </div>
                  </div>}
              </div>
              
            </div>
          </div>
        }
        
      </div>
    )
}