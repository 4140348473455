import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { validate } from 'bitcoin-address-validation';

import $ from 'jquery';

import paypalIcon from '../../img/paypal.png';
import netellerIcon from '../../img/neteller.png';
import bankIcon from '../../img/bankIcon.png';
import bitcoinIcon from '../../img/bitcoin.png';

const validateBtcAddr = validate;
class WithdrawModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.validateMsg = {
      empty: "%name% "+ props.textosShow.notempty,
      email: props.textosShow.emailcorrecto,
      invalidformat: props.textosShow.formatoinvalido
    };

    this.state = {
      payer: "bank",
      bankSuggerenceShowed: false,
      nombre: "",
      apellido: "",
      email: "",
      money: "",
      comision: 0,
      duelcash_comision: 1,
      iban: "",
      bic: "",
      address: "",
      validate: {
        money: {
          valid: true,
          msg: ""
        },
        nombre: {
          valid: true,
          msg: ""
        },
        apellido: {
          valid: true,
          msg: ""
        },
        email: {
          valid: true,
          msg: ""
        },
        iban: {
          valid: true,
          msg: ""
        },
        bic: {
          valid: true,
          msg: ""
        },
        address: {
          valid: true,
          msg: ""
        }
      }
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  };
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  };
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  };
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  };
  
  close() {
    $("#withdraw_modal").fadeOut("fast", function() {
      $("#withdraw_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  };
  
  validateNombre() {
    let valid = true;
    let msg = "";
    if (this.state.payer !== "bitcoin" && this.state.nombre === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, (this.props.textosShow.nombre || "Nombre"));
    }
    let validate = this.state.validate;
    validate.nombre = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  };
  
  validateApellido() {
    let valid = true;
    let msg = "";
    if (this.state.payer !== "bitcoin" && this.state.apellido === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, (this.props.textosShow.apellido || "Apellido"));
    }
    let validate = this.state.validate;
    validate.apellido = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  };
  
  validateEmail() {
    let valid = true;
    let msg = "";
    if (this.state.payer === "paypal") {
      if (this.state.email === "") {
        valid = false;
        msg = this.validateMsg.empty.replace(/%name%/, (this.props.textosShow.email || "Email"));
      }
      else {
        if (!this.isEmail(this.state.email)) {
          valid = false;
          msg = this.validateMsg.email;
        }
      }
    }
    let validate = this.state.validate;
    validate.email = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  };
  
  validateIban() {
    let valid = true;
    let msg = "";
    console.log('validando '+this.state.iban);
    if (this.state.payer === "bank") {
      if (this.state.iban === "") {
        valid = false;
        msg = this.validateMsg.empty.replace(/%name%/, "Account Number or IBAN");
      }
    } else if(this.state.payer === "bitcoin") {
      if (this.state.iban === "") {
        valid = false;
        msg = this.validateMsg.empty.replace(/%name%/, this.props.textosShow.direccionbtc || 'Dirección BTC');
      } else if(!validateBtcAddr(this.state.iban)){
        valid = false;
        msg = this.validateMsg.invalidformat;
      }
    }
    let validate = this.state.validate;
    validate.iban = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  };
  
  validateBic() {
    let valid = true;
    let msg = "";
    if (this.state.payer === "bank") {
      if (this.state.bic === "") {
        valid = false;
        msg = this.validateMsg.empty.replace(/%name%/, "Routing Number or BIC");
      }
    }
    let validate = this.state.validate;
    validate.bic = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  };
  
  validateAddress() {
    let valid = true;
    let msg = "";
    if (this.state.payer === "bank") {
      if (this.state.address === "") {
        valid = false;
        msg = this.validateMsg.empty.replace(/%name%/, (this.props.textosShow.domiciliocompleto || "Domicilio completo"));
      }
    }
    let validate = this.state.validate;
    validate.address = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  };

  isEmail(mail) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(mail);
  };
  
  validateMoney() {
    let money = parseFloat(this.state.money);
    let minimo =  this.props.defaultData[this.state.payer] ? parseFloat(this.props.defaultData[this.state.payer].minimo) : 10;
    let valid = true;
    let msg = "";
    if (this.state.money === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, (this.props.textosShow.retiras || "Retiras"));
    } 
    else if (money <= 0) {
      valid = false;
      msg = this.props.textosShow.debesermayorquecero || "Debe ser mayor que cero";
    }
    else if (money > this.props.userData.withdrawableAmount) {
      valid = false;
      msg = this.props.textosShow.pararetiraresacantidaddebetenerfondossuficientesensucuenta || "Para retirar esa cantidad, debe tener fondos suficientes en su cuenta";
    }
    else if (money < minimo) {
      valid = false;
      msg = (this.props.textosShow.nopuederetirarmenosde || "No puede retirar menos de") + " " + this.props.defaultData.moneda.signo + " " + minimo;
    }
    let comision = 0;
    if (this.state.payer === 'paypal') {
      comision = Math.round((money * 10 / 100) * 100) / 100
    }
    else if (this.state.payer === 'bitcoin') {
      comision = Math.round((money * 2.99 / 100) * 100) / 100;
    }
    let validate = this.state.validate;
    validate.money = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate,
      comision: comision
    });
    return valid;
  };

  sendRetiro() {
    if(this.state.payer === 'paypal' && !this.state.bankSuggerenceShowed) {
      this.setState({
        bankSuggerenceShowed: true
      });
      return;
    }
    this.setState({
      validate: {
        money: {
          valid: true,
          msg: ""
        },
        nombre: {
          valid: true,
          msg: ""
        },
        apellido: {
          valid: true,
          msg: ""
        },
        email: {
          valid: true,
          msg: ""
        },
        iban: {
          valid: true,
          msg: ""
        },
        bic: {
          valid: true,
          msg: ""
        },
        address: {
          valid: true,
          msg: ""
        }
      }
    });
    if (this.validateNombre() && this.validateApellido() && this.validateEmail() && this.validateMoney() &&
            this.validateIban() && this.validateBic() && this.validateAddress()) {
      let myHeaders = this.props.getHeadersToSend();
      let clase = this;
      fetch(this.props.urlGlobal + "api/extract", {
        method: "POST",
        body: JSON.stringify({
          action: "sendExtract",
          nombre: clase.state.nombre,
          apellido: clase.state.apellido,
          email: clase.state.email,
          money: clase.state.money,
          payer: clase.state.payer,
          iban: clase.state.iban,
          bic: clase.state.bic,
          address: clase.state.address,
          lan: clase.props.getLanActual()
        }),
        headers: myHeaders
      }).then(function(response) {
        return response.json();
      }).then(function(result) {
        if (result.success) {
          clase.setState({
            payer: "paypal",
            nombre: "",
            apellido: "",
            email: "",
            iban: "",
            bic: "",
            address: "",
            money: "",
            comision: 0,
            duelcash_comision: 1,
            validate: {
              money: {
                valid: true,
                msg: ""
              },
              nombre: {
                valid: true,
                msg: ""
              },
              apellido: {
                valid: true,
                msg: ""
              },
              email: {
                valid: true,
                msg: ""
              },
              iban: {
                valid: true,
                msg: ""
              },
              bic: {
                valid: true,
                msg: ""
              },
              address: {
                valid: true,
                msg: ""
              }
            }
          });
          alert((clase.props.textosShow.felicitacionessuretiroyaseencuentraenproceso || 'Felicitaciones su retiro ya se encuentra en proceso!') + 
            '\r\n\r\n' + (clase.props.textosShow.selenotificaracuandosehayarealizadoeldeposito || 'Se le notificará cuando se haya realizado el depósito, este proceso puede durar hasta 5 días hábiles.'));
          clase.close();
        }
        else {
          alert(result.msg);
        }
      });
    }
  };

  selPayer(payer) {
    if (payer === "paypal") {
      this.setState({
        payer: payer,
        iban: "",
        bic: "",
        address: "",
        duelcash_comision: 1,
      });
    }
    else if (payer === "neteller") {
      this.setState({
        payer: payer,
        nombre: "",
        apellido: "",
        email: "",
        iban: "",
        bic: "",
        address: ""
      });
    }
    else if (payer === "bank") {
      this.setState({
        payer: payer,
        email: "",
        duelcash_comision: 1,
      });
    }
    else if (payer === "bitcoin") {
      this.setState({
        payer: payer,
        nombre: "",
        apellido: "",
        email: "",
        bic: "",
        address: "",
        duelcash_comision: (Math.round((this.state.money * 2.99 / 100) * 100) / 100)
      });
    }
  };
  
  setNombre(e) {
    this.setState({
      nombre: e.target.value
    });
  };
  
  setApellido(e) {
    this.setState({
      apellido: e.target.value
    });
  };
  
  setEmail(e) {
    this.setState({
      email: e.target.value
    });
  };
  
  setIban(e) {
    this.setState({
      iban: e.target.value
    });
  };
  
  setBic(e) {
    this.setState({
      bic: e.target.value
    });
  };
  
  setAddress(e) {
    this.setState({
      address: e.target.value
    });
  };
  
  setMoney(e) {
    let money = parseFloat(e.target.value);
    if (isNaN(money)) {
      money = 0;
      e.target.value = money;
    }
    let comision = Math.round((money * 10 / 100) * 100) / 100;
    let duelcash_comision = this.state.duelcash_comision;
    if (this.state.payer === 'bitcoin') {
      duelcash_comision = Math.round((money * 2.99 / 100) * 100) / 100;
    }
    this.setState({
      money: money,
      comision: comision,
      duelcash_comision
    });
  };
  
  render() {
    return (
      <div 
        className="modalx-overlay close_mw"
        id="withdraw_modal_overlay">
        <div 
          className='modal_window' 
          id="withdraw_modal">
          <button 
            id="close_modal"
            className="link-button close_mw"
            onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                <h5>
                  {this.props.textosShow.retiro || "Retiro"}
                </h5>
                <div className='radio_r payment_system'>
                  {/* <input 
                    id="ser_5" 
                    name="radio3" 
                    value="three" 
                    type="radio"
                    checked={this.state.payer === "paypal"}
                    onClick={this.selPayer.bind(this, "paypal")} />
                  <label htmlFor="ser_5">
                    <img 
                      src={paypalIcon}
                      alt="PayPal" />
                  </label> */}
                  {/*<input 
                    id="ser_6" 
                    name="radio3" 
                    value="four" 
                    type="radio"
                    checked={this.state.payer === "neteller"}
                    onClick={this.selPayer.bind(this, "neteller")} />
                  <label htmlFor="ser_6">
                    <img 
                      src={netellerIcon}
                      alt="Neteller" />
                  </label>*/}
                  <input 
                    id="ser_7" 
                    name="radio3" 
                    value="five" 
                    type="radio"
                    checked={this.state.payer === "bank"}
                    onClick={this.selPayer.bind(this, "bank")} />
                  <label htmlFor="ser_7">
                    <img 
                      src={bankIcon}
                      alt="Bank" />
                  </label>
                  {/* <input 
                    id="ser_8" 
                    name="radio3" 
                    value="six" 
                    type="radio"
                    checked={this.state.payer === "bitcoin"}
                    onClick={this.selPayer.bind(this, "bitcoin")} />
                  <label htmlFor="ser_8">
                    <img 
                      src={bitcoinIcon}
                      alt="Bitcoin" />
                  </label> */}
                </div>
                {
                  this.state.payer !== "" ? (
                    this.state.payer === "paypal" ? (!this.state.bankSuggerenceShowed ? (
                      <div className="bank_suggerence mt-5">
                        <p>
                        {this.props.textosShow.banksuggerencevspaypal || "Buscas una comisión mas barata, proba retirar por cuenta bancaria, los retiros son mas rápidos"}
                        </p>
                      </div>
                    ) : (
                      <div className='modal_form'>
                        <div className='input_title'>
                          {this.props.textosShow.nombre || "Nombre"}
                        </div>
                        <div className={this.state.validate.nombre.valid ? 'input' : 'input error'}>
                          {
                            !this.state.validate.nombre.valid ? (
                              <div className='error_reason'>
                                {this.state.validate.nombre.msg}
                              </div> 
                            ) : (
                              null
                            )
                          }
                          <input 
                            type="text" 
                            name="nombre"
                            value={this.state.nombre}
                            onChange={this.setNombre.bind(this)} />
                        </div>
                        <div className='input_title'>
                          {this.props.textosShow.apellido || "Apellido"}
                        </div>
                        <div className={this.state.validate.apellido.valid ? 'input' : 'input error'}>
                          {
                            !this.state.validate.apellido.valid ? (
                              <div className='error_reason'>
                                {this.state.validate.apellido.msg}
                              </div> 
                            ) : (
                              null
                            )
                          }
                          <input 
                            type="text"
                            name="apellido"
                            value={this.state.apellido}
                            onChange={this.setApellido.bind(this)} />
                        </div>
                        <div className='input_title'>
                          {this.props.textosShow.emaildepaypal || "Email de PayPal"}
                        </div>
                        <div className={this.state.validate.email.valid ? 'input' : 'input error'}>
                          {
                            !this.state.validate.email.valid ? (
                              <div className='error_reason'>
                                {this.state.validate.email.msg}
                              </div>
                            ) : (
                              null
                            )
                          } 
                          <input 
                            type="text"
                            name="email"
                            value={this.state.email}
                            onChange={this.setEmail.bind(this)} />
                        </div>
                        <div className='input_title'>
                          {this.props.textosShow.saldoentucuenta || "Saldo en tu cuenta"}
                        </div>
                        <div className='input'>
                          <input 
                            type="text" 
                            name="saldo"
                            value={this.props.defaultData.moneda.signo + ' ' + this.props.userData.money}
                            readOnly />
                        </div>
                        <div className='input_title'>
                          {this.props.textosShow.saldoretirable || "Saldo retirable"}
                          <span 
                            data-tip 
                            data-for={`withdrawal-tooltip`} 
                            className="iconInfo">
                              <FontAwesomeIcon icon={faInfoCircle} />
                          </span>
                          <ReactTooltip id={`withdrawal-tooltip`}>
                            <p>
                              {this.props.textosShow.saldoretirableinformacion || "Saldo retirable información"}
                            </p>
                          </ReactTooltip>
                        </div>
                        <div className='input'>
                          <input 
                            type="text" 
                            name="saldoNoRetirable"
                            value={
                              this.props.defaultData.moneda.signo + ' ' + this.props.userData.withdrawableAmount + 
                              ' (' + this.props.defaultData.moneda.signo + ' ' + this.props.userData.noWithdrawableAmount + 
                              ' ' + (this.props.textosShow.blocked || "Blocked") + ')'
                            }
                            readOnly />
                        </div>
                        <div className='input_title'>
                          {this.props.textosShow.retiras || "Retiras"}
                        </div>
                        <div className={this.state.validate.money.valid ? 'input' : 'input error'}>
                          {
                            !this.state.validate.money.valid ? (
                              <div className='error_reason'>
                                {this.state.validate.money.msg}
                              </div>
                            ) : (
                              null
                            )
                          }
                          <input 
                            type="text" 
                            name="money"
                            value={this.state.money}
                            onChange={this.setMoney.bind(this)} />
                        </div>
                        <div className='input_title'>
                          {this.props.textosShow.comisionpaypal || "Comisión PayPal"} (10%)
                        </div>
                        <div className='input'>
                          <input 
                            type="text" 
                            name="comision_paypal"
                            value={this.props.defaultData.moneda.signo + ' ' + this.state.comision}
                            readOnly />
                        </div>
                        <div className='input_title'>
                          {this.props.textosShow.comisionduelcash || "Comisión DuelCash"}
                        </div>
                        <div className='input'>
                          <input 
                            type="text" 
                            name="comision_duelcash"
                            value={this.props.defaultData.moneda.signo + ' ' + this.state.duelcash_comision}
                            readOnly />
                        </div>
                      </div>
                    )) : this.state.payer === "neteller" ? (
                        <div className='modal_form'>
                          <p>
                            {this.props.textosShow.metododepagonodisponible || "Método de pago no disponible"}
                          </p>
                        </div>
                      ) : this.state.payer === "bitcoin" ? (
                        <div className='modal_form'>
                          <h4> 
                            {this.props.textosShow.bitcoinfeeadicional || 'La red bitcoin aplicara un fee adicional'}
                          </h4>
                          <div className='input_title'>
                            {this.props.textosShow.direccionbtc || 'Dirección BTC'}
                          </div>
                          <div className={this.state.validate.iban.valid ? 'input' : 'input error'}>
                            {
                              !this.state.validate.iban.valid ? (
                                <div className='error_reason'>
                                  {this.state.validate.iban.msg}
                                </div>
                              ) : (
                                null
                              )
                            }
                            <input 
                              type="text"
                              name="iban"
                              value={this.state.iban}
                              onChange={this.setIban.bind(this)} />
                          </div>
                          <div className='input_title'>
                            {this.props.textosShow.saldoentucuenta || "Saldo en tu cuenta"}
                          </div>
                          <div className='input'>
                            <input 
                              type="text" 
                              name="saldo"
                              value={this.props.defaultData.moneda.signo + ' ' + this.props.userData.money}
                              readOnly />
                          </div>
                          <div className='input_title'>
                            {this.props.textosShow.saldoretirable || "Saldo retirable"}
                          </div>
                          <div className='input'>
                            <input 
                              type="text" 
                              name="saldo_retirable"
                              value={this.props.defaultData.moneda.signo + ' ' + this.props.userData.withdrawableAmount}
                              readOnly />
                          </div>
                          <div className='input_title'>
                            {this.props.textosShow.retiras || "Retiras"}
                          </div>
                          <div className={this.state.validate.money.valid ? 'input' : 'input error'}>
                            {
                              !this.state.validate.money.valid ? (
                                <div className='error_reason'>
                                  {this.state.validate.money.msg}
                                </div>
                              ) : (
                                null
                              )
                            }
                            <input 
                              type="text" 
                              name="money"
                              value={this.state.money}
                              onChange={this.setMoney.bind(this)} />
                          </div>
                          <div className='input_title'>
                          {this.props.textosShow.comisionduelcash || "Comisión DuelCash"}
                          </div>
                          <div className='input'>
                            <input 
                              type="text" 
                              name="comision_duelcash"
                              value={this.props.defaultData.moneda.signo + ' ' + this.state.duelcash_comision}
                              readOnly />
                          </div>
                        </div>
                      ) : (
                        <div className='modal_form'>
                          <div className='input_title'>
                            {this.props.textosShow.nombre || "Nombre"}
                          </div>
                          <div className={this.state.validate.nombre.valid ? 'input' : 'input error'}>
                            {
                              !this.state.validate.nombre.valid ? (
                                <div className='error_reason'>
                                  {this.state.validate.nombre.msg}
                                </div>
                              ) : (
                                null
                              )
                            }
                            <input 
                              type="text" 
                              name="nombre"
                              value={this.state.nombre}
                              onChange={this.setNombre.bind(this)} />
                          </div>
                          <div className='input_title'>
                            {this.props.textosShow.apellido || "Apellido"}
                          </div>
                          <div className={this.state.validate.apellido.valid ? 'input' : 'input error'}>
                            {
                              !this.state.validate.apellido.valid ? (
                                <div className='error_reason'>
                                  {this.state.validate.apellido.msg}
                                </div>
                              ) : (
                                null
                              )
                            }
                            <input 
                              type="text"
                              name="apellido"
                              value={this.state.apellido}
                              onChange={this.setApellido.bind(this)} />
                          </div>
                          <div className='input_title'>
                            IBAN/SWIFT
                          </div>
                          <div className={this.state.validate.iban.valid ? 'input' : 'input error'}>
                            {
                              !this.state.validate.iban.valid ? (
                                <div className='error_reason'>
                                  {this.state.validate.iban.msg}
                                </div>
                              ) : (
                                null
                              )
                            }
                            <input 
                              type="text"
                              name="iban"
                              value={this.state.iban}
                              onChange={this.setIban.bind(this)} />
                          </div>
                          <div className='input_title'>
                            BIC
                          </div>
                          <div className={this.state.validate.bic.valid ? 'input' : 'input error'}>
                            {
                              !this.state.validate.bic.valid ? (
                                <div className='error_reason'>
                                  {this.state.validate.bic.msg}
                                </div>
                              ) : (
                                null
                              )
                            }
                            <input 
                              type="text"
                              name="bic"
                              value={this.state.bic}
                              onChange={this.setBic.bind(this)} />
                          </div>
                          <div className='input_title'>
                            {this.props.textosShow.domiciliocompleto || "Domicilio completo"}
                          </div>
                          <div className={this.state.validate.address.valid ? 'input' : 'input error'}>
                            {
                              !this.state.validate.address.valid ? (
                                <div className='error_reason'>
                                  {this.state.validate.address.msg}
                                </div>
                              ) : (
                                null
                              )
                            }
                            <input 
                              type="text"
                              name="address"
                              value={this.state.address}
                              onChange={this.setAddress.bind(this)} />
                          </div>
                          <div className='input_title'>
                            {this.props.textosShow.saldoentucuenta || "Saldo en tu cuenta"}
                          </div>
                          <div className='input'>
                            <input 
                              type="text" 
                              name="saldo"
                              value={this.props.defaultData.moneda.signo + ' ' + this.props.userData.money}
                              readOnly />
                          </div>
                          <div className='input_title'>
                            {this.props.textosShow.saldoretirable || "Saldo retirable"}
                          </div>
                          <div className='input'>
                            <input 
                              type="text" 
                              name="saldo_retirable"
                              value={this.props.defaultData.moneda.signo + ' ' + this.props.userData.withdrawableAmount}
                              readOnly />
                          </div>
                          <div className='input_title'>
                            {this.props.textosShow.retiras || "Retiras"}
                          </div>
                          <div className={this.state.validate.money.valid ? 'input' : 'input error'}>
                            {
                              !this.state.validate.money.valid ? (
                                <div className='error_reason'>
                                  {this.state.validate.money.msg}
                                </div>
                              ) : (
                                null
                              )
                            }
                            <input 
                              type="text" 
                              name="money"
                              value={this.state.money}
                              onChange={this.setMoney.bind(this)} />
                          </div>
                          <div className='input_title'>
                            {this.props.textosShow.comisionduelcash || "Comisión DuelCash"}
                          </div>
                          <div className='input'>
                            <input 
                              type="text" 
                              name="comision_duelcash"
                              value={this.props.defaultData.moneda.signo + ' ' + this.state.duelcash_comision}
                              readOnly />
                          </div>
                        </div>
                      )
                  ) : (
                    null
                  )
                }
                {
                  (this.state.payer === "paypal" || this.state.payer === "bank" || this.state.payer === "bitcoin") ? (
                    <div className='modal_button'>
                      <button 
                        className='button button_regular'
                        onClick={this.sendRetiro.bind(this)}>
                        {this.state.payer === "paypal" && !this.state.bankSuggerenceShowed ? (
                          this.props.textosShow.omitir || "omitir"
                        ) : (
                          this.props.textosShow.siguiente || "Siguiente")
                        }
                      </button>
                    </div>
                  ) : (
                    null
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
};

export default WithdrawModal;
  
