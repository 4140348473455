import React, {Component} from 'react';

import $ from 'jquery';

import SmokeCenter from '../components/SmokeCenter';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import FaqRewardsModal from '../components/FaqRewardsModal';

import rewardIcon1 from '../../img/reward_icons/icon_1.png';
import rewardIcon5 from '../../img/reward_icons/icon_5.png';
import rewardIcon10 from '../../img/reward_icons/icon_10.png';
import rewardIcon15 from '../../img/reward_icons/icon_25.png';
import rewardIcon100 from '../../img/reward_icons/icon_100.png';
import rewardIcon200 from '../../img/reward_icons/icon_200.png';

class Rewards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      plans: [],
      gemDiscount: 0,
      faq: null
    };
  };

  componentDidMount() {
    this.getGemDiscount();
    this.getPlans();
  };

  getGemDiscount() {
    let clase = this;
    clase.setState({
      loading: true
    });
    fetch(this.props.urlGlobal + "api/plan", {
      method: "POST",
      body: JSON.stringify({
        action: "getGemDiscount"
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.default && result.default.maintenance === true && clase.props.textosShow){
        alert(clase.props.textosShow['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        clase.setState({
          gemDiscount: result.gemDiscount,
          loading: false
        });
      }
    });
  };

  getPlans() {
    let clase = this;
    clase.setState({
      loading: true
    });
    fetch(this.props.urlGlobal + "api/plan", {
      method: "POST",
      body: JSON.stringify({
        action: "getGemasPlans"
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.setState({
          plans: result.plans,
          loading: false
        });
      }
    });
  };
  
  canjear(dinero, gemas) {
    let clase = this;
    clase.setState({
      loading: true
    });
    fetch(this.props.urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "cambiarGemas",
        gemas: gemas,
        dinero: dinero,
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.props.getDataUser();
        alert(result.msg);
      }
      else {
        alert(result.msg);
      }
      clase.setState({
        loading: false
      });
    });
  };

  showFaqModal() {
    $("#faq_rewards_modal_overlay").fadeIn("fast", function() {
      $("#faq_rewards_modal").fadeIn("fast", function() {
        document.body.style.overflow = "hidden";
      });
    });
  };

  render() { 
    const getRewardIcon = (plan) => {
      if (plan.icon === 1) {
        return rewardIcon1;
      }
      else if (plan.icon === 5) {
        return rewardIcon5;
      }
      else if (plan.icon === 10) {
        return rewardIcon10;
      }
      else if (plan.icon === 15) {
        return rewardIcon15;
      }
      else if (plan.icon === 15) {
        return rewardIcon15;
      }
      else if (plan.icon === 100) {
        return rewardIcon100;
      }
      else if (plan.icon === 200) {
        return rewardIcon200;
      }
    };

    return (
      <>
        <FaqRewardsModal
          urlGlobal={this.props.urlGlobal}
          getLanActual={this.props.getLanActual}
          getHeadersToSend={this.props.getHeadersToSend}></FaqRewardsModal>
        <div 
          className='content' 
          id='rewards'>
          <div className='wrapper'>
            <SmokeCenter />
            <div className='page_content'>
              <div className='c_center'>
                <div className='c_top'>
                  <div className='c_bottom'>
                    <Eagle />
                    <Leaves />
                    <div className='faq_help'>
                      <button 
                        className='faq_info'
                        onClick={this.showFaqModal.bind(this)}>
                          FAQ
                      </button>
                    </div>
                    <h4>
                      {this.props.textosShow.recompensas || "Recompensas"}
                    </h4>
                    <div className='reward_info'>
                      <div className='rew_text'>
                        <b>
                          {this.props.textosShow.miraloquetedarantusgemas || "Mira lo que te darán tus gemas"}
                        </b>
                        <br/>
                        {this.props.textosShow.losarticuloselegiblessedestacanacontinuacion || "Los artículos elegibles se destacan a continuación"}
                        <br/>
                        {this.props.textosShow.sinohayelementosresaltadossiguejugandopormas || "Si no hay elementos resaltados, sigue jugando por más"} <span style={{
                          color: "#fc7a52"
                        }}>
                          {this.props.textosShow.gemas || "Gemas"}
                        </span>!
                      </div>
                      <div className='rew_balance'>
                        <b>
                          {this.props.textosShow.saldoactual || "Saldo actual"}: <span>
                            {this.props.userData.gemas} {this.props.textosShow.gemas || "Gemas"}
                          </span>
                        </b>
                      </div>
                    </div>
                    <div className='rew_items'>
                      {
                        this.state.plans ? (
                          this.state.plans.map((plan) => (
                            <div 
                              className='r_item'
                              key={plan.icon}>
                              <div className='r_title'>
                                <b>
                                  {this.props.defaultData.moneda.signo}{plan.credit}
                                </b>
                                {this.props.textosShow.creditosdejuego || "Créditos de juego"}
                              </div>
                              <div className='r_icon'>
                                <img 
                                  src={getRewardIcon(plan)}
                                  alt="Reward" />
                              </div>
                              <div className='r_price'>
                                {this.props.textosShow.recompensas || "Recompensas"}: <span>
                                  {plan.gems * (1.0 - Number(this.state.gemDiscount))}
                                </span>
                                <br/>
                                <button 
                                  className="button deposit_button"
                                  disabled={this.state.loading || this.props.userData.gemas < plan.gems * (1.0 - Number(this.state.gemDiscount))}
                                  onClick={() => this.canjear(plan.credit, plan.gems)}>
                                  {this.props.textosShow.canjear || "Canjear"}
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          null
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
};

export default Rewards;
