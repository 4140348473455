import React, { Component } from 'react';

import $ from 'jquery';

import apprenticeIcon from '../../img/apprentice.png';
import warriorIcon from '../../img/warrior.png';
import proPlayerIcon from '../../img/pro-player.png';

class PerfilUser extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: null
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  };
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  };
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  };

  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  };
  
  close() {
    $("#profile_modal").fadeOut("fast", function() {
      $("#profile_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  };

  render() {
    return(
      <div 
        className="modalx-overlay close_mw"
        id="profile_modal_overlay">
        <div 
          className='modal_window' 
          id="profile_modal">
          <button 
            id="close_modal"
            className="link-button close_mw"
            onClick={this.close.bind(this)}></button>
          {
            this.state.user ?
              <div className='modal_top'>
                <div className='modal_bottom'>
                  <div className='modal_content'>
                    <div className='profile_avatar'>
                      <div className='status online'>
                        {this.props.textosShow.enlinea || "En línea"}
                      </div>
                      <div className='country'>
                        <img 
                          src={this.state.user.paisFoto}
                          alt='' />
                      </div>
                      <img 
                        src={this.state.user.img}
                        alt={this.state.user.nick} />
                    </div>
                    <h4>
                      {this.state.user.nick}
                    </h4>
                    <div className='personal_info'>
                      <h5>
                        {this.props.textosShow.informacionpersonal || "Información personal"}
                      </h5>
                      <hr/>
                      <div className='infos'>
                        {this.props.textosShow.edad || "EDAD"}: <span>
                          {this.state.user.edad} {this.props.textosShow.anos || "Años"}
                        </span>
                        <br/>
                        {this.props.textosShow.signo || "SIGNO"}: <span>
                          {this.state.user.signo}
                        </span>
                        <br/>
                        {this.props.textosShow.sexo || "SEXO"}: <span>
                          {this.state.user.sexo}
                        </span>
                        <br/>
                        {this.props.textosShow.registro || "REGISTRO"}: <span>
                          {this.state.user.fechaReg}
                        </span>
                      </div>
                      <hr/>
                      <div className='infos'>
                        {this.props.textosShow.totalganado || "TOTAL GANADO"}: <span style={{
                          'color': '#f08d2'
                        }}>
                          {this.props.defaultData.moneda.signo}{this.state.user.money}
                        </span>
                        <br/>
                        {this.props.textosShow.partidasjugadas || "PARTIDAS JUGADAS"}: <span>
                          {this.state.user.cantPartidas}
                        </span>
                      </div>
                    </div>
                    <div className='level_info'>
                      <h5>
                        {this.props.textosShow.nivelporjuego || "NIVEL POR JUEGO"}
                      </h5>
                      <hr/>
                      <div className='games_lev'>
                        {
                          this.state.user.juegos.map(juego =>
                            <div key={juego.foto}>
                              <img 
                                src={juego.foto}
                                alt={juego.slug} />
                              {this.props.textosShow.nivel || "NIVEL"}: <span>
                              {
                                (juego.level <= 2) ? (
                                  <>
                                    <img 
                                      src={apprenticeIcon}
                                      alt={this.props.textosShow.aprendiz || "Aprendiz"}
                                      height="24px"
                                      width="24px" />
                                    {this.props.textosShow.aprendiz || "Aprendiz"}
                                  </>
                                ) : (juego.level <= 4) ? (
                                  <>
                                    <img 
                                      src={warriorIcon}
                                      alt={this.props.textosShow.guerrero || "Guerrero"}
                                      height="24px"
                                      width="24px" />
                                    {this.props.textosShow.guerrero || "Guerrero"}
                                  </>
                                ) : (
                                  <>
                                    <img 
                                      src={proPlayerIcon}
                                      alt={this.props.textosShow.jugadorpro || "Jugador pro"}
                                      height="24px"
                                      width="24px" />
                                    {this.props.textosShow.jugadorpro || "Jugador pro"}
                                  </>
                                )
                              }
                              </span>
                            </div>
                          )
                        }
                      </div>
                      <hr/>
                      <button className="button deposit_button">
                        {this.props.textosShow.compararhabilidades || "Comparar habilidades"}
                      </button>
                    </div>
                  </div>
                </div>
              </div> :
              null
          }
	      </div>
      </div>
    );
  };
};

export default PerfilUser;
