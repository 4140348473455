import React, { Component } from 'react';
import chestIcon from '../../img/mobile-only-modal/chest.png';
import googleIcon from '../../img/mobile-only-modal/icn_google.png';
import appleIcon from '../../img/mobile-only-modal/icn_apple.png';
import dcIcon from '../../img/mobile-only-modal/icn_dc.png';
import gemsIcon from '../../img/mobile-only-modal/icn_gems.png';
import goldIcon from '../../img/mobile-only-modal/icn_gold.png';
import phoneLeftImg from '../../img/mobile-only-modal/phone_l.png';
import phoneRightImg from '../../img/mobile-only-modal/phone_r.png';
import smokeBg from '../../img/mobile-only-modal/smoke_1.png';
import smokeBg2 from '../../img/mobile-only-modal/smoke_2.png';

class NotifyGameMobileOnlyModal extends Component {

    constructor(props) {
        super(props);

        this.keyDown = this.keyDown.bind(this);
        this.close = this.close.bind(this);
    };

    componentDidMount() {
        document.addEventListener("keydown", this.keyDown, false);
    };

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyDown, false);
    };

    keyDown(e) {
        if (e.keyCode === 27) {
            e.preventDefault();
            this.close();
        }
    };

    close() {
        this.props.onClose();
    };

    render() {
        return (
            <div
                className="modalx-overlay close_mw"
                id="notify_game_mobile_only_modal_overlay">
                <div
                    className='modal_window'
                    id="notify_game_mobile_only_modal">
                    <button
                        id="close_modal"
                        className="link-button close_mw"
                        onClick={this.close.bind(this)}></button>
                    <div className='modal_top'>
                        <div className='modal_bottom'>
                            <div className='modal_content mobile_only_content'>
                                <div className='mo_welcome'>
                                    WELCOME GIFT FOR
                                </div>
                                <div className='mo_title'>
                                    MOBILE PLAYERS
                                </div>
                                <div className='mo_chest'>
                                    <img src={chestIcon} />
                                    <div className='btn_promo_code'>
                                        <button>GET PROMO CODE</button>
                                    </div>
                                </div>
                                <div className='mo_qr_scan'>
                                    <div className='text'>
                                        Scan the QR code below, install the app <br /> and enter the promo code in the bonus field
                                    </div>
                                    <div className='row'>
                                        <div className='col-6 p-0'>
                                            <div className='qr_item'>
                                                <img src="" />
                                            </div> 
                                            <div className='brand_store_info'>
                                                <div className='brand_logo'>
                                                    <img src={appleIcon} />
                                                </div>
                                                <div className='store_name'>
                                                    On <br /> App Store (Not Available)
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 p-0'>
                                            <div className='qr_item'>
                                                <img src="" />
                                            </div> 
                                            <div className='brand_store_info'>
                                                <div className='brand_logo'>
                                                    <img src={googleIcon} />
                                                </div>
                                                <div className='store_name'>
                                                    On <br /> Google Play
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mo_prize'>
                                    <div className='pt-1'>YOU WILL GET</div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <img src={dcIcon} />
                                            5
                                        </div>
                                        <div className='col-4'>
                                            <img src={gemsIcon} />
                                            5000
                                        </div>
                                        <div className='col-4'>
                                            <img src={goldIcon} />
                                            2500
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mo_bg_left_bottom'></div>
                            <div className='mo_bg_right_bottom'></div>
                            {/* <img src={phoneLeftImg} className='mo_bg_left_bottom' /> */}
                            {/* <img src={phoneRightImg} className='mo_bg_right_bottom' /> */}
                            <img src={smokeBg} className='mo_smoke_bg_bottom' />
                            <img src={smokeBg2} className='mo_smoke_bg_bottom' />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

export default NotifyGameMobileOnlyModal;
