import React, { Component } from 'react';

class Sparks extends Component {
  
  render() {
    return (
      <div className="sparks">
        <div className="spark_1"></div>
        <div className="spark_2"></div>
        <div className="spark_3"></div>
        <div className="spark_4 spark-big"></div>
        <div className="spark_5 spark-big"></div>
      </div>
    );
  }
  
};

export default Sparks;