import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';

import SmokeCenter from '../components/SmokeCenter';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';

class RecPass extends Component {

  constructor(props) {
    super(props);

    this.validateMsg = {
      empty: "%name% "+ props.textosShow.notempty
    };

    this.state = {
      validate: {
        pass: {
          valid: true,
          msg: ""
        }
      },
      password: "",
      email: ""
    };

    this.token = this.props.match.params.token;

    this.urlGlobal = props.urlGlobal;
  }
  
  setPassword(e) {
    e.preventDefault();
    this.setState({
      password: e.target.value
    });
  }

  validatePass() {
    let valid = true;
    let msg = "";
    if (this.state.password === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, this.props.textosShow.contrasena || "Contraseña");
    }
    let validate = this.state.validate;
    validate.pass = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  }

  RecPass(e) {
    e.preventDefault();
    let clase = this;
    if (clase.validatePass()) {
      let myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Token", "Bearer " + clase.token);
      let form = new FormData(document.getElementById('setpassForm'));
      fetch(clase.urlGlobal + "api/login", {
        method: "POST",
        body: form,
        headers: myHeaders
      }).then(function(response) {
        return response.json();
      }).then(function(result) {
        if (result.success) {
          alert(result.msg);
          window.location.href = "/";
        }
        else {
          alert(result.msg);
        }
      });
    }
  }

  render() {
    if (this.props.loggedIn) {
      return (<Redirect to={'/'} />);
    }

    return(
      <div className='content' 
           id='profile'>
        <div className='wrapper'>
          <SmokeCenter></SmokeCenter>
          <div className='page_content'>
            <div className='c_center'>
              <div className='c_top'>
                <div className='c_bottom'>
                  <Eagle></Eagle>
                  <Leaves></Leaves>
                  <h4>
                    {this.props.textosShow.resetearclave || "Resetear contraseña"}
                  </h4>
                  <div className='page_block'>
                    <form id="setpassForm" 
                          encType="multipart/form-data">
                      <input type="hidden" 
                             name="action" 
                             value="SetPass" />
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <h5>
                                {this.props.textosShow.introduzcaclave || "Introduzca contraseña"}:
                              </h5>
                            </td>
                            <td>
                              <div className='table_content'>
                                <div className={this.state.validate.pass.valid ? 'input' : 'input error'}>
                                  {
                                    !this.state.validate.pass.valid ?
                                      <div className='error_reason'>
                                        {this.state.validate.pass.msg}
                                      </div> :
                                      null
                                  }
                                  <input type="password" 
                                         name="pass"
                                         onChange={this.setPassword.bind(this)} />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <center>
                                <button className='button button_regular'
                                        onClick={this.RecPass.bind(this)}>
                                  {this.props.textosShow.aceptar || "Aceptar"}:
                                </button>
                              </center>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecPass;
