import React, { Component } from 'react';

import $ from 'jquery';

class ChallengeModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userId: null,
      user: null,
      games: [],
      selectedGame: null,
      selectedSala: null
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);

    this.urlGlobal = props.urlGlobal;
  }
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }

  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }
  
  close() {
    $("#challenger_modal").fadeOut("fast", function() {
      $("#challenger_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  }
  
  selectGame(gameSlug) {
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(this.urlGlobal + "api/juego", {
      method: "POST",
      body: JSON.stringify({
        action: "getJuego",
        juego: gameSlug,
        lan: lan
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        let juego = result.juego;
        clase.setState({
          selectedGame: juego,
          selectedSala: juego.salas.filter(sala => sala.modo === "Vs Simple")[0] || null
        });
      }
    });
  };
  
  selectSala(sala) {
    this.setState({
      selectedSala: sala
    });
  };
  
  challenge() {
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(clase.urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "desafiar",
        usuario: clase.state.userId,
        sala: clase.state.selectedSala.id,
        lan: lan
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.close();
        alert(result.msg);
      }
      else {
        alert(result.msg);
      }
    });
  };

  render() {
    const isSalaSelected = (salaId) => {
      return (salaId === this.state.selectedSala.id) ? "checked" : "";
    };
    
    return(
      <div className="modalx-overlay close_mw"
           id="challenger_modal_overlay">
        <div className='modal_window' 
             id="challenger_modal">
          <button id="close_modal"
                  className="link-button close_mw"
                  onClick={this.close.bind(this)}></button>
          {
            this.state.user ?
              <div className='modal_top'>
                <div className='modal_bottom'>
                  <div className='modal_content'>
                    <div className='profile_avatar'>
                      <div className='status online'>
                        Online
                      </div>
                      <div className='country'>
                        <img src={this.state.user.paisFoto}
                             alt="" />
                      </div>
                      <img src={this.state.user.img}
                           alt={this.state.user.nick} />
                    </div>
                    <h4>
                      {this.state.user.nick}
                    </h4>
                    <div className='game_selector'>
                      <h6>
                        {this.props.textosShow.seleccionarjuego || "Seleccionar juego"}
                      </h6>
                      <div className='game_sel'>
                        {
                          this.state.games.map(game =>
                            <div className='gs_item'
                                 key={game.slug}
                                 onClick={() => this.selectGame(game.slug)}>
                              <img src={game.logo} 
                                   alt={game.slug} />
                            </div>
                          )
                        }
                      </div>
                    </div>
                    {
                      this.state.selectedGame ?
                        <div className='entry_cost'>
                          <h6>
                            {this.props.textosShow.seleccionarcostodeentrada || "Seleccionar costo de entrada"} <span>- 1vs1</span>
                          </h6>
                          <div className='radio_r'>
                            {
                              this.state.selectedGame.salas
                                .filter(sala => sala.modo === "Vs Simple")
                                .map(sala =>
                                <span key={sala.id}
                                      onClick={() => this.selectSala(sala)}>
                                  <input id={"ser_" + sala.id}
                                         name="radio2" 
                                         type="radio"
                                         checked={isSalaSelected(sala.id)}
                                         readOnly />
                                  <label htmlFor="ser_1">
                                    {this.props.defaultData.moneda.signo}{sala.costo}
                                  </label>
                                </span>
                              )
                            }
                          </div>
                        </div> :
                        null
                    }
                    {
                      this.state.selectedSala ?
                        <div>
                          <div className='prize_c'>
                            <h6>
                              {this.props.textosShow.premio || "Premio"}
                            </h6>
                            <span>
                              {this.props.defaultData.moneda.signo}{this.state.selectedSala.premio}
                            </span>
                          </div>
                          <div className="activate_buttons">
                            <button className="button button_regular"
                                    onClick={this.challenge.bind(this)}>
                              {this.props.textosShow.desafiarahora || "Desafiar ahora"}
                            </button>
                          </div>
                        </div> :
                        null
                    }
                  </div>
                </div>
              </div> :
              null
          }
	</div>
      </div>
    );
  }
}

export default ChallengeModal;