import React, { Component } from 'react';

import facebookIcon from '../../img/facebook_icon.png';
import youtubeIcon from '../../img/youtube_icon.png';
import instagramIcon from '../../img/instagram_icon.png';

class Social extends Component {
  
  render() {
    return null;
    return (
      <div className='soc_panel'>
        <a 
          href="https://www.facebook.com/duelcash"
          target="_blank"
          rel="noreferrer">
          <img src={facebookIcon}
               alt="Facebook" />
        </a>
        <a 
          href="https://www.youtube.com/channel/UCYbbZE-I8i65SPBGVblLylQ"
          target="_blank"
          rel="noreferrer">
          <img src={youtubeIcon}
               alt="Youtube"/>
        </a>
        <a 
          href="https://www.instagram.com/duelcash/"
          target="_blank"
          rel="noreferrer">
          <img src={instagramIcon}
               alt="Instagram" />
        </a>
      </div>
    );
  }
  
};

export default Social;