const moveRemainingTime = (tiempo, highlight = false) => {
  let dias = tiempo.dias;
  let horas = tiempo.horas;
  let minutos = tiempo.minutos;
  let segundos = tiempo.segundos;
  let timeRemain = tiempo.timeRemain;
  timeRemain && timeRemain--;
  segundos--;
  if (segundos < 0) {
    if (minutos > 0) {
      segundos = 59;
      minutos--;
    }
    else {
      segundos = 0;
    }
  }
  if (minutos < 0) {
    if (horas > 0) {
      minutos = 59;
      horas--;
    }
    else {
      minutos = 0;
    }
  }
  if (horas < 0) {
    if (dias > 0) {
      horas = 23;
      dias--;
    }
    else {
      horas = 0;
    }
  }
  if (dias < 0) {
    dias = 0;
  }
  return {
    highlight: highlight && highlight({ dias, horas, minutos, segundos }),
    dias,
    horas,
    minutos,
    segundos,
    timeRemain
  };
}

export default moveRemainingTime;