import React from 'react';
import { faHourglassEnd, faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import getTipoTorneo from '../utils/getTipoTorneo';
import freeTicketIcon from '../../img/free-200.png';
import mobileIcon from '../../img/icon_mobile.png';

const FreerollsDesktop = ({ gameComponent, textosShow, defaultData, freerolls, onPlayClick, openPopupOnlyPlayOnMobile }) => {
  const getCustomTitleClass = (sala, index) => {
    if (sala && sala.custom_title && index === 0) {
      return 'custom_title';
    }
    else {
      return '';
    }
  };

  const getCustomTitleStyle = (sala, index) => {
    if (sala && sala.custom_title && sala.custom_title_color && index === 0) {
      return sala.custom_title_color;
    }
    else {
      return '';
    }
  };

  const getButtonTitle = (sala) => {
    if (sala.showReentry) {
      return (textosShow.reentrar || "Reentrar");
    }
    else {
        return (textosShow.entrar || "Entrar");
    }
  };

  const getEntryCosto = (sala) => {
    return sala.costo;
  };

  const isValid = (sala) => {
    return !(sala.modo === "Jackpots" && sala.tiempo.dias === 0 && sala.tiempo.horas === 0 && sala.tiempo.minutos === 0 && sala.tiempo.segundos === 0);
  };

  return (
    <table>
      <thead>
        <tr className='subtitle'>
          <td>
            <span style={{
              whiteSpace: 'nowrap',
            }}>
              {textosShow.costedeentradamin || "Coste de entrada"}
            </span>
          </td>
          <td>
            <span style={{
              whiteSpace: 'nowrap',
            }}>
              {textosShow.premioalganador || "Premio al ganador"}
            </span>
          </td>
          <td>
            <span style={{
              whiteSpace: 'nowrap',
            }}>
              {textosShow.juego || "Juego"}
            </span>
          </td>
          <td>
            <span style={{
              whiteSpace: 'nowrap',
            }}>
              {textosShow.tipodetorneo || "Tipo de torneo"}
            </span>
          </td>
          <td colSpan="2"></td>
        </tr>
      </thead>
      <tbody>
        {
          freerolls.map((s) => {
            let juego = s.juego;
            let sala = juego.salas.find((x) => x.id === s.id);

            return (sala && isValid(sala) && (
              <tr
                className={getTipoTorneo(textosShow, juego, sala).classes.join(" ") + `${sala.onlyOnMobile === 1 ? ' mobile-only' : ''}`}
                key={sala.id}>
                <td 
                  className='entry_pay p-2' 
                  style={{zIndex:"1000"}}>
                  <div className='d_flex'>
                    <span className={(juego.freeJuegoSalaId === sala.id || sala.showReentry) ? 'strike' : ''}>
                      {defaultData.moneda.signo} {getEntryCosto(sala)}
                    </span>
                    {
                      (juego.freeJuegoSalaId === sala.id && !juego.throughPromoCode) ? (
                        <img 
                          className='free_icon'
                          src={freeTicketIcon}
                          width='50px'
                          alt="" />
                      ) : (
                        null
                      )
                    }
                    { 
                      (sala.showReentry) && (
                        <span>
                          {defaultData.moneda.signo} {sala.costoReentry}
                        </span>
                      )
                    }
                    {
                      (juego.freeJuegoSalaId === sala.id && juego.throughPromoCode) ? (
                        <div>
                          <img 
                            data-tip data-for="promoCodeTooltip" 
                            className='free_icon' 
                            src={freeTicketIcon}
                            width='50px'
                            alt="" />
                            <ReactTooltip id="promoCodeTooltip">
                              <p>
                                {textosShow.thisticketwasredeemedinthepromocodeticket || 'This ticket was redeemed in the promo code ticket'}
                              </p>
                            </ReactTooltip>
                        </div>
                      ) : (
                        null
                      )
                    }
                  </div>
                </td>
                <td className='prize_td p-2'>
                  <span className={`sign_length_${defaultData.moneda.signo.length}`}>
                    <p>
                      {defaultData.moneda.signo}
                    </p>{sala.premioTotal}
                  </span>
                  <div className='tour_add ic_close'>
                    +{sala.gemas}
                  </div>
                </td>
                <td>
                  <img 
                    src={juego.logo}
                    alt={juego.slug}
                    height="150px" />
                </td>
                <td className='tour_info p-2'>
                  <span>
                    <b>
                      {
                        getTipoTorneo(textosShow, juego, sala).data.map((data, index) => (
                          <span 
                            key={data.id} 
                            className={getCustomTitleClass(sala, index)} 
                            style={{color: getCustomTitleStyle(sala, index)}}>
                            {index > 0 ? " / " : ""}
                            {data.title}
                            {
                              data.icon ? (
                                <span 
                                  data-tip 
                                  data-for={data.id} 
                                  className={`${data.iconContainerClass} pl-1`}>
                                  {data.icon}
                                </span>
                              ) : (
                                null
                              )
                            }
                            {
                              data.description ? (
                                <ReactTooltip id={data.id}>
                                  <p>
                                    {data.description}
                                  </p>
                                </ReactTooltip>
                              ) : (
                                null
                              )
                            }
                          </span>
                        ))
                      }
                    </b>
                    {(sala.cantJugador === -1) ? '∞' : sala.cantJugador}-{textosShow.jugadores || "Jugadores"}
                    <br />
                    <span className='more_info'>
                      {textosShow.masinfo || "Más info"}
                    </span>
                  </span>
                </td>
                <td className="tour_info reamaining p-2">
                  {
                    (sala.onlyOnMobile === 1) ? (
                      <div className="mobile-icon-box">
                        <div className="mobile-icon">
                          <img src={mobileIcon} />
                        </div>
                        <div className="mobile-label">
                          <span>PLAY</span>
                          <br />
                          <span>ON MOBILE</span>
                        </div>
                      </div>
                    ) : (
                      null
                    )
                  }

                  {
                    (sala.modo === "Jackpots") && sala.onlyOnMobile === 0 && (
                      <span>
                        {textosShow.tiemporestante || "Tiempo restante"}
                        <br />
                        {
                          sala.tiempo.highlight ? (
                            <span className="iconTimeRemaining pr-1">
                              <FontAwesomeIcon 
                                icon={faHourglassEnd} 
                                size="lg" />
                            </span>
                          ) : (
                            <span className="iconTimeRemaining pr-1">
                              <FontAwesomeIcon 
                                icon={faHourglassHalf} 
                                size="lg" />
                            </span>
                          )
                        }
                        <span className="remaining_time">
                          {sala.tiempo.dias}d {sala.tiempo.horas}:{sala.tiempo.minutos}:{sala.tiempo.segundos}
                        </span>
                      </span>
                    )
                  }
                </td>
                {
                  (sala.onlyOnMobile === 1) ? (
                    <td className='keep_training p-2' onClick={() => openPopupOnlyPlayOnMobile()}>
                      <div
                        className='mt_block step_4'
                        id={'deposit_now_' + sala.id}>
                        <div className='step'></div>
                        {textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir"}
                        <div className='notif_buttons'>
                          <button className="button green_button">
                            <Link to="/deposit">
                              {textosShow.depositarahora || "Depositar ahora"}
                            </Link>
                          </button>
                        </div>
                      </div>
                      <div
                        className='mt_block step_5'
                        id={'deposit_free_' + sala.id}>
                        <div className='step'></div>
                        {textosShow.necesitasdepositarparajugaraljuegogratis || "Necesitas depositar para jugar al juego gratis"}
                        <div className='notif_buttons'>
                          <button className="button green_button">
                            <Link to="/deposit">
                              {textosShow.depositarahora || "Depositar ahora"}
                            </Link>
                          </button>
                        </div>
                      </div>

                    {/*
                      <div
                        className='mt_block step_5'
                        id={'subscribe_now_' + sala.id}>
                        <div className='step'></div>
                        {textosShow.sololasusuariospremiumpuedenparticiparentorneosfreeroll || "Only premium users can enter freeroll tournaments."}
                        <div className='notif_buttons'>
                          <button className="button green_button">
                            <Link to="/subscription">
                              {textosShow.suscribirarahora || "Suscribir"}
                            </Link>
                          </button>
                        </div>
                      </div>

                      <div
                        className='mt_block step_5'
                        id={'subscribe_now_more_' + sala.id}>
                        <div className='step'></div>
                        {textosShow.hasllegadoallimite || "You have reached the limit of 5 free games per day, upgrade your account to continue playing."}
                        <div className='notif_buttons'>
                          <button className="button green_button">
                            <Link to="/subscription">
                              {textosShow.suscribirarahora || "Suscribir"}
                            </Link>
                          </button>
                        </div>
                      </div> 
                  */}                 
                      <div className="lock-chain"></div>
                      <button
                        className="button deposit_button enter_button">
                        {getButtonTitle(sala)}
                      </button>
                    </td>
                  ) : (
                    <td className='keep_training p-2'>
                      <div
                        className='mt_block step_4'
                        id={'deposit_now_' + sala.id}>
                        <div className='step'></div>
                        {textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir"}
                        <div className='notif_buttons'>
                          <button className="button green_button">
                            <Link to="/deposit">
                              {textosShow.depositarahora || "Depositar ahora"}
                            </Link>
                          </button>
                        </div>
                      </div>
                      <div
                        className='mt_block step_5'
                        id={'deposit_free_' + sala.id}>
                        <div className='step'></div>
                        {textosShow.necesitasdepositarparajugaraljuegogratis || "Necesitas depositar para jugar al juego gratis"}
                        <div className='notif_buttons'>
                          <button className="button green_button">
                            <Link to="/deposit">
                              {textosShow.depositarahora || "Depositar ahora"}
                            </Link>
                          </button>
                        </div>
                      </div>

                    {/*
                      <div
                        className='mt_block step_5'
                        id={'subscribe_now_' + sala.id}>
                        <div className='step'></div>
                        {textosShow.sololasusuariospremiumpuedenparticiparentorneosfreeroll || "Only premium users can enter freeroll tournaments."}
                        <div className='notif_buttons'>
                          <button className="button green_button">
                            <Link to="/subscription">
                              {textosShow.suscribirarahora || "Suscribir"}
                            </Link>
                          </button>
                        </div>
                      </div>

                      <div
                        className='mt_block step_5'
                        id={'subscribe_now_more_' + sala.id}>
                        <div className='step'></div>
                        {textosShow.hasllegadoallimite || "You have reached the limit of 5 free games per day, upgrade your account to continue playing."}
                        <div className='notif_buttons'>
                          <button className="button green_button">
                            <Link to="/subscription">
                              {textosShow.suscribirarahora || "Suscribir"}
                            </Link>
                          </button>
                        </div>
                      </div>          
                  */}        

                      <button
                        className="button deposit_button enter_button"
                        onClick={() => onPlayClick(juego, sala)}>
                        {getButtonTitle(sala)}
                      </button>
                    </td>
                  )
                }
                <div className='bg-padlock freerolls'></div>
              </tr>
            ))
          })
        }
      </tbody>
    </table>
  );
};

export default FreerollsDesktop;
