import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SmokeCenter from '../components/SmokeCenter';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import FechaSel from '../components/FechaSel';

class Perfil extends Component {

  constructor(props) {
    super(props);

    this.validateMsg = {
      empty: "%name% " + props.textosShow.notempty,
      passEqual: props.textosShow.debeescribirlamismaclave,
      email: props.textosShow.emailcorrecto
    };

    this.state = {
      redirectToReferrer: false,
      juegoFavorito: null,
      likeTo: null,
      juegoPor: null,
      signo: null,
      validate: {
        email: {
          valid: true,
          msg: ""
        },
        nick: {
          valid: true,
          msg: ""
        },
        pass: {
          valid: true,
          msg: ""
        },
        passNew: {
          valid: true,
          msg: ""
        },
        passRepit: {
          valid: true,
          msg: ""
        }
      },
      userData: null,
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    };

    this.urlGlobal = props.urlGlobal;
  }

  componentDidMount() {
    if (this.props.loggedIn) {
      let myHeaders = this.props.getHeadersToSend();
      let clase = this;
      fetch(this.urlGlobal + "api/user", {
        method: "POST",
        body: JSON.stringify({
          action: "getUser",
          lan: clase.props.getLanActual()
        }),
        headers: myHeaders
      }).then(function (response) {
        return response.json();
      }).then(function (result) {
        if (result.success) {
          let user = result.user;
          clase.setState({
            userData: user
          });
        }
      });
      this.getDataQuest(this.urlGlobal + "api/juegoFavorito", { action: "getData" }, "juegoFavorito");
      this.getDataQuest(this.urlGlobal + "api/likeTo", { action: "getData" }, "likeTo");
      this.getDataQuest(this.urlGlobal + "api/juegoPor", { action: "getData" }, "juegoPor");
      this.getDataQuest(this.urlGlobal + "api/signo", { action: "getData" }, "signo");
    }
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  getDataQuest(url, data, st) {
    let myHeaders = this.props.getHeadersToSend();
    let clase = this;
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        switch (st) {
          case "juegoFavorito":
            clase.setState({
              juegoFavorito: result.items
            });
            break;
          case "likeTo":
            clase.setState({
              likeTo: result.items
            });
            break;
          case "juegoPor":
            clase.setState({
              juegoPor: result.items
            });
            break;
          case "signo":
            clase.setState({
              signo: result.items
            });
            break;
          default:
            break;
        }
      }
    });
  }

  validateEmail() {
    let valid = true;
    let msg = "";
    if (this.state.userData.email === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, (this.props.textosShow.email || "Email"));
    }
    else {
      if (!this.isEmail(this.state.userData.email)) {
        valid = false;
        msg = this.validateMsg.email;
      }
    }
    let validate = this.state.validate;
    validate.email = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  }

  validateNick() {
    let valid = true;
    let msg = "";
    if (this.state.userData.name === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, (this.props.textosShow.nombredeusuario || "Nombre de usuario"));
    }
    let validate = this.state.validate;
    validate.nick = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  }

  validatePass() {
    let valid = true;
    let msg = "";
    if (this.state.oldPassword === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, (this.props.textosShow.contraseña || "Contraseña"));
    }
    let validate = this.state.validate;
    validate.pass = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  }

  validatePassNew() {
    let valid = true;
    let msg = "";
    if (this.state.newPassword === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, (this.props.textosShow.contraseña || "Contraseña"));
    }
    let validate = this.state.validate;
    validate.passNew = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  }

  validatePassRepit() {
    let valid = true;
    let msg = "";
    if (this.state.confirmNewPassword === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, (this.props.textosShow.contraseña || "Contraseña"));
    }
    else {
      if (this.state.confirmNewPassword !== this.state.newPassword) {
        valid = false;
        msg = this.validateMsg.passEqual;
      }
    }
    let validate = this.state.validate;
    validate.passRepit = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  }

  isEmail(mail) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(mail);
  }

  Perfil() {
    const clase = this;
    let validate = this.state.validate;
    validate.email = {
      valid: true,
      msg: ""
    };
    validate.nick = {
      valid: true,
      msg: ""
    };
    this.setState({
      validate: validate
    });
    if (this.validateEmail() && this.validateNick()) {
      let myHeaders = this.props.getHeadersToSend();
      let formData = new FormData();
      formData.append("action", "Perfil");
      formData.append("ano", this.state.userData.fechaNac.ano);
      formData.append("mes", this.state.userData.fechaNac.mes);
      formData.append("dia", this.state.userData.fechaNac.dia);
      formData.append("sexo", this.state.userData.sexo);
      formData.append("email", this.state.userData.email);
      formData.append("nick", this.state.userData.name);
      formData.append("optIn1", this.state.userData.optIn1);
      fetch(this.urlGlobal + "api/user", {
        method: "POST",
        body: formData,
        headers: myHeaders
      }).then(function (response) {
        return response.json();
      }).then(function (result) {
        alert(result.msg);
        if(result.success){
          clase.props.getDataUser && clase.props.getDataUser();
        }
      });
    }
  }

  ChangePass() {
    let validate = this.state.validate;
    validate.pass = {
      valid: true,
      msg: ""
    };
    validate.passNew = {
      valid: true,
      msg: ""
    };
    validate.passRepit = {
      valid: true,
      msg: ""
    };
    this.setState({
      validate: validate
    });
    if (this.validatePass() && this.validatePassNew() && this.validatePassRepit()) {
      let myHeaders = this.props.getHeadersToSend();
      let clase = this;
      let formData = new FormData();
      formData.append("action", "ChangePass");
      formData.append("pass", clase.state.oldPassword);
      formData.append("passNew", clase.state.newPassword);
      formData.append("passRepit", clase.state.confirmNewPassword);
      fetch(this.urlGlobal + "api/user", {
        method: "POST",
        body: formData,
        headers: myHeaders
      }).then(function (response) {
        return response.json();
      }).then(function (result) {
        alert(result.msg);
        clase.setState({
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: ""
        });
      });
    }
  }

  Info() {
    let myHeaders = this.props.getHeadersToSend();
    let formData = new FormData();
    formData.append("action", "Info");
    formData.append("juegoFavorito", this.state.userData.juegoFavorito);
    formData.append("likeTo", this.state.userData.likeTo);
    formData.append("juegoPor", this.state.userData.juegoPor);
    formData.append("signo", this.state.userData.signo);
    fetch(this.urlGlobal + "api/user", {
      method: "POST",
      body: formData,
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      alert(result.msg);
    });
  }

  setFechaNac(fechaNac) {
    let userData = this.state.userData;
    userData.fechaNac = fechaNac;
    this.setState({
      userData: userData
    });
  }

  setSexo(sexo) {
    let userData = this.state.userData;
    userData.sexo = sexo;
    this.setState({
      userData: userData
    });
  }

  setEmail(e) {
    let userData = this.state.userData;
    userData.email = e.target.value;
    this.setState({
      userData: userData
    });
  }

  setNick(e) {
    let userData = this.state.userData;
    userData.name = e.target.value;
    this.setState({
      userData: userData
    });
  }

  setCheckOptIn1(e) {
    let userData = this.state.userData;
    userData.optIn1 = e.target.checked;
    this.setState({
      userData: userData
    });
  }

  setJuegoFavorito(jf) {
    let userData = this.state.userData;
    userData.juegoFavorito = jf;
    this.setState({
      userData: userData
    });
  }

  setTambienMeGusta(lt) {
    let userData = this.state.userData;
    userData.likeTo = lt;
    this.setState({
      userData: userData
    });
  }

  setJuegoPor(jp) {
    let userData = this.state.userData;
    userData.juegoPor = jp;
    this.setState({
      userData: userData
    });
  }

  setSignoZodiaco(s) {
    let userData = this.state.userData;
    userData.signo = s;
    this.setState({
      userData: userData
    });
  }

  setOldPassword(e) {
    this.setState({
      oldPassword: e.target.value
    });
  }

  setNewPassword(e) {
    this.setState({
      newPassword: e.target.value
    });
  }

  setConfirmNewPassword(e) {
    this.setState({
      confirmNewPassword: e.target.value
    });
  }

  render() {
    if (!this.props.loggedIn) {
      return (<Redirect to={'/'} />);
    }

    let juegoFavoritos = this.state.juegoFavorito;
    let likeTos = this.state.likeTo;
    let juegoPors = this.state.juegoPor;
    let signos = this.state.signo;

    return (
      <div className='content'
        id='profile'>
        <div className='wrapper'>
          <SmokeCenter></SmokeCenter>
          <div className='page_content'>
            <div className='c_center'>
              <div className='c_top'>
                <div className='c_bottom'>
                  <Eagle></Eagle>
                  <Leaves></Leaves>
                  <h4>
                    {this.props.textosShow.miperfil || "Mi perfil"}
                  </h4>
                  <div style={{ textAlign: "right" }}><a className="btn btn-primary" style={{ letterSpacing: "1px", color: "black", background: "#b08250", border: "none", fontFamily: "Albertus" }} href="/profile">View Profile</a></div>
                  {
                    (this.state.userData !== null && juegoFavoritos !== null && likeTos !== null &&
                      juegoPors !== null && signos !== null) ?
                      <div className='page_block'>
                        <div className="tabs">
                          <input id="tab1"
                            type="radio"
                            name="tabs"
                            defaultChecked="true" />
                          <label htmlFor="tab1">
                            {this.props.textosShow.informacionpersonal || "Información Personal"}
                          </label>
                          <input id="tab2"
                            type="radio"
                            name="tabs" />
                          <label htmlFor="tab2">
                            {this.props.textosShow.informacionpublica || "Información Pública"}
                          </label>
                          <input id="tab3"
                            type="radio"
                            name="tabs" />
                          <label htmlFor="tab3">
                            {this.props.textosShow.cambiarcontrasena || "Cambiar Contraseña"}
                          </label>
                          <section id="content1">
                            <table>
                              <tbody>
                                <tr>
                                  <td className='td_title'>
                                    <h5>
                                      {this.props.textosShow.fechadenacimiento || "Fecha de nacimiento"}:
                                    </h5>
                                  </td>
                                  <td>
                                    <FechaSel name={this.props.textosShow.fechadenacimiento}
                                      ySince="70"
                                      yTo="10"
                                      dateSel={this.state.userData.fechaNac}
                                      onChange={this.setFechaNac.bind(this)}
                                      textosShow={this.props.textosShow} />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <h5>
                                      {this.props.textosShow.sexo || "Sexo"}:
                                    </h5>
                                  </td>
                                  <td>
                                    <div className='radio_r'>
                                      <input id="ser_1"
                                        name="radio2"
                                        value="one"
                                        defaultChecked={this.state.userData.sexo === "M"}
                                        onClick={() => this.setSexo("M")}
                                        type="radio" />
                                      <label htmlFor="ser_1">
                                        {this.props.textosShow.masculino || "Masculino"}
                                      </label>
                                      <input id="ser_2"
                                        name="radio2"
                                        value="two"
                                        defaultChecked={this.state.userData.sexo === "F"}
                                        onClick={() => this.setSexo("F")}
                                        type="radio" />
                                      <label htmlFor="ser_2">
                                        {this.props.textosShow.femenino || "Femenino"}
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <h5>
                                      {this.props.textosShow.email || "Email"}:
                                    </h5>
                                  </td>
                                  <td>
                                    <div className='table_content'>
                                      <div className={this.state.validate.email.valid ? 'input' : 'input error'}>
                                        {
                                          !this.state.validate.email.valid ?
                                            <div className='error_reason'>
                                              {this.state.validate.email.msg}
                                            </div> :
                                            null
                                        }
                                        <input type="text"
                                          name="email"
                                          value={this.state.userData.email}
                                          onChange={this.setEmail.bind(this)} />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <h5>
                                      {this.props.textosShow.nombredeusuario || "Nombre de usuario"}:
                                    </h5>
                                  </td>
                                  <td>
                                    <div className='table_content'>
                                      <div className={this.state.validate.nick.valid ? 'input' : 'input error'}>
                                        {
                                          !this.state.validate.nick.valid ?
                                            <div className='error_reason'>
                                              {this.state.validate.nick.msg}
                                            </div> :
                                            null
                                        }
                                        <input type="text"
                                          name="nick"
                                          value={this.state.userData.name}
                                          onChange={this.setNick.bind(this)} />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <center>
                                      <div className='checkbox'>
                                        <input id="check1"
                                          type="checkbox"
                                          name="check"
                                          value="check1"
                                          onChange={this.setCheckOptIn1.bind(this)}
                                          defaultChecked={this.state.userData.optIn1 === 1} />
                                        <label htmlFor="check1"></label>
                                      </div>
                                    </center>
                                  </td>
                                  <td>
                                    <div className='table_content'>
                                      {this.props.textosShow.dejeestaopcionactivadasidesearecibiralertasensucorreoelectronico || "Deje esta opción activada si desea recibir alertas en su correo electrónico"}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2">
                                    <center>
                                      <button className='button button_regular'
                                        onClick={this.Perfil.bind(this)}>
                                        {this.props.textosShow.guardardatos || "Guardar Datos"}:
                                      </button>
                                    </center>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </section>
                          <section id="content2">
                            <table>
                              <tbody>
                                <tr className='public_info'>
                                  <td className='td_title'>
                                    <h5>
                                      {this.props.textosShow.juegofavorito || "Juego Favorito"}:
                                    </h5>
                                  </td>
                                  <td>
                                    <div className='select'>
                                      <button className='main-item'>
                                        {
                                          juegoFavoritos.find(jf => jf.id === this.state.userData.juegoFavorito) ?
                                            juegoFavoritos.find(jf => jf.id === this.state.userData.juegoFavorito).nombre :
                                            "-"
                                        }
                                      </button>
                                      <ul className="sub-menu lang_menu">
                                        <li>
                                          {
                                            juegoFavoritos.map(jf =>
                                              <span className="link-button"
                                                key={jf.id}
                                                onClick={() => this.setJuegoFavorito(jf.id)}>
                                                {jf.nombre}
                                              </span>
                                            )
                                          }
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr className='public_info'>
                                  <td className='td_title'>
                                    <h5>
                                      {this.props.textosShow.tambienmegusta || "También me gusta"}:
                                    </h5>
                                  </td>
                                  <td>
                                    <div className='select'>
                                      <button className='main-item'>
                                        {
                                          likeTos.find(lt => lt.id === this.state.userData.likeTo) ?
                                            likeTos.find(lt => lt.id === this.state.userData.likeTo).nombre :
                                            "-"
                                        }
                                      </button>
                                      <ul className="sub-menu lang_menu">
                                        <li>
                                          {
                                            likeTos.map(lt =>
                                              <span className="link-button"
                                                key={lt.id}
                                                onClick={() => this.setTambienMeGusta(lt.id)}>
                                                {lt.nombre}
                                              </span>
                                            )
                                          }
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr className='public_info'>
                                  <td className='td_title'>
                                    <h5>
                                      {this.props.textosShow.juegopor || "Juego Por"}:
                                    </h5>
                                  </td>
                                  <td>
                                    <div className='select'>
                                      <button className='main-item'>
                                        {
                                          juegoPors.find(jp => jp.id === this.state.userData.juegoPor) ?
                                            juegoPors.find(jp => jp.id === this.state.userData.juegoPor).nombre :
                                            "-"
                                        }
                                      </button>
                                      <ul className="sub-menu lang_menu">
                                        <li>
                                          {
                                            juegoPors.map(jp =>
                                              <span className="link-button"
                                                key={jp.id}
                                                onClick={() => this.setJuegoPor(jp.id)}>
                                                {jp.nombre}
                                              </span>
                                            )
                                          }
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr className='public_info'>
                                  <td className='td_title'>
                                    <h5>
                                      {this.props.textosShow.signodelzodiaco || "Signo del Zodíaco"}:
                                    </h5>
                                  </td>
                                  <td>
                                    <div className='select'>
                                      <button className='main-item'>
                                        {
                                          signos.find(s => s.id === this.state.userData.signo) ?
                                            signos.find(s => s.id === this.state.userData.signo).nombre :
                                            "-"
                                        }
                                      </button>
                                      <ul className="sub-menu lang_menu">
                                        <li>
                                          {
                                            signos.map(s =>
                                              <span className="link-button"
                                                key={s.id}
                                                onClick={() => this.setSignoZodiaco(s.id)}>
                                                {s.nombre}
                                              </span>
                                            )
                                          }
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2">
                                    <center>
                                      <button className='button button_regular'
                                        onClick={this.Info.bind(this)}>
                                        {this.props.textosShow.guardadatos || "Guardar Datos"}
                                      </button>
                                    </center>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </section>
                          <section id="content3">
                            <table>
                              <tbody>
                                <tr className='public_info'>
                                  <td className='td_title'>
                                    <h5>
                                      {this.props.textosShow.contraseniaanterior || "Contraseña Anterior"}:
                                    </h5>
                                  </td>
                                  <td>
                                    <div className={this.state.validate.pass.valid ? 'input' : 'input error'}>
                                      {
                                        !this.state.validate.pass.valid ?
                                          <div className='error_reason'>
                                            {this.state.validate.pass.msg}
                                          </div> :
                                          null
                                      }
                                      <input type="password"
                                        value={this.state.oldPassword}
                                        onChange={this.setOldPassword.bind(this)} />
                                    </div>
                                  </td>
                                </tr>
                                <tr className='public_info'>
                                  <td className='td_title'>
                                    <h5>
                                      {this.props.textosShow.nuevacontrasenia || "Nueva Contraseña"}:
                                    </h5>
                                  </td>
                                  <td>
                                    <div className={this.state.validate.passNew.valid ? 'input' : 'input error'}>
                                      {
                                        !this.state.validate.passNew.valid ?
                                          <div className='error_reason'>
                                            {this.state.validate.passNew.msg}
                                          </div> :
                                          null
                                      }
                                      <input type="password"
                                        value={this.state.newPassword}
                                        onChange={this.setNewPassword.bind(this)} />
                                    </div>
                                  </td>
                                </tr>
                                <tr className='public_info'>
                                  <td className='td_title'>
                                    <h5>
                                      {this.props.textosShow.confirmarnuevacontrasenia || "Confirmar Nueva Contraseña"}:
                                    </h5>
                                  </td>
                                  <td>
                                    <div className={this.state.validate.passRepit.valid ? 'input' : 'input error'}>
                                      {
                                        !this.state.validate.passRepit.valid ?
                                          <div className='error_reason'>
                                            {this.state.validate.passRepit.msg}
                                          </div> :
                                          null
                                      }
                                      <input type="password"
                                        value={this.state.confirmNewPassword}
                                        onChange={this.setConfirmNewPassword.bind(this)} />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2">
                                    <center>
                                      <button className='button button_regular'
                                        onClick={this.ChangePass.bind(this)}>
                                        {this.props.textosShow.cambiarcontrasena || "Cambiar Contraseña"}
                                      </button>
                                    </center>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </section>
                        </div>
                      </div> :
                      null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Perfil;