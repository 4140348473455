import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router";

import $ from 'jquery';

import SmokeCenter from '../components/SmokeCenter';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import AddFriendModal from '../components/AddFriendModal';

import winIcon from '../../img/win_icon.png';
import waitIcon from '../../img/wait_icon.png';
import warningIcon from '../../img/warn_icon.png';
import ReactTooltip from 'react-tooltip';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import apprenticeIcon from '../../img/apprentice.png';
import warriorIcon from '../../img/warrior.png';
import proPlayerIcon from '../../img/pro-player.png';
import defaultImage from '../../img/no-image.png';

import MembershipUtil from '../utils/membership';
class Partida extends Component {

  mesaggeListener = this.onReceiveMessage.bind(this);

  constructor(props) {
    super(props);

    this.state = {
      gameData: null,
      partidaData: null,
      sala: null,
      addFriendMsg: '',
      freeReEntry: false
    };
  };

  componentDidMount() {
    if (this.props.loggedIn) {
      if (this.props.match.params.gameId === null || this.props.match.params.gameId === undefined) {
        this.getDataPartida();
        if (process.env.REACT_APP_GAME_AUTOREFRESH_TIMEOUT) {
          this.timerGetJuegos = setInterval(() => {
            this.getDataPartida();
          }, process.env.REACT_APP_GAME_AUTOREFRESH_TIMEOUT);
        }
      }
      else {
        this.getDataPartidaById();
        window.addEventListener("message", this.mesaggeListener, false);
      }

      //mover el tiempo de cada sala que sea jackpots
      this.timerJackpots = setInterval(() => {
        this.moveTimeJackpots();
      }, 1000);
    }
  };

  componentWillUnmount() {
    clearInterval(this.timerGetJuegos);
    clearInterval(this.timerJackpots);
    window.removeEventListener("message", this.mesaggeListener, false)
  };

  onReceiveMessage(event) {
    if (event.origin !== window.origin) {
      return;
    }

    console.log(event.data);
    if (event.data === 'change-partidas') {
      this.getDataPartidaById();
    }
  };

  getDataPartida() {
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(clase.props.urlGlobal + "api/juego", {
      method: "POST",
      body: JSON.stringify({
        action: "setPartida",
        juego: clase.props.match.params.gameSlug,
        sala: clase.props.match.params.gameSala,
        first: 0,
        lan: lan
      }),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        let juego = result.juego;
        let partida = result.partida;
        let sala = juego.salas.find(s => s.id === parseInt(clase.props.match.params.gameSala));
        clase.setState({
          gameData: juego,
          partidaData: partida,
          sala: sala
        });
      } else {
        clase.props.openAlertModal(
          clase.props.textosShow[result.msg] || result.msg,
          clase.props.textosShow[`${result.msg}descripcion`] || 'Error'
        )
      }
    });
  };

  getDataPartidaById() {
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(this.props.urlGlobal + "api/juego", {
      method: "POST",
      body: JSON.stringify({
        action: "getPartida",
        juego: clase.props.match.params.gameSlug,
        sala: clase.props.match.params.gameSala,
        partida: clase.props.match.params.gameId,
        lan: lan
      }),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        let juego = result.juego;
        let partida = result.partida;
        let sala = juego.salas.find(s => s.id === parseInt(clase.props.match.params.gameSala))
        clase.setState({
          gameData: juego,
          partidaData: partida,
          sala: sala,
          freeReEntry: result.freeReEntry
        });
        if (!partida.id) {
          clase.props.history.replace(`/game/${clase.props.match.params.gameSlug}`);
        }
      }
    });
  };

  moveTimeJackpots() {
    let partida = this.state.partidaData;
    if (partida !== null) {
      if (partida.modo === "Jackpots") {
        let dias = partida.tiempo.dias;
        let horas = partida.tiempo.horas;
        let minutos = partida.tiempo.minutos;
        let segundos = partida.tiempo.segundos;

        segundos--;
        if (segundos < 0) {
          if (minutos > 0) {
            segundos = 59;
            minutos--;
          }
          else {
            segundos = 0;
          }
        }
        if (minutos < 0) {
          if (horas > 0) {
            minutos = 59;
            horas--;
          }
          else {
            minutos = 0;
          }
        }
        if (horas < 0) {
          if (dias > 0) {
            horas = 23;
            dias--;
          }
          else {
            horas = 0;
          }
        }
        if (dias < 0) {
          dias = 0;
        }

        partida.tiempo = {
          dias: dias,
          horas: horas,
          minutos: minutos,
          segundos: segundos
        };

        this.setState({
          partidaData: partida
        });
      }
    }
  };

  canReentry() {  

    if (this.state.freeReEntry) {
      return true;
    }       

    if (this.state.sala) {

      if (this.state.partidaData && (this.state.partidaData.modo == 'Ladder' && this.state.partidaData.ladderEntradaMode)) {
        return true;        
      }

      if (this.state.partidaData && (this.state.partidaData.modo == 'Bonanza' && this.state.partidaData.bonanzaEntradaMode)) {
        return true;        
      }

      const freeSlot = this.state.partidaData.players.find(p => p.id === "");
      if (freeSlot || this.state.sala.cantJugador === -1) {
        if (this.state.partidaData.modo !== "Jackpots" && !this.state.partidaData.close && this.state.sala &&
          this.state.sala.reentry) {
          return true;
        }
        else if (this.state.partidaData.modo === "Jackpots" && !this.state.partidaData.close && this.state.sala &&
          this.state.sala.reentry && this.state.partidaData.plays === this.state.partidaData.intentos) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  };

  showPremio() {
    let partida = this.state.partidaData;
    if (!partida) return false;

    if (partida.modo === "Ladder" && partida.ladderEntradaMode) return false;
    if (partida.modo === "Bonanza" && partida.bonanzaEntradaMode) return false;

    return true;
  }

  onReentryClicked () {
    if (this.state.sala) {
      if (this.state.sala.modo == 'Ladder') {
        this.reentryLadder();
      } else if (this.state.sala.modo == 'Bonanza') {
        this.reentryBonanza();
      } else if (this.state.sala.modo == 'Spin')  {
        this.showSpin(1);
      } else {
        this.reentry();
      }
    }
  }

  onTryAgainClicked () {
    if (this.state.sala) {
      if (this.state.sala.modo == 'Spin')  {
        this.showSpin(0);
      } else {
        this.tryAgain();
      }
    }
  }

  reentry() {
    let clase = this;
    if (clase.props.loggedIn) {

      if (!this.state.freeReEntry) {
        if (clase.state.sala.modo !== "Jackpots" && clase.state.sala.costo === 0 && clase.state.sala.premioTotal
        && !clase.props.userData.hasDeposits) {
          alert(clase.props.textosShow.necesitasdepositarparajugaraljuegogratis || "Necesitas depositar para jugar al juego gratis");
          return;
        }
        else if (clase.state.sala.modo === "Jackpots" && clase.state.sala.costo === 0 &&
          clase.state.sala.costoReentry > clase.props.userData.money) {
          alert(clase.props.textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir");
          return;
        }
        else if ((clase.state.sala.modo !== "Jackpots" || (clase.state.sala.modo === "Jackpots" && clase.state.sala.costo > 0)) &&
          clase.state.sala.costo > clase.props.userData.money) {
          alert(clase.props.textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir");
          return;
        }        
      }
      
      fetch(clase.props.urlGlobal + "api/juego", {
        method: "POST",
        body: JSON.stringify({
          action: "setPartida",
          juego: clase.state.gameData.slug,
          sala: clase.state.sala.id,
          first: 1,
          lan: clase.props.getLanActual(),
          reentry: 1,
          partida: clase.state.partidaData.id
        }),
        headers: clase.props.getHeadersToSend()
      }).then(function (response) {
        return response.json();
      }).then(function (result) {
        if (result.success) {
          window.location = clase.props.urlGlobal + "playGame/" + clase.state.sala.slug + "/?gameMode=" + clase.state.sala.id + "&partida=" + clase.state.partidaData.id + "&game=" + clase.props.match.params.gameSlug;
        }
        else {
          //alert(result.msg);
          clase.props.openAlertModal(
            clase.props.textosShow[result.msg] || result.msg,
            clase.props.textosShow[`${result.msg}descripcion`] || 'Error'
          )
        }
      });
    }
    else {
      alert(clase.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta.");
    }
  };

  reentryLadder() {
    let clase = this;
    if (clase.props.loggedIn) {
      if (this.state.sala.costoReentry > this.props.userData.money) {
        alert(clase.props.textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir");
      } else {
        fetch(clase.props.urlGlobal + "api/juego", {
          method: "POST",
          body: JSON.stringify({
            action: "setLadderPlayer",
            lan: clase.props.getLanActual(),
            id: clase.state.sala.id,
            reentry: 1
          }),
          headers: clase.props.getHeadersToSend()
        }).then(function(response) {
          return response.json();
        }).then(function(result) {
          if (!result.success) {
            alert(result.msg);
          }
          else if (result.game_slug && result.ladder_slug) {
            window.location = clase.props.urlGlobal + "playGame/" + result.game_slug 
                              + "/?gameMode=" + clase.state.sala.id 
                              + "&partida=" + result.partida_id 
                              + "&game=" + result.ladder_slug
                              + "&ladder_player_id=" + result.ladder_player_id
                              + "&ladder_entrada_id=" + result.ladder_entrada_id;
          }
        });
      }
    }
    else {
      alert(clase.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta.");
    }
  }

  reentryBonanza() {
    let clase = this;
    if (clase.props.loggedIn) {
      if (this.state.sala.costoReentry > this.props.userData.money) {
        alert(clase.props.textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir");
      } else {
        fetch(clase.props.urlGlobal + "api/juego", {
          method: "POST",
          body: JSON.stringify({
            action: "setBonanzaPlayer",
            lan: clase.props.getLanActual(),
            id: clase.state.sala.id,
            reentry: 1
          }),
          headers: clase.props.getHeadersToSend()
        }).then(function(response) {
          return response.json();
        }).then(function(result) {
          if (!result.success) {
            alert(result.msg);
          }
          else if (result.bonanza_slug && result.game_slug) {
            window.location = clase.props.urlGlobal + "playGame/" + result.game_slug 
                              + "/?gameMode=" + clase.state.sala.id 
                              + "&partida=" + result.partida_id 
                              + "&game=" + result.bonanza_slug
                              + "&bonanza_player_id=" + result.bonanza_player_id
                              + "&bonanza_entrada_id=" + result.bonanza_entrada_id;
          }
        });
      }
    }
    else {
      alert(clase.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta.");
    }
  }

  tryAgain() {
    let clase = this;
    if (clase.props.loggedIn) {
      fetch(clase.props.urlGlobal + "api/juego", {
        method: "POST",
        body: JSON.stringify({
          action: "setPartida",
          juego: clase.state.gameData.slug,
          sala: clase.state.sala.id,
          first: 1,
          lan: clase.props.getLanActual(),
          reentry: 0,
          partida: clase.state.partidaData.id
        }),
        headers: clase.props.getHeadersToSend()
      }).then(function (response) {
        return response.json();
      }).then(function (result) {
        if (result.success) {
          window.location = clase.props.urlGlobal + "playGame/" + clase.state.sala.slug + "/?gameMode=" + clase.state.sala.id + "&partida=" + clase.state.partidaData.id + "&game=" + clase.props.match.params.gameSlug;
        }
        else {
          //alert(result.msg);
          clase.props.openAlertModal(
            clase.props.textosShow[result.msg] || result.msg,
            clase.props.textosShow[`${result.msg}descripcion`] || 'Error'
          )
        }
      });
    }
    else {
      alert(clase.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta.");
    }
  };


  showSpin(reentry) {
    this.props.showSpinModal(this.state.gameData.slug, this.state.sala.id, this.state.sala.slug, reentry, this.state.partidaData.id);
  }

  sendFriendRequest = (id) => {
    let clase = this;
    fetch(clase.props.urlGlobal + "api/friendRequest", {
      method: "POST",
      body: JSON.stringify({
        action: "sendFriendRequest",
        lan: clase.props.getLanActual(),
        friendId: id
      }),
      headers: this.props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        clase.setState({ addFriendMsg: result.msg })
        clase.openFriendModal();
      }

      else {
        clase.setState({ addFriendMsg: result.msg })
        clase.openFriendModal();
      }
    });
  };
  openFriendModal = () => {
    $("#alert_modal_overlay5").fadeIn("fast", function () {
      $("#alert_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };
  render() {
    if (!this.props.loggedIn) {
      return (<Redirect to={'/'} />);
    }

    let juego = this.state.gameData;
    let partida = this.state.partidaData;
    let salaUri = this.props.match.params.gameSala;

    return (
      <div>
        <AddFriendModal
          addFriendMsg={this.state.addFriendMsg}
        />
        <div
          className='content'
          id='game_ui'>
          <div className='wrapper'>
            <SmokeCenter></SmokeCenter>
            <div className='page_content'>
              <div className='c_center'>
                <div className='c_top'>
                  <div className='c_bottom'>
                    <Eagle></Eagle>
                    <Leaves></Leaves>
                    <h4>
                      {this.props.textosShow.infodeljuego || "Info del Juego"}
                    </h4>
                    <div className='page_block'>
                      {
                        (juego && partida) ? (
                          <div className='game_ui'>
                            <div className='game_stat'>
                              {this.props.textosShow.juego || "Juego"}: <span>
                                {juego.titulo || juego.slug}
                              </span>
                              <br />
                              {this.props.textosShow.iddeljuego || "ID del Juego"}: <span>
                                {partida.idAlias}
                              </span>
                            </div>
                            <div className='game_status'>
                              {this.props.textosShow.estadodeljuego || "Estado del Juego"}:
                              <br />
                              {
                                !partida.close ?
                                  <img
                                    src={waitIcon}
                                    alt={this.props.textosShow.enespera || "En espera"} /> :
                                  (partida.players.length && partida.players[0].nombre === this.props.userData.name) ? (
                                    <img
                                      src={winIcon}
                                      alt={this.props.textosShow.ganador || "Ganador"} />
                                  ) : (
                                      null
                                    )
                              }
                            </div>
                            <div className='game_main'>
                              <div className='game_icon_main'>
                                <img
                                  src={juego.logo}
                                  alt={juego.slug} />
                              </div>
                            </div>
                            {
                              partida.finish ? (
                                <div className='premio'>

                                  {
                                    (partida && partida.modo === "Spin") 
                                    ? (
                                      <>
                                        <h5>
                                          {this.props.textosShow.spinprize || "Spin Prize"}
                                        </h5>
                                        <div>
                                          {this.props.defaultData.moneda.signo} {partida.spinPrize}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <h5>
                                          {this.props.textosShow.premiototal || "Premio Total"}
                                        </h5>
                                        <div>
                                          {this.props.defaultData.moneda.signo} {partida.premio}
                                        </div>
                                      </>
                                    )
                                  }

                                  {
                                    (partida && partida.modo === "Ladder") ? (
                                      <Link 
                                        to={"/ladder/" + this.props.match.params.gameSala} 
                                        className="no-link">
                                          <button className='button button_regular'>
                                            {this.props.textosShow.ladderboard || "Ladder Board"}
                                          </button>
                                      </Link>
                                    ) : ((partida && partida.modo === "Bonanza") ? (
                                      <Link 
                                        to={"/bonanza/" + this.props.match.params.gameSala} 
                                        className="no-link">
                                          <button className='button button_regular'>
                                            {this.props.textosShow.bonanzaboard || "Bonanza Board"}
                                          </button>
                                      </Link>
                                    )
                                    :
                                    (!(this.state.sala && this.canReentry()) && (partida.modo === "Jackpots" && !partida.close && partida.plays < partida.intentos) ? (
                                      <button 
                                        className="button button_regular"
                                        onClick={this.onTryAgainClicked.bind(this)}>
                                        {this.props.textosShow.reentrar || "Reentrar"}
                                      </button>
                                    ) : (
                                      <Link 
                                        to={"/game/" + this.props.match.params.gameSlug} 
                                        className="no-link">
                                        <button className='button button_regular'>
                                          {this.props.textosShow.jugarotrosduelos || "Jugar otros duelos"}
                                        </button>
                                      </Link>
                                    )))
                                  }
                                  {
                                    (this.state.sala && this.canReentry()) ? (
                                      <button 
                                        className='button button_regular'
                                        onClick={this.onReentryClicked.bind(this)}
                                        style={{
                                          marginLeft: '10px'
                                        }}>
                                        {
                                          (this.state.sala.modo === "Jackpots" && this.state.freeReEntry === true) ? (
                                            (this.props.textosShow.reentrar || "Reentrar")
                                          ) : (this.state.sala.modo === "Jackpots" && this.state.sala.costoReentry > 0 ) ? (
                                            (this.props.textosShow.reentrarpor || "Reentrar por") + " " + this.props.defaultData.moneda.signo + this.state.sala.costoReentry
                                          ): (this.props.textosShow.reentrar || "Reentrar")
                                        }
                                      </button>
                                    ) : (
                                        null
                                      )
                                  }
                                </div>
                              ) : (
                                  <div className='premio'>
                                    {
                                      (partida && partida.modo === "Spin") 
                                      ? (
                                        <>
                                          <h5>
                                            {this.props.textosShow.spinprize || "Spin Prize"}
                                          </h5>
                                          <div>
                                            {this.props.defaultData.moneda.signo} {partida.spinPrize}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <h5>
                                            {this.props.textosShow.enespera || "En espera"}
                                          </h5>
                                          <div>
                                            {this.props.defaultData.moneda.signo} {partida.premio}
                                          </div>
                                        </>
                                      )
                                    }
                                  </div>
                                )
                            }
                            {
                              (partida.modo === "Jackpots" && partida.finish) ? (
                                <div className='game_subtitle'>
                                  {partida.plays} {this.props.textosShow.de || "de"} {partida.intentos} {this.props.textosShow.intentos || "intentos"}
                                </div>
                              ) : (
                                  null
                                )
                            }
                          </div>
                        ) : (
                            null
                          )
                      }
                      {
                        (juego !== null && partida !== null) ? (
                          partida.plays > 0 ? (
                            <div className='game_ui_list'>
                              <table>
                                <tbody>
                                  {
                                    partida.players.map((player) =>
                                      <tr
                                        className={(player.id === this.props.userData.id) ? 'tr_winner tr_player' : 'tr_player'}
                                        key={player.ind}>
                                        <td className='dark_td td_num'>
                                          <span>
                                            {player.ind}
                                          </span>
                                        </td>
                                        <td className='td_win'>
                                          <div className='select'>
                                            <button
                                              className='main-item'
                                              style={{
                                                color: '#ffffff'
                                              }}>
                                              <div className='country'>
                                                <img
                                                  src={player.pais}
                                                  alt="" />
                                              </div>

                                              <div className='membership-level'>
                                                {
                                                  (player.id && player["membership"]) ? (
                                                    <>
                                                      <img
                                                        src={MembershipUtil.getMembershipIcon(player["membership"])}
                                                        alt={player["membership"]["membershipName"]}
                                                        data-tip
                                                        data-for={`membership-tooltip-${player.ind}`} />
                                                      <ReactTooltip id={`membership-tooltip-${player.ind}`}>
                                                        {this.props.textosShow[player["membership"]["membershipSlug"]] || player["membership"]["membershipName"]}
                                                        <img
                                                          src={MembershipUtil.getMembershipIcon(player["membership"])}
                                                        />
                                                      </ReactTooltip>
                                                    </>
                                                  ) : (
                                                      null
                                                    )
                                                }
                                              </div>

                                              <div className='partida-level'>
                                                {
                                                  player.id ? (
                                                    (player.level <= 2) ? (
                                                      <>
                                                        <img
                                                          src={apprenticeIcon}
                                                          alt={this.props.textosShow.aprendiz || "Aprendiz"}
                                                          data-tip
                                                          data-for={`level-tooltip-${player.ind}`} />
                                                        <ReactTooltip id={`level-tooltip-${player.ind}`}>
                                                          {this.props.textosShow.aprendiz || "Aprendiz"}
                                                        </ReactTooltip>
                                                      </>
                                                    ) : (player.level <= 4) ? (
                                                      <>
                                                        <img
                                                          src={warriorIcon}
                                                          alt={this.props.textosShow.guerrero || "Guerrero"}
                                                          data-tip
                                                          data-for={`level-tooltip-${player.ind}`} />
                                                        <ReactTooltip id={`level-tooltip-${player.ind}`}>
                                                          {this.props.textosShow.guerrero || "Guerrero"}
                                                        </ReactTooltip>
                                                      </>
                                                    ) : (
                                                          <>
                                                            <img
                                                              src={proPlayerIcon}
                                                              alt={this.props.textosShow.jugadorpro || "Jugador pro"}
                                                              data-tip
                                                              data-for={`level-tooltip-${player.ind}`} />
                                                            <ReactTooltip id={`level-tooltip-${player.ind}`}>
                                                              {this.props.textosShow.jugadorpro || "Jugador pro"}
                                                            </ReactTooltip>
                                                          </>
                                                        )
                                                  ) : (
                                                      null
                                                    )
                                                }
                                              </div>
                                              <img src={player.foto} onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} className="partida-player-img" />
                                              &nbsp;
                                              {player.nombre}
                                              {player.blocked ? (
                                                <>
                                                  <span data-tip data-for={`blocked-tooltip-${player.id}`} className="iconInfo pl-1">
                                                    <FontAwesomeIcon icon={faLock} />
                                                  </span>
                                                  <ReactTooltip id={`blocked-tooltip-${player.id}`}>
                                                    <p>
                                                      {this.props.textosShow.usuariobloqueado || "Usuario Bloqueado"}
                                                    </p>
                                                  </ReactTooltip>
                                                </>
                                              ) : (<></>)}
                                            </button>
                                            <ul className="sub-menu lang_menu">
                                              <li>
                                                <a href={"/profile/" + player.id} className="link-button" style={{ color: "white" }}>
                                                  <span
                                                    style={{ color: "white", float: "left" }}>
                                                    {this.props.textosShow.verperfil || "Ver perfil"}
                                                  </span>
                                                </a>
                                                {
                                                  (player.id !== this.props.userData.id) ? (
                                                    <>
                                                      <span
                                                        className="link-button"
                                                        onClick={() => this.props.showChallengeModal(player.id)}>
                                                        {this.props.textosShow.desafiar || "Desafiar"}
                                                      </span>
                                                      <span
                                                        className="link-button"
                                                        onClick={() => this.sendFriendRequest(player.id)}>
                                                        {this.props.textosShow.addFriend || "Add Friend"}
                                                      </span>
                                                    </>
                                                  ) : (
                                                      null
                                                    )
                                                }
                                              </li>
                                            </ul>
                                          </div>
                                          {
                                            (player.winner === "winner") ? (
                                              <div
                                                style={{
                                                  color: '#a1e970',
                                                  float: 'right'
                                                }}>
                                                {player.estado}
                                              </div>
                                            ) : (
                                                <div
                                                  style={{
                                                    float: 'right'
                                                  }}>
                                                  {player.estado}
                                                </div>
                                              )
                                          }
                                        </td>
                                        <td className='dark_td td_pts'>
                                          <span>
                                            {player.score} pts
                                          </span>
                                        </td>
                                        <td className='td_award'>
                                          { (partida && partida.modo === "Spin")
                                            ? (
                                              <span>
                                                {
                                                  (salaUri === 0) ?
                                                    this.props.textosShow.gratis :
                                                    (this.props.defaultData.moneda.signo + ((partida !== null && player.ind == 1) ? partida.spinPrize : 0))
                                                }
                                              </span>
                                            ) : (
                                              <span>
                                                {
                                                  (salaUri === 0) ?
                                                    this.props.textosShow.gratis :
                                                    (this.props.defaultData.moneda.signo + ((partida !== null) ? player.premio : 0))
                                                }
                                                <small className="tour_add">
                                                  + {player.gemas}
                                                </small>
                                              </span>
                                            )
                                          }
                                          
                                        </td>
                                      </tr>
                                    )
                                  }
                                </tbody>
                              </table>
                            </div>
                          ) : (
                              null
                            )
                        ) : (
                            null
                          )
                      }
                      <div className='game_ui_warn'>
                        <span>
                          <img
                            src={warningIcon}
                            alt="Warning" />
                          {this.props.textosShow.importantenuestrosistemaalineamatchconoponentesdelmismonivel || "Importante! Nuestro sistema alinea match con openentes del mismo nivel"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default withRouter(Partida);
