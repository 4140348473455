import $ from 'jquery';
import React, { Component } from 'react';
import { Card, Col, Container, Row } from 'reactstrap';

import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import SmokeCenter from '../components/SmokeCenter';
import SmokeBox from '../components/SmokeBox';
import LadderGameCard from '../components/LadderGameCard';
import BonanzaGameCard from '../components/BonanzaGameCard';

class LadderList extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      salaSel: null,
      showDepositNow: false,
      showDepositFree: false,
      ladders: [],
      bonanzas: [],
      ladderPage: {},
    };
  };

  componentDidMount() {
    this.getLadderPage();
    this.getLadderData();
    this.getBonanzaData();
    this.timerJackpots = setInterval(() => {
      this.moveTimeLadders();
      this.moveTimeBonanzas();
    }, 1000);
    window.scrollTo(0, 0);
  };
  
  componentWillUnmount() {
    this.timerJackpots = null;
    this.setState = () => {
      return;
    };
  };


  getLadderData() {
    let clase = this;
    
    clase.setState({
      gameData: null
    });
    
    fetch(clase.props.urlGlobal + "api/juego", {
      method: "POST",
      body: JSON.stringify({
        action: "getLadderList",
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.default && result.default.maintenance === true && clase.props.textosShow){
        alert(clase.props.textosShow['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        console.log(result);
        clase.setState({
          ladders: result.ladders
        });
      }
    });
  };

  getBonanzaData() {
    let clase = this;
    
    clase.setState({
      gameData: null
    });
    
    fetch(clase.props.urlGlobal + "api/juego", {
      method: "POST",
      body: JSON.stringify({
        action: "getBonanzaList",
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        console.log(result);
        clase.setState({
          bonanzas: result.bonanzas
        });
      }
    });
  };

  moveTimeLadders() {
    let ladders = this.state.ladders;
    if (ladders) {
      for (let i = 0; i < ladders.length; i++) {
        let dias = ladders[i].tiempo.dias;
        let horas = ladders[i].tiempo.horas;
        let minutos = ladders[i].tiempo.minutos;
        let segundos = ladders[i].tiempo.segundos;
        segundos--;
        if (segundos < 0) {
          if (minutos > 0) {
            segundos = 59;
            minutos--;
          }
          else {
            segundos = 0;
          }
        }
        if (minutos < 0) {
          if (horas > 0) {
            minutos = 59;
            horas--;
          }
          else {
            minutos = 0;
          }
        }
        if (horas < 0) {
          if (dias > 0) {
            horas = 23;
            dias--;
          }
          else {
            horas = 0;
          }
        }
        if (dias < 0) {
          dias = 0;
        }
        ladders[i].tiempo = {
          highlight: (dias === 0 && horas < 6),
          dias: dias,
          horas: horas,
          minutos: minutos,
          segundos: segundos
        };
      }
    }
    this.setState({
      ladders: ladders
    });
  };

  moveTimeBonanzas() {
    let bonanzas = this.state.bonanzas;
    if (bonanzas) {
      for (let i = 0; i < bonanzas.length; i++) {
        let dias = bonanzas[i].tiempo.dias;
        let horas = bonanzas[i].tiempo.horas;
        let minutos = bonanzas[i].tiempo.minutos;
        let segundos = bonanzas[i].tiempo.segundos;
        segundos--;
        if (segundos < 0) {
          if (minutos > 0) {
            segundos = 59;
            minutos--;
          }
          else {
            segundos = 0;
          }
        }
        if (minutos < 0) {
          if (horas > 0) {
            minutos = 59;
            horas--;
          }
          else {
            minutos = 0;
          }
        }
        if (horas < 0) {
          if (dias > 0) {
            horas = 23;
            dias--;
          }
          else {
            horas = 0;
          }
        }
        if (dias < 0) {
          dias = 0;
        }
        bonanzas[i].tiempo = {
          highlight: (dias === 0 && horas < 6),
          dias: dias,
          horas: horas,
          minutos: minutos,
          segundos: segundos
        };
      }
    }
    this.setState({
      bonanzas: bonanzas
    });
  };

  showAcept(ladder) {
    this.setState({
      showDepositFree:false,
      showDepositNow:false
    });
    if (this.props.loggedIn) {
      if (ladder !== null) {
        if (ladder.costo > this.props.userData.money) {
          this.showDepositNow(ladder.id);
        }
        else {
          if (ladder.modo == 'Ladder') {
            if (ladder.ladderRegistered) {
              this.props.history.replace("/ladder/" + ladder.id);
            } else {

              this.props.ladderModalElement.current.setState({
                ladder: ladder,
                gameSlug: ladder.gameSlug,
              });
              
              $(".mt_block.step_6").fadeOut(0, function() {
                $("#ladder_modal_overlay").fadeIn("fast", function() {
                  $("#ladder_modal").fadeIn("fast", function() {
                    document.body.style.overflow = "hidden";
                  });
                });
              });
            }
          }          
        }
      }
      else {
        alert(this.props.textosShow.estamodalidadnoestaaundisponible || "Esta modalidad no esta aún disponible");
      }
    }
    else {
      alert(this.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta");
    }
  };

  showAceptBonanza(bonanza) {
    this.setState({
      showDepositFree:false,
      showDepositNow:false
    });
    if (this.props.loggedIn) {
      if (bonanza !== null) {
        if (bonanza.costo > this.props.userData.money) {
          this.showDepositNow(bonanza.id);
        }
        else {
          if (bonanza.modo == 'Bonanza') {
            if (bonanza.bonanzaRegistered) {
              this.props.history.replace("/bonanza/" + bonanza.id);
            } else {

              this.props.bonanzaModalElement.current.setState({
                bonanza: bonanza,
                gameSlug: bonanza.gameSlug,
              });
              
              $(".mt_block.step_6").fadeOut(0, function() {
                $("#bonanza_modal_overlay").fadeIn("fast", function() {
                  $("#bonanza_modal").fadeIn("fast", function() {
                    document.body.style.overflow = "hidden";
                  });
                });
              });
            }
          }          
        }
      }
      else {
        alert(this.props.textosShow.estamodalidadnoestaaundisponible || "Esta modalidad no esta aún disponible");
      }
    }
    else {
      alert(this.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta");
    }
  };

  getLadderPage() {
    let clase = this;
    clase.setState({
      loading: true
    });
    fetch(clase.props.urlGlobal + "api/page", {
      method: "POST",
      body: JSON.stringify({
        action: "getPage",
        page: "ladder",
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.setState({
          ladderPage: {
            titulo: result.page.titulo,
            texto: result.page.texto
          }
        });
      }
    });
  };
  
  showFaqModal() {
    $("#faq_modal_overlay").fadeIn("fast", function() {
      $("#faq_modal").fadeIn("fast", function() {
        document.body.style.overflow = "hidden";
      });
    });
  };
    
  showDepositNow(id) {
    $(".mt_block.step_6").fadeOut(0, function() {
      $("#deposit_now_" + id).fadeIn(0);
    });

    this.setState({
      showDepositNow: id
    });
    
  };
  
  showDepositFree(id) {
    $(".mt_block.step_6").fadeOut(0, function() {
      $("#deposit_free_" + id).fadeIn(0);
    });
    this.setState({
      showDepositFree: id
    });
  };

  render() {
    return(
      <div>
        <div id='laddergames'>
            <div className='wrapper'>
              <SmokeCenter></SmokeCenter>
              <div className='page_content'>
                <div className='c_center'>
                  <div className='c_top'>
                    <div className='c_bottom'>
                      <Eagle></Eagle>
                      <Leaves></Leaves>
                      <div className='game_image'>
                        <SmokeBox />
                      </div>
                      <h4>
                        {this.state.ladderPage.titulo || this.props.textosShow.laddergames || 'Ladder Games'} 
                      </h4>
                      <div className='faq_help'>
                        <button 
                          className='faq_info'
                          onClick={this.showFaqModal.bind(this)}>
                           FAQ
                        </button>
                      </div>
                      <Container fluid className='page_block tournament-card-container'>
                        <div className='tournament-row'>

                          { [...this.state.ladders].map((item) => {
                            return (
                              <div className='my-4 d-flex-center tournament-col'>
                                <LadderGameCard
                                  item={item}
                                  textosShow={this.props.textosShow}
                                  defaultData={this.props.defaultData}
                                  gameComponent={this}
                                  onPlayClick={this.showAcept} />
                              </div>
                            )
                          }) }

                          { [...this.state.bonanzas].map((item) => {
                            return (
                              <div className='my-4 d-flex-center tournament-col'>
                                <BonanzaGameCard
                                  item={item}
                                  textosShow={this.props.textosShow}
                                  defaultData={this.props.defaultData}
                                  gameComponent={this}
                                  onPlayClick={this.showAceptBonanza} />
                              </div>
                            )
                          }) }
                        </div>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  };
};

export default LadderList;
