import React from 'react';

import boxRed from '../../img/boxRed.png';
import boxLight from '../../img/boxLight.png';
import boxSmokeBg from '../../img/boxSmokeBg.png';

const SmokeBox = () => (
    <div className="redbox__img" style={{transform:'rotate(219deg)',marginTop:'86px',marginLeft:'-18px'}}>
    <div className="view">
        <img loading="lazy" src={boxSmokeBg} alt=""
            className="scale-up-center" />
        <img loading="lazy" src={boxSmokeBg} alt=""
            className="scale-up-center" style={{ animationDelay: "2.5s" }} />
        <span>

        </span>
        <img loading="lazy" src={boxSmokeBg} alt="" className="scale-up-side" />
        <img loading="lazy" src={boxSmokeBg} alt="" className="scale-up-side"
            style={{ animationDelay: "2.5s" }} />
    </div>
        </div>
)

export default SmokeBox;