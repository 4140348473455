import React, { useEffect, useRef, useState } from 'react';
import dateFormat from 'dateformat';
import Picker from 'emoji-picker-react';

import MembershipUtil from '../utils/membership';
import Util from '../utils/utils';
import $ from 'jquery';

import sendIcon from '../../img/send.png';
import emojiGreenIcon from '../../img/emoji-green.png';
import emojiWhiteIcon from '../../img/emoji-white.png';
import BlogCommentItem from './BlogCommentItem';

export default function BlogItem({item}) {

    const getBlogTime = () => {
        const createdAt = new Date(item.CreatedAt * 1000);
        return dateFormat(createdAt, "yyyy-mm-dd H:MM:ss");
    }

    return (
        <div className='blog-container'>
            <div className='blog-item'>
                <div className='blog-title'>{item.blog_title}</div>
                <div className='blog-img'>
                    <img
                        src={item.blog_img}/>
                </div>
                <div className='blog-text'>{item.blog_text}</div>
            </div>
        </div>
    )
}
