import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';

import waitIcon from '../../img/wait_icon.png';
import winIcon from '../../img/win_icon.png';
import CountDownTimer from '../components/CountDownTimer';
import {
  Container, Row, Col, Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from 'reactstrap';


import SmokeCenter from '../components/SmokeCenter';
import SmokeBox from '../components/SmokeBox';
import ObjectiveCard from '../components/ObjectiveCard';

import instagramIcon from '../../img/social/instagram.png';
import ticktokIcon from '../../img/social/tiktok.png';
import SocialCard from '../components/SocialCard';

class Objectives extends Component {

  constructor(props) {
    super(props);
    this.state = {
      partidas: [],
      total: 0,
      activePage: 0,
      totalPages: 0,
      objectiveList: [],
      socialTargetList: []
    };
    this.urlGlobal = props.urlGlobal;
  }

  componentDidMount() {
    this.getRewards()
    this.getSocialTargetList();
  }

  getRewards(page) {
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(this.urlGlobal + "api/objetivo", {
      method: "POST",
      body: JSON.stringify({
        action: "getObjetivos",
        lan: lan,
        page: page
      }),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.default && result.default.maintenance === true && clase.props.textosShow){
        alert(clase.props.textosShow['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        clase.setState({ objectiveList: result.objetivos })
        console.log(result.objetivos);
      }
      else {
        console.log(result.msg);
      }
    });
  }

  getSocialTargetList() {
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(this.urlGlobal + "api/social", {
      method: "POST",
      body: JSON.stringify({
        action: "getSocialTargetList",
        lan: lan,
      }),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        clase.setState({ socialTargetList: result.social_target_list })
      }
      else {
      }
    });
  }

  enterObjective(item) {

    this.props.showLoadingIndicator(true);

    let myHeaders = this.props.getHeadersToSend();
    let clase = this;

    fetch(this.urlGlobal + "api/objetivo", {
      method: "POST",
      body: JSON.stringify({
        action: "enterObjectvie",
        juego_id: item.game_id,
        objective_id: item.id
      }),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        var newObjectiveList = clase.state.objectiveList.map((objective) => {
          if (objective.game_id == item.game_id) {
            if (objective.id == item.id) {
              return {...objective, is_active_plan: true, total_number_of_games_played: result.total_number_of_games_played};
            }

            return objective;  
          }

          return objective;
        }); 

        clase.setState({ objectiveList: newObjectiveList});
      }
      clase.props.showLoadingIndicator(false);
    }).catch(function (error) {
      clase.props.showLoadingIndicator(false);
    });
  }

  onCardClicked(item) {
    if (!item.is_active_plan) {
      this.enterObjective(item);
    }
  }


  render() {
    if (!this.props.loggedIn) {
      return (<Redirect to={'/'} />);
    }

    const getResultImage = (result) => {
      if (result === "wait") {
        return <img src={waitIcon} alt="Wait" />;
      }
      else if (result === "win") {
        return <img src={winIcon} alt="Win" />;
      }
      else if (result === "lose") {
        return null;
      }
      else {
        return null;
      }
    };

    return (
      <div>
        <div className='content'
          id='objective'>
          <div className='wrapper'>
            <SmokeCenter />
            <div className='page_content'>
              <div className='c_center'>
                <div className='c_top'>
                  <div className='c_bottom'>
                    <Eagle></Eagle>
                    <Leaves></Leaves>
                    <div className='game_image'>
                      <SmokeBox />
                    </div>
                    <h4>
                      {this.props.textosShow.rewards || "Rewards"}
                    </h4>
                    <Container fluid className='page_block objective-card-container'>
                      <div className='objective-row'>
                        {this.state.objectiveList.map((item, key) =>
                          (
                            <div className='my-4 d-flex-center objective-col'>
                              <div className='objective-card-header'></div>
                              <ObjectiveCard 
                                item={item}
                                textosShow={this.props.textosShow}/>
                            </div>
                          ))}

                        {this.state.socialTargetList.map((item, key) =>
                          (
                            <div className="my-4 d-flex-center objective-col">
                              <div className='objective-card-header'></div>
                              <SocialCard 
                                item={item}
                                textosShow={this.props.textosShow}
                                urlGlobal={this.urlGlobal}
                                getHeadersToSend={this.props.getHeadersToSend}
                                getLanActual={this.props.getLanActual}
                                getDataUser={this.props.getDataUser}/>
                            </div>
                          ))}

                          {/* <div className="my-4 d-flex-center objective-col">
                              <div className='objective-card-header'></div>
                              <SocialCard 
                                textosShow={this.props.textosShow}
                                socialLogStatus={this.props.socialLogStatus}
                                socialShareCompleted={this.props.socialShareCompleted}
                                urlGlobal={this.urlGlobal}
                                getHeadersToSend={this.props.getHeadersToSend}
                                getLanActual={this.props.getLanActual}
                                getDataUser={this.props.getDataUser}/>
                          </div> */}
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Objectives;