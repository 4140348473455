import React from "react";

const FaqItem = ({ faq, setSelectedFaq, selectedFaq }) => {
  // const [show, setShow] = React.useState(false);

  const onClickFaq = () => {
    // setShow(!show);
    setSelectedFaq(selectedFaq === faq.id ? null : faq.id);
  }

  if(!faq) return null;

  return (
    <>
      <div className='faq_question' onClick={onClickFaq}>
          {faq.titulo}
      </div>
      {selectedFaq === faq.id && (
        <div className='faq_text' dangerouslySetInnerHTML={{ __html: faq.texto }} />
      )}
      <hr/>
    </>
  );
}

export default FaqItem;