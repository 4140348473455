import $ from 'jquery';
import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';

import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import SmokeCenter from '../components/SmokeCenter';
import SmokeBox from '../components/SmokeBox';
import SpinGameCard from '../components/SpinGameCard';

import iconBack from '../../img/spin/icon_back.png';

import coverBubbleShooter from '../../video/cover/cover_bubbleshooter.mp4';
import coverBubbleHorror2 from '../../video/cover/cover_bubblehorror2.mp4';
import coverPeppermagic from '../../video/cover/cover_peppermagic.mp4';
import coverSolitaire from '../../video/cover/cover_solitaire.mp4';
import coverSolitaireSpider from '../../video/cover/cover_solitairespider.mp4';
import coverTuruleka2 from '../../video/cover/cover_turuleka2.mp4';
import coverBingo from '../../video/cover/cover_bingo.mp4';
import coverGemswar from '../../video/cover/cover_gemswar.mp4';

import { getLogoWithSlug } from '../utils/utils';
import SpinCover from '../components/SpinCover';

const coverList = [
  { slug: 'bubbleshooter', cover: coverBubbleShooter },
  { slug: 'bubblehorror2', cover: coverBubbleHorror2 },
  { slug: 'peppermagic', cover: coverPeppermagic },
  { slug: 'solitaire', cover: coverSolitaire },
  { slug: 'solitairespider', cover: coverSolitaireSpider },
  { slug: 'turuleka2', cover: coverTuruleka2 },
  { slug: 'bingo', cover: coverBingo },
  { slug: 'gemwarseries', cover: coverGemswar },
];

class SpinList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      salaSel: null,
      showDepositNow: false,
      showDepositFree: false,
      gameSlug: null,
      spins: [],
      curSpin: null,
      coverList: []
    };
  };

  componentDidMount() {
    this.getSpinData();
    this.timerJackpots = setInterval(() => {
      // this.moveTimeSpins();
    }, 1000);
    window.scrollTo(0, 0);
  };

  componentWillUnmount() {
    this.timerJackpots = null;
    this.setState = () => {
      return;
    };
  };


  getSpinData() {
    let clase = this;

    clase.setState({
      gameData: null
    });

    fetch(clase.props.urlGlobal + "api/juego", {
      method: "POST",
      body: JSON.stringify({
        action: "getSpinJuegoList",
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.default && result.default.maintenance === true && clase.props.textosShow) {
        alert(clase.props.textosShow['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        console.log(result);
        clase.setState({
          coverList: result.coverList
        });
      }
    });
  };

  getSpinDataWithSlug(gameSlug) {
    let clase = this;
    clase.props.showLoadingIndicator(true);

    clase.setState({
      gameData: null
    });

    fetch(clase.props.urlGlobal + "api/juego", {
      method: "POST",
      body: JSON.stringify({
        action: "getSpinListWithSlug",
        slug: gameSlug,
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.default && result.default.maintenance === true && clase.props.textosShow) {
        alert(clase.props.textosShow['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        console.log(result);
        clase.setState({
          spins: result.spins
        });
      }
    }).finally(() => {
      clase.props.showLoadingIndicator(false);
    });
  };

  getRules() {
    let clase = this;
    fetch(clase.props.urlGlobal + "api/page", {
      method: "POST",
      body: JSON.stringify({
        action: "getPage",
        page: `spinrule`,
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        clase.props.getRule({
          titulo: result.page.titulo,
          texto: result.page.texto
        })

        clase.showRulesModal();
      }
    });
  };

  showAcept(spin) {
    this.setState({
      showDepositFree: false,
      showDepositNow: false
    });
    if (this.props.loggedIn) {
      if (spin !== null) {

        if (spin.costo > this.props.userData.money) {
          this.showDepositNow(spin.id);
        }
        else {
          if (spin.modo == 'Spin') {
            this.props.showSpinModal(spin.gameSlug, spin.id, spin.slug, 0, null);
          }
        }
      }
      else {
        alert(this.props.textosShow.estamodalidadnoestaaundisponible || "Esta modalidad no esta aún disponible");
      }
    }
    else {
      alert(this.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta");
    }
  };

  showFaqModal() {
    $("#faq_modal_overlay").fadeIn("fast", function () {
      $("#faq_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  showRulesModal() {
    $("#rules_modal_overlay").fadeIn("fast", function () {
      $("#rules_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  showDepositNow(id) {
    $(".mt_block.step_6").fadeOut(0, function () {
      $("#deposit_now_" + id).fadeIn(0);
    });

    this.setState({
      showDepositNow: id
    });

  };

  showDepositFree(id) {
    $(".mt_block.step_6").fadeOut(0, function () {
      $("#deposit_free_" + id).fadeIn(0);
    });
    this.setState({
      showDepositFree: id
    });
  };

  onSpinClick(spin) {
    this.setState({
      curSpin: spin
    });
  }

  onCoverClicked(gameSlug) {
    this.setState({
      gameSlug: gameSlug
    });
    this.getSpinDataWithSlug(gameSlug);
  }

  onBackClicked() {
    this.setState({
      gameSlug: null,
      curSpin: null,
      spins: []
    })
  }

  render() {
    return (
      <div>
        <div id='laddergames'>
          <div className='wrapper'>
            <SmokeCenter></SmokeCenter>
            <div className='page_content'>
              <div className='c_center'>
                <div className='c_top'>
                  <div className='c_bottom'>
                    <Eagle></Eagle>
                    <Leaves></Leaves>
                    <div className='game_image'>
                      <SmokeBox />
                    </div>
                    <h4>
                      {this.props.textosShow.spin || 'Spin'}
                    </h4>
                    <div className='faq_help'>
                      <button
                        className='faq_info'
                        onClick={this.showFaqModal.bind(this)}>
                        FAQ
                      </button>
                      <button
                        className="button bordered_button"
                        onClick={this.getRules.bind(this)}>
                        {this.props.textosShow.reglas || "Reglas"}
                      </button>
                    </div>
                    <Container fluid className='page_block spin-card-container'>
                      {this.state.gameSlug &&
                        <div onClick={() => this.onBackClicked()}>
                          <img src={iconBack} />
                        </div>
                      }

                      <Row>

                        {!this.state.gameSlug && this.state.coverList.length > 0 &&
                            this.state.coverList.map((item) => {
                              return (
                                <Col xs="3"
                                  className='mt-5'
                                >
                                  <SpinCover
                                    item={item}
                                    logo={getLogoWithSlug(this.props.games, item.slug)}
                                    onCoverClicked={(gameSlug) => this.onCoverClicked(gameSlug)} />


                                </Col>
                              );
                            }
                          )
                        }

                      </Row>

                      {this.state.gameSlug &&
                        <div className='tournament-row'>
                          {[...this.state.spins].map((item) => {
                            return (
                              <div className='my-4 d-flex-center tournament-col'>
                                <SpinGameCard
                                  item={item}
                                  curSpin={this.state.curSpin}
                                  textosShow={this.props.textosShow}
                                  defaultData={this.props.defaultData}
                                  gameComponent={this}
                                  onSpinClick={this.onSpinClick.bind(this)} />
                              </div>
                            )
                          })}
                        </div>
                      }

                    </Container>
                    {this.state.gameSlug && this.state.spins && this.state.spins.length > 0 &&
                      <div className='d-flex-center'>
                        <button
                          className="button-green"
                          onClick={() => this.showAcept(this.state.curSpin)}
                          disabled={this.state.curSpin == null}>
                          {this.props.textosShow.entrar || "Entrar"}
                        </button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default SpinList;
