import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';

import { Card, Col, Container, Row } from 'reactstrap';
import Smoke from './Smoke';
import Profile from "./../pages/Profile";
import PlayerBg from '../../img/friend-request/start-1.png';
import defaultImage from '../../img/no-image.png';

export default function FriendsCardSearch(props) {
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pages, setPages] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [recentDataImage, setRecentDataImage] = useState([]);

  function handleChangePage(page) {
    setActivePage(page)
    searchFriendRequest(page);
  }

  const searchFriendRequest = (page) => {
    props.showLoadingIndicator(true);
    fetch(props.urlGlobal + "api/friendRequest", {
      method: "POST",
      body: JSON.stringify({
        action: "getFriends",
        lan: props.getLanActual(),
        page: page + 1
      }),
      headers: props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        props.showLoadingIndicator(false);
        let totalPages = Math.ceil(result.total / 8);
        let pages = [];
        let firstPage = (page > 4) ? page - 4 : 0;
        let lastPage = (page + 4 < totalPages) ? page + 4 : totalPages - 1;
        for (let i = firstPage; i <= lastPage; i++) {
          pages.push(i);
        }
        setSearchData(result.result)
        setTotal(result.total)
        setTotalPages(totalPages)
        setPages(pages)
      }
    });
  }

  function openFriendModal() {
    $("#alert_modal_overlay3").fadeIn("fast", function () {
      $("#alert_modal3").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  function getRecentFriendRequests(id) {
    fetch(props.urlGlobal + "api/friendRequest", {
      method: "POST",
      body: JSON.stringify({
        action: "getRecentFriendRequests",
        lan: props.getLanActual(),
        clienteId: id
      }),
      headers: props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        setRecentData(result)
        setRecentDataImage(result.requests)
        openFriendModal()
      }
      else {
        setRecentData(result.msg)
      }
    });
  };

  useEffect(() => {
    searchFriendRequest(0);
  }, [props.searchValue])
  return (
    <Container>
      {/* <AlertFriendModal
          alertTitle={alertTitle}
        ></AlertFriendModal> */}

      <Row>
        {searchData &&
          searchData.length > 0 ?
          searchData.map((item) =>
            <Col xs="12" sm="6" md="4" lg="4" className="my-4 d-flex-center objective-col friend-col" style={{ marginTop: "30px" }}>
              {/* <Link
                to={{
                  pathname: "/profile/" + item.id,
                  state: searchData // your data array of objects
                }}
              > */}
              <a href={"/profile/" + item.id}>
                <Card

                  className='friend-card-bg'>
                  <div className='friend-card-bg-2' >
                    <div className='align-items-center membership-card'>
                      <div xs="12" className="text-center text-md-center d-flex-center d-flex-ver">
                        {/* <div className="player-bg" style={{ marginLeft: "-15px" }}>
                          <img className='membership-icon' style={{paddingTop : "25px"}} src={item.photo} />
                        </div> */}
                        <div className = "player-section">
                          <img src={item.photo} onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} class="fishes" />
                          <img src={PlayerBg} class="fish" />
                        </div>
                        <div className='friend-title'>
                          {item.name}
                        </div>
                        <div xs="12">
                          <div className='membership-description ' style={{ textAlign: 'center' }}>
                            <p>
                              {item.address}
                            </p>
                            <span style={{ color: "#9C9D9E" }}>
                              {item.country === "None" ? "" : item.country}
                              <img
                                style={{ width: '12px', marginBottom: '2px', marginLeft: '4px' }}
                                src={item.countryFlag}
                              />
                            </span>
                            {/* <h6 className='friend-point'>Bonus : {item.points}</h6> */}
                          </div>
                        </div>
                        {item.isOnline && item.isOnline === true ?
                          <div className='friendOnlineSearch friendSearchOnlineBackground'>
                            {props.textosShow.online || "Online "}
                          </div>
                          :
                          <div className='friendOfflineSearch friendSearchOfflineBackground'>
                            {props.textosShow.offline || "Offline "}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <Smoke />
                </Card>
              </a>
              {/* </Link> */}
            </Col>
          )
          :
          <h4 style={{ marginTop: "170px", position: "absolute", left: 0, right: 0 }}>{props.textosShow.nofriendfound || "No Friend Found "}</h4>
        }

      </Row>
      < div className='paginator'>
        {
          (activePage > 0) ?
            <span className='link-button prev'
              onClick={() => handleChangePage(activePage - 1)}></span> :
            null
        }
        {
          (pages.length && pages[0] > 0) ?
            <span className='link-button'
              onClick={() => handleChangePage(0)}>
              1
              </span> :
            null
        }
        {
          (pages.length && pages[0] > 0) ?
            <span>
              ...
              </span> :
            null
        }
        {
          pages.map(page =>
            <span className='link-button'
              id={(page === activePage) ? 'page_active' : ''}
              onClick={() => handleChangePage(page)}
              key={page}>
              {page + 1}
            </span>
          )
        }
        {
          (pages.length && pages[pages.length - 1] < totalPages - 1) ?
            <span>
              ...
              </span> :
            null
        }
        {
          (pages.length && pages[pages.length - 1] < totalPages - 1) ?
            <span className='link-button'
              onClick={() => handleChangePage(totalPages - 1)}>
              {totalPages}
            </span> :
            null
        }
        {
          (activePage < totalPages - 1) ?
            <span className='link-button next'
              onClick={() => handleChangePage(activePage + 1)}></span> :
            null
        }
      </div>
    </Container>
  )
}