import React, { Component } from 'react';

import $ from 'jquery';
import { Form } from 'reactstrap';

class MessageTicketModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ticket: null,
      mensaje: "",
      attachedFile: null
    };

    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  };

  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  };

  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  };

  close() {
    $("#message_ticket_modal").fadeOut("fast", function () {
      $("#message_ticket_modal_overlay").fadeOut("fast", function () {
        document.body.style.overflow = "auto";
      });
    });
  };

  handleUploadFileButton() {
    document.getElementById("upfile2").click();
  };
/*
  handleUploadFiles({ entity, entityId, handleFilesUploaded }) {
    const selectedFiles = document.getElementById("upfile2").files;

    if (selectedFiles && selectedFiles.length > 0) {
      setIsUploadingFiles(true);
      try {
        await uploadFiles({ entity, entityId, files: selectedFiles });
        toast.success(selectedFiles.length > 1 ? `${selectedFiles.length} archivos subidos exitosamente!` : `Archivo ${selectedFiles[0].name} subido exitosamente!`);
        document.getElementById("upfile2").value = "";
      } catch (err) {
        handleErrors(err, toast.error, refreshToken);
      } finally {
        if (handleFilesUploaded) {
          handleFilesUploaded();
        }
        setIsUploadingFiles(false);
      }
    }
  };
*/
  sendTicket() {
    let clase = this;
    let formData = new FormData();
    formData.append("action", "sendMessage");
    formData.append("Idioma", clase.props.getLanActual().id);
    formData.append("Ticket", clase.state.ticket.id);
    formData.append("Mensaje", clase.state.mensaje);
    formData.append("foto", clase.state.attachedFile);
    fetch(clase.props.urlGlobal + "api/ticket", {
      method: "POST",
      body: formData,
      headers: clase.props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        clase.setState({
          motivo: null,
          mensaje: "",
          attachedFile: null
        });
        document.dispatchEvent(new Event('onNewTicket'));
        alert(clase.props.textosShow.sumensajehasidoenviadoexitosamente || 'Su mensaje ha sido enviado exitosamente!');
        clase.close();
      }
      else {
        alert(result.msg);
      }
    });
  };

  setMensaje(e) {
    this.setState({
      mensaje: e.target.value
    });
  };

  upfileChanged() {
    const attachedFile = document.getElementById("upfile2").files.length ? 
      document.getElementById("upfile2").files[0] :
      null;
    this.setState({
      attachedFile: attachedFile
    });
  };

  removeUploadFile() {
    document.getElementById("upfile2").value = null;
    this.setState({
      attachedFile: null
    });
  };

  render() {
    return (
      <div
        className="modalx-overlay close_mw"
        id="message_ticket_modal_overlay">
        <div
          className='modal_window'
          id="message_ticket_modal">
          <button
            id="close_modal"
            className="link-button close_mw"
            onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              {
                this.state.ticket ? (
                  <div className='modal_content'>
                    <h5>
                      {this.props.textosShow.enviarmensaje || 'Enviar Mensaje'}
                      <br />
                      {this.props.textosShow.ticket || "Ticket"} #{this.state.ticket.id}
                    </h5>
                    <div className='modal_form'>
                      <div className='input_title'>
                        {this.props.textosShow.mensaje || 'Mensaje'}
                      </div>
                      <textarea
                        rows="4"
                        onChange={this.setMensaje.bind(this)}
                        value={this.state.mensaje}></textarea>
                      <Form
                        className="p-absolute"
                        style={{
                          height: "0px",
                          width: "0px",
                          overflow: "hidden"
                        }}>
                        <input
                          id="upfile2"
                          type="file"
                          accept="image/jpeg,image/gif,image/png"
                          onChange={this.upfileChanged.bind(this)} />
                      </Form>
                      {
                        this.state.attachedFile ? (
                          <div className="inputWithClearButton">
                            <input 
                              type="text"
                              value={this.state.attachedFile.name}
                              readOnly />
                            <button onClick={this.removeUploadFile.bind(this)}>
                              X
                            </button>
                          </div>
                        ) : (
                          null
                        )
                      }
                      <button 
                        onClick={this.handleUploadFileButton.bind(this)}
                        className="button bordered_button">
                        {this.props.textosShow.adjuntararchivo || 'Adjuntar Archivo'}
                      </button>
                    </div>
                    <div className='modal_button'>
                      <button
                        className='button button_regular'
                        onClick={this.sendTicket.bind(this)}>
                        {this.props.textosShow.enviar || 'Enviar'}
                      </button>
                    </div>
                  </div>
                ) : (
                  null
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default MessageTicketModal;
