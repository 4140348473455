import $, { timers } from 'jquery';
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import SmokeCenter from '../components/SmokeCenter';
import apprenticeIcon from '../../img/apprentice.png';
import warriorIcon from '../../img/warrior.png';
import proPlayerIcon from '../../img/pro-player.png';
import SalasDesktop from "../components/SalasDesktop";
import SalasMobile from '../components/SalasMobile';

import MembershipUtil from '../utils/membership';

class Game extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      gameData: null,
      redirect: false,
      salaSel: null,
      rules: null,
      showDepositNow: false,
      showDepositFree: false,
      showSubscribeFree: false,
      showSubscribeFreeMore: false,
      referralContests: [],
      generaljackpots: [],
    };
    
  };

  componentDidMount() {
    this.getJuegoData(this.props.match.params.gameSlug);
    this.getGeneralJackpots(this.props.match.params.gameSlug);
    // this.getRules(this.props.match.params.gameSlug);
    this.timerJackpots = setInterval(() => {
      this.moveTimeSala();
      this.moveTimeGeneraljackpots();
    }, 1000);
    window.scrollTo(0, 0);
  };
  
  componentWillUnmount() {
    this.timerJackpots = null;
    this.setState = () => {
      return;
    };
  };

  componentWillReceiveProps(nextProps) {
    var gameSlug = nextProps.match.params.gameSlug;
    if (this.props.match.params.gameSlug !== gameSlug) {
      this.getJuegoData(gameSlug);
      this.getGeneralJackpots(gameSlug);
      // this.getRules(gameSlug);
    }
  }
  
  getRules() {
    const gameSlug = this.props.match.params.gameSlug;
    let clase = this;
    fetch(clase.props.urlGlobal + "api/page", {
      method: "POST",
      body: JSON.stringify({
        action: "getPage",
        page: `reglas${gameSlug}`,
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.props.getRule({
          titulo: result.page.titulo,
          texto: result.page.texto
        })

        clase.showRulesModal();
      }
    });
  };

  getJuegoData(gameSlug) {
    let clase = this;
    
    clase.setState({
      gameData: null
    });
    
    fetch(clase.props.urlGlobal + "api/juego", {
      method: "POST",
      body: JSON.stringify({
        action: "getJuego",
        juego: gameSlug,
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.default && result.default.maintenance === true && clase.props.textosShow){
        alert(clase.props.textosShow['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        let juego = result.juego;
        clase.setState({
          gameData: juego
        });
      }
    });
  };

  getGeneralJackpots(gameSlug) {
    let clase = this;
    fetch(clase.props.urlGlobal + "api/generaljackpot", {
      method: "POST",
      body: JSON.stringify({
        action: "getGeneraljackpot",
        lan: clase.props.getLanActual(),
        juego: gameSlug,
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.default && result.default.maintenance === true && clase.props.textosShow){
        alert(clase.props.textosShow['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        clase.setState({
          generaljackpots: result.generaljackpots
        });
      }
    });
  };

  moveTimeSala() {
    let juego = this.state.gameData;
    if (juego) {
      for (let i = 0; i < juego.salas.length; i++) {
        if (juego.salas[i].modo === "Jackpots" || juego.salas[i].modo === "Ladder" || juego.salas[i].modo === "Bonanza") {
          let dias = juego.salas[i].tiempo.dias;
          let horas = juego.salas[i].tiempo.horas;
          let minutos = juego.salas[i].tiempo.minutos;
          let segundos = juego.salas[i].tiempo.segundos;
          segundos--;
          if (segundos < 0) {
            if (minutos > 0) {
              segundos = 59;
              minutos--;
            }
            else {
              segundos = 0;
            }
          }
          if (minutos < 0) {
            if (horas > 0) {
              minutos = 59;
              horas--;
            }
            else {
              minutos = 0;
            }
          }
          if (horas < 0) {
            if (dias > 0) {
              horas = 23;
              dias--;
            }
            else {
              horas = 0;
            }
          }
          if (dias < 0) {
            dias = 0;
          }
          juego.salas[i].tiempo = {
            highlight: dias === 0 && horas < 6,
            dias: dias,
            horas: horas,
            minutos: minutos,
            segundos: segundos
          };
        }
      }
    }
    this.setState({
      gameData: juego
    });
  };

  moveTimeGeneraljackpots() {
    let generaljackpots = this.state.generaljackpots;
    if (generaljackpots) {
      for (let i = 0; i < generaljackpots.length; i++) {
        let dias = generaljackpots[i].tiempo.dias;
        let horas = generaljackpots[i].tiempo.horas;
        let minutos = generaljackpots[i].tiempo.minutos;
        let segundos = generaljackpots[i].tiempo.segundos;
        segundos--;
        if (segundos < 0) {
          if (minutos > 0) {
            segundos = 59;
            minutos--;
          }
          else {
            segundos = 0;
          }
        }
        if (minutos < 0) {
          if (horas > 0) {
            minutos = 59;
            horas--;
          }
          else {
            minutos = 0;
          }
        }
        if (horas < 0) {
          if (dias > 0) {
            horas = 23;
            dias--;
          }
          else {
            horas = 0;
          }
        }
        if (dias < 0) {
          dias = 0;
        }
        generaljackpots[i].tiempo = {
          highlight: (dias === 0 && horas < 6),
          dias: dias,
          horas: horas,
          minutos: minutos,
          segundos: segundos
        };
      }
    }
    this.setState({
      generaljackpots: generaljackpots
    });
  };

  showAcept(sala) {
    this.setState({
      showDepositFree:false,
      showDepositNow:false,
      showSubscribeFree:false,
      showSubscribeFreeMore:false,
    });
    if (this.props.loggedIn) {
      if (sala !== null) {
        if (sala.modo === "Jackpots" && this.props.userData.jackpotBlocked === 1) {
          this.props.openAlertModal(
            this.props.textosShow.usuariobloqueado || "Usuario bloqueado",
            this.props.textosShow.jackpotsbloqueados || "Jackpots bloqueados"
          )
          return;
        }
        if (sala.modo !== "Jackpots" && sala.costo === 0 && sala.premioTotal && 
          !this.props.userData.hasDeposits && (this.state.gameData.freeJuegoSalaId != sala.id)) {
          this.showDepositFree(sala.id);
        }
        else if (sala.costo > this.props.userData.money && (this.state.gameData.freeJuegoSalaId != sala.id)) {
          this.showDepositNow(sala.id);
        }
        else if (sala.showReentry && sala.costoReentry > this.props.userData.money && (this.state.gameData.freeJuegoSalaId != sala.id)) {
          this.showDepositNow(sala.id);
        }
        else {
          if (sala.modo == 'Ladder') {
            if (sala.ladderRegistered) {
              this.props.history.replace("/ladder/" + sala.id);
            } else {
              this.props.ladderModalElement.current.setState({
                ladder: sala,
                gameSlug: this.props.match.params.gameSlug,
              });
              
              $(".mt_block.step_4").fadeOut(0, function() {
                $(".mt_block.step_5").fadeOut(0, function() {
                  $("#ladder_modal_overlay").fadeIn("fast", function() {
                    $("#ladder_modal").fadeIn("fast", function() {
                      document.body.style.overflow = "hidden";
                    });
                  });
                });
              });
            }
          } else if (sala.modo == 'Bonanza') {
            if (sala.bonanzaRegistered) {
              this.props.history.replace("/bonanza/" + sala.id);
            } else {
              this.props.bonanzaModalElement.current.setState({
                bonanza: sala,
                gameSlug: this.props.match.params.gameSlug,
              });
              
              $(".mt_block.step_4").fadeOut(0, function() {
                $(".mt_block.step_5").fadeOut(0, function() {
                  $("#bonanza_modal_overlay").fadeIn("fast", function() {
                    $("#bonanza_modal").fadeIn("fast", function() {
                      document.body.style.overflow = "hidden";
                    });
                  });
                });
              });
            }
          } else {
            
            if (sala.costo === 0) {

              let data = {
                action: "getGamePlayed",
                lan: this.props.getLanActual(),
                slug: this.props.match.params.gameSlug,
                sala: sala.id,
                type: 'game'
              }
              let clase = this;

              fetch(clase.props.urlGlobal + "api/user", {
                method: "POST",
                body: JSON.stringify(data),
                headers: clase.props.getHeadersToSend()
              }).then(function(response) {
                return response.json();
              }).then(function(result) {
                  if (result.success) {
                    /*if (result.played > 5){
                      clase.showSubscribeMoreNow(sala.id);
                      return;
                    }
                    if (!result.allowFreeroll) {
                      clase.showDepositFree(sala.id);
                      return;
                    }*/
                    clase.props.showPartidaModal(clase.props.match.params.gameSlug, sala);
                  }
              });           
            }
            else {
              this.props.showPartidaModal(this.props.match.params.gameSlug, sala);
            }
          }
          
        }
      }
      else {
        alert(this.props.textosShow.estamodalidadnoestaaundisponible || "Esta modalidad no esta aún disponible");
      }
    }
    else {
      alert(this.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta");
    }
  };

  showAceptGeneralJackpot(generaljackpot) {
    if (this.props.loggedIn) {
      if (generaljackpot !== null && !generaljackpot.type) {
        this.props.referralContestModalElement.current.setState({
          referralContest: generaljackpot
        });
        $(".mt_block.step_4").fadeOut(0);
        $(".mt_block.step_5").fadeOut(0);
        $("#referral_contest_modal_overlay").fadeIn("fast", function () {
          $("#referral_contest_modal").fadeIn("fast", function () {
            document.body.style.overflow = "hidden";
          });
        });
      } else if (generaljackpot !== null && generaljackpot.type) {
        if (generaljackpot.costo > this.props.userData.money) {
          this.showDepositNow(generaljackpot.id);
        } else {
          this.props.generaljackpotModalElement.current.setState({
            generaljackpot: generaljackpot
          });
          $(".mt_block.step_4").fadeOut(0);
          $(".mt_block.step_5").fadeOut(0);
          $("#generaljackpot_modal_overlay").fadeIn("fast", function () {
            $("#generaljackpot_modal").fadeIn("fast", function () {
              document.body.style.overflow = "hidden";
            });
          });
        }
      }
      else {
        alert(this.props.textosShow.estamodalidadnoestaaundisponible || "Esta modalidad no esta aún disponible");
      }
    }
    else {
      alert(this.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta");
    }
  };

  onClickSignUp(generaljackpot) {
    if (this.props.loggedIn) {
      if (generaljackpot !== null && !generaljackpot.type) {
        this.props.history.replace("/referral-contest/" + generaljackpot.id);
      } else if(generaljackpot !== null && generaljackpot.type){
        if(generaljackpot.registered) {
          this.props.history.replace("/general-jackpot/" + generaljackpot.id);
        } else {
          this.showAceptGeneralJackpot(generaljackpot);
        }
      }
    } else {
      alert(this.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta");
    }
  };
  
  showFaqModal() {
    $("#faq_modal_overlay").fadeIn("fast", function() {
      $("#faq_modal").fadeIn("fast", function() {
        document.body.style.overflow = "hidden";
      });
    });
  };
  
  showRulesModal() {
    $("#rules_modal_overlay").fadeIn("fast", function() {
      $("#rules_modal").fadeIn("fast", function() {
        document.body.style.overflow = "hidden";
      });
    });
  };

  showVideo() {
    this.props.openVideoModal((this.props.textosShow.seepreview || "Previsualizar") + ' ' + 
      this.state.gameData.titulo, `${this.props.urlGlobal}/api/content/Peppermagic spot.mp4`);
  };
  
  showDepositNow(id) {
    $(".mt_block.step_4").fadeOut(0, function() {
      $(".mt_block.step_5").fadeOut(0, function() {
        $("#deposit_now_" + id).fadeIn(0);
      });
    });
    this.setState({
      showDepositNow: id
    });
  };
  
  showSubscribeNow(id) {
    $(".mt_block.step_5").fadeOut(0, function() {
      $(".mt_block.step_4").fadeOut(0, function() {
        $("#subscribe_now_" + id).fadeIn(0);
      });
    });
    this.setState({
      showSubscribeFree: id
    });
  };

  showSubscribeMoreNow(id) {
    $(".mt_block.step_5").fadeOut(0, function() {
      $(".mt_block.step_4").fadeOut(0, function() {
        $("#subscribe_now_more_" + id).fadeIn(0);
      });
    });
    this.setState({
      showSubscribeFreeMore: id
    });
  };  

  showDepositFree(id) {
    $(".mt_block.step_5").fadeOut(0, function() {
      $(".mt_block.step_4").fadeOut(0, function() {
        $("#deposit_free_" + id).fadeIn(0);
      });
    });
    this.setState({
      showDepositFree: id
    });
  };

  getMembershipName(juego) {
    return `${this.props.textosShow[juego.membership['membershipSlug']] 
              || juego.membership['membershipName']} ${this.props.textosShow.membership 
              || 'Membership'}`;
  }

  render() {
    if (this.state.redirect) {
      return (<Redirect to={'/partida/' + this.props.match.params.gameSlug + '/' + this.state.salaSel.id} />);
    }
    
    let juego = this.state.gameData;
    
    return(
      <div>
        
        <div 
          className='content' 
          id='tournaments'>
            <div className='wrapper'>
              <SmokeCenter></SmokeCenter>
              <div className='page_content'>
                <div className='c_center'>
                  <div className='c_top'>
                    <div className='c_bottom'>
                      <Eagle></Eagle>
                      <Leaves></Leaves>
                      <div className='game_image'>
                        {
                          juego ? (
                            <img 
                              src={juego.logo}
                              alt={juego.slug} />
                          ) : (
                            null
                          )
                        }
                      </div>

                      <div className='faq_help game'>
                        <button 
                          className='faq_info'
                          onClick={this.showFaqModal.bind(this)}>
                           FAQ
                        </button>
                        <button 
                          className="button bordered_button"
                          onClick={this.getRules.bind(this)}>
                          {this.props.textosShow.reglas || "Reglas"}
                        </button>
                      </div>

                      {
                        juego ? (
                          <h4>
                            {juego.titulo || juego.slug}
                          </h4>
                        ) : (
                          null
                        )
                      }
                      {
                        this.props.userData?.registrationData?.haveGeneralLevelChallenge !== true && juego ? (
                          <div className='level_2 mb-4'>
                            {this.props.textosShow.tuniveles || "Tu nivel es"}: <span>
                              {
                                (juego.userLevel <= 2) ? (
                                  <>
                                    <img 
                                      src={apprenticeIcon}
                                      alt={this.props.textosShow.aprendiz || "Aprendiz"}
                                      height="24px"
                                      width="24px" />
                                    {this.props.textosShow.aprendiz || "Aprendiz"} {this.props.textosShow.nivel || "Nivel"} {juego.userLevel}
                                  </>
                                ) : (juego.userLevel <= 4) ? (
                                  <>
                                    <img 
                                      src={warriorIcon}
                                      alt={this.props.textosShow.guerrero || "Guerrero"}
                                      height="24px"
                                      width="24px" />
                                    {this.props.textosShow.guerrero || "Guerrero"} {this.props.textosShow.nivel || "Nivel"} {juego.userLevel}
                                  </>
                                ) : (
                                  <>
                                    <img 
                                      src={proPlayerIcon}
                                      alt={this.props.textosShow.jugadorpro || "Jugador pro"}
                                      height="24px"
                                      width="24px" />
                                    {this.props.textosShow.jugadorpro || "Jugador pro"} {this.props.textosShow.nivel || "Nivel"} {juego.userLevel}
                                  </>
                                )
                              }
                            </span>
                            <div className='bar_level'>
                              <div style={{width: Number(juego.userLevelProgress).toFixed(0) + '%'}}>
                                {Number(juego.userLevelProgress).toFixed(0)}%
                              </div>
                            </div>
                          </div>
                        ) : (
                          null
                        )
                      }                      

                      {
                        juego && juego.membership && 
                        <div className='d-flex-center'>
                          <Link to={{pathname: '/membership'}} style={{ textDecoration: 'none'}}>
                            <div className='game-membership-container'>
                              <img src={MembershipUtil.getMembershipIcon(juego.membership)}/>
                              <span>{this.props.textosShow.you_are || 'You are'} {this.getMembershipName(juego)} {this.props.textosShow.player || 'player'}</span>
                            </div>
                          </Link>
                          
                        </div>
                      }

                      {
                        juego ? (
                          <div style={{textAlign: 'right', color: '#FFC600', marginTop: 12}}>
                            {this.props.textosShow.jugadoresonlineen || "Jugadores online en"}
                            <br/>
                            {juego.titulo || juego.slug}: <b>{juego.jugadoresOnline}</b>
                          </div>
                        ) : (
                          null
                        )
                      }
                      <div className='page_block'>
                        {
                          juego ? (
                            <>
                              <div className='table_list d-none d-xl-block'>
                                <SalasDesktop 
                                  gameComponent={this} 
                                  juego={juego} 
                                  textosShow={this.props.textosShow} 
                                  defaultData={this.props.defaultData} 
                                  gameData={this.state.gameData} 
                                  onPlayClick={this.showAcept}
                                  referralContests={this.state.referralContests}
                                  generaljackpots={this.state.generaljackpots}
                                  openPopupOnlyPlayOnMobile={this.props.openPopupOnlyPlayOnMobile}
                                  onPlayGeneralJackpotClick={this.onClickSignUp} />
                              </div>
                              <div className='grid_list d-block d-xl-none'>
                                <SalasMobile
                                  gameComponent={this} 
                                  juego={juego} 
                                  textosShow={this.props.textosShow} 
                                  defaultData={this.props.defaultData} 
                                  gameData={this.state.gameData} 
                                  onPlayClick={this.showAcept}
                                  showDepositNow={this.state.showDepositNow}
                                  showDepositFree={this.state.showDepositFree}
                                  referralContests={this.state.referralContests}
                                  generaljackpots={this.state.generaljackpots}
                                  onPlayGeneralJackpotClick={this.onClickSignUp} />
                              </div>
                            </>
                          ) : (
                            null
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  };
};

export default Game;
