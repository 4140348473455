import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { Link, Redirect, withRouter } from 'react-router-dom';
import Switch from "react-switch";
import ReactTooltip from 'react-tooltip';
import { Col, Row } from 'reactstrap';
import coinbaseIcon from '../../img/coinbase.png';
import fortumoIcon from '../../img/fortumopay.png';
import gemIcon from '../../img/gem.png';
import netellerIcon from '../../img/neteller.png';
import paymentWall from '../../img/payment-wall.png';
import paypalIcon from '../../img/paypal.png';
import webpayIcon from '../../img/webpay.png';
import stripeIcon from '../../img/visa_master.png';
import stripeAllIcon from '../../img/stripe_all.png';
import eps from '../../img/eps.png';
import bancontact from '../../img/bancontact.png';
import klarna from '../../img/klarna.png';
import giropay from '../../img/giropay.png';
import ideal from '../../img/ideal.png';
import CoinbaseButton from '../components/CoinbaseButton';
import Eagle from '../components/Eagle';
import PaymentWallButton from '../components/PaymentWallButton/';
import PaypalButton from '../components/PaypalButton';
import MembershipUtil from '../utils/membership';
import StripeCard from '../components/StripeCard';
import StripeForm from '../components/StripeForm';
import StripePaymentElement from '../components/StripePaymentElement';
import logoFooter from '../../img/logo_title3_new.png';
import freeTrialIcon from '../../img/trial-200.png';
import bestOfferIcon from '../../img/best-offer.png';

const paymentMethodsIcon = {
  paypal: paypalIcon,
  coinbase: coinbaseIcon,
  fortumo: fortumoIcon,
  stripe: stripeIcon,
  stripe_all: stripeAllIcon,
  stripe_all_nl: ideal,
  stripe_all_de: giropay,
  stripe_all_fr: bancontact,
  stripe_all_at: eps,
  stripe_all_be: bancontact,
  stripe_all_us: klarna,
  neteller: netellerIcon,
  webpay: webpayIcon,
  payment_wall: paymentWall
}

class Subscription extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      plans: [],
      paymentMethods: [],
      plan: null,
      payed: props.payed || "paypal",
      successPay: 0,
      withPromotions: true,
    };

    this.urlGlobal = props.urlGlobal;
  }

  componentDidMount() {
    if (this.props.userData.hasActiveMembership) {
      this.props.history.replace('/');
    }
    this.getPlansData();
  }

  componentDidUpdate(oldProps, oldState) {
    const params = new URLSearchParams(window.location.search);
    if(this.state.plans.length > oldState.plans.length && this.props.history.location.pathname === '/check_stripe' && params.has('payment_intent')){
      this.setState({
        payed: "stripe"
      });
      this.deposit(params.get('payment_intent'));
    }
  }

  getPlansData() {
    let myHeaders = this.props.getHeadersToSend();
    let clase = this;
    fetch(this.urlGlobal + "api/plan", {
      method: "POST",
      body: JSON.stringify({
        action: "getSubscriptions"
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.default && result.default.maintenance === true && clase.props.textosShow){
        alert(clase.props.textosShow['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        clase.setState({
          plans: result.plans,
          plan: result.plans[0],
          paymentMethods: result.paymentMethods
        });
        clase.selPayed(result.paymentMethods[0].id)
      }
    });
  }
  
  selPlan(plan) {
    console.log(`select plan`, plan)
    this.setState({
      plan: plan
    });
    if(window.dataLayer){
      window.dataLayer.push({'PlanPrice': plan.valorReal});
    }
    ReactPixel.track("InitiateCheckout", {
      value: this.state.plan.price,
      content_type: this.state.plan.name,
      currency: 'USD',
      contents: {
        payed: this.state.payed,
      }
    });
  }

  selPayed(payed) {
    this.setState({
      payed: payed
    });
    if(this.state.plan){
      if(window.dataLayer){
        window.dataLayer.push({'PlanPrice': this.state.plan.price});
      }
      ReactPixel.track("InitiateCheckout", {
        value: this.state.plan.price,
        content_type: this.state.plan.name,
        currency: 'USD',
        contents: {
          payed: this.state.payed,
        }
      });
    }
  }

  createSubscription() {
    this.setState({
      successPay: 1
    });
    let myHeaders = this.props.getHeadersToSend();
    let clase = this;
    fetch(this.urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "subscription",
        payed: clase.state.payed, 
        planId: clase.state.plan.id,
        lan: clase.props.getLanActual(),
        isClaimedPromotions: '0'
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        document.location.href = result.msg;
      }
      else {
        clase.setState({
          successPay: 3
        });
      }
    });    
  }

  deposit(paymentId) {
    this.setState({
      successPay: 1
    });
    let myHeaders = this.props.getHeadersToSend();
    let clase = this;
    fetch(this.urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "subscription",
        payed: clase.state.payed, 
        cant: clase.state.plan.price,
        planId: clase.state.plan.id,
        paymentId: paymentId,
        lan: clase.props.getLanActual(),
        isClaimedPromotions: '0'
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.setState({
          successPay: 2
        });
        ReactPixel.track("Purchase", {
          value: clase.state.plan.valorReal,
          content_type: clase.state.plan.nombre,
          currency: 'USD',
          contents: {
            payed: clase.state.payed,
          }
        });
        clase.props.history.replace('/subscription_successful');
        setTimeout(function () {
          window.location.href = "/";
      }, 3000);        
      }
      else {
        clase.setState({
          successPay: 3
        });
      }
    });
  }

  errorPay() {
    this.setState({
      successPay: 3
    });
  }

  getPayerObj() {
    return this.state.paymentMethods.find(paymentMethod => paymentMethod.id === this.state.payed)
  }

  setWithPromotions(withPromotions) {
    this.setState({withPromotions});
  }

  
  render() {
    const onSuccess = (paymentId) => {
      console.log('Successful payment!', paymentId);
      this.deposit(paymentId);
    };

    const onError = (err) => {
      console.log('Erroneous payment OR failed to load script!', err);
      this.errorPay();
    };

    const onCancel = (data) => {
      console.log('Cancelled payment!', data);
      this.errorPay();
    };


    if (!this.props.loggedIn) {
      return (<Redirect to={'/'} />);
    }
    
    return (
      <div className='content' id='profile'>
        <div className='wrapper'>
          <div className='smoke_center'></div>
          <div className='page_content'>
            <Eagle></Eagle>
            <div className='leaves'></div>

            <div className='subscription_content'>
            <div className='subscription_logos'>
                <img 
                  src={logoFooter} 
                  className='logo_footer'
                  alt="DuelCash" />
              </div>

              <div className="subscription_layer">
              {
                this.state.successPay === 0 ? (
                  <>
                    {
                      this.state.plans && (!this.getPayerObj() || this.getPayerObj().withPlans) ? (
                        <>
                          <Row className='justify-content-center radio_r payment_sum payment_subscribe py-3'>
                            {this.state.plans.map((plan) => (
                              <div key={plan.id} className="mr-3">
                                <input 
                                  id={'deposit_plan_' + plan.id}
                                  name="radio2" 
                                  key={plan.id}
                                  value={plan.id}
                                  type="radio"
                                  defaultChecked={plan.id === this.state.plan.id}
                                  onClick={() => this.selPlan(plan)} />
                                <label htmlFor={'deposit_plan_' + plan.id} className="subscription_label">
                                  <div className='mt-3'>
                                    <img 
                                      style={{display: 'none'}}
                                      src={plan.foto}
                                      alt={this.props.defaultData.monedaReal.signo + ' ' + plan.name} />
                                      { (plan.bestoffer === 1) ?
                                          <img className='bestoffer_icon'
                                            src={bestOfferIcon}
                                            width='50px'/>
                                          : 
                                          null
                                      }
                                      <span className={(plan.trial_days && plan.trial_days > 0 ? 'strike' : '')}>
                                        {this.props.defaultData.monedaReal.signo}{(plan.price)}
                                      </span>
                                      { (plan.trial_days && plan.trial_days > 0 ) ?
                                          <img className='free_icon'
                                            src={freeTrialIcon}
                                            width='50px'/>
                                          : 
                                          null
                                      }
                                      <p className='mt-2'>{plan.name}</p>
                                      <div className='subscription_benefits'><ul>{plan.benefits.split('-').map((benefit) => (
                                       (benefit && <li>{benefit}</li>)
                                      ))}</ul></div>                                      
                                  </div>
                                </label>
                                {this.props.textosShow.promotionmembershipfixeddescription &&
                                  <ReactTooltip id="promotion_membership_fixed">
                                    <p>
                                      {this.props.textosShow.promotionmembershipfixeddescription}
                                    </p>
                                  </ReactTooltip>
                                }
                                {this.props.textosShow.promotionmembershippointsdescription &&
                                  <ReactTooltip id="promotion_membership_points">
                                    <p>
                                      {this.props.textosShow.promotionmembershippointsdescription}
                                    </p>
                                  </ReactTooltip>
                                }
                                {this.props.textosShow.promotionbonusgemsdescription &&
                                  <ReactTooltip id="promotion_bonus_gems">
                                    <p>
                                      {this.props.textosShow.promotionbonusgemsdescription}
                                    </p>
                                  </ReactTooltip>
                                }
                              </div>
                            ))}
                          </Row>
                          <Row className='justify-content-center text-light py-3'>
                            <Col xs="12" sm="5" className='text-center'>
                            {
                              (this.state.plan && this.state.payed === "stripe_all") ?
                                  <StripeForm
                                    urlGlobal={this.urlGlobal}
                                    getHeadersToSend={this.props.getHeadersToSend.bind(this)}
                                    total={this.state.plan.price}
                                    payed={this.state.payed}
                                    type="subscribe"
                                    planId={this.state.plan.id}
                                    // color='#000'
                                    onError={onError} >
                                    <StripePaymentElement
                                      urlGlobal={this.urlGlobal}
                                      getHeadersToSend={this.props.getHeadersToSend.bind(this)}
                                      total={this.state.plan.price}
                                      textosShow={this.props.textosShow}
                                      onSuccess={onSuccess}
                                      isSetup={true}
                                      onError={onError} />
                                  </StripeForm>:
                                null
                            }                                  
                            </Col>                              
                          </Row>
                        </>
                      ) : (
                        null
                      )
                    }
                    <Row className='justify-content-center text-dark py-3' style={{display: 'none'}}>
                      <Col xs="12" sm="5" className='text-center'>
                        <div className="activate_buttons">
                          <button className="button button_regular" onClick={() => this.createSubscription()}>
                            {this.props.textosShow.suscribir || "Suscribir"}
                          </button>
                      </div>                            
                      </Col>
                    </Row>
                  </>
                ) : (
                  (this.state.successPay === 1) ? (
                    <div></div>
                  ) : (
                    <Row>
                      <Col className='px-5 mt-5'>
                        {(this.state.successPay === 2) ? (
                          <div>
                              <h1 className='text-white'>
                                {this.props.textosShow.felicitacionespagocompletado || "FELICITACIONES PAGO COMPLETADO!"}
                              </h1>
                              <p className='text-white'>
                                {this.props.textosShow.seharecargadoexitosamente || "Se ha recargado exitosamente"}: 
                                {this.props.defaultData.moneda.signo} {this.state.plan.valorReal} {this.props.textosShow.atucuenta || "a tu cuenta!"}
                              </p>
                              <p>
                                <Link to="/" >
                                  {this.props.textosShow.volveraportada || "Volver a portada"}
                                </Link>
                              </p>
                          </div>
                        ) : (
                          <div>
                              <h1 className='text-white'>
                                {this.props.textosShow.pagofallido || "PAGO FALLIDO!"}
                              </h1>
                              <p className='text-white'>
                                {this.props.textosShow.nohemospodidocompletarelpago || "No   podido completar el pago. Revisa por favor que la cuenta que desees pagar tenga fondos!"}
                              </p>
                              <p>
                                <Link to="/">
                                  {this.props.textosShow.volveraportada || "Volver a portada"}
                                </Link>
                              </p>
                          </div>
                        )
                        }
                      </Col>
                    </Row>
                  )
                )
              }             
              </div>
              <Row className='text-light subscription-faq'>
                <Col>
                  <h5 className='text-center'>
                    {this.props.textosShow.promotionfaq1title}
                  </h5>
                  <div className='rules_text text-center p-0'>
                    {this.props.textosShow.promotionfaq1text}
                  </div>
                </Col>
                <Col>
                  <h5 className='text-center'>
                    {this.props.textosShow.promotionfaq2title}
                  </h5>
                  <div className='rules_text text-center p-0'>
                    {this.props.textosShow.promotionfaq2text}
                  </div>
                </Col>
              </Row>                 
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Subscription);
