import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { activate } from 'video-react/lib/actions/player';

import audioSpinningUrl from '../../sound/spinning-wheel.wav';
import audioClickUrl from '../../sound/spin-wheel-hit.wav';
import audioWinUrl from '../../sound/spin-wheel-hit-win.wav';

import spinEffectImg from '../../img/spin/spin-wheel-effect.png';

import Util, {
  customStyle,
  getPositionFromCenter,
  getSpinBgImg,
  getWheelVars,

  higherPrizeStyle,

  randWithFreqIndex
} from '../utils/utils';
import Sparks from './Sparks';
import Fire from './Fire';
import SmokeCenter from './SmokeCenter';
import BlocksShadow from './BlocksShadow';

const audioClick = new Audio(audioClickUrl);
const audioSpinning = new Audio(audioSpinningUrl);
const audioWin = new Audio(audioWinUrl);
audioClick.load();
audioSpinning.load();
audioWin.load();

export default function Spin({selectSpinItemFunc, onSelectItem, setSpinning, showLoadingIndicator, urlGlobal, defaultData, getHeadersToSend, 
    salaId, isSpinning, items, freq, idList, spinClickable, spinPrizeId}) {

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedCost, setSelectedCost] = useState(null);
  const [highestIdx, setHightesIdx] = useState(0);
  const [angle, setAngle] = useState(0);
  const [startAngle, setStartAngle] = useState(0);
  const [currentAngle, setCurrentAngle] = useState(0);
  const [boxCenterPoint, setBoxCenterPoint] = useState({});
  const [arrowSpinning, setArrowSpinning] = useState(null);
  const [bgAnimation, setBgAnimation] = useState('bg-animation');
  const [spinEffect, setSpinEffect] = useState(null);

  const selectedCostRef = useRef(selectedCost);
  selectedCostRef.current = selectedCost;

  const selectedIdRef = useRef(selectedId);
  selectedIdRef.current = selectedId;

  var box;

  useEffect(() => {
    const boxPosition = box.getBoundingClientRect();
    const boxCenterX = boxPosition.left + boxPosition.width / 2;
    const boxCenterY = boxPosition.top + boxPosition.height / 2;

    setBoxCenterPoint({ x: boxCenterX, y: boxCenterY });

  }, []);

  useEffect(() => {
    if (spinClickable)
      setBgAnimation('bg-animation');
  }, [spinClickable]);
  

  useEffect(() => {
    selectSpinItemFunc.current = selectItem;

    if (items && items.length > 0) {
      let newIdx = 0;
      items.map((item, index) => {
        if (item > items[newIdx]) {
          newIdx = index;
        }
      });

      setHightesIdx(newIdx);
    }

  }, [items]);

  useEffect(() => {
    if(spinPrizeId){
      const newItemIndx = idList.indexOf(spinPrizeId);
      setSelectedItem(newItemIndx);
      setSelectedCost(items[newItemIndx]);
      setSelectedId(spinPrizeId);
    } else {
      setSelectedItem(null);
      setSelectedCost(null);
      setSelectedId(null);
    }

  }, [spinPrizeId]);


  const onSpinningEnd = () => {   
    setTimeout(() => {
      audioWin.pause();
      onSelectItem(selectedCostRef.current, selectedIdRef.current);
    }, 400);

    setTimeout(() => {
      setSelectedItem(null);
      setSelectedId(null);
      setSelectedCost(null);
      setBgAnimation('bg-animation');
    }, 700);
  }

  const selectItem = (event) => {
    if (isSpinning) return;

    console.log('selectItem', event)

    setSpinning(true);
    
    const fromBoxCenter = getPositionFromCenter(event, boxCenterPoint);
    const newStartAngle =
      90 - Math.atan2(fromBoxCenter.y, fromBoxCenter.x) * (180 / Math.PI);
    
    setStartAngle(newStartAngle);

    const newCurrentAngle = currentAngle + (angle - startAngle);
    setCurrentAngle(newCurrentAngle);

    audioClick.play();

    if (audioSpinning)
      audioSpinning.play();

    setBgAnimation(null);
    setArrowSpinning('arrow-spinning');
    setTimeout(() => {
      setArrowSpinning('arrow-spinning-slow');
    }, 3800);
    setTimeout(() => {
      setArrowSpinning('arrow-spinning-1');
    }, 5000);
    setTimeout(() => {
      setArrowSpinning('arrow-spinning-2');
    }, 5500);

    setTimeout(() => {
      setSpinEffect('spin-effect-anim');
      audioWin.play();
    }, 7000);

    setTimeout(() => {
      setArrowSpinning(null);
      setSpinEffect(null);
      onSpinningEnd();
    }, 10400);
  }

  const deslectAll = () => {
    if (window.getSelection) {
      window.getSelection()?.removeAllRanges();
    }
  }

  const renderWheelItems = () => {
    return items.map((item, index) => (
      <div className="wheel-item" key={index} style={customStyle(index)}>
        {defaultData.moneda.signo} {item}
      </div>
    ));
  };

  const wheelVars = useMemo(() => {
    return getWheelVars(items.length, selectedItem);
  }, [selectItem]);

  const spinning = useMemo(() => {
    return selectedItem !== null ? 'spinning' : '';
  }, [selectItem]);

  return (
    <div>
      <div
        className={`wheel-container ${bgAnimation}`}
        ref={div => (box = div)}
      >
        <Sparks/>
        <div className='wheel-arrow-container'>
          <div className={`wheel-arrow ${arrowSpinning} rotate-arrow`}>
          </div>
        </div>
        <div className='wheel-wrapper'>
          <div
            className={`wheel ${spinning}`}
            style={wheelVars}
          >
            <div className='wheel-bg' style={higherPrizeStyle(highestIdx)}>
            </div>
            {renderWheelItems()}
          </div>
          <img
            className={`spin-effect ${spinEffect}`}
            src={spinEffectImg}/>
        </div>
      </div>
  </div>
  )
}
