import React from 'react';
import { withRouter } from 'react-router';

import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import flagIcon from '../../img/flagIcon.png';

const GeneralJackpotsHeader = ({ referralContests, generalJackpots, textosShow, history, loggedIn, generaljackpotModalElement, referralContestModalElement }) => {
  const games = [
    ...(referralContests || []),
    ...(generalJackpots || [])
  ]

  const showAceptGeneralJackpot = (generaljackpot) => {
    if (loggedIn) {
      if (generaljackpot !== null && !generaljackpot.type) {
        referralContestModalElement.current.setState({
          referralContest: generaljackpot
        });
        $(".mt_block.step_4").fadeOut(0, function () {

        });
        $(".mt_block.step_5").fadeOut(0, function () {

        });
        $("#referral_contest_modal_overlay").fadeIn("fast", function () {
          $("#referral_contest_modal").fadeIn("fast", function () {
            document.body.style.overflow = "hidden";
          });
        });
      } else if (generaljackpot !== null && generaljackpot.type) {
        generaljackpotModalElement.current.setState({
          generaljackpot: generaljackpot
        });
        $(".mt_block.step_4").fadeOut(0, function () {

        });
        $(".mt_block.step_5").fadeOut(0, function () {

        });
        $("#generaljackpot_modal_overlay").fadeIn("fast", function () {
          $("#generaljackpot_modal").fadeIn("fast", function () {
            document.body.style.overflow = "hidden";
          });
        });
      }
      else {
        alert(textosShow.estamodalidadnoestaaundisponible || "Esta modalidad no esta aún disponible");
      }
    }
    else {
      alert(textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta");
    }
  };

  const goToGeneralJackpots = () => {
    history.replace('/general-jackpots');
  }

  const countUnregistred = games.filter((game) => !game.registered).length

  return (
    <div className='notifications' >
      <button className='referral-contests' onClick={goToGeneralJackpots}
        data-for="bonus-button-header"
        data-tip>
        {
          countUnregistred ? (
            <span>
              {countUnregistred}
            </span>
          ) : (null)
        }
        <img src={flagIcon}
              alt=""
              height="32px"
              width="32px" />
        <p className="px-2 m-0 d-inline d-xl-none">
          {textosShow.bonusgames || 'Bonus Games'}
        </p>
      </button>
      <ReactTooltip id="bonus-button-header">
        <p>
          {textosShow.bonusgames || 'Bonus Games'}
        </p>
      </ReactTooltip>
    </div>
  )
}

export default withRouter(GeneralJackpotsHeader);