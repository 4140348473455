import React, { Component } from 'react';

import $ from 'jquery';

import errorAlert from '../../img/error_alert.png';
import Croper from '../components/Croper';

class NewsAndUpdatePopup extends Component {

  constructor(props) {
    super(props);

    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }

  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }

  close() {
    $("#alert_modal").fadeOut("fast", function () {
      $("#alert_modal_overlay7").fadeOut("fast", function () {
        document.body.style.overflow = "auto";
      });
    });
  }

  render() {
    return (
      <div style={{ zIndex: 10000 }} className="modalx-overlay close_mw"
        id="alert_modal_overlay7">
        <div className='modal_window'
          id="alert_modal">
          <button id="close_modal"
            className="link-button close_mw"
            onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                <div className='activate_icon'>
                  <img src={errorAlert}
                    alt="Alert" />
                </div>
                <Croper
                  userId={this.props.userId}
                  close={this.close.bind(this)}
                  urlGlobal={this.props.urlGlobal}
                  getHeadersToSend={this.props.getHeadersToSend}
                  showLoadingIndicator={this.props.showLoadingIndicator}
                  userData={this.props.userData}
                />
                {/* <div className='activate_buttopenFriendModalons'>
                  <button className="button button_regular button_orange"
                          onClick={this.close.bind(this)}>
                    Close
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default NewsAndUpdatePopup;