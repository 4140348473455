import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Link } from 'react-router-dom';  
import { Card } from 'reactstrap';
import gemIcon from '../../img/gem.png';
import shineImg from '../../img/race-game/bg-shine.png'
import prizeIcon from '../../img/race-game/icon-prize.png'
import questionIcon from '../../img/race-game/icon-question.png'
import Util from '../utils/utils';

export default function LadderGameCard({ item, textosShow, defaultData,  gameComponent, onPlayClick}) {

  const getCosto = () => {
    if (!item) {
      return 0;
    }
    if (item.ladderRegistered) {
      // return item.ladderStatus == 0 ? item.costoReentry : item.costoChallenge;
      return item.costoReentry;
    } else {
      return item.costo;
    }
  }

  const getButtonTitle = () => {
    if (item.modo == 'Ladder' && !item.ladderRegistered)  {
        return (textosShow.inscribirse || "Inscribirse");
    } else {
        return (textosShow.entrar || "Entrar");
    }
  }

  return (
    <>
      <Card className='tournament-card'>
        <div className={`top-container bg-slot_war`}>
          <img className='img-ladder-logo'
            src={item.logo}
          />
          <div className='divider'/>
          {/* <button className='btn-question'>
            <img
              src={questionIcon}
              />
          </button>
          <button className='btn-prize'>
            <img
              src={prizeIcon}
              />
          </button> */}
        </div>
        <div className='second-container'>
          <img className='shine-img'
            src={shineImg}/>

          <div className='mt-24 tournament-title line-camp-2'>
            {item.custom_title || (textosShow.ladder || 'ladder game')}
          </div>

          <div className='d-flex-ver detail-container'>
            <span className='indicator'>{textosShow.costedeentradamin || "Coste de entrada"}</span>
            <span className='text-value'>{defaultData.moneda.signo}{Number(getCosto()).toFixed(2) || '0.00'}</span>
          </div>

          <div className='d-flex-ver detail-container'>
            <span className='indicator'>{textosShow.premioalganador || "Premio"}</span>
            <span className='text-value'>+{item.totalLDRs} <img className='ldr-gem' src={gemIcon}/></span>
          </div>

          <div className='d-flex-ver detail-container'>
            <span className='indicator'>{textosShow.finalpremios || "Premio Final"}</span>
            <span className='text-value'>+{item.totalLFRs} <img className='ldr-gem' src={gemIcon}/></span>
          </div>

          <div className='d-flex-ver detail-container'>
            <span className='indicator'>{textosShow.tipodetorneo || "Tipo de torneo"}</span>
            <span className='text-value line-camp-1'>
              { (textosShow['laddergame'] || 'Ladder Game') }
            </span>
            <span className='guarantee'>+{textosShow.masinfo || "Más info"}</span>
          </div>

          <div className='time-remaining line-camp-1'>
            {textosShow.tiemporestante || "Tiempo restante"}
          </div>
          <div>
            <span className='text-value'>
            {item.tiempo.dias}d {item.tiempo.horas}:{item.tiempo.minutos}:{item.tiempo.segundos}
            </span>
          </div>


          <div
            className='mt_block step_6'
            id={'deposit_now_' + item.id}>
            <div className='step'></div>
            {textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir"}
            <div className=''>
              <button className="button-deposit">
                <Link to="/deposit">
                  {textosShow.depositarahora || "Depositar ahora"}
                </Link>
              </button>
            </div>
          </div>
          <div
            className='mt_block step_6'
            id={'deposit_free_' + item.id}>
            <div className='step'></div>
            {textosShow.necesitasdepositarparajugaraljuegogratis || "Necesitas depositar para jugar al juego gratis"}
            <div className=''>
              <button className="button-deposit">
                <Link to="/deposit">
                  {textosShow.depositarahora || "Depositar ahora"}
                </Link>
              </button>
            </div>
          </div>
          <button
            className="button-green"
            onClick={onPlayClick.bind(gameComponent, item)}>
                {getButtonTitle()}
          </button>
          
        </div>
        <div className='frame'>
          <div className='shadow'></div>
          <div className='effect'></div>
          <div className='effect right'></div>
        </div>
        <div>
          
        </div>
      </Card>
    </>
  )
}