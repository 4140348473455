import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { Link, Redirect, withRouter } from 'react-router-dom';
import Switch from "react-switch";
import ReactTooltip from 'react-tooltip';
import { Col, Row } from 'reactstrap';
import coinbaseIcon from '../../img/coinbase.png';
import fortumoIcon from '../../img/fortumopay.png';
import gemIcon from '../../img/gem.png';
import netellerIcon from '../../img/neteller.png';
import paymentWall from '../../img/payment-wall.png';
import paypalIcon from '../../img/paypal.png';
import webpayIcon from '../../img/webpay.png';
import stripeIcon from '../../img/visa_master.png';
import stripeAllIcon from '../../img/stripe_all.png';
import eps from '../../img/eps.png';
import bancontact from '../../img/bancontact.png';
import klarna from '../../img/klarna.png';
import giropay from '../../img/giropay.png';
import ideal from '../../img/ideal.png';
import CoinbaseButton from '../components/CoinbaseButton';
import Eagle from '../components/Eagle';
import PaymentWallButton from '../components/PaymentWallButton/';
import PaypalButton from '../components/PaypalButton';
import MembershipUtil from '../utils/membership';
import StripeCard from '../components/StripeCard';
import StripeForm from '../components/StripeForm';
import StripePaymentElement from '../components/StripePaymentElement';


const paymentMethodsIcon = {
  paypal: paypalIcon,
  coinbase: coinbaseIcon,
  fortumo: fortumoIcon,
  stripe: stripeIcon,
  stripe_all: stripeAllIcon,
  stripe_all_nl: ideal,
  stripe_all_de: giropay,
  stripe_all_fr: bancontact,
  stripe_all_at: eps,
  stripe_all_be: bancontact,
  stripe_all_us: klarna,
  neteller: netellerIcon,
  webpay: webpayIcon,
  payment_wall: paymentWall
}

class Deposit extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      plans: [],
      paymentMethods: [],
      plan: null,
      payed: props.payed || "stripe_all",
      successPay: 0,
      withPromotions: true,
    };

    this.urlGlobal = props.urlGlobal;
  }

  componentDidMount() {
    this.getPlansData();
    
  }

  componentDidUpdate(oldProps, oldState) {
    const params = new URLSearchParams(window.location.search);
    if(this.state.plans.length > oldState.plans.length && this.props.history.location.pathname === '/check_stripe' && params.has('payment_intent')){
      this.setState({
        payed: "stripe"
      });
      this.deposit(params.get('payment_intent'));
    }
  }

  getPlansData() {
    let myHeaders = this.props.getHeadersToSend();
    let clase = this;
    fetch(this.urlGlobal + "api/plan", {
      method: "POST",
      body: JSON.stringify({
        action: "getPlans"
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.default && result.default.maintenance === true && clase.props.textosShow){
        alert(clase.props.textosShow['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        clase.setState({
          plans: result.plans,
          plan: result.plans[0],
          paymentMethods: result.paymentMethods
        }, clase.startTimerBonus());
        clase.selPayed(result.paymentMethods[0].id)
      }
    });
  }

  startTimerBonus() {
    this.timerBonus = setInterval(() => {
      this.moveTimeBonus();
    }, 1000);
  }
  
  componentWillUnmount() {
    this.timerBonus = null;
    this.setState = () => {
      return;
    };
  }
  
  selPlan(plan) {
    console.log(`select plan`, plan)
    this.setState({
      plan: plan
    });
    if(window.dataLayer){
      window.dataLayer.push({'PlanPrice': plan.valorReal});
    }
    ReactPixel.track("InitiateCheckout", {
      value: this.state.plan.valorReal,
      content_type: this.state.plan.nombre,
      currency: 'USD',
      contents: {
        payed: this.state.payed,
      }
    });
  }

  selPayed(payed) {
    this.setState({
      payed: payed
    });
    if(this.state.plan){
      if(window.dataLayer){
        window.dataLayer.push({'PlanPrice': this.state.plan.valorReal});
      }
      ReactPixel.track("InitiateCheckout", {
        value: this.state.plan.valorReal,
        content_type: this.state.plan.nombre,
        currency: 'USD',
        contents: {
          payed: this.state.payed,
        }
      });
    }
  }

  deposit(paymentId) {
    this.setState({
      successPay: 1
    });
    let myHeaders = this.props.getHeadersToSend();
    let clase = this;
    fetch(this.urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "deposit",
        payed: clase.state.payed, 
        cant: clase.state.plan.valorReal,
        planId: clase.state.plan.id,
        paymentId: paymentId,
        lan: clase.props.getLanActual(),
        isClaimedPromotions: clase.isClaimedPromotions() ? '1' : '0'
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.props.history.replace('/deposit_successful');
        clase.setState({
          successPay: 2
        });
        ReactPixel.track("Purchase", {
          value: clase.state.plan.valorReal,
          content_type: clase.state.plan.nombre,
          currency: 'USD',
          contents: {
            payed: clase.state.payed,
          }
        });
      }
      else {
        clase.setState({
          successPay: 3
        });
      }
    });
  }

  errorPay() {
    this.setState({
      successPay: 3
    });
  }
  
  moveTimeBonus() {
    let plans = this.state.plans;
    if (plans) {
      for (let i = 0; i < plans.length; i++) {
        for (let j = 0; j < plans[i].bonus.length; j++) {
          let dias = plans[i].bonus[j].tiempo.dias;
          let horas = plans[i].bonus[j].tiempo.horas;
          let minutos = plans[i].bonus[j].tiempo.minutos;
          let segundos = plans[i].bonus[j].tiempo.segundos;
          segundos--;
          if (segundos < 0) {
            if (minutos > 0) {
              segundos = 59;
              minutos--;
            }
            else {
              segundos = 0;
            }
          }
          if (minutos < 0) {
            if (horas > 0) {
              minutos = 59;
              horas--;
            }
            else {
              minutos = 0;
            }
          }
          if (horas < 0) {
            if (dias > 0) {
              horas = 23;
              dias--;
            }
            else {
              horas = 0;
            }
          }
          if (dias < 0) {
            dias = 0;
          }
          if (dias > 0 || horas > 0 || minutos > 0 || segundos > 0) {
            plans[i].bonus[j].tiempo = {
              dias: dias,
              horas: horas,
              minutos: minutos,
              segundos: segundos
            };
          }
          else {
            plans[i].bonus.splice(j, 1);
          }
        }
      }
    }
    this.setState({
      plans: plans
    });
  }

  getPayerObj() {
    return this.state.paymentMethods.find(paymentMethod => paymentMethod.id === this.state.payed)
  }

  setWithPromotions(withPromotions) {
    this.setState({withPromotions});
  }

  getBonus = (plan) => {
    return plan && plan.bonus.find(b => b.payer === this.state.payed);
  }

  isClaimedPromotions = () => {
    return this.getBonus(this.state.plan) && this.state.withPromotions;
  }

  render() {
    const onSuccess = (paymentId) => {
      console.log('Successful payment!', paymentId);
      this.deposit(paymentId);
    };

    const onError = (err) => {
      console.log('Erroneous payment OR failed to load script!', err);
      this.errorPay();
    };

    const onCancel = (data) => {
      console.log('Cancelled payment!', data);
      this.errorPay();
    };


    if (!this.props.loggedIn) {
      return (<Redirect to={'/'} />);
    }
    
    return (
      <div className='content' id='profile'>
        <div className='wrapper'>
          <div className='smoke_center'></div>
          <div className='page_content'>
            <div className='c_center'>
              <div className='c_top'>
                <div className='c_bottom'>
                  <Eagle></Eagle>
                  <div className='leaves'></div>
                  <Row className='justify-content-center'>
                    <h4>
                      {this.props.textosShow.depositar || "Depositar"}
                    </h4>
                  </Row>
                  {
                    this.state.successPay === 0 ? (
                      <>
                        <Row className='justify-content-center radio_r payment_system py-3 my-0'>
                          { 
                            this.state.paymentMethods ? (
                            this.state.paymentMethods.map((paymentMethod) => (
                              <>
                                <input 
                                  id={`payment_${paymentMethod.id}`}
                                  type="radio"
                                  key={paymentMethod.id}
                                  checked={this.state.payed === paymentMethod.id}
                                  onClick={() => this.selPayed(paymentMethod.id)}
                                  onChange={() => {}} />
                                <label htmlFor={`payment_${paymentMethod.id}`}>
                                  <img 
                                    src={paymentMethodsIcon[`${paymentMethod.id}_${this.props.defaultData.pais.slug.toLowerCase()}`] ? paymentMethodsIcon[`${paymentMethod.id}_${this.props.defaultData.pais.slug.toLowerCase()}`] : paymentMethodsIcon[paymentMethod.id]}
                                    alt={paymentMethod.name} />
                                </label>
                              </>
                            ))) : null
                          }
                        </Row>
                        {
                          this.state.plans && (!this.getPayerObj() || this.getPayerObj().withPlans) ? (
                            <>
                              <Row className={`justify-content-center text-dark py-3 m-0 filter-bar`}>
                                <Col className='text-right'>
                                  <span>{this.props.textosShow.depositwithpromotions || "With Promotions"}</span>
                                  <span
                                    data-tip
                                    data-for="deposit-blocking-promotions"
                                    className="iconInfo pl-1">
                                    <FontAwesomeIcon icon={faInfo} />
                                  </span>
                                  <ReactTooltip id="deposit-blocking-promotions">
                                    <p>
                                      {this.props.textosShow.depositblockingpromotions || "Si acepta las promociones, el deposito sera bloqueado"}
                                    </p>
                                  </ReactTooltip>
                                </Col>
                                <Col className='text-left'>
                                  <Switch offColor="#333" onColor="#050" onChange={this.setWithPromotions.bind(this)} checked={this.state.withPromotions} />
                                </Col>
                              </Row>
                              <Row className='justify-content-center radio_r payment_sum py-3'>
                                {this.state.plans.map((plan) => (
                                  <div key={plan.id}>
                                    <input 
                                      id={'deposit_plan_' + plan.id}
                                      name="radio2" 
                                      key={plan.id}
                                      value={plan.id}
                                      type="radio"
                                      defaultChecked={plan.id === this.state.plan.id}
                                      onClick={() => this.selPlan(plan)} />
                                    <label htmlFor={'deposit_plan_' + plan.id}>
                                      <img 
                                        src={plan.img}
                                        alt={this.props.defaultData.moneda.signo + ' ' + plan.valor} />
                                      <span>
                                        {this.props.defaultData.moneda.signo} {plan.valor}
                                      </span>
                                      {
                                        this.state.withPromotions && this.getBonus(plan) ? (
                                          <>
                                            <small className="deposit-bonus-time">
                                              {this.getBonus(plan).tiempo.dias}d {this.getBonus(plan).tiempo.horas}h {this.getBonus(plan).tiempo.minutos}m {this.getBonus(plan).tiempo.segundos}s
                                            </small>
                                            {this.getBonus(plan).type === 'gems' && (
                                              <small className="deposit-bonus-gems">
                                                +{this.getBonus(plan).gemas}
                                                <img 
                                                  src={gemIcon} 
                                                  alt="Gems"  
                                                  data-tip
                                                  data-for="promotion_bonus_gems"
                                                />
                                              </small>
                                            )}
                                            {this.getBonus(plan).type === 'membership_points' && (
                                              <small className="deposit-bonus-membership-points">
                                                +{parseInt(this.getBonus(plan).membership_points)} Pts
                                                <br />
                                                <img 
                                                  src={MembershipUtil.getMembershipIcon({})} 
                                                  alt={this.getBonus(plan).membership_name} 
                                                  data-tip
                                                  data-for="promotion_membership_points"
                                                  />
                                              </small>
                                            )}
                                            {this.getBonus(plan).type === 'membership_fixed' && (
                                              <>
                                                <small className="deposit-bonus-membership">
                                                  <img 
                                                    src={MembershipUtil.getMembershipIcon({'membershipLevel': this.getBonus(plan).membership_level})} 
                                                    alt={this.getBonus(plan).membership_name}  
                                                    data-tip
                                                    data-for="promotion_membership_fixed"
                                                    />
                                                </small>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          null
                                        )
                                      }
                                    </label>
                                    {this.props.textosShow.promotionmembershipfixeddescription &&
                                      <ReactTooltip id="promotion_membership_fixed">
                                        <p>
                                          {this.props.textosShow.promotionmembershipfixeddescription}
                                        </p>
                                      </ReactTooltip>
                                    }
                                    {this.props.textosShow.promotionmembershippointsdescription &&
                                      <ReactTooltip id="promotion_membership_points">
                                        <p>
                                          {this.props.textosShow.promotionmembershippointsdescription}
                                        </p>
                                      </ReactTooltip>
                                    }
                                    {this.props.textosShow.promotionbonusgemsdescription &&
                                      <ReactTooltip id="promotion_bonus_gems">
                                        <p>
                                          {this.props.textosShow.promotionbonusgemsdescription}
                                        </p>
                                      </ReactTooltip>
                                    }
                                  </div>
                                ))}
                              </Row>
                            </>
                          ) : (
                            null
                          )
                        }
                        <Row className='justify-content-center text-dark py-3'>
                          <Col xs="12" sm="5" className='text-center'>
                            {
                              (this.state.plan && this.state.payed === "paypal") ? (
                                <PaypalButton 
                                  total={this.state.plan.valorReal}
                                  depositState={this.state}
                                  onSuccess={onSuccess}
                                  onError={onError}
                                  onCancel={onCancel} />
                              ) : (
                                null
                              )
                            }
                            {
                              (this.state.plan && this.state.payed === "stripe") ?
                                  <StripeForm
                                    urlGlobal={this.urlGlobal}
                                    getHeadersToSend={this.props.getHeadersToSend.bind(this)}
                                    total={this.state.plan.valorReal}
                                    payed={this.state.payed}
                                    type="deposit"
                                    planId={this.state.plan.id}
                                    withPromotions={this.state.withPromotions}
                                    onError={onError} >
                                    <StripeCard
                                      urlGlobal={this.urlGlobal}
                                      getHeadersToSend={this.props.getHeadersToSend.bind(this)}
                                      total={this.state.plan.valorReal}
                                      textosShow={this.props.textosShow}
                                      onSuccess={onSuccess}
                                      onError={onError} />
                                  </StripeForm>:
                                null
                            }
                            {
                              (this.state.plan && this.state.payed === "stripe_all") ?
                                  <StripeForm
                                    urlGlobal={this.urlGlobal}
                                    getHeadersToSend={this.props.getHeadersToSend.bind(this)}
                                    total={this.state.plan.valorReal}
                                    payed={this.state.payed}
                                    type="deposit"
                                    planId={this.state.plan.id}
                                    withPromotions={this.state.withPromotions}
                                    onError={onError} >
                                    <StripePaymentElement
                                      urlGlobal={this.urlGlobal}
                                      getHeadersToSend={this.props.getHeadersToSend.bind(this)}
                                      total={this.state.plan.valorReal}
                                      textosShow={this.props.textosShow}
                                      onSuccess={onSuccess}
                                      onError={onError} />
                                  </StripeForm>:
                                null
                            }
                            {
                              (this.state.plan && this.state.payed === "coinbase") ? (
                                <CoinbaseButton 
                                  urlGlobal={this.urlGlobal}
                                  getHeadersToSend={this.props.getHeadersToSend.bind(this)}
                                  total={this.state.plan.valorReal}
                                  onSuccess={onSuccess}
                                  onError={onError}
                                  onCancel={onCancel} />
                              ) : (
                                null
                              )
                            }
                            {
                              (this.state.plan && this.state.payed === "fortumo") ? (
                                <a id="fmp-button" href="#" rel={`${this.getPayerObj().config.ref}`}>
                                  <img src="https://assets.fortumo.com/fmp/fortumopay_150x50_red.png" width="150" height="50" alt="Mobile Payments by Fortumo" border="0" />
                                  { window.Fortumo && window.Fortumo.fmpWidget() }
                                </a>
                              ) : (
                                null
                              )
                            }
                            {
                              (this.state.plan && this.state.payed === "payment_wall") ? (
                                <PaymentWallButton 
                                  urlGlobal={this.urlGlobal}
                                  getHeadersToSend={this.props.getHeadersToSend.bind(this)}
                                  total={this.state.plan.valorReal}
                                  onSuccess={onSuccess}
                                  onError={onError}
                                  onCancel={onCancel} />
                              ) : (
                                null
                              )
                            }
                            {
                              (this.state.plan && this.state.payed === "neteller") ? (
                                <p>
                                  {this.props.textosShow.aunnoestalistaestaviadepago || "Aun no esta lista esta via de pago"}
                                </p>
                              ) : (
                                null
                              )
                            }
                            {
                              (this.state.plan && this.state.payed === "webpay") ? (
                                <p>
                                  {this.props.textosShow.aunnoestalistaestaviadepago || "Aun no esta lista esta via de pago"}
                                </p>
                              ) : (
                                null
                              )
                            }
                          </Col>
                        </Row>
                      </>
                    ) : (
                      (this.state.successPay === 1) ? (
                        <div></div>
                      ) : (
                        <Row>
                          <Col>
                            {(this.state.successPay === 2) ? (
                              <div>
                                  <h1>
                                    {this.props.textosShow.felicitacionespagocompletado || "FELICITACIONES PAGO COMPLETADO!"}
                                  </h1>
                                  <p>
                                    {this.props.textosShow.seharecargadoexitosamente || "Se ha recargado exitosamente"}: 
                                    {this.props.defaultData.moneda.signo} {this.state.plan.valorReal} {this.props.textosShow.atucuenta || "a tu cuenta!"}
                                  </p>
                                  <p>
                                    <Link to="/" >
                                      {this.props.textosShow.volveraportada || "Volver a portada"}
                                    </Link>
                                  </p>
                              </div>
                            ) : (
                              <div>
                                  <h1>
                                    {this.props.textosShow.pagofallido || "PAGO FALLIDO!"}
                                  </h1>
                                  <p>
                                    {this.props.textosShow.nohemospodidocompletarelpago || "No hemos podido completar el pago. Revisa por favor que la cuenta que desees pagar tenga fondos!"}
                                  </p>
                                  <p>
                                    <Link to="/">
                                      {this.props.textosShow.volveraportada || "Volver a portada"}
                                    </Link>
                                  </p>
                              </div>
                            )
                            }
                          </Col>
                        </Row>
                      )
                    )
                  }
                  <Row className='text-dark pt-3'>
                    <Col>
                      <h5 className='text-center'>
                        {this.props.textosShow.comoempezarunjuego}
                      </h5>
                      <div className='rules_text text-center p-0'>
                        {this.props.textosShow.comoempezarunjuegoparrafo}
                      </div>
                    </Col>
                    <Col>
                      <h5 className='text-center'>
                        {this.props.textosShow.otrainformacion}
                      </h5>
                      <div className='rules_text text-center p-0'>
                        {this.state.withPromotions ? 
                          (this.props.textosShow.otrainformacionparrafoconpromocion || "No, you need play games for unblock the money")
                           : 
                          this.props.textosShow.otrainformacionparrafo
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Deposit);
