import React, { Component } from 'react';

import $ from 'jquery';

import { Player } from 'video-react';

class VideoModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  }
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }
  
  close() {
    this.props.onClose();
    $("#video_modal").fadeOut("fast", function() {
      $("#video_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  }
  
  render() {
    return (
      <div className="modalx-overlay close_mw"
           id="video_modal_overlay">
        <div className='modal_window'
             id="video_modal">
          <button id="close_modal"
                  className="link-button close_mw"
                  onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                <div className='activate_icon'>
                </div>
                <h4>
                  {this.props.title}
                </h4>
                <div className='activate_text'>
                  <Player
                    src={this.props.url}
                    className='react-player fixed-bottom'
                    width='100%'
                    height='100%'
                  ></Player>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default VideoModal;