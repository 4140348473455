import React, { Component } from 'react';

import $ from 'jquery';
import { withRouter } from 'react-router';

class GeneralJackpotModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      generaljackpot: null
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  };
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  };
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  };
  
  keyDown(e) {
    if (e.keyCode === 27) {
      this.close();
    }
  };
  
  close() {
    $("#generaljackpot_modal").fadeOut("fast", function() {
      $("#generaljackpot_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  };
  
  play() {
    let clase = this;
    if (clase.props.loggedIn) {
      fetch(clase.props.urlGlobal + "api/generaljackpot", {
        method: "POST",
        body: JSON.stringify({
          action: "setPlayer",
          lan: clase.props.getLanActual(),
          id: clase.state.generaljackpot.id
        }),
        headers: clase.props.getHeadersToSend()
      }).then(function(response) {
        return response.json();
      }).then(function(result) {
        if (!result.success) {
          alert(result.msg);
        }
        else {
          clase.props.history.replace("/general-jackpot/" + clase.state.generaljackpot.id);
          clase.close();
        }
      });
    }
    else{
      alert(clase.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta.");
    }
  };
  
  render() {
    return (
      <div 
        className="modalx-overlay close_mw"
        id="generaljackpot_modal_overlay">
        <div 
          className='modal_window' 
          id="generaljackpot_modal">
          <button 
            id="close_modal" 
            className="link-button close_mw"
            onClick={this.close.bind()}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                {
                  this.state.generaljackpot ? (
                    <div>
                      <h5>
                        {this.props.textosShow.totalpremios || "Total Premios"}
                      </h5>
                      <div className='premio'>
                        <div>
                          {this.props.defaultData.moneda.signo} {this.state.generaljackpot.dinero}
                        </div>
                      </div>
                      <table>
                        <thead>
                          <tr className='subtitle'>
                            <td colSpan="2">
                              <span>
                                <center>
                                  {this.props.textosShow.totalpremios || "Premios"}
                                </center>
                              </span>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.generaljackpot.posiciones.map((posicion) =>
                              <tr key={posicion.posicion}>
                                <td>
                                  <span>
                                    #{posicion.posicion}
                                  </span>
                                </td>
                                <td className='entry_pay'>
                                  <span>
                                    {this.props.defaultData.moneda.signo} {posicion.dinero}
                                    <small className="tour_add">
                                      + {posicion.gemas}
                                    </small>
                                  </span>
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                      <div className='vs_player'>
                        <h6>
                          {this.props.textosShow['generaljackpot_'+this.state.generaljackpot.type] || this.state.generaljackpot.type}
                        </h6>
                      </div>
                      <div className='modal_button'>
                        <button 
                          className='button button_regular'
                          onClick={this.play.bind(this)}>
                          {this.props.textosShow.inscribirse || "Inscribirse"}
                        </button>
                      </div>
                    </div>
                  ) : (
                    null
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default GeneralJackpotModal;
