import React, { useEffect, useMemo } from "react";
import moment from 'moment';
import FechaSel from "./FechaSel"
import { Formik } from 'formik';
import * as Yup from 'yup';

const EmailVerificationValidateForm = ({ resendTimer, setResendTimer, sendEmailVerification, timestamp, getDataUser, userData, urlGlobal, getHeadersToSend, textosShow, showLoadingIndicator, openAlertModal, lastEmail }) => {
  const MailValidationSchema = useMemo(() => Yup.object().shape({
    nombre: Yup.string()
      .min(2, textosShow.formatoinvalido)
      .max(100, textosShow.formatoinvalido)
      .required(textosShow.notempty),
    apellidos: Yup.string()
      .min(2, textosShow.formatoinvalido)
      .max(100, textosShow.formatoinvalido)
      .required(textosShow.notempty),
    fechaNac: Yup.object().shape({
      dia: Yup.number()
        .required(textosShow.notempty),
      mes: Yup.number()
        .required(textosShow.notempty),
      ano: Yup.number()
        .required(textosShow.notempty),
    }),
    code: Yup.string()
      .min(6, textosShow.formatoinvalido)
      .max(6, textosShow.formatoinvalido)
      .required(textosShow.pleaseenterthevalidationcode || 'Please enter the validation code'),
  }), [textosShow]);

  useEffect(() => {
    let timeout = null;
    if (resendTimer > 0) {
      timeout = setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [resendTimer]);

  const ValidateEmailCode = (values) => {
    showLoadingIndicator(true);
    fetch(urlGlobal + "api/register", {
      method: "POST",
      body: JSON.stringify({
        action: "ValidateEmailCode",
        ...values,
        email: lastEmail,
      }),
      headers: getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      showLoadingIndicator(false);
      if (!result.success) {
        openAlertModal(textosShow[result.slug] || result.msg);
      } else {
        getDataUser();
      }
    });
  };

  return (
    <Formik
      initialValues={{
        nombre: userData.nombre,
        apellidos: userData.apellidos,
        fechaNac: userData.fechaNac,
        code: "",
      }}
      validationSchema={MailValidationSchema}
      onSubmit={ValidateEmailCode}
    >
      {({ errors, touched, setFieldValue, handleChange, handleBlur, values, submitForm }) => (
        <>
          <div className="row">
            <div className="col-12">
              <section id="content1">
                <table>
                  <tbody>
                    <tr>
                      <td className='td_title'>
                        <h5>
                          {textosShow.email || "Email"}:
                        </h5>
                      </td>
                      <td className='pl-4'>
                        <b>
                          {lastEmail}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td className='td_title'>
                        <h5>
                          {textosShow.fechadenacimiento || "Fecha de nacimiento"}:
                        </h5>
                      </td>
                      <td className=''>
                        <div className='table_content'>
                          <div className={!touched.fechaNac || !errors.fechaNac ? 'input' : 'input error'}>
                            {
                              touched.fechaNac && errors.fechaNac &&
                              <div className='error_reason'>
                                {errors.fechaNac[0]}
                              </div>
                            }
                            <FechaSel
                              ySince="70"
                              yTo="10"
                              dateSel={values.fechaNac}
                              onChange={(val) => setFieldValue('fechaNac', val)}
                              textosShow={textosShow} />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>
                          {textosShow.nombre || "Nombre"}:
                        </h5>
                      </td>
                      <td className=''>
                        <div className='table_content'>
                          <div className={!touched.nombre || !errors.nombre ? 'input' : 'input error'}>
                            {
                              touched.nombre && errors.nombre &&
                              <div className='error_reason'>
                                {errors.nombre}
                              </div>
                            }
                            <input type="text"
                              name="nombre"
                              className='w-100'
                              value={values.nombre}
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>
                          {textosShow.apellido || "Apellido"}:
                        </h5>
                      </td>
                      <td className=''>
                        <div className='table_content'>
                          <div className={!touched.apellidos || !errors.apellidos ? 'input' : 'input error'}>
                            {
                              touched.apellidos && errors.apellidos &&
                              <div className='error_reason'>
                                {errors.apellidos}
                              </div>
                            }
                            <input type="text"
                              className='w-100'
                              name="apellidos"
                              value={values.apellidos}
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>
                          {textosShow.validationcode || "Validation code"}:
                        </h5>
                      </td>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                          <div className='table_content'>
                            <div className={!touched.code || !errors.code ? 'input' : 'input error'}>
                              {
                                touched.code && errors.code &&
                                <div className='error_reason'>
                                  {errors.code}
                                </div>
                              }
                              <input type="text"
                                name="code"
                                value={values.code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={textosShow.validationcode || "Validation code"} />
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-secondary button_mini"
                            disabled={resendTimer > 0}
                            onClick={() => sendEmailVerification()}
                          >
                            {resendTimer > 0 ?
                              (textosShow.resendvalidationcodein || "Resend in {time}s").replace('{time}', resendTimer) :
                              (textosShow.resendvalidationcodein || "Resend code")
                            }
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="text-center">
                {(textosShow.theemailwassentat || "The email was sent at {time}").replace('{time}', moment(timestamp * 1000).utc().format('YYYY-MM-DD HH:mm:ss'))}
              </p>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-12 col-xl-5 d-flex justify-content-center">
              <button type="button" className='button button_regular' onClick={submitForm}>
                {textosShow.validatecode || "Validate code"}
              </button>
            </div>
          </div>
        </>
      )}
    </Formik >
  )
}

export default EmailVerificationValidateForm