import React, { Component } from 'react';

import $ from 'jquery';

class PrivacyModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      titulo: "",
      texto: ""
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  }
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
    
    let me = this;
    fetch(me.props.urlGlobal + "api/page", {
      method: "POST",
      body: JSON.stringify({
        action: "getPage",
        page: "politicasdeprivacidad",
        lan: me.props.getLanActual()
      }),
      headers: me.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        me.setState({
          titulo: result.page.titulo,
          texto: result.page.texto
        });
      }
    });
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }
  
  close() {
    $("#privacy_modal").fadeOut("fast", function() {
      $("#privacy_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  }
  
  render() {
    return (
      <div className="modalx-overlay close_mw"
           id="privacy_modal_overlay">
        <div className='modal_window' 
             id="privacy_modal">
          <button id="close_modal"
                  className="link-button close_mw"
                  onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                {
                  this.state.titulo ? (
                    <h5>
                      {this.state.titulo}
                    </h5>
                  ) : null
                }
                <div className='modal_form'>
                  <div className='terms_text'>
                    <div className='terms_scroll'>
                      {
                        this.state.texto ? (
                          <div dangerouslySetInnerHTML={{__html: this.state.texto}}></div>
                        ) : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default PrivacyModal;