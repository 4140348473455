import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Util from '../utils/utils';
import ReactTooltip from 'react-tooltip';

export default function ProfileAndLevel({ textosShow, userData, className = "", mini = false }) {
  const level = userData.level;
  const levelProgress = userData.levelProgress;
  const foto = userData.foto;
  const name = userData.name;


  return (
    <>
      <div
        data-tip data-for="generalLevelTooltip"
        className={[
          mini ? "mini-profile-level" : "profile-level",
          ...(Array.isArray(className) ? className : [className])
        ].join(" ")}>
        <CircularProgressbarWithChildren
          strokeWidth={10}
          value={levelProgress > 50 ? levelProgress : 0}
          styles={buildStyles({
            pathColor: "#d0d217",
            trailColor: "#060405",
            strokeLinecap: "butt"
          })}
        >
          <CircularProgressbarWithChildren
            strokeWidth={10}
            value={levelProgress > 50 ? 50 : levelProgress}
            styles={buildStyles({
              pathColor: "#1c990d",
              trailColor: "transparent",
              strokeLinecap: "butt"
            })}
          >
            <img
              src={foto} onError={(e) => {
                e.target.src = Util.getDefaultAvatar();
              }}
              alt={name} />
            <div className="level">
              <span>LVL:{level}</span>
            </div>
          </CircularProgressbarWithChildren>
        </CircularProgressbarWithChildren>
      </div>
      <ReactTooltip id="generalLevelTooltip">
        <p>
          XP: {parseInt(levelProgress)}%
        </p>
      </ReactTooltip>
    </>
  );
}