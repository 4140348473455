import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassEnd, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import $ from 'jquery';

import SmokeCenter from '../components/SmokeCenter';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';

import winIcon from '../../img/win_icon.png';
import waitIcon from '../../img/wait_icon.png';
import warningIcon from '../../img/warn_icon.png';
import crownRed from '../../img/crown-red.png';
import crown from '../../img/crown.png';
import ReactTooltip from 'react-tooltip';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import boxRedIcon from '../../img/boxRedIcon.png';

import gemIcon from '../../img/gem.png';
import coinIcon from '../../img/coin_icon_big.png';

class Ladder extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ladder: null,
      helpPage: {}
    };
  };

  componentDidMount() {
    if (this.props.match.params.ladderId) {
      this.getDataLadder();
      if (process.env.REACT_APP_GAME_AUTOREFRESH_TIMEOUT) {
        this.timerGetData = setInterval(() => {
          this.getDataLadder();
        }, process.env.REACT_APP_GAME_AUTOREFRESH_TIMEOUT);
      }
    }

    //mover el tiempo de cada sala
    this.timerTime = setInterval(() => {
      this.moveTime();
      this.movePartialTime();
    }, 1000);
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.match.params.ladderId !== nextProps.match.params.ladderId) {
      this.props = nextProps;
      this.componentDidMount();
    }
    return true;
  }

  componentWillUnmount() {
    clearInterval(this.timerGetData);
    clearInterval(this.timerTime);
  };

  getDataLadder() {
    let clase = this;
    fetch(clase.props.urlGlobal + "api/juego", {
      method: "POST",
      body: JSON.stringify({
        action: "getLadderPosiciones",
        id: clase.props.match.params.ladderId,
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        clase.setState({
          ladder: result.ladder
        });

        clase.getLadderPage(result.ladder);

      }
    });
  };

  getLadderPage(ladder) {
    let clase = this;
    clase.setState({
      loading: true
    });
    fetch(clase.props.urlGlobal + "api/page", {
      method: "POST",
      body: JSON.stringify({
        action: "getPage",
        // page: "generaljackpot_" + generaljackpot.type,
        page: 'ladder',
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        clase.setState({
          helpPage: {
            titulo: result.page.titulo,
            texto: result.page.texto
          }
        });
      }
    });
  }

  moveTime() {
    let ladder = this.state.ladder;
    if (ladder) {
      let dias = ladder.tiempo.dias;
      let horas = ladder.tiempo.horas;
      let minutos = ladder.tiempo.minutos;
      let segundos = ladder.tiempo.segundos;

      segundos--;
      if (segundos < 0) {
        if (minutos > 0) {
          segundos = 59;
          minutos--;
        }
        else {
          segundos = 0;
        }
      }
      if (minutos < 0) {
        if (horas > 0) {
          minutos = 59;
          horas--;
        }
        else {
          minutos = 0;
        }
      }
      if (horas < 0) {
        if (dias > 0) {
          horas = 23;
          dias--;
        }
        else {
          horas = 0;
        }
      }
      if (dias < 0) {
        dias = 0;
      }

      ladder.tiempo = {
        dias: dias,
        horas: horas,
        minutos: minutos,
        segundos: segundos
      };

      this.setState({
        ladder: ladder
      });
    }
  };

  movePartialTime() {
    let ladder = this.state.ladder;
    if (ladder) {
      let dias = ladder.nextRewards.dias;
      let horas = ladder.nextRewards.horas;
      let minutos = ladder.nextRewards.minutos;
      let segundos = ladder.nextRewards.segundos;

      segundos--;
      if (segundos < 0) {
        if (minutos > 0) {
          segundos = 59;
          minutos--;
        }
        else {
          segundos = 0;
        }
      }
      if (minutos < 0) {
        if (horas > 0) {
          minutos = 59;
          horas--;
        }
        else {
          minutos = 0;
        }
      }
      if (horas < 0) {
        if (dias > 0) {
          horas = 23;
          dias--;
        }
        else {
          horas = 0;
        }
      }
      if (dias < 0) {
        dias = 0;
      }

      if(dias === 0 && horas === 0 && minutos === 0 && segundos === 0){
        ladder.nextRewards = ladder.periodDuration;
      } else {
        ladder.nextRewards = {
          dias: dias,
          horas: horas,
          minutos: minutos,
          segundos: segundos
        };
      }

      this.setState({
        ladder: ladder
      });
    }
  };

  getRewardRemainingTime() {
    let ladder = this.state.ladder;
    if (!ladder) return;
    
    let days = ladder.nextRewards.dias;
    let hour = ladder.nextRewards.horas;
    let minute = ladder.nextRewards.minutos;
    let second = ladder.nextRewards.segundos;
    
    return (`${hour}`.padStart(2, '0') + ':' + `${minute}`.padStart(2, '0') + ':' + `${second}`.padStart(2, '0'));
  }

  canReentry = (player) => {
    return ( player.jugador.id == this.props.userData.id 
              && this.state.ladder['ladderStatus'] == 0 
              && this.state.ladder['reentry'] == 1 );
  }

  canChallenge = (player) => {

    const me = this.getMePlayer();
    if (!me) return false;

    const range = Math.floor(Math.pow(2, (Math.ceil(Math.log2(me.position)) - 1)));

    return ( player.position < me.position && player.position >= range
      && this.state.ladder['ladderStatus'] == 1);
  }

  checkChallenge = (player) => {
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(clase.props.urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "checkChallenge",
        usuario: player.jugador.id,
        sala: clase.state.ladder.id,
        lan: lan
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.challenge(player);
      }
      else {
        alert(result.msg);
        if (result.ladderStatus == 2) {
          clase.props.history.replace('/');
        }
      }
    });
  }

  getMePlayer = () => {
    return this.state.ladder.players.find((item) => item.jugador.id == this.props.userData.id);
  }

  reentry = () => {
    let clase = this;
    if (clase.props.loggedIn) {
      fetch(clase.props.urlGlobal + "api/juego", {
        method: "POST",
        body: JSON.stringify({
          action: "setLadderPlayer",
          lan: clase.props.getLanActual(),
          id: clase.state.ladder.id,
          reentry: 1
        }),
        headers: clase.props.getHeadersToSend()
      }).then(function(response) {
        return response.json();
      }).then(function(result) {
        if (!result.success) {
          alert(result.msg);
        }
        else {
          if (result.ladder_slug && result.game_slug)
          window.location = clase.props.urlGlobal + "playGame/" + result.game_slug 
                            + "/?gameMode=" + clase.state.ladder.id 
                            + "&partida=" + result.partida_id 
                            + "&game=" + result.ladder_slug
                            + "&ladder_player_id=" + result.ladder_player_id
                            + "&ladder_entrada_id=" + result.ladder_entrada_id;
        }
      });
    }
    else{
      alert(clase.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta.");
    }
  };

  challenge = (player) => {
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(clase.props.urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "desafiar",
        usuario: player.jugador.id,
        sala: clase.state.ladder.id,
        lan: lan
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        alert(result.msg);
      }
      else {
        alert(result.msg);
      }
    });
  }

  onReentryClicked = (player) => {
    if (this.state.ladder.costoReentry > this.props.userData.money) {
      this.showDepositNow(player.position);
    } else {
      this.reentry();
    }
  }

  onChallengeClicked = (player) => {
    if (this.state.ladder.costoChallenge > this.props.userData.money) {
      this.showDepositNow(player.position);
    } else {
      this.checkChallenge(player);
      // this.challenge(player);
    }
  }

  showDepositNow = (id) => {
    $(".mt_block.step_4").fadeOut(0, function() {
      $("#deposit_now_" + id).fadeIn(0);
    });
  }

  isTimeExpired = () => {
    return (this.state.ladder && this.state.ladder.tiempo.timeRemain == 0);
  }

  isEnded = () => {
    return (this.state.ladder && this.state.ladder.ladderStatus == 2);
  }

  getLadderRewardTypeTitle() {
    if (!this.state.ladder || !this.state.ladder.ladderRewardType || !this.state.ladder.ladderRewardMinutes) {
      return ''
    }

    var rewardTypeSlug = 'dailypremios';
    var minutes = '';

    switch (this.state.ladder.ladderRewardType) {
      case 'Daily':
        rewardTypeSlug = 'dailypremios';
        break;

      case 'Hourly':
        rewardTypeSlug = 'hourlypremios';
        break;

      case 'Minutes':
        minutes = this.state.ladder.ladderRewardMinutes;
        rewardTypeSlug = 'minutespremios';
        break;
    }
    
    var defaultTitle = (this.state.ladder.ladderRewardType + " Premios");
    return minutes + ' ' + (this.props.textosShow[rewardTypeSlug] || defaultTitle);
  }

  render() {
    if (!this.props.loggedIn || (this.state.ladder && !this.state.ladder.registered)) {
      return (<Redirect to={'/'} />);
    }

    /*if (this.isTimeExpired() || this.isEnded()) {
      return (<Redirect to={'/'} />);
    }*/
    
    return (
      <div>
        <div
          className='content lg red'
          id='ladder'>
          <div className='wrapper'>
            <SmokeCenter></SmokeCenter>
            <div className='page_content'>
              <div className='c_center'>
                <div className='c_top'>
                  <div className='c_bottom'>
                    <Eagle></Eagle>
                    <Leaves></Leaves>
                    <div className='page_block'>
                      {
                        this.state.ladder ? (
                          <div class="cloth game-ui">
                            <Container className='px-5 pt-5'>
                              <Row>
                                <Col>
                                  <div className="tour_info reamaining text-white w-100 text-center">
                                    {
                                      !this.state.ladder.closed ? (
                                        <span>
                                          {this.props.textosShow.tiemporestante || "Tiempo restante"}:
                                            <span className="iconTimeRemaining pr-1 ml-2">
                                              <FontAwesomeIcon
                                                icon={faHourglassHalf}
                                                size="lg" />
                                            </span>
                                          <h4 className="remaining_time pt-3">
                                            {this.state.ladder.tiempo.dias}d {this.state.ladder.tiempo.horas}:{this.state.ladder.tiempo.minutos}:{this.state.ladder.tiempo.segundos}
                                          </h4>
                                        </span>
                                      ) : (
                                        <h4 className="remaining_time pt-3">
                                          {this.props.textosShow.termino || "Finalizado"}
                                        </h4>
                                      )
                                    }
                                  </div>
                                </Col>
                              </Row>
                              <div className='game_main'>
                                <div className='game_icon_main'>
                                </div>
                              </div>
                              <div className='premio mt-5'>
                                <h5>
                                  {this.props.textosShow.costo || "Cost"}
                                </h5>
                                <div>
                                  <img
                                    src={coinIcon}
                                    />
                                  <span>
                                    {this.props.defaultData.moneda.signo} {this.state.ladder.costoReentry}
                                  </span>
                                </div>
                              </div>
                              <div className='premio mt-2'>
                                <h5>
                                  {this.getLadderRewardTypeTitle()}
                                </h5>
                                <div>
                                  <img
                                    src={gemIcon}
                                    />
                                  <span>
                                    {this.state.ladder.totalLDRs}
                                  </span>
                                </div>
                              </div>
                              <div className='premio mt-1'>
                                <h5>
                                  {this.props.textosShow.finalpremios || "Final Reward Total"}
                                </h5>
                                <div>
                                  <img
                                    src={gemIcon}
                                    />
                                  <span>
                                    {this.state.ladder.totalLFRs}
                                  </span>
                                </div>
                              </div>
                              <div className="bg-semitransparent shadow card p-4"
                                style={{ marginTop: '-50px', zIndex: 3 }}>
                                {
                                  this.state.helpPage.titulo ? (
                                    <h3 className=" text-center">
                                      {this.state.helpPage.titulo}
                                    </h3>
                                  ) : (null)
                                }

                                {
                                  this.state.helpPage.texto ? (
                                    <div dangerouslySetInnerHTML={{ __html: this.state.helpPage.texto }} />
                                  ) : (null)
                                }
                              </div>                        
                            </Container>
                          </div>
                        ) : (
                          null
                        )
                      }
                      {
                        this.state.ladder ? (
                          <div className='game_ui_list'>
                            <table>
                              <thead>
                                <tr>
                                  <td className='dark_td td_num'>
                                    <span>
                                      #
                                    </span>
                                  </td>
                                  <td className='td_win'>
                                    <center>
                                      {this.props.textosShow.player || "Player"}
                                    </center>
                                  </td>
                                  <td className='dark_td td_pts'>
                                    {this.props.textosShow.enterpoint || "Enter Point"}
                                  </td>
                                  <td className='td_award'>
                                    {this.getLadderRewardTypeTitle()}
                                  </td>
                                  <td className='td_award'>
                                    {this.props.textosShow.finalpremios || "Final Premios"}
                                  </td>
                                  <td>

                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.ladder.players.map((player, index) =>
                                    <tr
                                      className={`${(player.jugador.id === this.props.userData.id) && 'tr_winner'} ${(player.jugador.id == null) && 'f-blur-1'}`}
                                      key={player.position}>
                                      <td className='dark_td td_num'>
                                        <span>
                                          {player.position}
                                        </span>
                                      </td>
                                      <td className='td_win'>
                                        <div className="d-flex justify-content-between align-items-center">
                                        {
                                          player.jugador.id ? (
                                            <div className='select pl-2 d-flex align-items-center'>
                                              <button
                                                className='main-item'
                                                style={{
                                                  color: '#ffffff'
                                                }}>
                                                <div className='country'>
                                                  <img
                                                    src={player.jugador.pais}
                                                    alt="" />
                                                </div>
                                                {player.jugador.nick}
                                                {
                                                  player.jugador.blocked ? (
                                                    <>
                                                      <span
                                                        data-tip
                                                        data-for={`blocked-tooltip-${player.jugador.id}`}
                                                        className="iconInfo pl-1">
                                                        <FontAwesomeIcon icon={faLock} />
                                                      </span>
                                                      <ReactTooltip id={`blocked-tooltip-${player.jugador.id}`}>
                                                        <p>
                                                          {this.props.textosShow.usuariobloqueado || "Usuario Bloqueado"}
                                                        </p>
                                                      </ReactTooltip>
                                                    </>
                                                  ) : (
                                                    null
                                                  )
                                                }
                                              </button>
                                              <ul className="sub-menu lang_menu">
                                                <li>
                                                  <span
                                                    className="link-button"
                                                    onClick={() => this.props.showPerfilUserModal(player.jugador.id)}>
                                                    {this.props.textosShow.verperfil || "Ver perfil"}
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          ) : (
                                            null
                                          )
                                        }
                                        </div>
                                      </td>
                                      <td className='dark_td td_pts'>
                                        {
                                          player.jugador.id ? (
                                            <span>
                                              {player.enter_point ? player.enter_point : 0} pts
                                            </span>
                                          ) : (
                                            null
                                          )
                                        }
                                      </td>
                                      <td className='td_award'>
                                        <img
                                          src={gemIcon}/>
                                        <span>
                                          + {player.ldr}
                                        </span>
                                        { (player.jugador.id != null && this.state.ladder && this.state.ladder.ladderStatus != 2) && 
                                          <span className='counter'>
                                            <FontAwesomeIcon
                                              icon={faHourglassHalf}
                                              size="lg" />
                                            {this.getRewardRemainingTime()} 
                                          </span>
                                        }
                                        
                                      </td>
                                      <td className='td_award'>
                                        <img
                                          src={gemIcon}/>
                                        <span>
                                          + {player.lfr}
                                        </span>
                                      </td>
                                      <td className='p-relative'>
                                        <div className='center '>
                                          <div
                                            className='mt_block step_4'
                                            id={'deposit_now_' + player.position}>
                                            <div className='step'></div>
                                            {this.props.textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir"}
                                            <div className='notif_buttons'>
                                              <button className="button green_button">
                                                <Link to="/deposit">
                                                  {this.props.textosShow.depositarahora || "Depositar ahora"}
                                                </Link>
                                              </button>
                                            </div>
                                          </div>
                                          { this.canReentry(player) &&
                                            <button 
                                              className='button button_reentry'
                                              onClick={() => this.onReentryClicked(player)}
                                              >
                                              {
                                                (this.props.textosShow.reentrar || "Reentrar")
                                              }
                                            </button>
                                          }
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                }
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          null
                        )
                      }
                      <div className='game_ui_warn'>
                        <span>
                          <img
                            src={warningIcon}
                            alt="Warning" />
                          {this.props.textosShow.importantenuestrosistemaalineamatchconoponentesdelmismonivel || "Importante! Nuestro sistema alinea match con openentes del mismo nivel"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default Ladder;
