import React, { Component } from 'react';
import Carousel from 'react-elastic-carousel';

import $ from 'jquery';
import Spin from './Spin';
import SmokeBox from '../components/SmokeBox';
import { formatDate } from '../utils/utils';

import iconLogo from '../../img/modal_logo_2.png';
import iconCoin from '../../img/coin_icon_medium.png';
import congratsImg from '../../img/congrats.gif';
import spinResultItemBorder from '../../img/spin/spin-result-item-border.png';


const breakPoints = [
  { width: 1, itemsToShow: 1, pagination: false },
  { width: 720, itemsToShow: 6, pagination: false },
  { width: 1000, itemsToShow: 8, pagination: false },
];

class SpinModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      gameSlug: "",
      salaId: null,
      salaSlug: "",
      reentry: 0,
      partidaId: null,
      isSpinning: false,
      spinClickable: false,
      items: [],
      freq: [],
      idList: [],
      spinPrizeList: [],
      spinPrizeId: null
    };

    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);

    this.carouselRef = React.createRef(null);

    this.selectSpinItemFunc = React.createRef();

  };

  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  };

  keyDown(e) {
    if (e.keyCode === 27) {
      this.close(false, 0);
    }
  };

  setSpinning(value) {
    this.setState({
      isSpinning: value
    });
  }

  close(done, spinPrize, spinPrizeId) {
    const clase = this;
    $("#spin_modal").fadeOut("fast", function () {
      $("#spin_modal_overlay").fadeOut("fast", function () {
        document.body.style.overflow = "auto";
        $('#app-container').removeClass('blur-bg');
        clase.setSpinning(false);
        //clase.props.onSpinClose(done, clase.state.gameSlug, clase.state.salaId, clase.state.salaSlug, clase.state.reentry, clase.state.partidaId, spinPrize, spinPrizeId);
      });
    });
  };

  spinClicked(event) {
    if (this.selectSpinItemFunc && this.selectSpinItemFunc.current) {
      this.setState({
        spinClickable: false
      });

      this.onSpinStart(event);
    }
  }

  onSpinStart(event) {
    let clase = this;
    if (clase.props.loggedIn) {
      let myHeaders = clase.props.getHeadersToSend();
      let lan = clase.props.getLanActual();
      clase.props.showLoadingIndicator(true);
      fetch(clase.props.urlGlobal + "api/juego", {
          method: "POST",
          body: JSON.stringify({
            action: "spinRoulette",
            juego: clase.state.gameSlug,
            sala: clase.state.salaId,
            first: 1,
            lan: lan,
            reentry: clase.state.reentry,
            partida: clase.state.partidaId
          }),
          headers: myHeaders
      }).then(function(response) {
        return response.json();
      }).then(function(result) {
        if (result.success) {
          console.log('========result=====', result);
          clase.setState({
            spinPrizeId: result.spinPrizeId,
            partidaId: result.partida.id
          })
          clase.props.getDataUser();
          clase.props.showLoadingIndicator(false);
          clase.selectSpinItemFunc.current(event);
        }
        else {
          clase.props.showLoadingIndicator(false);
          //alert(result.msg);
          
          clase.props.openAlertModal(
            clase.props.textosShow[result.msg] || result.msg,
            clase.props.textosShow[`${result.msg}descripcion`] || 'Error'
          )
        }
      });
    }
    else{
      alert(clase.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta.");
    }
  }

  onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      this.carouselRef.current.goTo(0);
    }
  };
  onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      this.carouselRef.current.goTo(this.state.spinPrizeList?.length);
    }
  };

  render() {
    return (
      <div
        className="modalx-overlay close_mw"
        id="spin_modal_overlay">
        <div
          className='modal_window'
          id="spin_modal">
          <button
            id="close_modal"
            className="link-button close_mw"
            onClick={() => {
              if (this.state.isSpinning) return;
              this.close(false, 0);
            }} />
          <div className='modal_content'>
            <div className='c_center'>
              <div className='c_top'>
                <div className='c_bottom'>
                  <div className='spin-container'>
                    <img className='spin-mobile'
                      src={iconLogo} />
                    <div>
                      <Spin
                        selectSpinItemFunc={this.selectSpinItemFunc}
                        salaId={this.state.salaId}
                        isSpinning={this.state.isSpinning}
                        items={this.state.items}
                        freq={this.state.freq}
                        idList={this.state.idList}
                        spinClickable={this.state.spinClickable}
                        urlGlobal={this.props.urlGlobal}
                        defaultData={this.props.defaultData}
                        getHeadersToSend={this.props.getHeadersToSend}
                        showLoadingIndicator={this.props.showLoadingIndicator}
                        setSpinning={this.setSpinning.bind(this)}
                        spinPrizeId={this.state.spinPrizeId}
                        onSelectItem={(item, id) => {
                          console.log('=======onSelectItem=======', item);
                          this.close(true, item, id);
                          window.location = this.props.urlGlobal + "playGame/" + this.state.salaSlug + "/?gameMode=" + this.state.salaId + "&partida=" + this.state.partidaId + "&game=" + this.state.gameSlug;
                        }} />
                    </div>
                    <div className='spin-right'>
                      <img
                        src={iconLogo} />
                      <div className='spin-text jackpot text-shadow'>
                        <span>{this.props.textosShow.spininstruction || "Spin the wheel to win prize"} </span>
                      </div>
                      <div className='d-flex-center'>
                        <button
                          className="button-green mt-36"
                          disabled={!this.state.spinClickable}
                          onClick={(event) => this.spinClicked(event)}>
                          {this.props.textosShow.spinnow || "Spin Now"}
                        </button>
                      </div>
                    </div>

                    <div className='spin-mobile spin-text jackpot text-shadow'>
                      <span>{this.props.textosShow.spininstruction || "Spin the wheel to win prize"} </span>
                    </div>
                    <div className='d-flex-center spin-mobile'>
                      <button
                        className="button-green mt-36"
                        disabled={!this.state.spinClickable}
                        onClick={(event) => this.spinClicked(event)}>
                        {this.props.textosShow.spinnow || "Spin Now"}
                      </button>
                    </div>
                  </div>


                  {this.state.spinPrizeList && this.state.spinPrizeList.length > 0 && (
                    <div className='spin-result-container'>
                      <div className='spin-latest-prize-container'>
                        {this.props.textosShow.latestspinprizes || "Latest Spin Prizes"}
                      </div>
                      <div className='spin-result'>
                        <Carousel
                          ref={this.carouselRef}
                          onPrevStart={this.onPrevStart.bind(this)}
                          onNextStart={this.onNextStart.bind(this)}
                          breakPoints={breakPoints}
                          disableArrowsOnEnd={false}>
                          {
                            this.state.spinPrizeList.map((item, index) => {
                              return (
                                <div className={`spin-result-item-container ${index == 0 ? 'highlight' : ''}`}>
                                  
                                  <div className={`spin-result-item`}>
                                    {index == 0 && 
                                      <>
                                        <img
                                          className='img-congrats' 
                                          src={congratsImg}/>
                                      </>
                                    }
                                    <div className='user-info d-flex-center-j'>
                                      <span className='line-camp-1'>
                                        {formatDate(item?.fecha?.date)}
                                      </span>
                                    </div>
                                    <div className='user-info d-flex-center-j'>
                                      <div className='country'>
                                        <img
                                          src={item?.pais}
                                          alt="" />
                                      </div>
                                      <span className='line-camp-1'>
                                        {item?.clienteName}
                                      </span>
                                    </div>
                                    <div>
                                      <img className='img-coin'
                                        src={iconCoin} />
                                      {this.props.defaultData.moneda.signo} {item.prize}
                                    </div>
                                  </div>
                                </div>
                                
                              );
                            })
                          }
                        </Carousel>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    );
  };
};

export default SpinModal;
