import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SmokeCenter from '../components/SmokeCenter';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import FriendsCardSearch from '../components/FriendsCardSearch';
import FriendsCard from '../components/FriendsCard';
import logo from '../../img/logo_big.png';
import FriendInvite from "../components/FriendInvite"
import SentFriendsCardRequest from "../components/SentFriendsCardRequest"
import Sparks from '../components/Sparks'
import Fire from '../components/Fire'
import Bg from '../../img/premio_bg_modal.png';

import searchIcon from '../../img/friend-request/search.png';
import closeIcon from '../../img/friend-request/close.png';
import friendHeaderBg from '../../img/friend-request/search-bg-1.png';

import {
    Container, Row, Col,
} from 'reactstrap';

class Friends extends Component {

    constructor(props) {
        super(props);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.state = {
            redirectToReferrer: false,
            juegoFavorito: null,
            likeTo: null,
            juegoPor: null,
            signo: null,
            total: 0,
            textosShow: '',
            activePage: 0,
            openSearch: true,
            openFriend: false,
            inviteOpen: false,
            totalPages: 0,
            searchValue: '',
            pages: [],
            sendFriendOpen: false,
            SearchData: []
        };
        this.urlGlobal = props.urlGlobal;
    }

    searchMyFriends = (e) => {
        if (e.length > 3 || e.length === 0) {
            this.setState({ searchValue: e })
        }
    }

    openSearch = () => {
        this.setState({ openSearch: false });
        this.setState({ openFriend: true })
        this.setState({ searchValue: "" })
    }

    componentDidMount() {
        this.getDataQuest()
    }

    showTab = (id) => {
        switch (id) {
            case 0:
                this.setState({ openSearch: false })
                this.setState({ inviteOpen: false })
                this.setState({ sendFriendOpen: false })
                this.setState({ openFriend: true })
                break;
            case 1:
                this.setState({ openSearch: true })
                this.setState({ inviteOpen: false })
                this.setState({ sendFriendOpen: false })
                this.setState({ openFriend: false })
                break;
            case 2:
                this.setState({ inviteOpen: true })
                this.setState({ openSearch: false })
                this.setState({ sendFriendOpen: false })
                this.setState({ openFriend: false })
                break;
            case 3:
                this.setState({ sendFriendOpen: true })
                this.setState({ inviteOpen: false })
                this.setState({ openSearch: false })
                this.setState({ openFriend: false })
                break;
        }
    }

    handleChangePage(page) {
        this.setState({
            activePage: page
        });
    }

    getDataQuest(url, data, st) {
        let myHeaders = this.props.getHeadersToSend();
        let clase = this;
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: myHeaders
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.success) {
                switch (st) {
                    case "juegoFavorito":
                        clase.setState({
                            juegoFavorito: result.items
                        });
                        break;
                    case "likeTo":
                        clase.setState({
                            likeTo: result.items
                        });
                        break;
                    case "juegoPor":
                        clase.setState({
                            juegoPor: result.items
                        });
                        break;
                    case "signo":
                        clase.setState({
                            signo: result.items
                        });
                        break;
                    default:
                        break;
                }
            }
        });
    }

    render() {
        if (!this.props.loggedIn) {
            return (<Redirect to={'/'} />);
        }
        return (
            <div className='content'
                id='membership'>
                <div className='wrapper' >
                    <SmokeCenter></SmokeCenter>
                    <div className='page_content'>
                        <div className='c_center'>
                            <div className='c_top' >
                                <div className='c_bottom'>
                                    <Eagle></Eagle>
                                    <Leaves></Leaves>
                                    <div className='game_image'>
                                        <img style={{ width: 'inherit', marginTop: '30px' }} src={logo} />
                                    </div>
                                    <div>
                                        <h4>
                                            {this.state.openSearch ? this.props.textosShow.searchFriends || "Search Friends" : this.state.inviteOpen ? this.props.textosShow.pendingRequest || "Pending Request" : this.state.sendFriendOpen ? this.props.textosShow.sentRequest || "Sent Request" : this.props.textosShow.yourFriends || "Your Friends"}
                                        </h4>
                                        <div style={{ position: 'absolute', left: 0, right: 0, top: 80, zIndex: 100 }}>
                                            <Sparks />
                                            <Fire />
                                        </div>
                                        {
                                            this.state.openSearch ? (
                                                <div className="searchBox" style={{ position: "absolute", zIndex: 1001, left: "0", right: "0" }}>
                                                    <Row>
                                                        <Col xs="1" style={{ display: "flex", paddingTop: "35px", paddingLeft: "30px" }}>
                                                            <img src={searchIcon} style={{ width: "25px", height: "25px" }} />
                                                        </Col>
                                                        <Col xs="9">
                                                            <input type="text" placeholder={this.props.textosShow.search || "Search"}
                                                                onChange={(e) => this.searchMyFriends(e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col xs="1" style={{ display: "flex", paddingTop: "35px", paddingLeft: "10px" }}>
                                                            <img src={closeIcon} style={{ width: "23px", height: "23px", cursor: "pointer" }} onClick={() => this.openSearch()} />
                                                        </Col>
                                                    </Row>
                                                </div>)
                                                :
                                                null
                                        }
                                        <Container style={{ marginTop: "-20px", position: "absolute", zIndex: 1000, height: "100px", right: 0, left: 0, boxShadow: '2px 9px 33px #763633', backgroundSize: "100% 100%" }}>
                                            <Row>
                                                <Col xs="2" className="friend_col_1"><button
                                                    style={{
                                                        width: '175px', float: 'left', marginTop: "35px",
                                                    }}
                                                    onClick={() => this.showTab(0)}
                                                    className='home_button'
                                                >
                                                    <span className='objective-rewards'>{this.props.textosShow.yourfriends || "Your Friends"}</span>
                                                </button></Col>
                                                <Col xs="6" className="friend_col_2">  <button style={{
                                                    marginTop: "35px",

                                                    width: '175px'
                                                }}
                                                    onClick={() => this.showTab(1)}
                                                    className='home_button'
                                                >
                                                    <span className='objective-rewards'>{this.props.textosShow.searchFriends || "Search Friends"}</span>
                                                </button></Col>
                                                <Col xs="2" className="friend_col_3">  <button style={{
                                                    marginTop: "35px",
                                                    width: '155px'
                                                }}
                                                    onClick={() => this.showTab(2)}
                                                    className='home_button'
                                                >
                                                    <span className='objective-rewards'>{this.props.textosShow.invites || "Invites"}</span>
                                                </button></Col>
                                                <Col xs="2" className="friend_col_4">  <button style={{
                                                    marginTop: "35px",
                                                    width: '175px', float: 'right'
                                                }}
                                                    onClick={() => this.showTab(3)}
                                                    className='home_button'
                                                >
                                                    <span className='objective-rewards'>{this.props.textosShow.sentrequests || "Sent Requests"}</span>
                                                </button></Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    <br /><br />

                                    <Container fluid className='page_block objective-card-container container-fluid'  >
                                        <Row>
                                            {this.state.inviteOpen ?
                                                <FriendInvite
                                                    inviteOpen={this.state.inviteOpen}
                                                    textosShow={this.props.textosShow}
                                                    inviteData={this.state.SearchData}
                                                    showLoadingIndicator={this.props.showLoadingIndicator}
                                                    urlGlobal={this.props.urlGlobal}
                                                    getLanActual={this.props.getLanActual}
                                                    getHeadersToSend={this.props.getHeadersToSend}
                                                    showChallengeModal={this.props.showChallengeModal}
                                                />
                                                :
                                                this.state.openSearch ?
                                                    <FriendsCardSearch
                                                        openSearch={this.state.openSearch}
                                                        textosShow={this.props.textosShow}
                                                        penInvite={this.state.inviteOpen}
                                                        searchValue={this.state.searchValue}
                                                        urlGlobal={this.props.urlGlobal}
                                                        showLoadingIndicator={this.props.showLoadingIndicator}
                                                        getLanActual={this.props.getLanActual}
                                                        getHeadersToSend={this.props.getHeadersToSend}
                                                        showChallengeModal={this.props.showChallengeModal}
                                                    />
                                                    :
                                                    this.state.sendFriendOpen ?
                                                        <SentFriendsCardRequest
                                                            openSearch={this.state.openSearch}
                                                            textosShow={this.props.textosShow}
                                                            penInvite={this.state.inviteOpen}
                                                            showLoadingIndicator={this.props.showLoadingIndicator}
                                                            searchValue={this.state.searchValue}
                                                            urlGlobal={this.props.urlGlobal}
                                                            getLanActual={this.props.getLanActual}
                                                            getHeadersToSend={this.props.getHeadersToSend}
                                                            showChallengeModal={this.props.showChallengeModal}
                                                        />
                                                        :
                                                        this.state.openFriend ?
                                                            <FriendsCard
                                                                openSearch={this.state.openSearch}
                                                                textosShow={this.props.textosShow}
                                                                penInvite={this.state.inviteOpen}
                                                                showLoadingIndicator={this.props.showLoadingIndicator}
                                                                searchValue={this.state.searchValue}
                                                                urlGlobal={this.props.urlGlobal}
                                                                getLanActual={this.props.getLanActual}
                                                                getHeadersToSend={this.props.getHeadersToSend}
                                                                showChallengeModal={this.props.showChallengeModal}
                                                            />
                                                            :
                                                            <FriendsCardSearch
                                                                openSearch={this.state.openSearch}
                                                                textosShow={this.props.textosShow}
                                                                penInvite={this.state.inviteOpen}
                                                                searchValue={this.state.searchValue}
                                                                urlGlobal={this.props.urlGlobal}
                                                                showLoadingIndicator={this.props.showLoadingIndicator}
                                                                getLanActual={this.props.getLanActual}
                                                                getHeadersToSend={this.props.getHeadersToSend}
                                                                showChallengeModal={this.props.showChallengeModal}
                                                            />
                                            }
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Friends;