import React, { Component } from 'react';
import { Link } from "react-router-dom";

import $ from 'jquery';

import PrivacyModal from './PrivacyModal';
import TermsModal from './TermsModal';
import FaqModal from './FaqModal';
import ContactModal from './ContactModal';

import logoFooter from '../../img/logo_footer.png';
import age18 from '../../img/18_age.png';


class Footer extends Component {
  
  showPrivacyPolicyModal() {
    $("#privacy_modal_overlay").fadeIn("fast", function() {
      $("#privacy_modal").fadeIn("fast", function() {
        document.body.style.overflow = "hidden";
      });
    });
  };
  
  showTermsModal() {
    $("#terms_modal_overlay").fadeIn("fast", function() {
      $("#terms_modal").fadeIn("fast", function() {
        document.body.style.overflow = "hidden";
      });
    });
  };
  
  showFaqModal() {
    $("#faq_modal_overlay").fadeIn("fast", function() {
      $("#faq_modal").fadeIn("fast", function() {
        document.body.style.overflow = "hidden";
      });
    });
  };

  showContactModal() {
    $("#contact_modal_overlay").fadeIn("fast", function() {
      $("#contact_modal").fadeIn("fast", function() {
        document.body.style.overflow = "hidden";
      });
    });
  };

  render() {
    return (
      <div>
        <div className='footer container-fluid'>
          <div className='wrapper'>
            <div className='payments'>
              <Link to="/" id="paypallink"></Link>
              <Link to="/" id="mastercard"></Link>
              <Link to="/" id="visa"></Link>
              <Link to="/" id="bitcoin"></Link>
              <Link to="/" id="ideal"></Link>
              <Link to="/" id="klarna"></Link>
              <Link to="/" id="union_pay"></Link>
              <Link to="/" id="giropay"></Link>
              <Link to="/" id="bancontact"></Link>
              <Link to="/" id="eps"></Link>
            </div>
            <div className='marginer'></div>
            <div className='copyright'>
              <div className='f_menu'>
                <Link to="/">
                  {this.props.textosShow.inicio || "Inicio"}
                </Link>
                <span
                  className="link-button"
                  onClick={this.showPrivacyPolicyModal.bind(this)}>
                  {this.props.textosShow.politicadeprivacidad || "Política de privacidad"}
                </span>
                <span
                  className="link-button"
                  onClick={this.showTermsModal.bind(this)}>
                  {this.props.textosShow.terminosdelservicio || "Términos del servicio"}
                </span>
                <span
                  className="link-button"
                  onClick={this.showContactModal.bind(this)}>
                  {this.props.textosShow.contacto || "Contacto"}
                </span>
                <span
                  className="link-button"
                  onClick={this.showFaqModal.bind(this)}>
                  FAQ
                </span>
                <Link to="/referral">
                  {this.props.textosShow.convierteteensociodeduelcash || "Conviértete en socio de DuelCash"}
                </Link>
              </div>
              © 2022 DuelCash.com - {this.props.textosShow.todoslosderechosreservados || "Todos los derechos reservados"}.
            </div>
            <div className='f_content'>
              <div className='f_logos'>
                <img 
                  src={logoFooter} 
                  className='logo_footer'
                  alt="DuelCash" />
                <img 
                  src={age18}
                  alt="18+" />
              </div>
              {this.props.defaultData && this.props.defaultData.pais.slug.toLowerCase() === 'us' ? (
                <div className='f_text'>
                  {this.props.textosShow.estesitiowebnoestaasociadoconymirywebzenusa || "Este sitio web no está asociado con YMIR y WEBZEN. USA"}
                  <br />
                  {this.props.textosShow.ellogotipodemetin2yloslogotiposdewebzenymirsonmarcascomercialesomarcascomercialesregistradasdewebzenincusa || "El logotipo de METIN2 y los logotipos de WEBZEN YMIR son marcas comerciales o marcas comerciales registradas de WEBZEN, INC. USA"}
                </div>
              ) : (
                <div className='f_text'>
                  {this.props.textosShow.estesitiowebnoestaasociadoconymirywebzen || "Este sitio web no está asociado con YMIR y WEBZEN."}
                  <br />
                  {this.props.textosShow.ellogotipodemetin2yloslogotiposdewebzenymirsonmarcascomercialesomarcascomercialesregistradasdewebzeninc || "El logotipo de METIN2 y los logotipos de WEBZEN YMIR son marcas comerciales o marcas comerciales registradas de WEBZEN, INC."}
                </div>
              )}
            </div>
          </div>
        </div>
        <PrivacyModal 
          textosShow={this.props.textosShow}
          getHeadersToSend={this.props.getHeadersToSend}
          getLanActual={this.props.getLanActual}
          urlGlobal={this.props.urlGlobal}></PrivacyModal>
        <TermsModal 
          textosShow={this.props.textosShow}
          userData={this.props.userData}
          getHeadersToSend={this.props.getHeadersToSend}
          getLanActual={this.props.getLanActual}
          urlGlobal={this.props.urlGlobal}></TermsModal>
        <FaqModal 
          textosShow={this.props.textosShow}
          userData={this.props.userData}
          getHeadersToSend={this.props.getHeadersToSend}
          getLanActual={this.props.getLanActual}
          urlGlobal={this.props.urlGlobal}></FaqModal>
        <ContactModal
          textosShow={this.props.textosShow}
          getHeadersToSend={this.props.getHeadersToSend}
          getLanActual={this.props.getLanActual}
          urlGlobal={this.props.urlGlobal}></ContactModal>
      </div>
    );
  };
};

export default Footer;
