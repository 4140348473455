import React, { useEffect } from "react";
import Modal from 'react-modal';

export const ModalIFrame = ({ src, onLoad, onChargeSuccess, onModalClose, onPaymentStart, onPaymentEnd }) => {
    const handleMessage = (event) => {
        if (event.origin !== 'https://api.paymentwall.com') return;
        const msg = JSON.parse(event.data);
        
        switch (msg.event) {
            case 'paymentSuccess':
                onChargeSuccess && onChargeSuccess(msg.data);
                break;
            case 'paymentProcessingStart':
                onPaymentStart && onPaymentStart(msg.data);
                break;
            case 'paymentProcessingEnd':
                onPaymentEnd && onPaymentEnd(msg.data);
                break;
            default:
                // Do nothing
                break;
        }
    };

    useEffect(() => {
        console.log("register events");
        window.addEventListener('message', handleMessage);
        return () => {
            console.log("un register events");
            window.removeEventListener('message', handleMessage);
        }
    }, []);


    return (
        <Modal isOpen style={{
            overlay: {
                zIndex: 3000,
                background: "rgba(6,6,6,0.85)"
            },
            content: {
                zIndex: 3000,
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                height: '75%',
                width: '75%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: "0px"
            }
        }}>
            <button id="close_modal"
                style={{
                    top: "0px",
                    right: "0px"
                }}
                className="link-button close_mw"
                onClick={onModalClose}></button>
            {src !== null && (
                <iframe
                    title="Payment Wall Checkout"
                    style={{
                        height: '100%',
                        width: '100%'
                    }}
                    onLoad={onLoad}
                    src={src}
                />
            )}
        </Modal>
    )
}