import HoverVideoPlayer from "react-hover-video-player";

export default function SpinCover({item, logo, onCoverClicked}) {
  return (
    <div className='pt-5 pb-5 pl-2 pr-2'>
      <div
        className='ml-3 mr-3 spin-cover-container'
        onClick={() => onCoverClicked(item.slug)}>
        <HoverVideoPlayer
          className='video-container'
          videoSrc={item.cover}
          restartOnPaused
          // pausedOverlay={
          //   <div className='spin-overlay'>
          //     <img src={logo} 
          //     alt={item.slug}/>
          //   </div>
          // }
        />
      </div>
    </div>
    
  )
}