import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import thunder from '../../img/thunder32.png';
/**
 * Devuelve un array de los tipos de torneo que representa la sala, cada uno 
 * tiene opcionalmente un title, un icon y una description
 * Otro array con las clases que debe tener el elemento contenedor de la sala
 * @param {Array<Object()>} sala 
 */
const getTipoTorneo = (textosShow, juego, sala) => {
    const data = [];
    const classes = [];

    if (sala.custom_title) {
        data.push({
            id: `customtitle_${sala.id}`,
            title: sala.custom_title,
            icon: null,
            iconContainerClass: null,
            description: null
        });
    }

    // Modo de juego
    if (sala.modo === "Spin") {
        classes.push('spin');

        data.push({
            id: `spin_${sala.id}`,
            title: textosShow.spintitle || "Spin",
            icon: ( < FontAwesomeIcon icon = { faInfoCircle }
                />),
            iconContainerClass: 'iconInfo',
            description: textosShow.spininfo || "Spin Mode"
        });
    } else if (sala.modo === "Bonanza") {
        classes.push('bonanza');
        if (sala.tiempo.highlight) {
            classes.push('highlight');
        }

        data.push({
            id: `bonanza_${sala.id}`,
            title: `${sala.bonanzaRewardType === 'Minutes' ? sala.bonanzaRewardMinutes : ''} ${sala.bonanzaRewardType}`,
            icon: ( < FontAwesomeIcon icon = { faInfoCircle }
                />),
            iconContainerClass: 'iconInfo',
            description: textosShow.bonanzainfo || "Bonanza Game"
        });

        if (sala.costo === 0) {
            // Bonanza Sin costo sin reentry
            data.push({
                id: `freerol-${sala.id}`,
                title: textosShow.bonanzafreerol || "Bonanza Freerol",
                icon: null,
                iconContainerClass: null,
                description: null
            });
        }
    } else if (sala.modo === "Ladder") {
        classes.push('ladder');
        if (sala.tiempo.highlight) {
            classes.push('highlight');
        }

        data.push({
            id: `ladder_${sala.id}`,
            title: textosShow.laddertitle || "Ladder",
            icon: ( < FontAwesomeIcon icon = { faInfoCircle }
                />),
            iconContainerClass: 'iconInfo',
            description: textosShow.ladderinfo || "Ladder Game"
        });

        if (sala.costo === 0) {
            data.push({
                id: `freerol-${sala.id}`,
                title: textosShow.ladderfreerol || "Ladder Freerol",
                icon: null,
                iconContainerClass: null,
                description: null
            });
        }
    } else if (sala.modo === "Jackpots") {
        classes.push(sala.mega ? 'mega-featured' : 'featured');
        if (sala.tiempo.highlight) {
            classes.push('highlight');
        }
        if (sala.mega) {
            // Modo megajackpot
            data.push({
                id: `megajackpot_${sala.id}`,
                title: textosShow.megajackpottitle || "Mega Jackpot",
                icon: null,
                iconContainerClass: null,
                description: null
            });
        } else if (sala.costo === 0) {
            // Jackpot Sin costo
            if (sala.reentry === 1) {
                // Jackpot Sin costo con reentry
                data.push({
                        id: `reentry-${sala.id}`,
                        title: textosShow.jackpotfreenium || "Jackpot Freenium",
                        icon: ( < FontAwesomeIcon icon = { faInfoCircle }
                            />),
                            iconContainerClass: 'iconInfo',
                            description: textosShow.jackpotfreeniumdescription || "Jackpot Freenium Description"
                        });
            }
            else {
                // Jackpot Sin costo sin reentry
                data.push({
                    id: `freerol-${sala.id}`,
                    title: textosShow.jackpotfreerol || "Jackpot Freerol",
                    icon: null,
                    iconContainerClass: null,
                    description: null
                });
            }
        } else {
            // Jackpot Con costo
            if (sala.cantJugador !== -1) {
                data.push({
                    id: `jackpot-${sala.id}`,
                    title: textosShow.jackpotentradalimitada || "Jackpot entrada limitada",
                    icon: null,
                    iconContainerClass: null,
                    description: null
                });
            } else {
                data.push({
                    id: `jackpot-${sala.id}`,
                    title: textosShow.jackpotentradailimitada || "Jackpot entrada ilimitada",
                    icon: null,
                    iconContainerClass: null,
                    description: null
                });
            }
        }
    } else {
        if (sala.reentry) {
            classes.push('reentry');
        }
        // Duelo simple o multi normal
        if (sala.cantJugador !== -1) {
            data.push({
                id: `duelo-${sala.id}`,
                title: textosShow.entradalimitada || "Entrada limitada",
                icon: null,
                iconContainerClass: null,
                description: null
            });
        } else {
            data.push({
                id: `duelo-${sala.id}`,
                title: textosShow.entradailimitada || "Entrada ilimitada",
                icon: null,
                iconContainerClass: null,
                description: null
            });
        }
    }
    //Modificadores del modo de juego
    if (sala.reentry && (sala.modo !== "Jackpots" || sala.costo !== 0) && (sala.modo !== "Ladder" && sala.modo !== "Bonanza")) {
        data.push({
            id: `duelo-reentry-${sala.id}`,
            title: textosShow.reentradaactivada || "Reentrada activada",
            icon: ( < FontAwesomeIcon icon = { faInfoCircle }
                />),
                iconContainerClass: 'iconInfo',
                description: (sala.modo != 'Ladder' && sala.modo !='Bonanza') ? (textosShow.descripciondereentrada || "Descripcion de reentrada") : ""
            });
    }

    if ((sala.fast)) {
        data.push({
            id: `fast-${sala.id}`,
            title: textosShow.modorapido || "Modo Rapido",
            icon: ( < img src = { thunder }
                alt = "Thunder"  /> ),
            iconContainerClass: 'iconFast',
            description: (sala.modo != 'Ladder' && sala.modo !='Bonanza') ? (textosShow[`descripcionderapido${juego.slug}`] || textosShow.descripcionderapido || "Descripcion de rapido") : ""
        });
    } else if (sala.gameMode) {
        data.push({
                id: `${sala.gameMode}-${sala.id}`,
                title: textosShow[`modo${sala.gameMode}`] || `Modo ${sala.gameMode}`,
                icon: ( < FontAwesomeIcon icon = { faInfoCircle }
                    />),
                    iconContainerClass: 'iconInfo',
                    description: (sala.modo != 'Ladder' && sala.modo !='Bonanza') ? (textosShow[`descripcionde${sala.gameMode}${juego.slug}`] || textosShow[`descripcionde${sala.gameMode}`] || `${sala.gameMode}`) : ""
        });
    }

    return { data, classes };
};

export default getTipoTorneo;