import React, { Component } from 'react';

import $ from 'jquery';
import FaqItem from './FaqItem';

class FaqModal extends Component{

  constructor(props) {
    super(props);

    this.state = {
      faqs: [],
      selectedFaq: null
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  }

  setSelectedFaq(faqId) {
    this.setState({
      selectedFaq: faqId
    })
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
    this.findFaqs();
  }

  componentDidUpdate(prevProps, prevState) {
    const currFreeUser = this.props.userData.freeUser;
    const prevFreeUser = prevProps.userData.freeUser;
    if (currFreeUser !== prevFreeUser) {
      this.findFaqs();
    }
  }

  findFaqs(){
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(clase.props.urlGlobal + "api/faq", {
        method: "POST",
        body: JSON.stringify({
          action:"getFaqs",
          lan:lan
        }),
        headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.setState({
          faqs: result.faqs
        });
      }
    });
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }
  
  close() {
    const clase = this;
    $("#faq_modal").fadeOut("fast", function() {
      $("#faq_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
        clase.setSelectedFaq(null);
      });
    });
  }

  render() {
    let faqs = this.state.faqs;

    return (
      <div className="modalx-overlay close_mw"
           id="faq_modal_overlay">
        <div className='modal_window' 
             id="faq_modal">
          <button id="close_modal"
                    className="link-button close_mw"
                    onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                <h5>
                  FAQ
                </h5>
                {
                  faqs ? 
                    <div className='modal_form'>
                      <div className='terms_text'>
                        <div className='terms_scroll'>
                          <div className='faq_title'>
                            {this.props.textosShow.preguntasgenerales || "Preguntas generales"}
                          </div>
                          {
                            faqs.map(faq =>
                              <div key={faq.id}>
                                <FaqItem faq={faq} setSelectedFaq={this.setSelectedFaq.bind(this)} selectedFaq={this.state.selectedFaq} />
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div> : 
                    null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FaqModal;