import React, { Component } from 'react';
import { Form } from 'reactstrap';

import $ from 'jquery';

class NewTicketModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      motivos: [],
      motivo: null,
      mensaje: "",
      attachedFile: null
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  };
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
    
    let clase = this;
    fetch(clase.props.urlGlobal + "api/motivoConsulta", {
      method: "POST",
      body: JSON.stringify({
        action: "getMotivosConsulta",
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.setState({
          motivos: result.motivos,
          motivo: result.motivos[0]
        });
      }
    });
  };
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  };
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  };
  
  close() {
    $("#create_ticket_modal").fadeOut("fast", function() {
      $("#create_ticket_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  };
  
  sendTicket() {
    let clase = this;
    let formData = new FormData();
    formData.append("action", "sendTicket");
    formData.append("Idioma", clase.props.getLanActual().id);
    formData.append("Motivo", clase.state.motivo.id);
    formData.append("Mensaje", clase.state.mensaje);
    formData.append("foto", clase.state.attachedFile);
    fetch(clase.props.urlGlobal + "api/ticket", {
      method: "POST",
      body: formData,
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.setState({
          motivo: null,
          mensaje: "",
          attachedFile: null
        });
        document.dispatchEvent(new Event('onNewTicket'));
        alert(clase.props.textosShow.sutickethasidoenviadoexitosamente || 'Su ticket ha sido enviado exitosamente!');
        clase.close();
      }
      else {
        alert(result.msg);
      }
    });
  };
  
  setMotivo(motivo) {
    this.setState({
      motivo: motivo
    });
  };
  
  setMensaje(e) {
    this.setState({
      mensaje: e.target.value
    });
  };

  handleUploadFileButton() {
    document.getElementById("upfile").click();
  };

  upfileChanged() {
    const attachedFile = document.getElementById("upfile").files.length ? 
      document.getElementById("upfile").files[0] :
      null;
    this.setState({
      attachedFile: attachedFile
    });
  };

  removeUploadFile() {
    document.getElementById("upfile").value = null;
    this.setState({
      attachedFile: null
    });
  };
  
  render() {
    return (
      <div 
        className="modalx-overlay close_mw"
        id="create_ticket_modal_overlay">
        <div 
          className='modal_window' 
          id="create_ticket_modal">
          <button 
            id="close_modal"
            className="link-button close_mw"
            onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              {
                (this.state.motivos && this.state.motivo) ? (
                  <div className='modal_content'>
                    <h5>
                      {this.props.textosShow.crearticket || 'Crear Ticket'}
                    </h5>
                    <div className='modal_form'>
                      <div className='input_title'>
                        {this.props.textosShow.motivo || 'Motivo'}
                      </div>
                      <div className="select">
                        <button className="main-item">
                          {this.state.motivo.nombre}
                        </button>
                        <ul className="sub-menu lang_menu">
                          {
                            this.state.motivos.map(motivo =>
                              <li key={motivo.id}>
                                <span 
                                  className="link-button"
                                  onClick={() => this.setMotivo(motivo)}>
                                  {motivo.nombre}
                                </span>
                              </li>
                            )
                          }
                        </ul>
                      </div>
                      <div className='input_title'>
                        {this.props.textosShow.mensaje || 'Mensaje'}
                      </div>
                      <textarea 
                        rows="4" 
                        onChange={this.setMensaje.bind(this)}></textarea>
                      <Form
                        className="p-absolute"
                        style={{
                          height: "0px",
                          width: "0px",
                          overflow: "hidden"
                        }}>
                        <input
                          id="upfile"
                          type="file"
                          accept="image/jpeg,image/gif,image/png"
                          onChange={this.upfileChanged.bind(this)} />
                      </Form>
                      {
                        this.state.attachedFile ? (
                          <div className="inputWithClearButton">
                            <input 
                              type="text"
                              value={this.state.attachedFile.name}
                              readOnly />
                            <button onClick={this.removeUploadFile.bind(this)}>
                              X
                            </button>
                          </div>
                        ) : (
                          null
                        )
                      }
                      <button 
                        onClick={this.handleUploadFileButton.bind(this)}
                        className="button bordered_button">
                        {this.props.textosShow.adjuntararchivo || 'Adjuntar Archivo'}
                      </button>
                    </div>
                    <div className='modal_button'>
                      <button 
                        className='button button_regular'
                        onClick={this.sendTicket.bind(this)}>
                        {this.props.textosShow.enviar || 'Enviar'}
                      </button>
                    </div>
                  </div>
                ) : (
                  null
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default NewTicketModal;
