import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';

import Eagle from '../components/Eagle';
import Sparks from '../components/Sparks';
import Fire from '../components/Fire';
import SmokeCenter from '../components/SmokeCenter';
import BlocksShadow from '../components/BlocksShadow';

import sliderIcon1 from '../../img/icon_4.png';
import sliderIcon2 from '../../img/icon_5.png';
import sliderIcon3 from '../../img/icon_6.png';
import sliderIcon4 from '../../img/icon_7.png';
import sliderIcon5 from '../../img/icon_8.png';
import sliderIcon6 from '../../img/icon_9.png';
import AdvantajeCard from '../components/AdvantajeCard';
import { Col } from 'reactstrap';

const Home = ({juegos, defaultData, textosShow, defaultSeeAll = false}) => {
  const [seeAll, setSeeAll] = useState(defaultSeeAll);

  useEffect(() => {
    $('#slider').rotateSlider({});
  }, []);

  console.log(seeAll)

  return (
    <div>
      <Eagle></Eagle>
      <div className='content container-fluid'>
        <div className='wrapper'>
          <div className='top_blocks d-none d-lg-block'>
            {
              juegos.map((game, index) => (
                  index < 3 ? (
                    <Link 
                      to={'/game/' + game.slug}
                      key={game.slug}>
                      <div className='block'>
                        <div>
                          <img src={game.logo}
                              alt={game.slug} />
                          <p>
                            {game.slug}
                          </p>
                          <span>
                            {defaultData.moneda.signo}{game.salaPremioJackpots}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ) : (null)
              ))
            }
          </div>
          <div className='top_blocks row d-lg-none'>
            {
              juegos.map((game, index) => (
                  index < 4 || seeAll ? (
                    <Col xs="6">
                      <Link 
                        to={'/game/' + game.slug}
                        key={game.slug}>
                        <div className='block'>
                          <div>
                            <img src={game.logo}
                                alt={game.slug} />
                            <p>
                              {game.slug}
                            </p>
                            <span>
                              {defaultData.moneda.signo}{game.salaPremioJackpots}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  ) : (null)
              ))
            }
          </div>
          <div className='row pt-5 d-lg-none'>
            <Col className="text-center">
              <button 
                  className='button bordered_button'
                  onClick={() => setSeeAll(!seeAll)} >
                  {
                    seeAll ? 
                      textosShow.verjuegosprincipales || 'Best Games'
                    :
                      textosShow.vertodoslosjuegos || 'All Games'
                  }
              </button>
            </Col>
          </div>
          <Sparks></Sparks>
          <Fire></Fire>
          <SmokeCenter></SmokeCenter>
          <BlocksShadow></BlocksShadow>
          <div className='marginer'></div>
          <div className='page_content'>
            <div className='block_c about'>
            <div className="row">
              <div className='col-ld-5 ba_left'>
                  <h2>
                    {textosShow.acercadeljuego}
                  </h2>
                  <div className='text'>
                    {textosShow.acercadeljuegoparrafo1}
                    <br />
                    <br />
                    {textosShow.acercadeljuegoparrafo2}
                  </div>
                  <button className='button play_button'>
                    {textosShow.jugarahora}
                  </button>
                </div>
                <div className='col-ld-7 ba_right'>
                  <div 
                    className="rotateslider-container" 
                    id="slider">
                    <div className="rotateslider-item">
                      <div className='slide_content'>
                        <img 
                          src={sliderIcon1}
                          alt="Easy to play" />
                        <h3>
                          {textosShow.facildejugar}
                        </h3>
                        <span>
                          {textosShow.facildejugarparrafo}
                        </span>
                      </div>
                    </div>
                    <div className="rotateslider-item">
                      <div className='slide_content'>
                        <img 
                          src={sliderIcon2}
                          alt="Epic Battles" />
                        <h3>
                          {textosShow.batallasepicas}
                        </h3>
                        <span>
                          {textosShow.batallasepicasparrafo}
                        </span>
                      </div>
                    </div>
                    <div className="rotateslider-item">
                      <div className='slide_content'>
                        <img 
                          src={sliderIcon3}
                          alt="Mega Jackpot" />
                        <h3>
                          {textosShow.megajackpot3}
                        </h3>
                        <span>
                          {textosShow.megajackpotparrafo3}
                        </span>
                      </div>
                    </div>
                    <div className="rotateslider-item">
                      <div className='slide_content'>
                        <img 
                          src={sliderIcon4}
                          alt="Fast Withdraw" />
                        <h3>
                          {textosShow.retirorapido}
                        </h3>
                        <span>
                          {textosShow.retirorapidoparrafo}
                        </span>
                      </div>
                    </div>
                    <div className="rotateslider-item">
                      <div className='slide_content'>
                        <img 
                          src={sliderIcon5}
                          alt="Best Support" />
                        <h3>
                          {textosShow.elmejorsoporte}
                        </h3>
                        <span>
                          {textosShow.elmejorsoporteparrafo}
                        </span>
                      </div>
                    </div>
                    <span 
                      className="arrow right js-rotateslider-arrow" 
                      data-action="next">
                      <i className="fa fa-angle-right fa-3x"></i>
                    </span>
                    <span 
                      className="arrow left js-rotateslider-arrow" 
                      data-action="prev">
                      <i className="fa fa-angle-left fa-3x"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
              <div className='jackpot jackpot2'>
                {textosShow.megajackpot4}
                <div>
                  {textosShow.numerojackpot4}
                </div>
              </div>
              <div className='block_c advantages'>
                <div className='page_title'>
                  <span>
                    <p>
                      {textosShow.ventajas}
                    </p>
                  </span>
                </div>
                <div className='adv_list row'>
                  <AdvantajeCard 
                    icon={sliderIcon1}
                    iconAlt="Easy to Play"
                    title={textosShow.facildejugar2}
                    description={textosShow.facildejugarparrafo2}
                  />
                  <AdvantajeCard 
                    icon={sliderIcon2}
                    iconAlt="Epic Battles"
                    title={textosShow.batallasepicas2}
                    description={textosShow.batallasepicasparrafo2}
                  />
                  <AdvantajeCard 
                    icon={sliderIcon3}
                    iconAlt="Mega Jackpot"
                    title={textosShow.megajackpot2}
                    description={textosShow.megajackpotparrafo2}
                  />
                  <AdvantajeCard 
                    icon={sliderIcon4}
                    iconAlt="Fast Withdraw"
                    title={textosShow.retirorapido2}
                    description={textosShow.retirorapidoparrafo2}
                  />
                  <AdvantajeCard 
                    icon={sliderIcon5}
                    iconAlt="Best Support"
                    title={textosShow.elmejorsoporte2}
                    description={textosShow.elmejorsoporteparrafo2}
                  />
                  <AdvantajeCard 
                    icon={sliderIcon6}
                    iconAlt="Free Bonuses"
                    title={textosShow.bonosgratis}
                    description={textosShow.bonosgratisparrafo}
                  />
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
  