import React, { Component } from 'react';

import $ from 'jquery';

class HowToLevelModal extends Component{

  constructor(props) {
    super(props);
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);

    // inicialize state
    this.state = {
      titulo: '',
      texto: ''
    }

    this.getHowToPage();
  }

  getHowToPage() {
    let clase = this;
    fetch(clase.props.urlGlobal + "api/page", {
      method: "POST",
      body: JSON.stringify({
        action: "getPage",
        page: `howincreselevel`,
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        clase.setState({
          titulo: result.page.titulo,
          texto: result.page.texto
        });
      }
    });
  };

  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }
  
  close() {
    $("#how_to_level_modal").fadeOut("fast", function() {
      $("#how_to_level_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  }

  render() {
    return (
      <div className="modalx-overlay close_mw"
           id="how_to_level_modal_overlay">
        <div className='modal_window' 
             id="how_to_level_modal">
          <button id="close_modal"
                    className="link-button close_mw"
                    onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                {
                  this.state.titulo ? (
                    <h5>
                      {this.state.titulo}
                    </h5>
                  ) : null
                }
                {
                  this.state.texto ? (
                    <div className='modal_form'>
                      <div className='terms_text'>
                        <div className='terms_scroll'>
                          <div dangerouslySetInnerHTML={{__html: this.state.texto}}></div>
                        </div>
                      </div>
                    </div>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HowToLevelModal;