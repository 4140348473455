import React, { useEffect, useRef, useState } from 'react';
import dateFormat from 'dateformat';
import Ripples from 'react-ripples';

import apprenticeIcon from '../../img/apprentice.png';
import warriorIcon from '../../img/warrior.png';
import proPlayerIcon from '../../img/pro-player.png';
import congratsImg from '../../img/congrats.gif';
import moneyIcon from '../../img/coin_icon_small.png';
import gemIcon from '../../img/gem.png';
import wonIcon from '../../img/crown.png';

import MembershipUtil from '../utils/membership';
import Util from '../utils/utils';
import $ from 'jquery';


export default function BlogCommentItem({item, userData, defaultData, textosShow, lastReadCommentId, updateLastReadCommentId, showPerfilUserModal, showChallengeModal, onEditBlogComment, onDeleteBlogComment}) {

    const ref = useRef(null);
    const intersectionOption = {
        threshold: 1.0
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            if (entry && entry.isIntersecting && item.IsReal) {
                updateLastReadCommentId(item.Id);
            }
            
        }, intersectionOption);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current)    observer.unobserve(ref.current);
        };

    }, [ref, intersectionOption]);

    const getBlogTime = () => {
        const createdAt = new Date(item.CreatedAt * 1000);
        return dateFormat(createdAt, "mm-dd H:MM");
    }

    return (
        <div className='blog-comment-item' ref={ref}>
            <div className='blog-comment-header select'>
                <button className='main-item'>
                    <img className='blog-comment-avatar'
                        src={item.UserFoto}
                        onError={(e) => {
                            e.target.src = Util.getDefaultAvatar();
                        }} />
                    <div className='badge'>
                        <img className='blog-comment-user-membership'
                            src={MembershipUtil.getMembershipIcon2(item.ClienteMembershipId)} />
                        <img className='blog-comment-user-flag'
                            src={item.PaisFoto} />
                    </div>
                </button>
                <ul className="sub-menu lang_menu">
                    <li>
                        <span 
                            className="link-button"
                            onClick={() => showPerfilUserModal(item.IdCliente)}>
                            {textosShow.verperfil || "Ver perfil"}
                        </span>
                        {
                            (item.IdCliente !== userData.id) ? (
                            <span
                                className="link-button"
                                onClick={() => showChallengeModal(item.IdCliente)}>
                                {textosShow.desafiar || "Desafiar"}
                            </span>
                            ) : (
                            null
                            )
                        }
                    </li>
                </ul>
            </div>
            <div className='blog-comment-content'>
                <div className='title'>
                    <span className='user-name'>{item.UserName || ''}</span>
                    <span className='time'>{getBlogTime()}</span>
                </div>
                
                <div className='d-flex'>
                    <div className='message'>
                        {item.BlogCommentContent}
                        { item.BlogCommentType == 'winner_msg' &&
                            <div className='blog-comment-winner'>
                                <div className='game-info'>
                                    <div className='game-icon'>
                                        <img 
                                            src={item.JuegoIcon}
                                        />
                                    </div>
                                    <div className='game-info-text'>
                                        <span className='game-title'>{item.JuegoName}</span>
                                        <span className='game-mode'>{item.JuegoMode}</span>
                                    </div>
                                </div>
                                <div className='winner-won'>
                                    <img 
                                        className='img-won'
                                        src={wonIcon}/>
                                    <span>{textosShow.won || "Won"}</span>
                                </div>
                                <div className='winner-prize'>
                                    <div className='money'>
                                        <img
                                            className='img-congrats' 
                                            src={congratsImg}/>
                                        <img
                                            className='icon-money'
                                            src={moneyIcon}
                                            />
                                        {defaultData.moneda.signo}                                            
                                        {item.Premio}
                                    </div>
                                    {/* <div className='gem'>
                                        <img
                                            className='img-congrats' 
                                            src={congratsImg}/>
                                        <img
                                            className='icon-gem'
                                            src={gemIcon}
                                            />
                                        {item.Gemas}
                                    </div> */}
                                </div>
                            </div>
                        }
                    </div>
                    
                    <div className='p-relative'>
                        { ((item.IdCliente === userData.id) || userData.moderator)  &&
                            <div className='select'>
                                <button className='btn-more main-item'><i class="fa fa-ellipsis-h" aria-hidden="true"></i></button>
                                <ul className="sub-menu lang_menu">
                                    <li>
                                        <span 
                                            className="link-button"
                                            onClick={() => {onEditBlogComment(item)}}>
                                            {textosShow.edit || 'Edit'}
                                        </span>
                                    </li>
                                    <li>
                                        <span 
                                            className="link-button"
                                            onClick={() => {onDeleteBlogComment(item)}}>
                                            {textosShow.delete || 'Delete'}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        }

                        { (item.Id === lastReadCommentId) &&
                            <div className='msg-seen'>
                                <img
                                    src={userData.foto}
                                    onError={(e) => {
                                        e.target.src = Util.getDefaultAvatar();
                                    }}/>
                            </div>
                        }
                    </div>
                    
                    
                </div>
            </div>
        </div>
    )
}
