import React, { useEffect, useRef, useState } from 'react';

import iconLoading from '../../img/icon_small.png';

export default function LoadingIndicator() {

    return (
      <div className='modalx-overlay' style={{display: 'block', zIndex: 5000}}>
          <div 
            className='d-flex-center'
            id='loading_modal'>
            <img 
              className='rotate-item'
              src={iconLoading}/>
          </div>
          
      </div>
    )
}