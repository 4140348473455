import React, { Component } from 'react';

class NoticiaModal extends Component {

  constructor(props) {
    super(props);
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  };

  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  };
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  };
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  };
  
  close() {
    this.props.onClose();
  };

  render() {
    return (
      <div
        className="modalx-overlay close_mw"
        id="noticia_modal_overlay">
        <div
          className='modal_window' 
          id="noticia_modal">
          <button
            id="close_modal"
            className="link-button close_mw"
            onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              {
                this.props.noticia ? (
                  <div className='modal_content'>
                    <h5>
                      {this.props.noticia.titulo}
                    </h5>
                    <div className='modal_form'>
                      <div className='terms_text'>
                        <div className='terms_scroll'>
                          <div 
                            className='noticia_text'
                            dangerouslySetInnerHTML={{__html: this.props.noticia.texto}}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  null
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default NoticiaModal;
