import React, { Component } from 'react';

class SmokeCenter extends Component {
  
  render() {
    return (
      <div className='smoke_center'></div>
    );
  }
  
};

export default SmokeCenter;