import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export default function Croper(props) {
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);

    function generateDownload(canvas, crop) {
        if (!crop || !canvas) {
            return;
        }

        canvas.toBlob(
            (blob) => {

                //Now convert the blob to a wav file or whatever the type you want
                var wavefilefromblob = new File([blob], 'filename.jpg');

                props.showLoadingIndicator(true)
                var formData = new FormData();
                formData.append("Foto", wavefilefromblob);
                formData.append("action", "updateProfile");
                fetch(props.urlGlobal + "api/cliente", {
                    method: "POST",
                    body: formData,
                    headers: props.getHeadersToSend(),
                    contentType: false,
                    processData: false,
                }).then(function (response) {
                    return response.json();
                }).then(function (result) {
                    if (result.success) {
                        props.showLoadingIndicator(false)
                        console.log("result", result)
                        props.close();
                        setUpImg();
                        setCrop({ unit: '%', width: 30, aspect: 16 / 9 });
                        setCompletedCrop(null);
                        window.location.reload(false);
                    }
                    else {
                    }
                });
            },
            'image/png',
            1
        );
    }

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null;
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }, [completedCrop]);

    return (
        <div className="App">
            <div style={{paddingLeft:"80px"}}>
                {/* <h1>{props.userId}</h1> */}
                <input type="file" accept="image/*" onChange={onSelectFile} style={{ background: "none" }} />
            </div>
            <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
            />
            <div style={{ visibility: "hidden" }}>
                <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                        width: 0,
                        height: 0
                    }}
                />
            </div>
            <div className='activate_buttopenFriendModalons'>
                <button type="button" className="button button_regular button_orange" disabled={!completedCrop?.width || !completedCrop?.height} onClick={() =>
                    generateDownload(previewCanvasRef.current, completedCrop)
                }>
                    Upload
                  </button>
            </div>
        </div>
    );
}
