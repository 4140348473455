import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './font/albertus_medium.ttf';
import './css/OpenSans.css';
import './css/LiberationSerif.css';
import './css/circular-progress.css';

import './js/static/jquery.rotateSlider.js';
import App from './js/App';
import '../node_modules/video-react/dist/video-react.css';
import DocumentMeta from 'react-document-meta';
import MobileCheckoutApp from './js/MobileCheckoutApp';

const meta = {
  title: 'DuelCash.com - Making the world more fun',
  description: 'DuelCash.com - Making the world more fun',
  canonical: 'https://www.duelcash.com',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'duelcash,game,play'
    }
  }
};

// retrive path from url
const isMobileCheckout = window.location.pathname === "/mobile-checkout";

if (!isMobileCheckout) {
  window.addEventListener('load', function(event) {
    document.body.style.background = '';
  });
  import('./css/Style.css');
  import('./css/Spin.css');
  import('./css/searchBar.css');
  import('./css/Animation.css');
  import('./css/SmokeBox.css');
  import('./css/effects.css');
  import('./css/MobileStyle.css');
  import('./css/slick.css');
  import('./css/slick-theme.css');
}

ReactDOM.render(
  isMobileCheckout ? (
    <BrowserRouter>
        <MobileCheckoutApp />
    </BrowserRouter>
  ) : (
  <DocumentMeta {...meta}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
  </DocumentMeta>
  ),
  document.getElementById('root')
);
// registerServiceWorker();
