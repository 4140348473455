import React, { Component } from 'react';

import $ from 'jquery';

import errorAlert from '../../img/error_alert.png';

class AlertModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  }
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }
  
  close() {
    $("#alert_modal").fadeOut("fast", function() {
      $("#alert_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  }
  toSubscription() {
    document.location.href="/subscription"
  }

  render() {
    return (
      <div className="modalx-overlay close_mw"
           id="alert_modal_overlay">
        <div className='modal_window'
             id="alert_modal">
          <button id="close_modal"
                  className="link-button close_mw"
                  onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                <div className='activate_icon'>
                  <img src={errorAlert}
                       alt="Alert" />
                </div>
                <h4>
                  {this.props.title}
                </h4>
                <div className='activate_text'>
                  {this.props.message}
                </div>
                <div className='activate_buttons'>
                  {
                    this.props.purchase && (
                      <button className="button button_regular button-green"
                        onClick={this.toSubscription.bind(this)}>
                        {this.props.textosShow.mejora || "Mejora"}
                      </button>
                    )
                  }
                                    
                  <button className="button button_regular button_orange"
                          onClick={this.close.bind(this)}>
                    {this.props.textosShow.aceptar || "Aceptar"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default AlertModal;