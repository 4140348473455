import React, { Component } from 'react';

import $ from 'jquery';

import errorAlert from '../../img/error_alert.png';

class AlertFriendModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  }
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }
  
  close() {
    $("#alert_modal").fadeOut("fast", function() {
      $("#alert_modal_overlay2").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  }
  
  render() {
    return (
      <div style={{zIndex:10000}} className="modalx-overlay close_mw"
           id="alert_modal_overlay2">
        <div className='modal_window'
             id="alert_modal">
          <button id="close_modal"
                  className="link-button close_mw"
                  onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                <div className='activate_icon'>
                  <img src={errorAlert}
                       alt="Alert" />
                </div>
                <h4>
                  {/* {this.props.alertTitle} */}
                  {this.props.textosShow.therequesthasbeensent || "The request has been sent"}
                </h4>
                {/* <h6 className='activate_text'>
                  {this.props.message}
                </h6> */}
                <div className='activate_buttopenFriendModalons'>
                  <button className="button button_regular button_orange"
                          onClick={this.close.bind(this)}>
                    {/* {this.state.textosShow.aceptar || "Aceptar"} */}
                    {this.props.textosShow.close || "Close"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default AlertFriendModal;