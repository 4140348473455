import React, { Component } from 'react';

import $ from 'jquery';

class FaqReferralModal extends Component{

  constructor(props) {
    super(props);

    this.state = {
      faqs: null
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  };

  componentDidMount() {
    this.getFaqs();
    document.addEventListener("keydown", this.keyDown, false);
  };
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  };
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  };
  
  close() {
    $("#faq_referral_modal").fadeOut("fast", function() {
      $("#faq_referral_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  };

  getFaqs() {
    let clase = this;
    clase.setState({
      loading: true
    });
    fetch(clase.props.urlGlobal + "api/page", {
      method: "POST",
      body: JSON.stringify({
        action: "getPage",
        page: "faqreferral",
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.setState({
          faqs: {
            titulo: result.page.titulo,
            texto: result.page.texto
          }
        });
      }
    });
  };

  render() {
    return (
      <div className="modalx-overlay close_mw"
           id="faq_referral_modal_overlay">
        <div className='modal_window' 
             id="faq_referral_modal">
          <button id="close_modal"
                    className="link-button close_mw"
                    onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                <h5>
                  FAQ
                </h5>
                {
                  this.state.faqs ? (
                    <div className='modal_form'>
                      <div className='terms_text'>
                        <div className='terms_scroll'>
                          <div className='faq_title'>
                            {this.state.faqs.titulo}
                          </div>
                          <div 
                            className='faq_text'
                            dangerouslySetInnerHTML={{__html: this.state.faqs.texto}}></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    null
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default FaqReferralModal;
