import React, { Component } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Row, Col,Button } from 'reactstrap';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import $ from 'jquery';
import Sparks from '../components/Sparks';
import Fire from '../components/Fire';

import logo from '../../img/logo_big.png';

import CashIcon from '../../img/cash.png';
import MembershipIcon from '../../img/membership/bronze.png';
import Crowns from '../../img/crown1.png';
import GemsIcon from '../../img/gem.png';
import FaqPromoCodeModal from '../components/FaqPromoCodeModal';

export default class PromoCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: null,
            promoCodes: [],
            reedemPomoCodes: [],
            promoCode: null,
            errorMessage:null,
            successMessage : null,
            msgSlug:null,
            appliedPromoCode:null,
            successPromoCode : null
        };
    };

    /**
     * Get promo codes
     */
     getPromoCodes() {
        this.props.showLoadingIndicator(true)
        let className = this;
        fetch(this.props.urlGlobal + "api/promoCodes", {
            method: "POST",
            body: JSON.stringify({
                action: "getPromoCodes",
                lan: this.props.getLanActual()
            }),
            headers: this.props.getHeadersToSend()
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.success) {
                className.props.showLoadingIndicator(false)
                className.setState({ promoCodes: result.data });
            }
            else {
            }
        });
    };

     /**
     * Get reedem promo codes
     */
      getReedemPromoCodes() {
        this.props.showLoadingIndicator(true)
        let className = this;
        fetch(this.props.urlGlobal + "api/promoCodes", {
            method: "POST",
            body: JSON.stringify({
                action: "getReedemPromoCodes",
                lan: this.props.getLanActual()
            }),
            headers: this.props.getHeadersToSend()
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.success) {
                className.props.showLoadingIndicator(false)
                className.setState({ reedemPomoCodes: result.data })
            }
            else {
            }
        });
    };

    /**
     * Get Image
     * 
     * @param {*} item 
     * @returns 
     */
    getImage(item){
        if(item.type === 'cash'){
            return CashIcon;
        }else if(item.type === 'gems'){
            return GemsIcon;
        }else if(item.type === 'membership'){
            return MembershipIcon;
        }else if(item.type === 'entryFees'){
            return Crowns;
        }
        return CashIcon;
    }

    /**
     * Apply Promo Code
     */
    applyPromoCode(promoCode){
        if(promoCode && promoCode !== ''){
            this.setState({appliedPromoCode:promoCode});
            this.props.showLoadingIndicator(true)
            let className = this;
            fetch(this.props.urlGlobal + "api/promoCodes", {
                method: "POST",
                body: JSON.stringify({
                    action: "applyPromoCode",
                    lan: this.props.getLanActual(),
                    promoCode : promoCode
                }),
                headers: this.props.getHeadersToSend()
            }).then(function (response) {
                return response.json();
            }).then(function (result) {
                className.props.showLoadingIndicator(false)
                if (result.success) {
                    className.props.getDataUser(result.data);
                    className.setState({successPromoCode : result.data});
                    className.setState({promoCode : null});
                    className.setState({ successMessage: result.msg });
                    className.setState({ errorMessage: null });
                    className.getReedemPromoCodes();
                }
                else {
                    className.setState({ successMessage: null })
                    className.setState({ errorMessage: result.msg })
                }
                className.setState({ msgSlug: result.msgSlug });
            });
        }else{
            this.setState({ successMessage: null })
            this.setState({ errorMessage: "Enter promo code" })
            this.setState({ msgSlug: "enterpromocode" });
            this.setState({appliedPromoCode:""});
        }
    }

    showFaqModal(){
        $("#faq_promo_code_modal_overlay").fadeIn("fast", function () {
          $("#faq_promo_code_modal").fadeIn("fast", function () {
            document.body.style.overflow = "hidden";
          });
        });
      }

    componentDidMount() {
        this.getPromoCodes();
        this.getReedemPromoCodes();
    }

    render() {
        if (!this.props.loggedIn) {
            return (<Redirect to={'/'} />);
          }
        return (
            <div>
                <FaqPromoCodeModal
                urlGlobal={this.props.urlGlobal}
                getLanActual={this.props.getLanActual}
                getHeadersToSend={this.props.getHeadersToSend} />
                <div className='content' id="membership">
                    <div className='wrapper'>
                        <div className='page_content'>
                            <div className='c_center'>
                                <div className='c_top bg_main' >
                                    <div className='c_bottom'>
                                        <Eagle></Eagle>
                                        <Leaves></Leaves>
                                        <div className='game_image'>
                                            <img style={{ width: 'inherit', marginTop: '30px' }} src={logo} />
                                        </div>
                                        <div style={{ position: 'absolute', left: 0, right: 0, top: 80, zIndex: 100 }}>
                                                <Sparks />
                                                <Fire />
                                            </div>
                                        <div>
                                            <h4 style={{ color: "#C09262", marginTop: "-20px" }}>{this.props.textosShow.promocode || "Promo Code"}</h4>
                                            <div className='faq_help'>
                                                <button
                                                className='faq_info'
                                                onClick={this.showFaqModal}>
                                                FAQ
                                                </button>
                                            </div>
                                            <br></br>
                                        </div>
                                        <Row style={{ paddingLeft: '36px', textAlign: 'initial', color: '#ffffff', fontFamily: 'Albertus', fontSize: '14px' }}>
                                            <Col xs='7'>
                                                <h4 style={{textAlign:"center",fontSize:"20px"}}>{this.props.textosShow.applypromocode || "Apply Promo Code"}</h4>
                                                    <Row>
                                                        <Col xs="10">
                                                            <input type="text" onChange={(e) => this.setState({ promoCode: e.target.value })}  placeholder={this.props.textosShow.enterpromocode || "Enter promo code"} style={{width:"100%",height:"70px",fontSize:"20px"}}/>
                                                        </Col>
                                                        <Col xs="2">
                                                            <Button type="button" className='apply-promo-code-button glow-on-hover' style={{width:"83px",height:"70px",fontSize:"20px",marginTop:"0px"}} onClick={() => this.applyPromoCode(this.state.promoCode)}>{this.props.textosShow.apply || "Apply"}</Button>
                                                        </Col>
                                                    </Row>
                                                <div className='marginer' style={{height:"67px",marginTop:"-20px",marginBottom:"-20px"}}></div>
                                                {
                                                    this.state.successMessage ? 
                                                    <div className='crackers' style={{height:"100px",width:"100%",border:"2px solid rgb(192, 146, 98)",textAlign:"center",padding:"10px"}}>
                                                        <p style={{fontSize:"20px"}}>
                                                            {this.props.textosShow[this.state.msgSlug] ? this.props.textosShow[this.state.msgSlug] : this.state.successMessage}
                                                            &nbsp; (
                                                            {this.state.successPromoCode.type === 'cash' ? (this.props.textosShow.discountworth ? this.props.textosShow.discountworth + " " : "Discount worth ") + "$" + this.state.successPromoCode.amount : null}
                                                            {this.state.successPromoCode.type === 'gems' ? (this.props.textosShow.gemscredit ? this.props.textosShow.gemscredit + " " : "Gems credit ") + this.state.successPromoCode.gems : null}
                                                            {this.state.successPromoCode.type === 'membership' ? ((this.props.textosShow.avail ? this.props.textosShow.avail + " " : "Avail ") + this.state.successPromoCode.membership + (this.props.textosShow.membership ? " " + this.props.textosShow.membership : " membership")) : null}
                                                            {this.state.successPromoCode.type === 'entryFees' ? ((this.props.textosShow.avail ? this.props.textosShow.avail + " " : "Avail ") + this.state.successPromoCode.juego + (this.props.textosShow.entryfee ? " " + this.props.textosShow.entryfee : " entry fee")) : null}
                                                            )
                                                            </p>
                                                        <span style={{color:"#a48007",fontSize:"20px",fontWeight:"bold"}}>{this.state.appliedPromoCode}</span>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {
                                                    this.state.errorMessage ? 
                                                    <div className='promo-code-error' style={{height:"100px",width:"100%",textAlign:"center",padding:"10px"}}>
                                                        <p style={{fontSize:"20px",color:"red",fontFamily: "Exocet",fontWeight:"bold",marginTop:"10px",marginBottom:"0px"}}>{this.props.textosShow[this.state.msgSlug] ? this.props.textosShow[this.state.msgSlug] : this.state.errorMessage}</p>
                                                        <span style={{color:"#a48007",fontSize:"20px",fontWeight:"bold",fontFamily: "Exocet"}}>{this.state.appliedPromoCode}</span>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                <p style={{textAlign:"left",fontSize:"20px",marginTop:"20px",marginBottom:"0px",fontFamily: "Exocet"}}>{this.props.textosShow.orselectaoffer || "Or select a offer"} ({this.state.promoCodes.length})</p>
                                                
                                                <div style={{height:"400px"}} className="promoCodeOverflow">
                                                    {this.state.promoCodes && this.state.promoCodes.length > 0 ?
                                                        this.state.promoCodes.map((item, key) =>
                                                            <div className='promo-code' style={{ marginTop: "10px", color: "#8589A1" }}>
                                                                <Row>
                                                                    <Col xs="1">
                                                                        <img src={this.getImage(item)} style={{ width: "40px", height: "40px",marginTop:"10px",marginLeft:"5px" }}/>
                                                                    </Col>
                                                                    <Col xs="9" style={{ marginTop: '10px' }}>
                                                                        <span style={{fontWeight:"bold",color:"white"}}>{item.promoCode}</span> | &nbsp;
                                                                        {item.type === 'cash' ? (this.props.textosShow.discountworth ? this.props.textosShow.discountworth + " " : "Discount worth ") + "$" + item.amount : null}
                                                                        {item.type === 'gems' ? (this.props.textosShow.gemscredit ? this.props.textosShow.gemscredit + " " : "Gems credit ") + item.gems : null}
                                                                        {item.type === 'membership' ? ((this.props.textosShow.avail ? this.props.textosShow.avail + " " : "Avail ") + item.membership + (this.props.textosShow.membership ? " " + this.props.textosShow.membership : " membership")) : null}
                                                                        {item.type === 'entryFees' ? ((this.props.textosShow.avail ? this.props.textosShow.avail + " " : "Avail ") + item.juego + (this.props.textosShow.entryfee ? " " + this.props.textosShow.entryfee : " entry fee")) : null}
                                                                        <p>{item.description}</p>
                                                                    </Col>
                                                                    <Col xs="1" style={{textAlign:"right" }}>
                                                                        <Button type="button" className='apply-promo-code-button glow-on-hover' onClick={() => this.applyPromoCode(item.promoCode)}>{this.props.textosShow.apply || "Apply"}</Button>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                        :
                                                        <div style={{ textAlign: "center", paddingTop: "40px" }}>{this.props.textosShow.nodataavailable || "No Data Available"}</div>
                                                    }
                                                </div>
                                            </Col>
                                            <Col xs='5' style={{ color: '#ffffff' }}>
                                            <h4 style={{textAlign:"center",fontSize:"20px"}}>{this.props.textosShow.yourreedemedpromocode || "Your Reedemed Promo Code"}</h4>
                                            <div style={{height:"560px"}} className="promoCodeOverflow">
                                                    {this.state.reedemPomoCodes && this.state.reedemPomoCodes.length > 0 ?
                                                        this.state.reedemPomoCodes.map((item, key) =>
                                                            <div className='promo-code' style={{ marginTop: "10px", color: "#8589A1" }}>
                                                                <Row>
                                                                    <Col xs="2">
                                                                        <img src={this.getImage(item)} style={{ width: "40px", height: "40px",marginTop:"15px",marginLeft:"20px" }}/>
                                                                    </Col>
                                                                    <Col xs="10" style={{ marginTop: '5px' }}>
                                                                        <span style={{fontWeight:"bold",color:"white"}}>{item.promoCode}</span> | &nbsp;
                                                                        {item.type === 'cash' ? (this.props.textosShow.discountworth ? this.props.textosShow.discountworth + " " : "Discount worth ")+ "$" + item.amount : null}
                                                                        {item.type === 'gems' ? (this.props.textosShow.gemscredit ? this.props.textosShow.gemscredit + " " : "Gems credit ") + item.gems : null}
                                                                        {item.type === 'membership' ? ((this.props.textosShow.avail ? this.props.textosShow.avail + " " : "Avail ") + item.membership + (this.props.textosShow.membership ? " " + this.props.textosShow.membership : " membership")) : null}
                                                                        {item.type === 'entryFees' ? ((this.props.textosShow.avail ? this.props.textosShow.avail + " " : "Avail ") + item.juego + (this.props.textosShow.entryfee ? " " + this.props.textosShow.entryfee : " entry fee")) : null}
                                                                        <p>{item.description}</p>
                                                                        <p style={{textAlign:"left",marginTop:"-15px",paddingRight:"5px",fontWeight:"bold"}}>{item.fecha}</p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                        :
                                                        <div style={{ textAlign: "center", paddingTop: "40px" }}>{this.props.textosShow.nodataavailable || "No Data Available"}</div>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
