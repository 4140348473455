import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Card, Col, Container, Row } from 'reactstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import gemIcon from '../../img/gem.png';
import completed from '../../img/completed.png';

export default function ObjectiveCard({ item, textosShow }) {

  const [currentTime, setCurrentTime] = useState(Date.now());
  const targetTime = new Date(item.remainingTime).getTime();

  const timeBetween = useMemo(() => {
    return targetTime - currentTime;
  }, [currentTime, targetTime]);

  const minutes = useMemo(() => {
    return Math.floor((timeBetween / 1000 / 60) % 60);
  }, [timeBetween]);
  const hours = useMemo(() => {
    return Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
  }, [timeBetween]);
  const days = useMemo(() => {
    return Math.floor(timeBetween / (1000 * 60 * 60 * 24));
  }, [timeBetween]);
  const seconds = useMemo(() => {
    return Math.floor((timeBetween / 1000) % 60);;
  }, [timeBetween]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);

  }, []);

  const getObjectiveProgressPercent = () => {
    return item.total_number_of_games_played / item.play_number * 100;
  }

  const getMaxDay = () => {

    let result = 0;
    switch (item.time_type) {
      case 'week':
        result = item.time_duration * 7;
        break;

      case 'days':
        result = timeBetween.length >= 0 && days.length >= 0 ? item.time_duration : 10;
        break;
    }

    return result;
  }

  const circularProgressStyle = () => {
    return buildStyles({
      textColor: "#A17945",
      pathColor: "#A17945",
      trailColor: "#161212",
    });
  }

  return (
    <>
      <Card className='objective-card'>
        <div className='objective-img-container'>
          <div className='img-bg'></div>
          <img src={item.img} />
        </div>
        <div className='description line-camp-2'>
          <span>
            {textosShow.play || 'Play'} {item.play_number} {item.game_name} {textosShow.games_in || 'Games in'} {item.time_duration} {textosShow[item.time_type] || item.time_type}
          </span>
        </div>
        <div className='objective-bar-container'>
          <div className='objective-bar'>
            <div className='objective-progress' style={{ width: `${getObjectiveProgressPercent()}%` }}>
            </div>
            <div className='objective-fr progress-text d-flex-center'>
              {item.total_number_of_games_played} / {item.play_number}
            </div>
          </div>
        </div>
        <div className='objective-dont-miss line-camp-1'>
          {textosShow.dont_miss_deal || "Don't miss the deal !!!"}
        </div>
        {item.total_number_of_games_played == item.play_number ?
          <div>

            <img style={{ width: "150px", transform: 'rotate(22.5deg)', marginTop: '-1px', marginLeft: '52px' }} src={completed} />
          </div>

          :
          <div className='timer-container' style={{ marginLeft: "-5px" }}>
            <div className='timer-item d-flex-center d-flex-ver'>
              <div className='timer-progress'>
                <CircularProgressbar
                  value={days}
                  maxValue={getMaxDay()}
                  styles={circularProgressStyle()} />
                <span className='circular-progress-text'>{days > 0 ? days : 0}</span>
              </div>
              <span className='timer-desc'>{textosShow.days || 'Day'}</span>
            </div>
            <div className='timer-seperator d-flex-center d-flex-ver'>:</div>
            <div className='timer-item d-flex-center d-flex-ver'>
              <div className='timer-progress'>
                <CircularProgressbar
                  value={hours}
                  maxValue={24}
                  styles={circularProgressStyle()} />
                <span className='circular-progress-text'>{hours > 0 ? hours : 0}</span>
              </div>
              <span className='timer-desc'>{textosShow.hours || 'Hour'}</span>
            </div>
            <div className='timer-seperator d-flex-center d-flex-ver'>:</div>
            <div className='timer-item d-flex-center d-flex-ver'>
              <div className='timer-progress'>
                <CircularProgressbar
                  value={minutes}
                  maxValue={60}
                  styles={circularProgressStyle()} />
                <span className='circular-progress-text'>{minutes > 0 ? minutes : 0}</span>
              </div>
              <span className='timer-desc'>{textosShow.minute || 'Minute'}</span>
            </div>
            <div className='timer-seperator d-flex-center d-flex-ver'>:</div>
            <div className='timer-item d-flex-center d-flex-ver'>
              <div className='timer-progress'>
                <CircularProgressbar
                  value={seconds}
                  maxValue={60}
                  styles={circularProgressStyle()} />
                <span className='circular-progress-text'>{seconds > 0 ? seconds : 0}</span>
              </div>
              <span className='timer-desc'>{textosShow.second || 'Second'}</span>
            </div>
          </div>
        }


        <button className='btn-objective'>
          <img
            src={gemIcon} />
          <span className='objective-rewards'>
              {item.gems} {textosShow.gemas || 'Gems'}
            </span>
        </button>
      </Card>
    </>
  )
}