import React, { Component } from 'react';

class BlocksShadow extends Component {
  
  render() {
    return (
      <div className="blocks_shadow"></div>
    );
  }
  
};

export default BlocksShadow;