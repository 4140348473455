import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ReactTooltip from 'react-tooltip';
import {ReactComponent as RingSVG} from "../../img/ring.svg";

import thunder from '../../img/thunder64.png';
import MembershipUtil from '../utils/membership';
import Util from '../utils/utils';

import duelcash_icon from '../../img/companies/icon_small_comb.png';
import icon_arkadium from '../../img/companies/icon_arkadium.png';
import icon_zygomatic from '../../img/companies/icon_zygomatic.png';
import icon_coolgames from '../../img/companies/icon-coolgames.png';
import icon_famobi from '../../img/companies/icon-famobi.png';

const company_icons = {
  "duelcash": duelcash_icon,
  "arkadium": icon_arkadium,
  "zygomatic": icon_zygomatic,
  "coolgames": icon_coolgames,
  "famobi": icon_famobi,
}

class Games extends Component {

  constructor(props) {
    super(props);
    this.state = {
      company: 'duelcash'
    }
  }

  handleCompanyChange = (e) => {
    this.setState({
      company: e.target.id
    });
  }

  currentCompany = () => {
    //find current company object from props
    let company = this.props.companies.find(company => company.id === this.state.company);
    return company || {};
  }

  render() {
    const settings = {
      dots: false,
      vertical: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false
    };
    
    return (
      <div>
        <div className='vs_block'>
          {this.props.companies && (
            <div className='company_selector'>
              <button className='main-item'>
                <img
                  src={company_icons[this.state.company]}
                  alt={this.currentCompany().name} />
              </button>
              <ul className="sub-menu profile_menu">
                <li>
                  {this.props.companies.map((company, index) => (
                    <span
                      className="link-button"
                      onClick={() => this.handleCompanyChange({target: company, index})}>
                      <img
                        src={company_icons[company && company.id]}
                        alt={company && company.name} />
                    </span>
                  ))}                
                </li>
              </ul>
            </div>
          )}
            <div className='mt_block step_1'>
              <div className='step'>
                1
              </div>
              <h6>
                {this.props.textosShow.paso || "Paso"} 1
              </h6>
              {this.props.textosShow.estaeslabarradeselecciondejuego || "Esta es la barra de selección de juego"}
              <div className='notif_buttons'>
                <button 
                  className="button green_button"
                  onClick={this.props.openTutorialStep2.bind(this)}>
                  Ok
                </button>
              </div>
            </div>
            <Slider 
              {...settings}
              className="vertical slider">
              {
                this.props.games.filter((game) => game.company === this.state.company).map((game) => (
                  <div 
                    key={game.slug}
                    className={`slider-game ${game.resaltar === "1" ? 'highlight' : ''} `}>
                      <Link to={'/game/' + game.slug} >
                        <img 
                          src={game.logo}
                          alt={game.slug} />
                        <div className="w-100 d-flex-center" style={{marginTop: - 15}}>
                          {game.fast ? (
                              <img className="iconFast"
                                src={thunder}
                                alt={game.slug}
                              />
                          ) : (
                            <>
                            </>
                          )
                          }
                          <span>
                            {this.props.defaultData.moneda.signo}{game.salaPremioJackpots}
                          </span>
                        </div>
                      </Link>
                  </div>
                ))
              }
            </Slider>
          <RingSVG />
        </div>
      </div>
    );
  }
  
};

export default Games;
