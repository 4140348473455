import React, { Component } from 'react';

import $ from 'jquery';

import RecoveryIcon from '../../img/recovery_icon.png';

class RecoverModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.validateMsg = {
      empty: "%name% " + props.textosShow.notempty,
      email: props.textosShow.emailcorrecto
    };
    
    this.state = {
      loadingShow: false,
      email: "",
      validate: {
        email: {
          valid: true,
          msg: ""
        }
      },
      error: ""
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
    
    this.urlGlobal = props.urlGlobal;
  }
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }
  
  close() {
    $("#forgot_modal").fadeOut("fast", function() {
      $("#forgot_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  }
  
  setEmail(e) {
    e.preventDefault();
    this.setState({
      email: e.target.value
    });
  }
  
  validateEmail() {
    let valid = true;
    let msg = "";
    if (this.state.email === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, "Email");
    }
    else {
      if (!this.isEmail(this.state.email)) {
        valid = false;
        msg = this.validateMsg.email;
      }
    }
    let validate = this.state.validate;
    validate.email = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  }

  isEmail(mail) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(mail);
  }

  Recover() {
    let clase = this;
    clase.setState({
      validate: {
        email: {
          valid: true,
          msg: ""
        }
      }
    });
    if (clase.validateEmail()) {
      clase.setState({
        loadingShow: true
      });
      let form = new FormData(document.getElementById('recpassForm'));
      fetch(this.urlGlobal + "api/login", {
        method: "POST",
        body: form
      }).then(function(response) {
        return response.json();
      }).then(function(result) {
        if (result.success) {
          clase.setState({
            loadingShow: false
          });
          clase.close();
          $("#forgot_modal_success_overlay").fadeIn("fast", function() {
            $("#forgot_modal_success").fadeIn("fast", function() {
              document.body.style.overflow = "hidden";
            });
          });
        }
        else {
          clase.setState({
            loadingShow: false,
            error: result.msg
          });
        }
      });
    }
  }
  
  render() {
    return (
      <div className="modalx-overlay close_mw"
           id="forgot_modal_overlay">
        <div className='modal_window'
             id="forgot_modal">
          <button id="close_modal"
                  className="link-button close_mw"
                  onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                <div className="activate_icon">
                  <img src={RecoveryIcon}
                       alt="Recuperar contraseña" />
                </div>
                <h5>
                  {this.props.textosShow.recuperarcontrasena || 'Recuperar contraseña'}
                </h5>
                <div className='rec_text'>
                  {this.props.textosShow.ingresesuemail || 'Ingrese su email.'}
                  <br/>
                  {this.props.textosShow.leenviaremosuncorreo || 'Le enviaremos un correo con instrucciones sobre cómo recuperar su contraseña.'}
                </div>
                <form id="recpassForm">
                  <input type="hidden" 
                         name="action" 
                         value="RecPass" />
                  <div className='modal_form'>
                    <div className='input_title'>
                      {this.props.textosShow.tuemail || 'Tu email'}
                    </div>
                    <div className={this.state.validate.email.valid && !this.state.error ? 'input' : 'input error'}>
                      {
                        !this.state.validate.email.valid ?
                          <div className='error_reason'>
                            {this.state.validate.email.msg}
                          </div> :
                          null
                      }
                      {
                        this.state.error ?
                          <div className='error_reason'>
                            {this.state.error}
                          </div> :
                          null
                      }
                      <input type="text"
                             name="email"
                             placeholder={this.props.textosShow.introduzcacorreo}
                             onChange={this.setEmail.bind(this)} />
                    </div>
                  </div>
                </form>
                <div className='modal_button'>
                  <button className='button button_regular' 
                          onClick={this.Recover.bind(this)}>
                    {this.props.textosShow.continuar || 'Continuar'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default RecoverModal;