import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import reactReferer from 'react-referer';

import $ from 'jquery';

import Home from "./pages/Home";
import RecPass from "./pages/RecPass";
import Deposit from "./pages/Deposit";
import Subscription from "./pages/Subscription";
import GameHistory from "./pages/GameHistory";
import Game from "./pages/Game";
import Perfil from "./pages/Perfil";
import Partida from "./pages/Partida";
import Tickets from "./pages/Tickets";
import Rewards from './pages/Rewards';
import Transacciones from "./pages/Transacciones";
import ReferralContest from './pages/ReferralContest';

import Header from "./components/Header";
import ToTop from './components/ToTop';
import Social from './components/Social';
import Games from './components/Games';
import LoadingIndicator from './components/LoadingIndicator';
import ChallengeModal from './components/ChallengeModal';
import PerfilUser from './components/PerfilUser';
import RulesModal from './components/RulesModal';
import Footer from "./components/Footer";
import ContactModal from './components/ContactModal';
import NoticiaModal from './components/NoticiaModal';
import NotifyGameMobileOnlyModal from './components/NotifyGameMobileOnlyModal';

import loadingIcon from '../img/icon_small.png';

import AlertModal from './components/AlertModal';
import VideoModal from './components/VideoModal';

import ReactPixel from 'react-facebook-pixel';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import Referral from './pages/Referral';
import Objectives from './pages/Objectives';
import GeneralJackpotModal from './components/GeneralJackpotModal';
import ReferralContestModal from './components/ReferralContestModal';
import Generaljackpot from './pages/Generaljackpot';
import Generaljackpots from './pages/Generaljackpots';
import Membership from './pages/Membership';
import Friends from './pages/Friends';
import BlogBox from './components/BlogBox';
import Notification from './components/Notification';
import Profile from './pages/Profile';
import PromoCode from './pages/PromoCode';
import LadderModal from './components/LadderModal';
import Ladder from './pages/Ladder';
import BonanzaModal from './components/BonanzaModal';
import Bonanza from './pages/Bonanza';
import LadderList from './pages/LadderList';
import SpinModal from './components/SpinModal';
import PartidaModal from './components/PartidaModal';
import SpinList from './pages/SpinList';
import Freerolls from './pages/Freerolls';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import EmailVerification from './components/EmailVerification';

import 'react-circular-progressbar/dist/styles.css';
import HowToLevelModal from './components/HowToLevelModal';

const cookies = new Cookies();
class App extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      popupInsert: null,
      popupShow: false,
      popupTam: "",
      popupOut: false,
      redirectToReferrer: false,
      marketingData: null,
      userData: {
        name: "",
        email: "",
        type: "",
        level: 0,
        levelProgress: 0,
        money: 0,
        gemas: 0,
        desafios: [],
        hasDeposits: false,
        socialLogStatus: [],
        ladderCnt: 0,
        spinCnt: 0,
        marketing_data: {},
        freeUser: true
      },
      juegos: [],
      companies: [],
      idiomas: [],
      lan: cookies.get("lan") || "",
      textos: null,
      loadingShow: false,
      dataWebRelevant: {
        usersRegister: 0,
        usersOnline: 0
      },
      authorized: true,
      defaultData: null,
      loggedIn: cookies.get("token") ? true : false,
      token: cookies.get("token") || "",
      alertTitle: "",
      alertMessage: "",
      alertPurchase: false,
      videoTitle: "",
      videoUrl: "",
      noticias: [],
      noticiaToShow: null,
      referral: null,
      rules: null,

      blogOpen: false,
      wsConnect: null,
      socketData: null,
      unreadBlogCommentCnt: 0,
      objectiveNotificationData: [],
      objectiveNotificationCount: 0,
      showIndicator: false
    };

    this.perfilUserModalElement = React.createRef();
    this.challengeModalElement = React.createRef();

    this.urlGlobal = process.env.REACT_APP_BACKEND_ENDPOINT;

    this.generaljackpotModalElement = React.createRef();
    this.referralContestModalElement = React.createRef();
    this.ladderModalElement = React.createRef();
    this.bonanzaModalElement = React.createRef();
    this.spinModalElement = React.createRef();
    this.partidaModalElement = React.createRef();
  };

  customeStyle =  {
    overlay: {
        backdropFilter: 'blur(5px)',
   
    },
  };

  openAlertModal(title, message, purchase = false) {

    this.setState({
      alertTitle: title,
      alertMessage: message,
      alertPurchase: purchase
    })
    $("#alert_modal_overlay").fadeIn("fast", function () {
      $("#alert_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  openVideoModal(title, url) {
    this.setState({
      videoTitle: title,
      videoUrl: url
    })
    $("#video_modal_overlay").fadeIn("fast", function () {
      $("#video_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  closeVideoModal() {
    this.setState({
      videoTitle: "",
      videoUrl: ""
    })
  };

  toggleBlog() {
    this.setState({ blogOpen: !this.state.blogOpen })
  }

  selectLan({ ind }) {
    const lan = this.state.idiomas[ind].name;
    cookies.set('lan', lan, {
      path: '/',
      expires: new Date(2147483647 * 1000)
    });
    this.setState({
      lan: this.state.idiomas[ind],
      loadingShow: true
    }, () => {
      this.getDataTextos(this.urlGlobal + "api/texto", {
        action: "getTextos",
        lan: lan
      });
    });
  };

  Login() {
    this.setState({
      loadingShow: true
    });
    let clase = this;
    let form = new FormData(document.getElementById('loginForm'));
    fetch(this.urlGlobal + "api/login", {
      method: "POST",
      body: form
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.default && result.default.maintenance === true && clase.textos){
        alert(clase.textos['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        clase.saveLoginStorage(result.jwt);
        clase.setState({
          redirectToReferrer: true,
          loadingShow: false
        }, () => {
          clase.getDataFromServer(clase.urlGlobal + "api/user", {
            action: "getUser",
            lan: clase.getLanActual()
          });
          clase.getNoticias();
          clase.getRewards()
        });
      }
      else {
        clase.setState({
          loadingShow: false
        });
        clase.openAlertModal(
          clase.state.textos[result.msgSlug],
          clase.state.textos[`${result.msgSlug}descripcion`]
        )
      }
    });
  }
  getRewards(page) {
    let clase = this;
    let myHeaders = this.getHeadersToSend();
    fetch(this.urlGlobal + "api/objetivo", {
      method: "POST",
      body: JSON.stringify({
        action: "getNewReleaseObjetivos",
        lan: clase.getLanActual(),
        page: page
      }),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        clase.setState({ objectiveNotificationData: result.objetivos })
        clase.setState({ objectiveNotificationCount: result.objetivos.length })
      }
      else {
        console.log(result.msg);
      }
    });
  }
  saveLoginStorage(token) {
    localStorage.setItem('token', token);
    cookies.set('token', token, {
      path: '/',
      expires: new Date(2147483647 * 1000)
    });
    this.setState({
      loggedIn: true,
      token: token
    });
  };

  Logout() {
    cookies.remove("token", {
      path: '/',
      expires: new Date(2147483647 * 1000)
    });
    let duType = this.state.userData.type;
    if (duType === "Facebook") {
      //al pulsar en salir cerramos sesión y mandamos al inicio
      window.FB.logout();
    }
    this.setState({
      objectiveNotificationData: [],
      objectiveNotificationCount: 0,
      redirectToReferrer: true,
      userData: {
        name: "",
        email: "",
        type: "",
        level: 0,
        levelProgress: 0,
        money: 0,
        gemas: 0,
        foto:"",
        hasDeposits: false,
        socialLogStatus: [],
        freeUser: true
      },
      blogOpen: false,
      loggedIn: false,
      token: ""
    });
    window.location = window.location;
  }

  showLoadingIndicator(bShow) {
    this.setState({ showIndicator: bShow });
  }

  showPerfilUserModal(userId) {
    this.showLoadingIndicator(true);

    let myHeaders = this.getHeadersToSend();
    let clase = this;
    fetch(this.urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "getUserPerfil",
        idUser: userId,
        lan: clase.getLanActual()
      }),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        let user = result.user;
        clase.perfilUserModalElement.current.setState({
          user: user
        });
        $("#profile_modal_overlay").fadeIn("fast", function () {
          $("#profile_modal").fadeIn("fast", function () {
            document.body.style.overflow = "hidden";
          });
        });
      }
    }).finally(function () {
      clase.showLoadingIndicator(false);
    });
  };

  showChallengeModal(userId) {
    this.showLoadingIndicator(true);

    let myHeaders = this.getHeadersToSend();
    let clase = this;
    fetch(this.urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "getUserPerfil",
        idUser: userId
      }),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        let user = result.user;
        clase.challengeModalElement.current.setState({
          userId: userId,
          user: user,
          games: clase.state.juegos
        });
        $("#challenger_modal_overlay").fadeIn("fast", function () {
          $("#challenger_modal").fadeIn("fast", function () {
            document.body.style.overflow = "hidden";
          });
        });
      }

    }).finally(function () {
      clase.showLoadingIndicator(false);
    });
  };

  checkAndShowSpinModal(gameSlug, salaId, salaSlug, reentry, partidaId) {

    // console.log('=============checkAndShowSpinModal==============', partidaId);
    // this.showSpinModal(gameSlug, salaId, salaSlug, reentry, null, null);
    // return;

    const clase = this;
    clase.showLoadingIndicator(true);

    let myHeaders = clase.getHeadersToSend();
    let lan = clase.getLanActual();
    fetch(clase.urlGlobal + "api/juego", {
        method: "POST",
        body: JSON.stringify({
          action: "getLatestSpinPrize",
          juego: gameSlug,
          sala: salaId,
          first: 1,
          lan: lan,
          reentry: reentry,
          partida: partidaId,
        }),
        headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        clase.showSpinModal(gameSlug, salaId, salaSlug, reentry, result.partidaId, result.spinPrize);
      }
    }).finally(() => {
      clase.showLoadingIndicator(false);
    });
  }

  showSpinModal(gameSlug, salaId, salaSlug, reentry, partidaId, orgSpinPrize) {

    const clase = this;
    clase.showLoadingIndicator(true);

    let myHeaders = clase.getHeadersToSend();
    let lan = clase.getLanActual();
    fetch(clase.urlGlobal + "api/juego", {
      method: "POST",
      body: JSON.stringify({
        action: "getSpinData",
        salaId: salaId
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.spinData) {
        const prizes = result.spinData.map((item) => {
          return Number(item.prize);
        });

        const ids = result.spinData.map((item) => {
          return item.id;
        });

        clase.spinModalElement.current.setState({
          gameSlug, salaId, salaSlug, reentry, partidaId, orgSpinPrize, spinPrizeList: result.spinPrizes, idList: ids, items:prizes, spinClickable: true, spinPrizeId: null
        });
    
        $("#spin_modal_overlay").fadeIn("fast", function() {
          $("#spin_modal").fadeIn("fast", function() {
            document.body.style.overflow = "hidden";
            $('#app-container').addClass('blur-bg');
          });
        });
      }

    }).finally(() => {
      clase.showLoadingIndicator(false);
    });
  }

  showPartidaModal(gameSlug, sala) {
    this.partidaModalElement.current.setState({
      gameSlug: gameSlug,
      sala: sala
    });

    $("#premios_modal_overlay").fadeIn("fast", function() {
      $("#premios_modal").fadeIn("fast", function() {
        document.body.style.overflow = "hidden";
      });
    });
  }

  getBlogClienteBan(action, data) {

    if (data && data.IdCliente == this.state.userData.id) {
      switch (action) {
        case 'new':
          this.setState({ userData: { ...this.state.userData, banned: true } });
          this.setState({ blogOpen: false });
          break;
        case 'delete':
          this.setState({ userData: { ...this.state.userData, banned: false } });
          this.setState({ blogOpen: true });
          break;
      }
    }
  }

  setUnreadBlogCommentCnt(cnt) {
    this.setState({ unreadBlogCommentCnt: cnt });
  }

  increaseUnreadBlogCommentCnt() {
    this.setState({ unreadBlogCommentCnt: this.state.unreadBlogCommentCnt + 1 });
  }

  getRule(rules) {
    this.setState({
      rules: rules
    });
  }

  getDataUser() {
    if (this.state.loggedIn) {
      this.getDataFromServer(this.urlGlobal + "api/user", {
        action: "getUser",
        lan: this.getLanActual()
      });
    }
  }

  setDataUserState(userData) {
    if (this.state.loggedIn) {
      this.setState({
        userData: userData
      });
    }
  }

  setDataMarketingState() {
    let marketingData = null;
    if (!this.state.loggedIn) {
      const referer = reactReferer.referer();
      const registerParams = queryString.parse(this.props.location.search);
      marketingData = {
        referer,
        registerParams
      };
    } else if(this.state.userData){
      marketingData = this.state.userData.marketing_data;
    }

    if(marketingData && JSON.stringify(marketingData) !== JSON.stringify(this.state.marketingData)){
      this.setState({
        marketingData
      });
      if(window.dataLayer){
        window.dataLayer.push({'marketing_data': marketingData});
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }

    const currFreeUser = !this.state.userData || this.state.userData.freeUser;
    const prevFreeUser = !prevState.userData || prevState.userData.freeUser;
    if (currFreeUser !== prevFreeUser) {
      this.getDataTextos(this.urlGlobal + "api/texto", {
        action: "getTextos",
        lan: this.state.lan
      });
    }
  }

  onRouteChanged() {
    ReactPixel.pageView();
  }

  onAcceptCookies() {
    ReactPixel.grantConsent();
  }

  componentDidMount() {
    this.initWS();
    let params = queryString.parse(this.props.location.search);
    if (params.ref) {
      this.setState({
        referral: params.ref
      });
    }

    //obtener datos de usuarios
    this.getDataUser();
    if (process.env.REACT_APP_USER_AUTOREFRESH_TIMEOUT) {
      this.timerGetUser = setInterval(() => {
        this.getDataUser();
      }, process.env.REACT_APP_USER_AUTOREFRESH_TIMEOUT);
    }

    this.setDataMarketingState();

    //obtener data web relevant
    this.getDataWebRelevant(this.urlGlobal + "api/webservice", {
      action: "getDataRelevant"
    });

    //obtener idiomas
    this.getDataIdiomas(this.urlGlobal + "api/idioma", {
      action: "getIdiomas"
    }, () => {
      this.getDataJuegos(this.urlGlobal + "api/juego", {
        action: "getJuegos",
        lan: this.state.lan
      });

      this.getDataTextos(this.urlGlobal + "api/texto", {
        action: "getTextos",
        lan: this.state.lan
      });
    });

    ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
    if (!getCookieConsentValue()) {
      ReactPixel.revokeConsent();
    }

    //para coneccion con facebook
    let clase = this;
    // Iniciamos el sdk y su configuración
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '472064574338046', //la appid de tu aplicación facebook
        status: true,
        cookie: true,
        xfbml: true,
        oauth: true //enables OAuth 2.0
      });

      //manejador para comprobar si el status del usuario ha cambiado o no
      window.FB.Event.subscribe('auth.statusChange', function (response) {
        if (response.status === 'connected') {
          if (response.authResponse) {
            //si el usuario es logueado correctamente le saludamos
            window.FB.api('/me?fields=id,first_name,last_name,email,birthday,gender,picture', function (me) {
              clase.RegisterFBAction(me);
            });
          }
          else {
            //si el usuario no tiene permiso porque ha cerrado sesión o simplemente
            //no tiene permisos de la aplicación redirigimos al login
            alert(this.state.textos.ocurriounerror || "Ocurrió un error");
          }
        }
      });
    };

    // cargamos el sdk de forma asincrónica
    (function (d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id; js.async = true;
      js.src = "//connect.facebook.net/en_US/all.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    // init twitter
    window.twttr = (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f) {
        t._e.push(f);
      };

      return t;
    }(document, "script", "twitter-wjs"));
  }

  componentWillUnmount() {
    if (this.state.wsConnect) {
      this.state.wsConnect.close();
    }
  }

  initWS() {
    var con = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_BASE}/blog`.replace('{host}', window.location.host));
    con.onmessage = (e) => {
      this.setState({ socketData: e.data });
    };
    this.setState({ wsConnect: con });

    var objetivoCon = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_BASE}/objetivo`.replace('{host}', window.location.host));
    objetivoCon.onmessage = (e) => {
      var data = JSON.parse(e.data);
      this.setState({ objectiveNotificationData: data.data });
      this.setState({ objectiveNotificationCount: this.state.objectiveNotificationCount + 1 });
    };

  }

  RegisterFBAction(user) {
    if (!this.state.loggedIn) {
      this.setState({
        loadingShow: true
      });
      let clase = this;
      let myHeaders = this.getHeadersToSend();
      let form = {
        action: "Register",
        withFacebook: true,
        email: user.email,
        user: user,
        referral: this.state.referral,
        lan: clase.getLanActual().name,
        marketing_data: this.state.marketingData,
      };
      fetch(this.urlGlobal + "api/register", {
        method: "POST",
        body: JSON.stringify(form),
        headers: myHeaders
      }).then(function (response) {
        return response.json();
      }).then(function (result) {
        if (result.success) {
          clase.saveLoginStorage(result.jwt);
          clase.setState({
            redirectToReferrer: true,
            loadingShow: false
          });
          clase.getDataUser();
          ReactPixel.track("CompleteRegistration", {
            status: true
          });
        }
        else {
          clase.setState({
            loadingShow: false
          });
          alert(result.msg);
          ReactPixel.track("CompleteRegistration", {
            status: false
          });
        }
      });
    }
  }

  getLanActual() {
    return this.state.lan;
  }

  getDataFromServer(url, data) {
    let myHeaders = this.getHeadersToSend();
    let clase = this;
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.default && result.default.maintenance === true && clase.textos){
        alert(clase.textos['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        if(result.user && result.user.id !== clase.state.userData.id && window.dataLayer){
          window.dataLayer.push({'user_id': result.user.id});
        }
        clase.setState({
          userData: result.user,
          defaultData: result.default
        });
        clase.setDataMarketingState();
      }
      else {
        clearInterval(clase.timerGetUser);
        clase.Logout();
        clase.searchForAuthorized(result);
      }
    });
  }

  getDataIdiomas(url, data, then) {
    let myHeaders = this.getHeadersToSend();
    let clase = this;
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        let indlan = 0;
        let defaultData = result.default;
        if (!clase.state.lan) {
          for (let i = 0; i < result.idiomas.length; i++) {
            if (defaultData.idioma.slug === result.idiomas[i].name) {
              indlan = i;
            }
          }
          cookies.set("lan", result.idiomas[indlan].name, {
            path: '/',
            expires: new Date(2147483647 * 1000)
          });
        }
        else {
          for (let i = 0; i < result.idiomas.length; i++) {
            if (clase.state.lan === result.idiomas[i].name) {
              indlan = i;
            }
          }
        }

        clase.setState({
          idiomas: result.idiomas,
          lan: result.idiomas[indlan],
          defaultData: defaultData
        }, () => {
          then();
        });
      }
      else {
        clase.searchForAuthorized(result);
      }
    });
  }

  getDataWebRelevant(url, data) {
    let myHeaders = this.getHeadersToSend();
    let clase = this;
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        clase.setState({
          dataWebRelevant: result.data
        });
      }
    });
  }

  getDataJuegos(url, data) {
    let myHeaders = this.getHeadersToSend();
    let clase = this;
    console.log('url', url);
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.default && result.default.maintenance === true && clase.textos){
        alert(clase.textos['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        const juegos = result.juegos.sort((a, b) => {
          if (a.salaPremioJackpots >= b.salaPremioJackpots) {
            return -1;
          }
          else {
            return 1;
          }
        });
        clase.setState({
          juegos: juegos,
          companies: result.companies,
        });
      } else {
        clase.setState({
          loadingShow: false
        });
        alert(result.msg)
      }
    });
  }

  getDataTextos(url, data) {
    let myHeaders = this.getHeadersToSend();
    let clase = this;
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        if (result.default && result.default.maintenance === true){
          alert(result.textos['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
        }
        clase.setState({
          textos: result.textos,
          loadingShow: false
        });
      }
    });
  }

  getNoticias() {
    let clase = this;
    fetch(clase.urlGlobal + "api/noticia", {
      method: "POST",
      body: JSON.stringify({
        action: "getNoticias",
        lan: clase.getLanActual().name
      }),
      headers: clase.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        clase.setState({
          noticias: result.noticias,
          noticiaShow: result.noticias.length ? result.noticias[0] : null
        }, () => {
          if (clase.state.noticiaShow) {
            clase.openNoticiaModal();
            clase.setNoticiaReaded(clase.state.noticiaShow);
          }
        });
      }
    });
  };

  setNoticiaReaded(noticia) {
    let clase = this;
    fetch(clase.urlGlobal + "api/noticia", {
      method: "POST",
      body: JSON.stringify({
        action: "setVista",
        id: noticia.id
      }),
      headers: clase.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {

      }
      else {

      }
    });
  };

  openNoticiaModal() {
    $("#noticia_modal_overlay").fadeIn("fast", function () {
      $("#noticia_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  closeNoticiaModal() {
    let clase = this;
    $("#noticia_modal").fadeOut("fast", function () {
      $("#noticia_modal_overlay").fadeOut("fast", function () {
        document.body.style.overflow = "auto";
        let noticias = clase.state.noticias;
        noticias.shift();
        clase.setState({
          noticias: noticias,
          noticiaShow: noticias.length ? noticias[0] : null
        }, () => {
          if (clase.state.noticiaShow) {
            clase.openNoticiaModal();
            clase.setNoticiaReaded(clase.state.noticiaShow);
          }
        });
      });
    });
  };

  getHeadersToSend() {
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    if (this.state.token) {
      myHeaders.append("Token", "Bearer " + this.state.token);
    }
    return myHeaders;
  }

  onSpinClose(done, gameSlug, salaId, salaSlug, reentry, partidaId, spinPrize, spinPrizeId) {
    console.log('===========onSpinClose===========', partidaId, spinPrize);
    if (done) {
      this.playSpinGame(gameSlug, salaId, salaSlug, reentry, partidaId, spinPrize, spinPrizeId);
    }
  }

  playSpinGame(gameSlug, salaId, salaSlug, reentry, partidaId, spinPrize, spinPrizeId) {

    console.log('=======playSpinGame=====', partidaId, spinPrize);

    let clase = this;
    if (clase.state.loggedIn) {
      let myHeaders = clase.getHeadersToSend();
      let lan = clase.getLanActual();
      fetch(clase.urlGlobal + "api/juego", {
          method: "POST",
          body: JSON.stringify({
            action: "setPartida",
            juego: gameSlug,
            sala: salaId,
            first: 1,
            lan: lan,
            reentry: reentry,
            partida: partidaId,
            spinPrize: spinPrize,
            spinPrizeId: spinPrizeId
          }),
          headers: myHeaders
      }).then(function(response) {
        return response.json();
      }).then(function(result) {
        if (result.success) {
          console.log('========result=====', result);
          window.location = clase.urlGlobal + "playGame/" + salaSlug + "/?gameMode=" + salaId + "&partida=" + result.partida.id + "&game=" + gameSlug;
        }
        else {
          //alert(result.msg);
          clase.openAlertModal(
            clase.state.textos[result.msg] || result.msg,
            clase.state.textos[`${result.msg}descripcion`] || 'Error'
          )
        }
      });
    }
    else{
      alert(clase.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta.");
    }
  };

  searchForAuthorized(result) {
    if (result.error !== undefined && result.error === "unauthorized") {
      this.setState({
        authorized: false
      });
    }
  }

  openTutorialStep1(e) {
    e.preventDefault();
    this.props.history.replace('/');
    $("#activated_modal").fadeOut("fast", function () {
      $("#activated_modal_overlay").fadeOut("fast", function () {
        document.body.style.overflow = "auto";
        $(".mt_block.step_1").fadeIn(0);
      });
    });
  };

  openTutorialStep2(e) {
    e.preventDefault();
    $(".mt_block.step_1").fadeOut(0, function () {
      $(".mt_block.step_2").fadeIn(0);
    });
  };

  openTutorialStep3(e) {
    e.preventDefault();
    $(".mt_block.step_2").fadeOut(0, function () {
      $(".mt_block.step_3").fadeIn(0);
    });
  };

  closeTutorialStep3(e) {
    e.preventDefault();
    $(".mt_block.step_3").fadeOut(0);
  };

  showContactModal() {
    $("#contact_modal_overlay").fadeIn("fast", function () {
      $("#contact_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  showPopupOnlyPlayOnMobile() {
    $("#notify_game_mobile_only_modal_overlay").fadeIn("fast", function() {
      $("#notify_game_mobile_only_modal").fadeIn("fast", function() {
        document.body.style.overflow = "hidden";
      });
    });
  }

  closePopupOnlyPlayOnMobile() {
    $("#notify_game_mobile_only_modal").fadeOut("fast", function () {
      $("#notify_game_mobile_only_modal_overlay").fadeOut("fast", function () {
          document.body.style.overflow = "auto";
      })
    })
  }

  render() {
    const NotFound = () => (
      <div className='content' id='profile'>
        <div className='wrapper'>
          <div className='page_content'>
            <div className='c_center'>
              <div className='c_top'>
                <div className='c_bottom'>
                  <h1>
                    {this.state.textos.lapaginanoseencuentra || "La página no se encuentra"}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      this.state.authorized ? (
        <Switch>
          {
            (this.state.textos && this.state.defaultData && !this.state.loadingShow) ? (
              <div className={this.state.blogOpen ? 'App-blog-open' : 'App'}>
                <div id='app-container'>
                  <ToTop textosShow={this.state.textos}></ToTop>
                  <Social></Social>
                  <PerfilUser
                    ref={this.perfilUserModalElement}
                    urlGlobal={this.urlGlobal}
                    defaultData={this.state.defaultData}
                    textosShow={this.state.textos}
                    getHeadersToSend={this.getHeadersToSend.bind(this)}
                    getLanActual={this.getLanActual.bind(this)}
                  ></PerfilUser>
                  <Notification
                    objectiveNotificationData={this.state.objectiveNotificationData}
                    urlGlobal={this.urlGlobal}
                    loggedIn={this.state.loggedIn}
                    textosShow={this.state.textos}
                  />

                  <ChallengeModal
                    ref={this.challengeModalElement}
                    textosShow={this.state.textos}
                    urlGlobal={this.urlGlobal}
                    defaultData={this.state.defaultData}
                    getHeadersToSend={this.getHeadersToSend.bind(this)}
                    getLanActual={this.getLanActual.bind(this)}
                  ></ChallengeModal>
                  {this.state.rules &&
                    <RulesModal
                      getHeadersToSend={this.getHeadersToSend.bind(this)}
                      getLanActual={this.getLanActual.bind(this)}
                      urlGlobal={this.urlGlobal}
                      titulo={this.state.rules.titulo}
                      texto={this.state.rules.texto}></RulesModal>
                  }
                  <HowToLevelModal
                      getHeadersToSend={this.getHeadersToSend.bind(this)}
                      getLanActual={this.getLanActual.bind(this)}
                      urlGlobal={this.urlGlobal}></HowToLevelModal>

                  <Games
                    games={this.state.juegos}
                    companies={this.state.companies}
                    userData={this.state.userData}
                    defaultData={this.state.defaultData}
                    textosShow={this.state.textos}
                    openTutorialStep2={this.openTutorialStep2.bind(this)}></Games>
                  <VideoModal
                    title={this.state.videoTitle}
                    textosShow={this.state.textos}
                    url={this.state.videoUrl}
                    onClose={this.closeVideoModal.bind(this)}
                  ></VideoModal>
                  <GeneralJackpotModal
                    ref={this.generaljackpotModalElement}
                    textosShow={this.state.textos}
                    defaultData={this.state.defaultData}
                    getHeadersToSend={this.getHeadersToSend.bind(this)}
                    getLanActual={this.getLanActual.bind(this)}
                    showPerfilUserModal={this.showPerfilUserModal.bind(this)}
                    urlGlobal={this.urlGlobal}
                    loggedIn={this.state.loggedIn}
                    history={this.props.history}
                  />
                  <ReferralContestModal
                    ref={this.referralContestModalElement}
                    textosShow={this.state.textos}
                    defaultData={this.state.defaultData}
                    getHeadersToSend={this.getHeadersToSend.bind(this)}
                    getLanActual={this.getLanActual.bind(this)}
                    urlGlobal={this.urlGlobal}
                    loggedIn={this.state.loggedIn}
                    history={this.props.history}
                  />
                  <LadderModal
                    ref={this.ladderModalElement}
                    textosShow={this.state.textos}
                    defaultData={this.state.defaultData}
                    getHeadersToSend={this.getHeadersToSend.bind(this)}
                    getLanActual={this.getLanActual.bind(this)}
                    showPerfilUserModal={this.showPerfilUserModal.bind(this)}
                    urlGlobal={this.urlGlobal}
                    loggedIn={this.state.loggedIn}
                    history={this.props.history}
                  />
                  <BonanzaModal
                    ref={this.bonanzaModalElement}
                    textosShow={this.state.textos}
                    defaultData={this.state.defaultData}
                    getHeadersToSend={this.getHeadersToSend.bind(this)}
                    getLanActual={this.getLanActual.bind(this)}
                    showPerfilUserModal={this.showPerfilUserModal.bind(this)}
                    urlGlobal={this.urlGlobal}
                    loggedIn={this.state.loggedIn}
                    history={this.props.history}
                  />
                  <PartidaModal
                    ref={this.partidaModalElement}
                    haveGeneralLevelChallenge={this.state.userData?.registrationData?.haveGeneralLevelChallenge}
                    textosShow={this.state.textos}
                    defaultData={this.state.defaultData}
                    getHeadersToSend={this.getHeadersToSend.bind(this)}
                    getLanActual={this.getLanActual.bind(this)}
                    showSpinModal={this.checkAndShowSpinModal.bind(this)}
                    openAlertModal={this.openAlertModal.bind(this)}
                    urlGlobal={this.urlGlobal}
                    loggedIn={this.state.loggedIn}></PartidaModal>
                  
                  <NoticiaModal
                    noticia={this.state.noticiaShow}
                    onClose={this.closeNoticiaModal.bind(this)}></NoticiaModal>
                  <NotifyGameMobileOnlyModal 
                    onClose={this.closePopupOnlyPlayOnMobile.bind(this)}
                  />
                  <Header
                    Login={this.Login.bind(this)}
                    Logout={this.Logout.bind(this)}
                    marketingData={this.state.marketingData}
                    userData={this.state.userData}
                    idiomas={this.state.idiomas}
                    lan={this.state.lan}
                    blogOpen={this.state.blogOpen}
                    unreadBlogCommentCnt={this.state.unreadBlogCommentCnt}
                    toggleBlog={this.toggleBlog.bind(this)}
                    selectLan={this.selectLan.bind(this)}
                    textosShow={this.state.textos}
                    getHeadersToSend={this.getHeadersToSend.bind(this)}
                    getLanActual={this.getLanActual.bind(this)}
                    urlGlobal={this.urlGlobal}
                    defaultData={this.state.defaultData}
                    saveLoginStorage={this.saveLoginStorage.bind(this)}
                    getDataUser={this.getDataUser.bind(this)}
                    isIndex="true"
                    loggedIn={this.state.loggedIn}
                    token={this.state.token}
                    generaljackpotModalElement={this.generaljackpotModalElement}
                    referralContestModalElement={this.referralContestModalElement}
                    openTutorialStep1={this.openTutorialStep1.bind(this)}
                    openTutorialStep2={this.openTutorialStep2.bind(this)}
                    openTutorialStep3={this.openTutorialStep3.bind(this)}
                    closeTutorialStep3={this.closeTutorialStep3.bind(this)}
                    referral={this.state.referral}
                    objectiveNotificationCount={this.state.objectiveNotificationCount}></Header>
                  <CookieConsent
                    onAccept={this.onAcceptCookies.bind(this)}
                    buttonText={this.state.textos.aceptar || "Aceptar"}
                  >
                    {this.state.textos.cookiecompliance || "This website uses cookies to enhance the user experience."}
                  </CookieConsent>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => {
                        return (
                          <Home
                            dataWeb={this.state.dataWebRelevant}
                            juegos={this.state.juegos}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            saveLoginStorage={this.saveLoginStorage.bind(this)}
                            getDataUser={this.getDataUser.bind(this)}
                            defaultData={this.state.defaultData} />
                        );
                      }} />
                    <Route
                      exact
                      path="/register_successful"
                      render={() => {
                        return (
                          <Home
                            dataWeb={this.state.dataWebRelevant}
                            juegos={this.state.juegos}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            saveLoginStorage={this.saveLoginStorage.bind(this)}
                            getDataUser={this.getDataUser.bind(this)}
                            defaultData={this.state.defaultData}
                            getLanActual={this.getLanActual.bind(this)}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                          />
                        );
                      }} />
                    <Route
                      path="/recpass/:token"
                      render={({ match }) => {
                        return (
                          <RecPass
                            match={match}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            loggedIn={this.state.loggedIn} />
                        );
                      }} />
                    <Route
                      path="/validate_email"
                      render={() => {
                        return (
                          <EmailVerification
                            loggedIn={this.state.loggedIn}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            defaultData={this.state.defaultData}
                            getLanActual={this.getLanActual.bind(this)}
                            getDataUser={this.getDataUser.bind(this)}
                            userData={this.state.userData}
                            showLoadingIndicator={this.showLoadingIndicator.bind(this)}
                            openAlertModal={this.openAlertModal.bind(this)}
                            mandatory={this.state.userData.isDepositDisallowed === 'email_not_validated'}
                          />
                        );
                      }} />
                    <Route
                      path="/deposit"
                      render={() => {
                        return this.state.userData.isDepositDisallowed === 'email_not_validated' ? (
                          <EmailVerification
                            loggedIn={this.state.loggedIn}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            defaultData={this.state.defaultData}
                            getLanActual={this.getLanActual.bind(this)}
                            getDataUser={this.getDataUser.bind(this)}
                            userData={this.state.userData}
                            showLoadingIndicator={this.showLoadingIndicator.bind(this)}
                            openAlertModal={this.openAlertModal.bind(this)}
                            mandatory={this.state.userData.isDepositDisallowed === 'email_not_validated'}
                          >
                            <Deposit
                              loggedIn={this.state.loggedIn}
                              textosShow={this.state.textos}
                              urlGlobal={this.urlGlobal}
                              getHeadersToSend={this.getHeadersToSend.bind(this)}
                              defaultData={this.state.defaultData}
                              getLanActual={this.getLanActual.bind(this)}
                              getDataUser={this.getDataUser.bind(this)} />
                          </EmailVerification>
                        ) : (
                          <Deposit
                            loggedIn={this.state.loggedIn}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            defaultData={this.state.defaultData}
                            getLanActual={this.getLanActual.bind(this)}
                            getDataUser={this.getDataUser.bind(this)} />
                        );
                      }} />
                    <Route
                      path="/subscription"
                      render={() => {
                        return (
                          <Subscription
                            loggedIn={this.state.loggedIn}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            userData={this.state.userData}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            defaultData={this.state.defaultData}
                            getLanActual={this.getLanActual.bind(this)} />
                        );
                      }} />  

                    <Route
                      path="/subscription_successful"
                      render={() => {
                        return (
                          <Subscription
                            loggedIn={this.state.loggedIn}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            userData={this.state.userData}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            defaultData={this.state.defaultData}
                            getLanActual={this.getLanActual.bind(this)} />
                        );
                      }} />

                    <Route
                      path="/deposit_successful"
                      render={() => {
                        return (
                          <Deposit
                            loggedIn={this.state.loggedIn}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            defaultData={this.state.defaultData}
                            userData={this.state.userData}
                            getLanActual={this.getLanActual.bind(this)}
                            getDataUser={this.getDataUser.bind(this)} />
                        );
                      }} />
                    <Route
                      path="/check_stripe"
                      render={() => {
                        return (
                          <Deposit
                            payed="stripe_all"
                            loggedIn={this.state.loggedIn}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            defaultData={this.state.defaultData}
                            userData={this.state.userData}
                            getLanActual={this.getLanActual.bind(this)}
                            getDataUser={this.getDataUser.bind(this)} />
                        );
                      }} />
                    <Route
                      path="/perfil"
                      render={() => {
                        return (
                          <Perfil
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            loggedIn={this.state.loggedIn}
                            getDataUser={this.getDataUser.bind(this)} />
                        );
                      }} />
                    <Route
                      path="/referral"
                      render={() => {
                        return (
                          <Referral
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            loggedIn={this.state.loggedIn}
                            userData={this.state.userData} />
                        );
                      }} />
                    <Route
                      path="/membership"
                      render={() => {
                        return (
                          <Membership
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            loggedIn={this.state.loggedIn}
                            userData={this.state.userData} />
                        );
                      }} />
                    <Route
                      path="/objectives"
                      render={() => {
                        return (
                          <Objectives
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            getDataUser={this.getDataUser.bind(this)}
                            showLoadingIndicator={this.showLoadingIndicator.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            loggedIn={this.state.loggedIn}
                            objectives={this.props.objectives} />
                        );
                      }} />
                    {/* <Route
                      path="/Friends"
                      render={() => {
                        return (
                          <Friends
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            showLoadingIndicator={this.showLoadingIndicator.bind(this)}
                            loggedIn={this.state.loggedIn}
                            userData={this.state.userData}
                            showChallengeModal={this.showChallengeModal.bind(this)} />
                        );
                      }} /> */}
                    <Route
                      path="/game/:gameSlug"
                      render={({ match }) => {
                        return (
                          <Game
                            match={match}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            getRule={this.getRule.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            userData={this.state.userData}
                            loggedIn={this.state.loggedIn}
                            token={this.state.token}
                            history={this.props.history}
                            generaljackpotModalElement={this.generaljackpotModalElement}
                            referralContestModalElement={this.referralContestModalElement}
                            ladderModalElement={this.ladderModalElement}
                            bonanzaModalElement={this.bonanzaModalElement}
                            openAlertModal={this.openAlertModal.bind(this)}
                            openVideoModal={this.openVideoModal.bind(this)}
                            openPopupOnlyPlayOnMobile={this.showPopupOnlyPlayOnMobile.bind(this)}
                            showPartidaModal={this.showPartidaModal.bind(this)} />
                        );
                      }} />
                    <Route
                      path="/history"
                      render={() => {
                        return (
                          <GameHistory
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            showLoadingIndicator={this.showLoadingIndicator.bind(this)}
                            showSpinModal={this.checkAndShowSpinModal.bind(this)}
                            textosShow={this.state.textos}
                            userData={this.state.userData}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            loggedIn={this.state.loggedIn}
                            history={this.props.history} />
                        );
                      }} />
                    <Route
                      path="/partida/:gameSlug/:gameSala/:gameId?"
                      render={({ match }) => {
                        return (
                          <Partida
                            match={match}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            showPerfilUserModal={this.showPerfilUserModal.bind(this)}
                            showChallengeModal={this.showChallengeModal.bind(this)}
                            showSpinModal={this.checkAndShowSpinModal.bind(this)}
                            openAlertModal={this.openAlertModal.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            userData={this.state.userData}
                            juegos={this.state.juegos}
                            loggedIn={this.state.loggedIn}
                            token={this.state.token} />
                        );
                      }} />
                    <Route
                      path="/referral-contest/:referralContestId"
                      render={({ match }) => {
                        return (
                          <ReferralContest
                            match={match}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            showPerfilUserModal={this.showPerfilUserModal.bind(this)}
                            showChallengeModal={this.showChallengeModal.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            userData={this.state.userData}
                            juegos={this.state.juegos}
                            loggedIn={this.state.loggedIn}
                            generaljackpotModalElement={this.generaljackpotModalElement}
                            referralContestModalElement={this.referralContestModalElement}
                            token={this.state.token} />
                        );
                      }} />
                    <Route
                      path="/bonanza/:bonanzaId"
                      render={({ match }) => {
                        return (
                          <Bonanza
                            match={match}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            showPerfilUserModal={this.showPerfilUserModal.bind(this)}
                            showChallengeModal={this.showChallengeModal.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            userData={this.state.userData}
                            juegos={this.state.juegos}
                            loggedIn={this.state.loggedIn}
                            history={this.props.history}
                            generaljackpotModalElement={this.generaljackpotModalElement}
                            referralContestModalElement={this.referralContestModalElement}
                            token={this.state.token} />
                        );
                      }} />
                    <Route
                      path="/ladder/:ladderId"
                      render={({ match }) => {
                        return (
                          <Ladder
                            match={match}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            showPerfilUserModal={this.showPerfilUserModal.bind(this)}
                            showChallengeModal={this.showChallengeModal.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            userData={this.state.userData}
                            juegos={this.state.juegos}
                            loggedIn={this.state.loggedIn}
                            history={this.props.history}
                            generaljackpotModalElement={this.generaljackpotModalElement}
                            referralContestModalElement={this.referralContestModalElement}
                            token={this.state.token} />
                        );
                      }} />

                    <Route
                      path="/ladders"
                      render={({ match }) => {
                        return (
                          <LadderList
                            match={match}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            userData={this.state.userData}
                            juegos={this.state.juegos}
                            loggedIn={this.state.loggedIn}
                            generaljackpotModalElement={this.generaljackpotModalElement}
                            referralContestModalElement={this.referralContestModalElement}
                            ladderModalElement={this.ladderModalElement}
                            bonanzaModalElement={this.bonanzaModalElement}
                            token={this.state.token}
                            history={this.props.history} />
                        );
                      }} />

                    <Route
                      path="/spins"
                      render={({ match }) => {
                        return (
                          <SpinList
                            match={match}
                            games={this.state.juegos}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            showPartidaModal={this.showPartidaModal.bind(this)}
                            showSpinModal={this.checkAndShowSpinModal.bind(this)}
                            getRule={this.getRule.bind(this)}
                            showLoadingIndicator={this.showLoadingIndicator.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            userData={this.state.userData}
                            juegos={this.state.juegos}
                            loggedIn={this.state.loggedIn}
                            token={this.state.token}
                            history={this.props.history} />
                        );
                      }} />  
                    <Route
                      path="/freerolls"
                      render={({ match }) => {
                        return (
                          <Freerolls
                            match={match}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            showPerfilUserModal={this.showPerfilUserModal.bind(this)}
                            showChallengeModal={this.showChallengeModal.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            userData={this.state.userData}
                            juegos={this.state.juegos}
                            loggedIn={this.state.loggedIn}
                            generaljackpotModalElement={this.generaljackpotModalElement}
                            referralContestModalElement={this.referralContestModalElement}
                            showPartidaModal={this.showPartidaModal.bind(this)}
                            openPopupOnlyPlayOnMobile={this.showPopupOnlyPlayOnMobile.bind(this)}
                            token={this.state.token} />
                        );
                      }} />  
                    <Route
                      path="/general-jackpot/:generaljackpotId"
                      render={({ match }) => {
                        return (
                          <Generaljackpot
                            match={match}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            showPerfilUserModal={this.showPerfilUserModal.bind(this)}
                            showChallengeModal={this.showChallengeModal.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            userData={this.state.userData}
                            juegos={this.state.juegos}
                            loggedIn={this.state.loggedIn}
                            generaljackpotModalElement={this.generaljackpotModalElement}
                            referralContestModalElement={this.referralContestModalElement}
                            token={this.state.token} />
                        );
                      }} />
                    <Route
                      path="/general-jackpots"
                      render={({ match }) => {
                        return (
                          <Generaljackpots
                            match={match}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            defaultData={this.state.defaultData}
                            userData={this.state.userData}
                            juegos={this.state.juegos}
                            loggedIn={this.state.loggedIn}
                            generaljackpotModalElement={this.generaljackpotModalElement}
                            referralContestModalElement={this.referralContestModalElement}
                            ladderModalElement={this.ladderModalElement}
                            bonanzaModalElement={this.bonanzaModalElement}
                            token={this.state.token}
                            history={this.props.history} />
                        );
                      }} />
                    <Route
                      path="/support"
                      render={() => {
                        return (
                          <Tickets
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            textosShow={this.state.textos}
                            urlGlobal={this.urlGlobal}
                            loggedIn={this.state.loggedIn} />
                        );
                      }} />
                    <Route
                      path="/rewards"
                      render={() => {
                        return (
                          <Rewards
                            defaultData={this.state.defaultData}
                            textosShow={this.state.textos}
                            getLanActual={this.getLanActual.bind(this)}
                            userData={this.state.userData}
                            getDataUser={this.getDataUser.bind(this)}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            urlGlobal={this.urlGlobal} />
                        );
                      }} />
                    <Route
                      path="/transactions"
                      render={() => {
                        return (
                          <Transacciones
                            defaultData={this.state.defaultData}
                            textosShow={this.state.textos}
                            getLanActual={this.getLanActual.bind(this)}
                            userData={this.state.userData}
                            getDataUser={this.getDataUser.bind(this)}
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            urlGlobal={this.urlGlobal}
                            history={this.props.history}
                            loggedIn={this.state.loggedIn} />
                        );
                      }} />
                    <Route
                      path="/Profile"
                      render={() => {
                        return (
                          <Profile
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            textosShow={this.state.textos}
                            showLoadingIndicator={this.showLoadingIndicator.bind(this)}
                            urlGlobal={this.urlGlobal}
                            showChallengeModal={this.showChallengeModal.bind(this)}
                            defaultData={this.state.defaultData}
                            loggedIn={this.state.loggedIn}
                            userData={this.state.userData}
                             />
                        );
                      }} />
                      <Route
                      path="/PromoCode"
                      render={() => {
                        return (
                          <PromoCode
                            getHeadersToSend={this.getHeadersToSend.bind(this)}
                            getLanActual={this.getLanActual.bind(this)}
                            textosShow={this.state.textos}
                            getDataUser={this.getDataUser.bind(this)}
                            showLoadingIndicator={this.showLoadingIndicator.bind(this)}
                            urlGlobal={this.urlGlobal}
                            showChallengeModal={this.showChallengeModal.bind(this)}
                            defaultData={this.state.defaultData}
                            loggedIn={this.state.loggedIn}
                            userData={this.state.userData}
                             />
                        );
                      }} />
                    <Route component={NotFound} />
                  </Switch>
                  <Footer
                    getHeadersToSend={this.getHeadersToSend.bind(this)}
                    getLanActual={this.getLanActual.bind(this)}
                    userData={this.state.userData}
                    textosShow={this.state.textos}
                    urlGlobal={this.urlGlobal}
                    defaultData={this.state.defaultData} />
                </div>
                <BlogBox
                  blogOpen={this.state.blogOpen}
                  getHeadersToSend={this.getHeadersToSend.bind(this)}
                  getLanActual={this.getLanActual.bind(this)}
                  showPerfilUserModal={this.showPerfilUserModal.bind(this)}
                  showChallengeModal={this.showChallengeModal.bind(this)}
                  getBlogClienteBan={this.getBlogClienteBan.bind(this)}
                  setUnreadBlogCommentCnt={this.setUnreadBlogCommentCnt.bind(this)}
                  increaseUnreadBlogCommentCnt={this.increaseUnreadBlogCommentCnt.bind(this)}
                  showLoadingIndicator={this.showLoadingIndicator.bind(this)}
                  getRule={this.getRule.bind(this)}
                  textosShow={this.state.textos}
                  urlGlobal={this.urlGlobal}
                  defaultData={this.state.defaultData}
                  userData={this.state.userData}
                  wsConnect={this.state.wsConnect}
                  socketData={this.state.socketData}
                />
                <SpinModal 
                    ref={this.spinModalElement}
                    textosShow={this.state.textos}
                    defaultData={this.state.defaultData}
                    onSpinClose={this.onSpinClose.bind(this)}
                    showLoadingIndicator={this.showLoadingIndicator.bind(this)}
                    openAlertModal={this.openAlertModal.bind(this)}
                    urlGlobal={this.urlGlobal}
                    loggedIn={this.state.loggedIn}
                    getHeadersToSend={this.getHeadersToSend.bind(this)}
                    getLanActual={this.getLanActual.bind(this)}
                    getDataUser={this.getDataUser.bind(this)}></SpinModal>
                <AlertModal
                    title={this.state.alertTitle}
                    textosShow={this.state.textos}
                    message={this.state.alertMessage}
                    purchase={this.state.alertPurchase}
                  ></AlertModal>
                {this.state.showIndicator && <LoadingIndicator />}
              </div>
            ) : (
                <div className="loadingIni">
                  <img
                    src={loadingIcon}
                    alt="Loading" />
                </div>
              )
          }
        </Switch>
      ) : (
          <div
            className='content'
            id='profile'>
            <div className='wrapper'>
              <div className='page_content'>
                <div className='c_center'>
                  <div className='c_top'>
                    <div className='c_bottom'>
                      <h1>
                        YOUR COUNTRY IS NOT YET ALLOWED TO PLAY FOR MONEY
                    </h1>
                      <p>
                        IF YOU THINK THIS IS AN ERROR, PLEASE <span
                          className="link-button"
                          style={{ color: "#F00" }}
                          onClick={this.showContactModal.bind(this)}>
                          CONTACT US
                      </span>
                      </p>
                      <ContactModal
                        textosShow={{
                          notempty: "not be empty",
                          emailcorrecto: "Must be a email address",
                          nombre: "Name",
                          mensaje: "Message",
                          contacto: "Contact",
                          tunombre: "Your name",
                          tuemail: "Your email",
                          enviar: "Send"
                        }}
                        getHeadersToSend={this.getHeadersToSend.bind(this)}
                        getLanActual={this.getLanActual.bind(this)}
                        urlGlobal={this.urlGlobal}></ContactModal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    );
  }
}

export default withRouter(App);
