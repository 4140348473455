import React from "react";
import Eagle from "./Eagle";

const Papyrus = ({ children }) => (
  <div className='content' id='profile'>
    <div className='wrapper'>
      <div className='smoke_center'></div>
      <div className='page_content'>
        <div className='c_center'>
          <div className='c_top'>
            <div className='c_bottom'>
              <Eagle></Eagle>
              <div className='leaves'></div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Papyrus;
