import React from 'react';
import { faHourglassEnd, faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, CardText, Col, Collapse, Container, Row } from 'reactstrap';
import getTipoTorneo from '../utils/getTipoTorneo';
import freeTicketIcon from '../../img/free-200.png';

const FreerollsMobile = ({ gameComponent, textosShow, defaultData, freerolls, onPlayClick, showDepositNow, showDepositFree }) => {
  const getCustomTitleClass = (sala, index) => {
    if (sala && sala.custom_title && index === 0) {
      return 'custom_title';
    }
    else {
      return '';
    }
  };

  const getCustomTitleStyle = (sala, index) => {
    if (sala && sala.custom_title && sala.custom_title_color && index === 0) {
      return sala.custom_title_color;
    }
    else {
      return '';
    }
  };

  const getButtonTitle = (sala) => {
    if (sala.showReentry)  {
        return (textosShow.reentrar || "Reentrar");
    }
    else {
        return (textosShow.entrar || "Entrar");
    }
  }

  const getEntryCosto = (sala) => {
    return sala.costo;
  };

  const isValid = (sala) => {
    return !(sala.modo === "Jackpots" && sala.tiempo.dias === 0 && sala.tiempo.horas === 0 && sala.tiempo.minutos === 0 && sala.tiempo.segundos === 0);
  };

  return (
    <Container fluid>
      <Row>
        {
          freerolls.map((s) => {
            let juego = s.juego;
            let sala = juego.salas.find((x) => x.id === s.id);

            return (sala && isValid(sala) && sala.onlyOnMobile === 0 && (
              <Col 
                xs="12" 
                sm="6" 
                className={"rounded-lg py-3 tour_info " + getTipoTorneo(textosShow, juego, sala).classes.join(" ")}
                key={sala.id}>
                <Card className="rounded-lg shadow border-secondary h-100">
                  <CardHeader className='p-0'>
                    <Row className="w-100 m-0">
                      <Col 
                        xs="5" 
                        className='entry_pay my-auto align-content-center'>
                        <div className="title">
                          {textosShow.costedeentradamin || "Coste de entrada"}
                        </div>
                        <div className='d_flex'>
                          <span className={(juego.freeJuegoSalaId === sala.id || sala.showReentry) ? 'strike' : ''}>
                            {defaultData.moneda.signo}{getEntryCosto(sala)}
                          </span>
                          {
                            (juego.freeJuegoSalaId === sala.id) && (
                              <img className='free_icon'
                                src={freeTicketIcon}
                                width='70px'
                                alt="" />
                            )
                          }
                          {
                            (sala.showReentry) && (
                              <span>
                                {defaultData.moneda.signo} {sala.costoReentry}
                              </span>
                            )
                          }
                        </div>
                      </Col>
                      <Col 
                        xs="7" 
                        className='prize_card_header p-3'>
                        <div className="title">
                          {textosShow.premioalganador || "Premio al ganador"}
                        </div>
                        <span className={`sign_length_${defaultData.moneda.signo.length}`}>
                          <p>
                            {defaultData.moneda.signo}
                          </p>{sala.premioTotal}
                        </span>
                        <div className='tour_add ic_close'>
                          +{sala.gemas}
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex flex-column align-content-between">
                      <div className='py-2'>
                        <img 
                          src={juego.logo}
                          alt={juego.slug}
                          height="150px" />
                      </div>
                      <div className='py-2'>
                        <b>
                          {
                            getTipoTorneo(textosShow, juego, sala).data.map((data, index) => (
                              <span 
                                key={data.id} 
                                className={getCustomTitleClass(sala, index)} 
                                style={{color: getCustomTitleStyle(sala, index)}}>
                                {index > 0 ? " / " : ""}
                                {data.title}
                              </span>
                            ))
                          }
                        </b>
                      </div>
                      <div className="py-2">
                        {(sala.cantJugador === -1) ? '∞' : sala.cantJugador}-{textosShow.jugadores || "Jugadores"}
                        <br />
                        <span className='more_info'>
                          {textosShow.masinfo || "Más info"}
                        </span>
                      </div>
                      {
                        getTipoTorneo(textosShow, juego, sala).data.find(data => data.description) ? (
                          <div className="py-2">
                            {
                              getTipoTorneo(textosShow, juego, sala).data.map((data) =>
                                data.description ? (
                                  <div>
                                    <small>
                                      {data.description}
                                      {
                                        data.icon ? (
                                          <span className={`${data.iconContainerClass} pr-1`}>
                                            {data.icon}
                                          </span>
                                        ) : (
                                          null
                                        )
                                      }
                                    </small>
                                  </div>
                                ) : (
                                  null
                                )
                              )
                            }
                          </div>
                        ) : (
                          null
                        )
                      }
                      <div className="py-2">
                        <CardText>
                          <small>
                            {
                              sala.tiempo.highlight ? (
                                <span className="iconTimeRemaining pr-1">
                                  <FontAwesomeIcon 
                                    icon={faHourglassEnd} 
                                    size="lg" />
                                </span>
                              ) : (
                                <span className="iconTimeRemaining pr-1">
                                  <FontAwesomeIcon 
                                    icon={faHourglassHalf} 
                                    size="lg" />
                                </span>
                              )
                            }
                            <span className="remaining_time">
                              {sala.tiempo.dias}d {sala.tiempo.horas}:{sala.tiempo.minutos}:{sala.tiempo.segundos}
                            </span>
                          </small>
                        </CardText>
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Collapse isOpen={showDepositNow === sala.id}>
                      <b>
                        {textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir"}
                      </b>
                      <button className="button deposit_button enter_button mt-2">
                        <Link to="/deposit">
                          {textosShow.depositarahora || "Depositar ahora"}
                        </Link>
                      </button>
                    </Collapse>
                    <Collapse isOpen={showDepositFree === sala.id}>
                      <b>
                        {textosShow.necesitasdepositarparajugaraljuegogratis || "Necesitas depositar para jugar al juego gratis"}
                      </b>
                      <button className="button deposit_button enter_button mt-2">
                        <Link to="/deposit">
                          {textosShow.depositarahora || "Depositar ahora"} 
                        </Link>
                      </button>
                    </Collapse>
                    <Collapse isOpen={showDepositFree !== sala.id && showDepositNow !== sala.id}>
                      <button
                        className="button deposit_button enter_button"
                        onClick={() => onPlayClick(juego, sala)}>
                        {getButtonTitle(sala)}
                      </button>
                    </Collapse>
                  </CardFooter>
                </Card>
              </Col>
            ))
          })
        }
      </Row>
    </Container>
  );
};

export default FreerollsMobile;
