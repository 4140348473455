import noneMembershipIcon from '../../img/membership/none.png';
import bronzeMembershipIcon from '../../img/membership/bronze.png';
import goldMembershipIcon from '../../img/membership/gold.png';
import proMembershipIcon from '../../img/membership/pro.png';
import diamondMembershipIcon from '../../img/membership/diamond.png';
import rubyMembershipIcon from '../../img/membership/ruby.png';
import crownMembershipIcon from '../../img/membership/crown.png';

export default class MembershipUtil {
    static getMembershipIcon = (membership) => {
        if (!membership) {
            return noneMembershipIcon;
        }
        
        switch (membership["membershipLevel"]) {
            case 0:
                return noneMembershipIcon;

            case 1:
                return bronzeMembershipIcon;

            case 2:
                return goldMembershipIcon;

            case 3:
                return proMembershipIcon;

            case 4:
                return diamondMembershipIcon;

            case 5:
                return rubyMembershipIcon;

            case 6:
                return crownMembershipIcon;

            default:
                return noneMembershipIcon;
        }
    }

    static getMembershipIcon2 = (membershipId) => {
        switch (membershipId) {
            case 1:
                return noneMembershipIcon;

            case 2:
                return bronzeMembershipIcon;

            case 3:
                return goldMembershipIcon;

            case 4:
                return proMembershipIcon;

            case 5:
                return diamondMembershipIcon;
            
            case 6:
                return rubyMembershipIcon;
            
            case 7:
                return crownMembershipIcon;

            default:
                return noneMembershipIcon;
        }
    }
      
    static getMembershipProgressWidth = (membership, nextMembership) => {
        return (nextMembership ? membership.membershipPoints * 100 / nextMembership.membershipPoints : 100) + '%';
    }

    static getMembershipProgressText = (membership, nextMembership) => {
        return nextMembership ? (membership.membershipPoints * 100 / nextMembership.membershipPoints) + '%' : '';
    }

    static getMembershipClass = (membership) => {
        if (!membership) {
            return '';
        }
    }
}

