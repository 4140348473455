import React, { Component } from 'react';

import $ from 'jquery';

class ContactModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.validateMsg = {
      empty: "%name% " + props.textosShow.notempty,
      email: props.textosShow.emailcorrecto
    };
    
    this.state = {
      loading: false,
      name: "",
      email: "",
      message: "",
      validate: {
        name: {
          valid: true,
          msg: ""
        },
        email: {
          valid: true,
          msg: ""
        },
        message: {
          valid: true,
          msg: ""
        }
      }
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  };
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  };
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  };
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  };
  
  close() {
    $("#contact_modal").fadeOut("fast", function() {
      $("#contact_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  };

  setName(e) {
    e.preventDefault();
    this.setState({
      name: e.target.value
    });
  };
  
  setEmail(e) {
    e.preventDefault();
    this.setState({
      email: e.target.value
    });
  };
  
  setMessage(e) {
    e.preventDefault();
    this.setState({
      message: e.target.value
    });
  };

  validateName() {
    let valid = true;
    let msg = "";
    if (this.state.name === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, this.props.textosShow.nombre);
    }
    let validate = this.state.validate;
    validate.name = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  };
  
  validateEmail() {
    let valid = true;
    let msg = "";
    if (this.state.email === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, "Email");
    }
    else {
      if (!this.isEmail(this.state.email)) {
        valid = false;
        msg = this.validateMsg.email;
      }
    }
    let validate = this.state.validate;
    validate.email = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  };

  validateMessage() {
    let valid = true;
    let msg = "";
    if (this.state.message === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, this.props.textosShow.mensaje);
    }
    let validate = this.state.validate;
    validate.message = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  };

  isEmail(mail) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(mail);
  };

  send() {
    let clase = this;
    clase.setState({
      validate: {
        name: {
          valid: true,
          msg: ""
        },
        email: {
          valid: true,
          msg: ""
        },
        message: {
          valid: true,
          msg: ""
        }
      }
    });
    if (clase.validateName() && clase.validateEmail() && clase.validateMessage()) {
      clase.setState({
        loading: true
      });
      fetch(clase.props.urlGlobal + "api/contact", {
        method: "POST",
        body: JSON.stringify({
          action: "send",
          lan: this.props.getLanActual(),
          name: clase.state.name,
          email: clase.state.email,
          message: clase.state.message
        }),
        headers: this.props.getHeadersToSend()
      }).then(function(response) {
        return response.json();
      }).then(function(result) {
        if (result.success) {
          alert(result.msg);
          clase.setState({
            loading: false,
            name: "",
            email: "",
            message: "",
            validate: {
              name: {
                valid: true,
                msg: ""
              },
              email: {
                valid: true,
                msg: ""
              },
              message: {
                valid: true,
                msg: ""
              }
            }
          });
          clase.close();
        }
        else {
          alert(result.msg);
          clase.setState({
            loading: false
          });
        }
      });
    }
  };
  
  render() {
    return (
      <div 
        className="modalx-overlay close_mw"
        id="contact_modal_overlay">
        <div 
          className='modal_window'
          id="contact_modal">
          <button 
            id="close_modal"
            className="link-button close_mw"
            onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                <h5>
                  {this.props.textosShow.contacto}
                </h5>
                <div className="modal_form">
                <div className='input_title'>
                    {this.props.textosShow.tunombre || 'Tu Nombre'}
                  </div>
                  <div className={this.state.validate.name.valid && !this.state.error ? 'input' : 'input error'}>
                    {
                      !this.state.validate.name.valid ? (
                        <div className='error_reason'>
                          {this.state.validate.name.msg}
                        </div>
                      ) : (
                        null
                      )
                    }
                    <input 
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.setName.bind(this)} />
                  </div>
                  <div className='input_title'>
                    {this.props.textosShow.tuemail || 'Tu Email'}
                  </div>
                  <div className={this.state.validate.email.valid && !this.state.error ? 'input' : 'input error'}>
                    {
                      !this.state.validate.email.valid ? (
                        <div className='error_reason'>
                          {this.state.validate.email.msg}
                        </div>
                      ) : (
                        null
                      )
                    }
                    <input 
                      type="text"
                      name="email"
                      value={this.state.email}
                      onChange={this.setEmail.bind(this)} />
                  </div>
                  <div className='input_title'>
                    {this.props.textosShow.mensaje || 'Mensaje'}
                  </div>
                  <div className={this.state.validate.message.valid ? 'input' : 'input error'}>
                    {
                      !this.state.validate.message.valid ? (
                        <div className='error_reason'>
                          {this.state.validate.message.msg}
                        </div>
                      ) : (
                        null
                      )
                    }
                    <textarea 
                      rows="5"
                      name="message"
                      onChange={this.setMessage.bind(this)}
                      value={this.state.message}>
                    </textarea>
                  </div>
                </div>
                <div className='modal_button'>
                  <button 
                    className='button button_regular'
                    onClick={this.send.bind(this)}
                    disabled={this.state.loading}>
                    {this.props.textosShow.enviar || "Enviar"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
};

export default ContactModal;
