import React from 'react';

const AdvantajeCard = ({icon, iconAlt, title, description}) => (
  <div className='adv col-12 col-sm-6 col-md-4'>
    <div className='adv_icon'>
      <img 
        src={icon}
        alt={iconAlt} />
    </div>
    <h3>
      {title}
    </h3>
    <span>
      {description}
    </span>
  </div>
);

export default AdvantajeCard;