import React, { Component } from 'react';

import $ from 'jquery';

import activatedIcon from '../../img/activate_icon.png';
import { withRouter } from 'react-router-dom';

class AccountActivatedModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  }
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }
  
  close() {
    this.props.history.replace('/');
    $("#activated_modal").fadeOut("fast", function() {
      $("#activated_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  }
  
  render() {
    return (
      <div className="modalx-overlay close_mw"
           id="activated_modal_overlay">
        <div className='modal_window'
             id="activated_modal">
          <button id="close_modal"
                  className="link-button close_mw"
                  onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                <div className='activate_icon'>
                  <img src={activatedIcon}
                       alt="Account activated" />
                </div>
                <h4>
                  {this.props.textosShow.cuentaactivada || "Cuenta activada"}
                </h4>
                <div className='activate_text'>
                  {this.props.textosShow.cuentaactivadaexitosamente || "Tu cuenta ha sido activada exitosamente"}
                  <br/>
                  {this.props.textosShow.ahorapuededisfrutareljuego || "Ahora puede disfrutar el juego!"}
                </div>
                <div className='activate_buttons'>
                  <button className="button button_regular"
                          onClick={this.props.openTutorialStep1.bind(this)}>
                    {this.props.textosShow.comenzartutorial || "Comenzar tutorial"}
                  </button>
                  <button className="button button_regular button_orange"
                          onClick={this.close.bind(this)}>
                    {this.props.textosShow.competirpordinero || "Competir por dinero"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default withRouter(AccountActivatedModal);