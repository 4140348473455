import React, { Component } from 'react';
import social from '../../img/social.png'
import {
  Container, Row, Col, CardImg
} from 'reactstrap';
import $ from 'jquery';

class FriendModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      payer: "paypal",
      nombre: "",
      apellido: "",
      email: "",
      money: "",
      comision: 0,
      duelcash_comision: 1,
      iban: "",
      bic: "",
      address: ""
    };

    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  };

  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  };

  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  };

  close() {
    $("#alert_modal3").fadeOut("fast", function () {
      $("#alert_modal_overlay3").fadeOut("fast", function () {
        document.body.style.overflow = "auto";
      });
    });
  }

  render() {
    return (
      <div className="modalx-overlay close_mw"
        id="alert_modal_overlay3">
        <div className='modal_window'
          id="alert_modal3">
          <button id="close_modal"
            className="link-button close_mw"
            onClick={this.close.bind(this)}></button>
          <div className='modal_top' style={{ marginTop: '50px' }}>
            <div className='modal_bottom'>
              <div className='modal_content'>
                <h5>
                  Profile
                </h5>
                <Container>
                  {this.props.recentData !== undefined && this.props.recentData.cliente !== undefined
                    ?
                    <Row style={{ color: 'black', fontSize: '12px', marginTop: '20px', fontWeight: 'bold', boxShadow: '1px 2px 7px #888888' }}>
                      <Col xs="2">
                        <div style={{ textAlign: 'center', width: '100%', marginTop: '10px' }}>
                          <CardImg style={{ width: '50px' }} src={this.props.recentData.cliente.photo} alt="Card image cap" />
                          <div style={{ color: 'black', fontWeight: 'bold' }}>
                            <span>
                              <h7>{this.props.recentData.cliente.isOnline === true ? "Online" : "Offline"}</h7>
                              <img
                                src={this.props.recentData.cliente.countryFlag}
                                style={{ width: '12px', marginBottom: '2px', marginLeft: '4px' }}
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col xs="10">
                        <Row>
                          <Col xs="6">
                            Living in:
                          </Col>
                          <Col xs="6">
                            {this.props.recentData.cliente.name}
                          </Col>
                          <Col xs="6">
                            Member since:
                          </Col>
                          <Col xs="6">
                            {this.props.recentData.cliente.memeberSince}
                          </Col>
                          <Col xs="6">
                            Amount of duels:
                          </Col>
                          <Col xs="6">
                            {this.props.recentData.cliente.amountOfDuels}
                          </Col>
                          <Col xs="6">
                            Profile views:
                          </Col>
                          <Col xs="6">
                            {this.props.recentData.cliente.profileViews}
                          </Col>
                          <Col xs="6">
                            Favorite game:
                          </Col>
                          <Col xs="6">
                            {this.props.recentData.cliente.favorito_juego}
                          </Col>
                        </Row>
                      </Col>
                    </Row> : null
                  }
                  <Row style={{ color: 'black', fontSize: '12px', marginTop: '20px', fontWeight: 'bold', boxShadow: '1px 2px 7px #888888' }}>
                    <Col xs="3">
                      <div style={{ textAlign: 'center', width: '100%', marginTop: '5px' }}>
                        {console.log("this.props.itemData", this.props.itemData)}
                        <CardImg style={{ width: '50px' }} src={social} alt="Card image cap" />
                        <div style={{ color: 'black', fontWeight: 'bold' }}>
                          <span>
                            <h7>New Friends</h7>
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col xs='9'>
                      <div className='scrollableElement' style={{ height: '96px' }}>
                        {this.props.recentDataImage && this.props.recentDataImage.map((item, key) =>
                          <div style={{ marginLeft: "10px", textAlign: 'center', marginTop: '5px' }}>
                            <CardImg style={{ width: '50px', height: '51px' }} top src={item.photo} alt="Card image cap" />
                            <div style={{ marginTop: '6px' }}>{item.name}</div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div style={{ color: 'black', fontSize: '12px', marginTop: '20px', fontWeight: 'bold', boxShadow: '1px 2px 7px #888888',textAlign:"center" }}>
                    <span
                      className="link-button"
                      onClick={() => this.props.showChallengeModal(this.props.recentData.cliente.id)}>
                      {this.props.textosShow.desafiar || "Desafiar"}
                    </span>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default FriendModal;