import React from 'react';

import boxRed from '../../img/boxRed.png';
import boxLight from '../../img/boxLight.png';
import boxSmokeBg from '../../img/boxSmokeBg.png';

const SmokeBox = () => (
        <div className="redbox__img">
            <div className="view">
                <img loading="lazy" src={boxSmokeBg} alt=""
                    className="scale-up-center" />
                <img loading="lazy" src={boxSmokeBg} alt=""
                    className="scale-up-center" style={{ animationDelay: "2.5s" }} />
                <span>
                    <div>
                        <img loading="lazy" src={boxRed} alt="" />
                        <img loading="lazy" src={boxLight} alt=""
                            className="anim-light" />
                    </div>
                </span>
                <img loading="lazy" src={boxSmokeBg} alt="" className="scale-up-side" />
                <img loading="lazy" src={boxSmokeBg} alt="" className="scale-up-side"
                    style={{ animationDelay: "3.5s" }} />
            </div>
        </div>
)

export default SmokeBox;