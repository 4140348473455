import React, { useEffect, useState } from 'react';
import { Container, Row, Col, } from 'reactstrap';
import $ from 'jquery';
import FriendInviteModal from "./FriendInviteModal"
import FriendModal from "./FriendModal";
import defaultImage from '../../img/no-image.png';

function FriendInvite(props) {
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pages, setPages] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [inviteFriendMsg, setInviteFriendMsg] = useState('');
  const [count, setCount] = useState(0);
  const [recentData, setRecentData] = useState([]);
  const [recentDataImage, setRecentDataImage] = useState([]);

  function handleChangePage(page) {
    setActivePage(page)
    searchFriendRequest(page);
  }

  const searchFriendRequest = (page) => {
    props.showLoadingIndicator(true);
    fetch(props.urlGlobal + "api/friendRequest", {
      method: "POST",
      body: JSON.stringify({
        action: "getInviteFriendRequests",
        lan: props.getLanActual(),
        filter: props.searchValue,
        page: page + 1
      }),
      headers: props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        props.showLoadingIndicator(false);
        let totalPages = Math.ceil(result.total / 6);
        let pages = [];
        let firstPage = (page > 4) ? page - 4 : 0;
        let lastPage = (page + 4 < totalPages) ? page + 4 : totalPages - 1;
        for (let i = firstPage; i <= lastPage; i++) {
          pages.push(i);
        }
        setSearchData(result.result)
        setTotal(result.total)
        setTotalPages(totalPages)
        setPages(pages)
      }
    });
  }

  const sendFriendRequest = (id, status) => {
    var a = 1;
    fetch(props.urlGlobal + "api/friendRequest", {
      method: "POST",
      body: JSON.stringify({
        action: "updateEstado",
        lan: props.getLanActual(),
        friendId: id,
        estado: status
      }),
      headers: props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        setInviteFriendMsg(result.msg)
        openFriendInviteModal();
        setCount(a => a + 1)
      }
      else {
        setInviteFriendMsg(result.msg)
        openFriendInviteModal(result.msg);
      }
    });
  };

  const openFriendInviteModal = () => {
    $("#alert_modal_overlay4").fadeIn("fast", function () {
      $("#alert_modal4").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  function openFriendModal() {
    $("#alert_modal_overlay3").fadeIn("fast", function () {
      $("#alert_modal3").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  function getRecentFriendRequests(id) {
    fetch(props.urlGlobal + "api/friendRequest", {
      method: "POST",
      body: JSON.stringify({
        action: "getRecentFriendRequests",
        lan: props.getLanActual(),
        clienteId: id
      }),
      headers: props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        setRecentData(result)
        setRecentDataImage(result.requests)
        openFriendModal()
      }
      else {
        setRecentData(result.msg)
      }
    });
  };

  useEffect(() => {
    searchFriendRequest(0);
  }, [count])
  return (
    <Container>
      <FriendInviteModal
        inviteFriendMsg={inviteFriendMsg}
      />
      <FriendModal
        recentData={recentData}
        recentDataImage={recentDataImage}
        textosShow={props.textosShow}
        showChallengeModal={props.showChallengeModal}
      />
      <div className='content'
        id='referral'></div>
      <div style={{ width: '100%' }}>
        <div className='page_block'>
          <div className='game_invite'>
            <div className='page_block'>
              <Row style={{ marginTop: '20px' }}>
                {/* <Col xs="1">
                </Col> */}
                <Col xs="12" className="invite_friend_table">
                  {searchData && searchData.length > 0 ?
                    <div >
                      <table >
                        <thead >
                          <tr>
                            <td >
                              <span>
                              </span>
                            </td>
                            <td >
                              <span>
                                {props.textosShow.number || "Place"}
                              </span>
                            </td>
                            <td>
                              <span>
                                {props.textosShow.name || "Player"}
                              </span>
                            </td>
                            <td>
                              <span>
                                {props.textosShow.gems || "Gems "}
                              </span>
                            </td>
                            <td>
                              <span>
                                {props.textosShow.request || "Request"}
                              </span>
                            </td>
                            <td>
                              <span>
                                {props.textosShow.action || "Action"}
                              </span>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {searchData.map((t, key) =>
                            <tr
                              className="invite-hover-button"
                              key={t.id}
                            >
                              <td style={{ cursor: 'pointer' }} className="invite-hover-button--on">
                                <a href={"/profile/" + t.id} style={{ zIndex: 1000 }}><span>
                                </span></a>
                              </td>
                              <td style={{ cursor: 'pointer' }} >
                                <a href={"/profile/" + t.id} style={{ zIndex: 1000 }}>
                                  <span>
                                    {activePage * 6 + (key + 1)}
                                  </span>
                                </a>
                              </td>
                              <td style={{ cursor: 'pointer' }}>
                                <a href={"/profile/" + t.id}>
                                  <span>
                                    <img style={{ width: '40px', float: 'left' }}
                                      src={t.img} onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }}
                                    />
                                    <img style={{ width: '20px', float: 'left', marginLeft: '6px', marginTop: '13px' }}
                                      src={t.flag}
                                    />
                                    <div style={{ fontSize: '13px', marginTop: '14px', fontWeight: 'bold' }}>{t.name} </div>
                                  </span>
                                </a>
                              </td>
                              <td style={{ cursor: 'pointer' }}>
                                <a href={"/profile/" + t.id}>
                                  <span>
                                    {t.points}
                                  </span>
                                </a>
                              </td>
                              <td style={{ width: '140px', cursor: 'pointer' }}>
                                <a href={"/profile/" + t.id}>
                                  <Row>
                                    <Col xs="6"> <span >
                                      {props.textosShow.pending || "Pending "}
                                    </span></Col>
                                    <Col xs="auto">
                                      <span className="loading"></span>
                                    </Col>
                                  </Row>
                                </a>
                              </td>
                              <td >
                                <span style={{ zIndex: 11, left: 25 }}>
                                  <button onClick={() => sendFriendRequest(t.id, 1)} className="inviteFriendButton inviteFriendBackground">{props.textosShow.accept || "Accept "}</button>
                                </span>
                                <span>
                                  <button style={{ marginTop: '-12px', color: '#f6ff52' }} onClick={() => sendFriendRequest(t.id, 2)} className="inviteBarButton searchBarButtonBackground">Reject</button>
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    :
                    <h4 style={{ marginTop: "170px", position: "absolute", left: 0, right: 0 }}>No Pending Request Found</h4>
                  }
                </Col>
              </Row>
              {searchData && searchData.length > 0 ?
                < div className='paginator'>
                  {
                    (activePage > 0) ?
                      <span className='link-button prev'
                        onClick={() => handleChangePage(activePage - 1)}></span> :
                      null
                  }
                  {
                    (pages.length && pages[0] > 0) ?
                      <span className='link-button'
                        onClick={() => handleChangePage(0)}>
                        1
                      </span> :
                      null
                  }
                  {
                    (pages.length && pages[0] > 0) ?
                      <span>
                        ...
                      </span> :
                      null
                  }
                  {
                    pages.map(page =>
                      <span className='link-button'
                        id={(page === activePage) ? 'page_active' : ''}
                        onClick={() => handleChangePage(page)}
                        key={page}>
                        {page + 1}
                      </span>
                    )
                  }
                  {
                    (pages.length && pages[pages.length - 1] < totalPages - 1) ?
                      <span>
                        ...
                      </span> :
                      null
                  }
                  {
                    (pages.length && pages[pages.length - 1] < totalPages - 1) ?
                      <span className='link-button'
                        onClick={() => handleChangePage(totalPages - 1)}>
                        {totalPages}
                      </span> :
                      null
                  }
                  {
                    (activePage < totalPages - 1) ?
                      <span className='link-button next'
                        onClick={() => handleChangePage(activePage + 1)}></span> :
                      null
                  }
                </div>
                :
                null}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default FriendInvite;