import React, { Component } from 'react';

class Eagle extends Component {
  
  render() {
    return (
      <div className="eagle"></div>
    );
  }
  
};

export default Eagle;