import React from 'react';
import { Link } from "react-router-dom";

//import logo from '../../img/logo-christmas.png';
import logo from '../../img/logo_big.png';

const LogoBig = ({ children }) => {
  return (
    <div className='logo_area'>
      {/*<div className='logo_area christmas'>*/}
      <Link to="/">
        <img src={logo}
          alt="DuelCash" />
      </Link>
      {children}
    </div>
  );
};

export default LogoBig;