import React, { useEffect, useRef, useState } from 'react';

import $ from 'jquery';
import SmokeCenter from '../components/SmokeCenter';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import { Col, Container, Row } from 'reactstrap';
import MembershipCard from '../components/MembershipCard';
import MembershipProgress from '../components/MembershipProgress';
import FaqMembership from '../components/FaqMembership';
import SmokeBox from '../components/SmokeBox';

import moment from 'moment';
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import Spin from '../components/Spin';

const Membership = ({ urlGlobal, getLanActual, getHeadersToSend, textosShow, userData, defaultData }) => {

  useEffect(() => {
    getMembershipList();
  }, []);

  const [membershipList, setMembershipList] = useState();

  const getMembershipList = () => {
    fetch(urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "getMembershipList",
      }),
      headers: getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        setMembershipList(result.membershipList);
      }
    });
  }

  const showFaqModal = () => {
    $("#faq_membership_modal_overlay").fadeIn("fast", function () {
      $("#faq_membership_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  return (
    <>
      <FaqMembership
          urlGlobal={urlGlobal}
          getLanActual={getLanActual}
          getHeadersToSend={getHeadersToSend} />
      <div
        className='content'
        id='membership'>
        <div className='wrapper'>
          <SmokeCenter />
          <div className='page_content'>
            <div className='c_center'>
              <div className='c_top'>
                <div className='c_bottom'>
                  <Eagle />
                  <Leaves />
                  <div className='game_image'>
                    <SmokeBox />
                  </div>
                  <div className='faq_help'>
                    <button
                      className='faq_info'
                      onClick={showFaqModal}>
                      FAQ
                    </button>
                  </div>

                  { userData.membership && 
                    <h4>
                      {textosShow.membership || 'Membership'}
                    </h4>
                  }

                  <MembershipProgress
                    membership={userData.membership}
                    nextMembership={userData.nextMembership}
                    textosShow={textosShow}
                    urlGlobal={urlGlobal}
                    getHeadersToSend={getHeadersToSend}
                  />

                  <Container fluid className='page_block membership-card-container'>
                    <Row>
                      {
                        membershipList && 
                        membershipList.map((membership) => {
                          return (
                            <Col xs="12" sm="6" md="3" lg="3" className="my-4 d-flex-center">
                              <MembershipCard
                                membership={membership}
                                textosShow={textosShow}
                                defaultData={defaultData}/>
                            </Col>
                          );
                        })
                      }
                      
                    </Row>
                  </Container>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Membership;
