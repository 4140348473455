import $ from 'jquery';
import React, { Component } from 'react';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import SmokeCenter from '../components/SmokeCenter';
import FreerollsDesktop from "../components/FreerollsDesktop";
import FreerollsMobile from '../components/FreerollsMobile';
import SmokeBox from '../components/SmokeBox';

class Freerolls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDepositNow: false,
      showDepositFree: false,
      showSubscribeFree: false,
      showSubscribeFreeMore: false,
      freerolls: [],
    };
  };

  componentDidMount() {
    this.getFreerolls();
    this.timerJackpots = setInterval(() => {
      this.moveTimeSala();
    }, 1000);
    window.scrollTo(0, 0);
  };
  
  componentWillUnmount() {
    this.timerJackpots = null;
    this.setState = () => {
      return;
    };
  };

  getFreerolls() {
    let clase = this;
    fetch(clase.props.urlGlobal + "api/juego", {
      method: "POST",
      body: JSON.stringify({
        action: "getFreerollsList",
        lan: clase.props.getLanActual(),
      }),
      headers: clase.props.getHeadersToSend(),
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.setState({
          freerolls: result.freerolls,
        });
      }
    });
  };

  moveTimeSala() {
    let freerolls = this.state.freerolls;

    if (freerolls && freerolls.length) {
      for (let i = 0; i < freerolls.length; i++) {
        for (let j = 0; j < freerolls[i].juego.salas.length; j++) {
          if (freerolls[i].juego.salas[j].modo === "Jackpots") {
            let dias = freerolls[i].juego.salas[j].tiempo.dias;
            let horas = freerolls[i].juego.salas[j].tiempo.horas;
            let minutos = freerolls[i].juego.salas[j].tiempo.minutos;
            let segundos = freerolls[i].juego.salas[j].tiempo.segundos;
            segundos--;
            if (segundos < 0) {
              if (minutos > 0) {
                segundos = 59;
                minutos--;
              }
              else {
                segundos = 0;
              }
            }
            if (minutos < 0) {
              if (horas > 0) {
                minutos = 59;
                horas--;
              }
              else {
                minutos = 0;
              }
            }
            if (horas < 0) {
              if (dias > 0) {
                horas = 23;
                dias--;
              }
              else {
                horas = 0;
              }
            }
            if (dias < 0) {
              dias = 0;
            }
            freerolls[i].juego.salas[j].tiempo = {
              highlight: dias === 0 && horas < 6,
              dias: dias,
              horas: horas,
              minutos: minutos,
              segundos: segundos,
            };
          }
        }
      }
    }
    this.setState({
      freerolls: freerolls,
    });
  };

  showSubscribeNow(id) {
    $(".mt_block.step_5").fadeOut(0, function() {
      $(".mt_block.step_4").fadeOut(0, function() {
        $("#subscribe_now_" + id).fadeIn(0);
      });
    });
    this.setState({
      showSubscribeFree: id
    });
  };

  showSubscribeMoreNow(id) {
    $(".mt_block.step_5").fadeOut(0, function() {
      $(".mt_block.step_4").fadeOut(0, function() {
        $("#subscribe_now_more_" + id).fadeIn(0);
      });
    });
    this.setState({
      showSubscribeFreeMore: id
    });
  };

  showDepositNow(id) {
    $(".mt_block.step_4").fadeOut(0, function() {
      $(".mt_block.step_5").fadeOut(0, function() {
        $("#deposit_now_" + id).fadeIn(0);
      });
    });
    this.setState({
      showDepositNow: id,
    });
  };
  
  showDepositFree(id) {
    $(".mt_block.step_5").fadeOut(0, function() {
      $(".mt_block.step_4").fadeOut(0, function() {
        $("#deposit_free_" + id).fadeIn(0);
      });
    });
    this.setState({
      showDepositFree: id,
    });
  };

  showAcept(juego, sala) {
    console.log(juego);
    console.log(sala);
    this.setState({
      showDepositFree:false,
      showDepositNow:false,
      showSubscribeFree: false,
      showSubscribeFreeMore: false
    });
    if (this.props.loggedIn) {
      if (sala !== null) {
        if (sala.modo === "Jackpots" && this.props.userData.jackpotBlocked === 1) {
          this.props.openAlertModal(
            this.props.textosShow.usuariobloqueado || "Usuario bloqueado",
            this.props.textosShow.jackpotsbloqueados || "Jackpots bloqueados"
          );
          return;
        }
        if (sala.modo !== "Jackpots" && sala.costo === 0 && sala.premioTotal && !this.props.userData.hasDeposits && (juego.freeJuegoSalaId !== sala.id)) {
          this.showDepositFree(sala.id);
        }
        else if (sala.costo > this.props.userData.money && (juego.freeJuegoSalaId !== sala.id)) {
          this.showDepositNow(sala.id);
        }
        else if (sala.showReentry && sala.costoReentry > this.props.userData.money && (juego.freeJuegoSalaId !== sala.id)) {
          this.showDepositNow(sala.id);
        }
        else {
          if (sala.costo === 0) {
            let data = {
              action: "getGamePlayed",
              lan: this.props.getLanActual(),
              slug: juego.slug,
              sala: sala.id,
              type: 'freeroll'
            }
            let clase = this;

            fetch(clase.props.urlGlobal + "api/user", {
              method: "POST",
              body: JSON.stringify(data),
              headers: clase.props.getHeadersToSend()
            }).then(function(response) {
              return response.json();
            }).then(function(result) {
                if (result.success) {
                  /*if (result.played > 5){
                    clase.showSubscribeMoreNow(sala.id);
                    return;
                  }
                  if (!result.allowFreeroll) {
                    clase.showDepositFree(sala.id);
                    return;
                  }*/
                  clase.props.showPartidaModal(juego.slug, sala);
                }
            });           
          }
          else {
            this.props.showPartidaModal(juego.slug, sala);
          }
        }
      }
      else {
        alert(this.props.textosShow.estamodalidadnoestaaundisponible || "Esta modalidad no esta aún disponible");
      }
    }
    else {
      alert(this.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta");
    }
  };
  
  showFaqModal() {
    $("#faq_modal_overlay").fadeIn("fast", function() {
      $("#faq_modal").fadeIn("fast", function() {
        document.body.style.overflow = "hidden";
      });
    });
  };

  render() {
    return(
      <div>
        <div 
          className='content' 
          id='tournaments'>
            <div className='wrapper'>
              <SmokeCenter></SmokeCenter>
              <div className='page_content'>
                <div className='c_center'>
                  <div className='c_top'>
                    <div className='c_bottom'>
                      <Eagle></Eagle>
                      <Leaves></Leaves>
                      <div className='game_image'>
                        <SmokeBox />
                      </div>
                      <h4>
                      {this.props.textosShow.freerolls || 'Freerolls'}
                      </h4>
                      <div className='faq_help'>
                        <button 
                          className='faq_info'
                          onClick={this.showFaqModal.bind(this)}>
                           FAQ
                        </button>
                      </div>
                      <div className='page_block'>
                        <div className='table_list d-none d-xl-block'>
                          <FreerollsDesktop 
                            gameComponent={this}
                            textosShow={this.props.textosShow} 
                            defaultData={this.props.defaultData} 
                            freerolls={this.state.freerolls}
                            openPopupOnlyPlayOnMobile={this.props.openPopupOnlyPlayOnMobile}
                            onPlayClick={this.showAcept.bind(this)} />
                        </div>
                        <div className='grid_list d-block d-xl-none'>
                          <FreerollsMobile
                            gameComponent={this}
                            textosShow={this.props.textosShow} 
                            defaultData={this.props.defaultData} 
                            freerolls={this.state.freerolls}
                            showDepositNow={this.state.showDepositNow}
                            showDepositFree={this.state.showDepositFree}
                            onPlayClick={this.showAcept.bind(this)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  };
};

export default Freerolls;
