import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default function StripePaymentElement({ urlGlobal, getHeadersToSend, total, onReady, onBlur, onChange, textosShow, onSuccess, onError, onCancel, clientSecret, isSetup, trialDays }) {
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setDisabled(true);
  }, [clientSecret]);
  
  const handleReady = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
    onReady && onReady(event);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    let payload = {};
    if(isSetup) {
      payload = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/check_stripe`,
        },
        redirect: 'if_required'
      });
    } else {
      payload = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/check_stripe`,
        },
        redirect: 'if_required'
      });
    } 
    setProcessing(false);
    if (payload.error) {
      onError(payload.error.message);
    }else if (isSetup){
      if (payload.setupIntent.status !== "succeeded") {
        onCancel(payload.setupIntent.cancellation_reason);
      }
      else {
        onSuccess(payload.setupIntent.id);
      }
    }else {
      if (payload.paymentIntent.status !== "succeeded") {
        onCancel(payload.paymentIntent.cancellation_reason);
      }
      else {
        onSuccess(payload.paymentIntent.id);
      }
    }
  };
  
  return <form id="payment-form" onSubmit={handleSubmit}>
        {disabled && (
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        )}
        <div style={{
          padding: '10px',
          borderRadius: '10px',
          color: '#fff'
        }}>
          <PaymentElement onReady={handleReady} onBlur onChange />
        </div>
        {
          error && (
            <p>
              {error}
            </p>
          )
        }
        <br/>
        <button
          disabled={processing || disabled}
          id="submit"
          style={{
            backgroundColor: '#000000',
            color: '#ffffff',
            height: '55px',
            width: '100%',
            borderRadius: '10px',
            fontSize: '18px',
            lineHeight: '55px'
          }}>
          <span id="button-text">
            {
              processing ? (
                textosShow.procesando || "Procesing..."
              ) : (
                textosShow.pagarconstripe || "Pay"
              )
            }
          </span>
          {trialDays > 0 && (
            <span style={{
              lineHeight: '55px',
              fontSize: '18px',
              fontWeight: 'bold',
            }}
            >
              {` (${(textosShow.diasdeprueba || "{trialdays} trial days")})`.replace("{trialdays}", trialDays)}
            </span>
          )}
        </button>
      </form>;
};