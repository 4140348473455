import React, { Component, useCallback, useEffect, useRef, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';

import moment from 'moment';
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import { faHourglassEnd, faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import waitIcon from '../../img/wait_icon.png';
import winIcon from '../../img/win_icon.png';
import trumpet from '../../img/trumpet.png';
import boxRedIcon from '../../img/boxRedIcon.png';
import Util from '../utils/utils';
import Partida from './Partida';

const GameHistory  = (props) => {

  const ref = useRef(null);

  const history = useHistory();

  const [partidas, setPartidas]  = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pages, setPages] = useState([]);
  const [hoverEffectId, setHoverEffectId] = useState(-1);

  const [localTimeCount, setLocalTimeCount] = useState(0);

  const [closed, setClosed] = useState(false);
  const [activeFilterDate, setActiveFilterDate] = useState('filter_date_all');
  const [activeFilterTournament, setActiveFilterTournament] = useState('filter_tournament_all');

  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: null,
    key: 'selection'
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const [filterDates, setFilterDates] = useState([
    {
      id: "filter_date_all",
      value: props.textosShow.filter_date_all || "All Dates",
      selected: true
    },
    {
      id: "filter_date_today",
      value: props.textosShow.filter_date_today || "Today",
      selected: false
    },
    {
      id: "filter_date_this_week",
      value: props.textosShow.filter_date_this_week || "This Week",
      selected: false
    },
    {
      id: "filter_date_this_month",
      value: props.textosShow.filter_date_this_month || "This month",
      selected: false
    },
    {
      id: "filter_date_custom_range",
      value: props.textosShow.filter_date_custom_range || "Custom Range",
      selected: false
    },
  ]);

  const [filterTournaments, setFilterTournaments] = useState([
    {
      id: "filter_tournament_all",
      value: props.textosShow.filter_tournament_all || "All Tournaments",
      selected: true
    },
    {
      id: "filter_tournament_vs_simple",
      value: props.textosShow.filter_tournament_vs_simple || "VS Simple",
      selected: false
    },
    {
      id: "filter_tournament_vs_multi",
      value: props.textosShow.filter_tournament_vs_multi || "VS Multi",
      selected: false
    },
    {
      id: "filter_tournament_jackpot",
      value: props.textosShow.filter_tournament_jackpot || "Jackpot",
      selected: false
    },
    {
      id: "filter_tournament_general_jackpot",
      value: props.textosShow.filter_tournament_general_jackpot || "General Jackpot",
      selected: false
    },
    {
      id: "filter_tournament_referral_contest",
      value: props.textosShow.filter_tournament_referral_contest || "Referral Contest",
      selected: false
    },
    {
      id: "filter_tournament_challenge",
      value: props.textosShow.filter_tournament_challenge || "Challenge",
      selected: false
    },
  ]);

  const handleClickOutside = useCallback((event) => {
    if ( showCalendar && ref.current && !ref.current.contains(event.target)) {
      setShowCalendar(false);
      setFilterDate(filterDates[4]);
    }
  }, [showCalendar]); 

  const increaseTimer = useCallback(() => {
    // console.log('============increaseTimer========', localTimeCount);
    setLocalTimeCount(localTimeCount + 1);
  }, [localTimeCount]);

  const isShowRemainingTime = useCallback((p) => {
    if (p.tiempo == null) return false;

    const countSecond = localTimeCount % 60;
    const countMinute = (localTimeCount / 60) % 60;
    const countHour = (localTimeCount / 60 / 60) % 24;
    const countDay = Math.floor(localTimeCount / 60 / 60 / 24);

    let day = p.tiempo.dias - countDay;
    let hour = p.tiempo.horas - countHour;
    let minute = p.tiempo.minutos - countMinute;
    let second = p.tiempo.segundos - countSecond;

    if (second < 0) {
      second += 60;
      minute -= 1;
    }

    if (minute < 0) {
      minute += 60;
      hour -= 1;
    }

    if(hour < 0) {
      hour += 24;
      day -= 1;
    }

    if (day > 0) {
      return true;
    } else if (day < 0) {
      return false;
    } else {
      return !(hour == 0 && minute == 0 && second == 0);
    }
  }, [localTimeCount]);

  const getRemainingTime = useCallback((p) => {
    if (p.tiempo == null) return '';

    const countSecond = Math.floor(localTimeCount % 60);
    const countMinute = Math.floor((localTimeCount / 60) % 60);
    const countHour = Math.floor((localTimeCount / 60 / 60) % 24);
    const countDay = Math.floor(localTimeCount / 60 / 60 / 24);

    let day = p.tiempo.dias - countDay;
    let hour = p.tiempo.horas - countHour;
    let minute = p.tiempo.minutos - countMinute;
    let second = p.tiempo.segundos - countSecond;

    if (second < 0) {
      second += 60;
      minute -= 1;
    }

    if (minute < 0) {
      minute += 60;
      hour -= 1;
    }

    if(hour < 0) {
      hour += 24;
      day -= 1;
    }

    if (day < 0) return '0d 0:0:0';
    return `${day}d ${hour}:${minute}:${second}`;

  }, [localTimeCount]);

  const getDateRangeTime = useCallback(() => {
    const startDateTime = Math.floor(new Date(startDate).getTime() / 1000);
    const endDateTime = Math.floor(Util.getLastOfDay(endDate).getTime() / 1000);
    
    return {startDateTime, endDateTime};    
  }, [startDate, endDate]);

  useEffect(() => {
    if (props.loggedIn) {
      getPartidas(activePage, closed, activeFilterDate, activeFilterTournament);
    }
  }, []);

  useEffect(() => {
    const timerJackpots = setInterval(() => increaseTimer(), 1000);

    return () => {
      clearInterval(timerJackpots);
    }
  }, [increaseTimer]);

  useEffect(() => {
    if(!handleClickOutside) return;

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);


  const onDateRangeChanged = useCallback((item) => {
    setDateRange({...dateRange, ...item.selection});
    setStartDate(item.selection.startDate);
    setEndDate(item.selection.endDate);
  }, []);

  const getPartidas = (page, isClosed, filterDate, filterTournament) => {
    let myHeaders = props.getHeadersToSend();
    let lan = props.getLanActual();

    var filterStartDateTime = 0;
    var filterEndDateTime = new Date().getTime() / 1000;
    
    props.showLoadingIndicator(true);

    switch (filterDate) {
      case 'filter_date_all':
        filterStartDateTime = 0;
        break;
      
      case 'filter_date_today':
        filterStartDateTime = Util.getToday().getTime() / 1000;
        break;

      case 'filter_date_this_week':
        filterStartDateTime = Util.getFirstOfWeek().getTime() / 1000;
        break;

      case 'filter_date_this_month':
        filterStartDateTime = Util.getFirstOfMonth().getTime() / 1000;
        break;

      case 'filter_date_custom_range':
        const range = getDateRangeTime();
        filterStartDateTime = range.startDateTime;
        filterEndDateTime = range.endDateTime;
        break;
    }

    setPartidas([]);

    fetch(props.urlGlobal + "api/juego", {
      method: "POST",
      body: JSON.stringify({
        action: "getPartidas",
        lan: lan,
        page: page,
        close: isClosed ? 1 : 0,
        filter_start_date: filterStartDateTime,
        filter_end_date: filterEndDateTime,
        filter_tournament: filterTournament,
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.default && result.default.maintenance === true && props.textosShow){
        alert(props.textosShow['siteundermaintenance'] || 'Site Under Maintenance, Please try again later.');
      }
      if (result.success) {
        let curPage = result.page;
        let totalPagesTemp = Math.ceil(result.total / 5);
        let pagesTemp = [];
        let firstPage = (curPage > 4) ? curPage - 4 : 0;
        let lastPage = (curPage + 4 < totalPagesTemp) ? curPage + 4 : totalPagesTemp - 1;

        for (let i = firstPage; i <= lastPage; i++) {
          pagesTemp.push(i);
        }

        setPartidas([...result.partidas]);
        setTotal(result.total);
        setTotalPages(totalPagesTemp);
        setPages(pagesTemp);
        setActivePage(result.page);

        setLocalTimeCount(0);

      }
    }).finally(() => {
      props.showLoadingIndicator(false);
    })
    ;
  }

  const getDateFilterTitle = () => {
    const currentItem = filterDates.find(t => t.selected);
    if (currentItem.id != 'filter_date_custom_range') {
      return currentItem.value;
    } else {
      if (startDate != null && endDate != null) {
        return moment(startDate).format('YYYY/MM/DD') + '-' + moment(endDate).format('YYYY/MM/DD')
      }
      return currentItem.value;
    }
  }  

  const onFilterDateItem = (item) => {
    if (item.id == 'filter_date_custom_range') {
      onBtnFilterDate();
    } else {
      setFilterDate(item);
    }
  }

  const onBtnFilterDate = () => {
    setShowCalendar(true);
  }

  const handleChangePage = (page) => {
    setActivePage(page);
    getPartidas(page, closed, activeFilterDate, activeFilterTournament);
  }

  const setCloseMode = (isClosed) => {
    if (closed != isClosed) {
      setClosed(isClosed);
      getPartidas(activePage, isClosed, activeFilterDate, activeFilterTournament);
    }
  }

  const setFilterDate = (filterDate) => {
    if (filterDate && ((filterDate.id != activeFilterDate) || (filterDate.id == 'filter_date_custom_range'))) {
      let filterDatesTemp = [...filterDates];
      filterDatesTemp.find(t => t.selected).selected = false;
      filterDatesTemp.find(t => t.id === filterDate.id).selected = true;
      
      setFilterDates([...filterDatesTemp]);
      setActiveFilterDate(filterDate.id);

      getPartidas(activePage, closed, filterDate.id, activeFilterTournament);
    }
  }

  const setFilterTournament = (filterTournament) => {
    if (filterTournament && filterTournament.id != activeFilterTournament) {
      let filterTournamentsTemp = [...filterTournaments];
      filterTournamentsTemp.find(t => t.selected).selected = false;
      filterTournamentsTemp.find(t => t.id === filterTournament.id).selected = true;
      
      setFilterTournaments([...filterTournamentsTemp]);
      setActiveFilterTournament(filterTournament.id);

      getPartidas(activePage, closed, activeFilterDate, filterTournament.id);
    }
  };

  const redirectToPartida = (partida) => {
    if (partida.referral) {
      history.replace("/referral-contest/" + partida.id);
    } else if(partida.generaljackpot){
      history.replace("/general-jackpot/" + partida.id);
    } else {
      history.replace("/partida/" + partida.juegoSlug + "/" + partida.sala + "/" + partida.id);
    }
  }

  const canReentry = (partida) => {
    return (partida.salaData && partida.salaData.Modo === "Jackpots" && partida.salaData.Reentry === 1) || 
      (partida.reentry && partida.gameSlug && (partida.salaData && partida.salaData.Modo != 'Ladder' && partida.salaData.Modo != 'Bonanza'));
  }

  const onReentryClick = (e, partida) => {
    e.stopPropagation();
    
    const sala = partida.salaData;

    if (!sala) {
      return;
    }

    if (sala.Modo == 'Spin') {
      showSpin(partida, 1);
    } else {
      reentry(partida);
    }
  }

  const onTryAgainClick = (e, partida) => {
    e.stopPropagation();
    const sala = partida.salaData;
    if (!sala) {
      return;
    }

    if (sala.Modo == 'Spin') {
      showSpin(partida, 0);
    } else {
      tryAgain(partida);
    }
  }

  const showSpin = (partida, reentry) => {
    props.showSpinModal(partida.juegoSlug, partida.salaData.Id, partida.gameSlug, reentry, partida.id);
  }

  const reentry = (partida) => {
    const sala = partida.salaData;

    if (props.loggedIn) {
      if (sala.Modo !== "Jackpots" && sala.Costo === 0 && sala.PremioTotal 
        && !props.userData.hasDeposits) {
        alert(props.textosShow.necesitasdepositarparajugaraljuegogratis || "Necesitas depositar para jugar al juego gratis");
      }
      else if (sala.Modo === "Jackpots" && sala.Costo === 0 && sala.CostoReentry > props.userData.money) {
        alert(props.textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir");
      }
      else if ((sala.Modo !== "Jackpots" || (sala.Modo === "Jackpots" && sala.Costo > 0)) 
                && sala.Costo > props.userData.money) {
        alert(props.textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir");
      }
      else {
        fetch(props.urlGlobal + "api/juego", {
          method: "POST",
          body: JSON.stringify({
            action: "setPartida",
            juego: partida.juegoSlug,
            sala: sala.Id,
            first: 1,
            lan: props.getLanActual(),
            reentry: 1,
            partida: partida.id
          }),
          headers: props.getHeadersToSend()
        }).then(function(response) {
          return response.json();
        }).then(function(result) {
          if (result.success) {
            window.location = props.urlGlobal + "playGame/" + partida.gameSlug + "/?gameMode=" + sala.Id + "&partida=" + partida.id + "&game=" + partida.juegoSlug;
          }
          else {
            alert(result.msg);
          }
        });
      }
    }
    else {
      alert(props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta.");
    }
  }

  const tryAgain = (partida) => {
    const sala = partida.salaData;

    if (props.loggedIn) {
      fetch(props.urlGlobal + "api/juego", {
        method: "POST",
        body: JSON.stringify({
          action: "setPartida",
          juego: partida.juegoSlug,
          sala: sala.Id,
          first: 1,
          lan: props.getLanActual(),
          reentry: 0,
          partida: partida.id
        }),
        headers: props.getHeadersToSend()
      }).then(function(response) {
        return response.json();
      }).then(function(result) {
        if (result.success) {
          window.location = props.urlGlobal + "playGame/" + partida.gameSlug + "/?gameMode=" + sala.Id + "&partida=" + partida.id + "&game=" + partida.juegoSlug;
        }
        else {
          alert(result.msg);
        }
      });
    }
    else {
      alert(props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta.");
    }
  };

  const getResultImage = (result) => {
    if (result === "wait") {
      return <img src={waitIcon} alt="Wait" />;
    }
    else if (result === "win") {
      return <img src={winIcon} alt="Win" />;
    }
    else if (result === "lose") {
      return null;
    }
    else {
      return null;
    }
  };

  if (!props.loggedIn) {
    return (<Redirect to={'/'} />);
  }

  return (
    <div>
        <div className='content' id='game_history'>
          <div className='wrapper'>
            <div className='smoke_center'></div>
            <div className='page_content'>
              <div className='c_center'>
                <div className='c_top'>
                  <div className='c_bottom'>
                    <Eagle></Eagle>
                    <Leaves></Leaves>
                    <h4>
                      {props.textosShow.historicodejuegos || "Histórico de juegos"}
                    </h4>
                    <div className='page_block'>
                      <div className='filter-bar'>
                        <button className={`btn-status-tab ${!closed ? 'tab-active' : 'tab-deactive'}`} onClick={()=> setCloseMode(false)}>
                          {props.textosShow.ongoing || "Ongoing"}
                        </button>
                        <button className={`btn-status-tab ${closed ? 'tab-active' : 'tab-deactive'}`} style={{marginLeft: 4}} onClick={()=> setCloseMode(true)}>
                          {props.textosShow.closed || "Closed"}
                        </button>
                    
                        <div className='right-container'>
                          {<div className='select'>
                            <button className='main-item'>
                              {
                                getDateFilterTitle()
                              }
                            </button>
                            <ul className="sub-menu lang_menu filter_date animation">
                              <li>                                
                                {
                                  filterDates.map(t =>
                                    <span 
                                      className="link-button"
                                      key={t.id}
                                      onClick={() => onFilterDateItem(t)}>
                                      {t.value}
                                    </span>
                                  )
                                }
                              </li>
                            </ul>
                          </div>}

                          <div className='select'>
                            <button className='main-item'>
                              {
                                filterTournaments.find(t => t.selected).value
                              }
                            </button>
                            <ul className="sub-menu lang_menu animation">
                              <li>
                                {
                                  filterTournaments.map(t =>
                                    <span 
                                      className="link-button"
                                      key={t.id}
                                      onClick={() => setFilterTournament(t)}>
                                      {t.value}
                                    </span>
                                  )
                                }
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>

                      <div className='date-container' ref={ref}>
                        { showCalendar &&
                          <DateRangePicker
                            className='date-ranger'
                            onChange={(item) => {
                              onDateRangeChanged(item)
                            }}
                            minDate={addDays(new Date(), -300)}
                            maxDate={addDays(new Date(), 900)}
                            direction="vertical"
                            scroll={{ enabled: true }}
                            ranges={[dateRange]}
                          />
                        }
                      </div>

                      <div className='table_list'>
                        <table className="table">
                          <thead>
                            <tr className='subtitle'>
                              <td className='t_game_icon'>
                                <span>
                                  {props.textosShow.juego || "Juego"}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {props.textosShow.fechayhora || "Fecha y hora"}
                                </span>
                              </td>
                              <td className='th-costo'>
                                <span>
                                  {props.textosShow.costedeentradamin || "Coste de entrada"}
                                </span>
                              </td>
                              <td className='th-costo'>
                                <span>
                                  {props.textosShow.premioalganador || "Premio al ganador"}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {props.textosShow.estado || "Estado"}
                                </span>
                              </td>
                              { !closed && 
                                <td className='th-reentry'>
                                  <span>
                                    {props.textosShow.reentrar || "Reentrar"}
                                  </span>
                                </td>
                              }
                              
                            </tr>
                          </thead>
                          <tbody>
                            {
                              partidas.map(p =>
                                <tr key={p.id}
                                    onMouseOver={() => setHoverEffectId(p.id)}
                                    onMouseOut={() => setHoverEffectId(-1)}
                                    onClick={() => redirectToPartida(p)}>
                                  <td className='t_game_icon'>
                                    <span>
                                      {
                                        p.referral ? (
                                          <img 
                                            src={trumpet}
                                            alt=""
                                            style={{
                                              height: 48,
                                              width: 48
                                            }} />
                                        ) : ( 
                                          p.generaljackpot ? (
                                            <img 
                                            src={boxRedIcon}
                                            alt=""
                                            style={{
                                              height: 32,
                                              width: 32
                                            }} />
                                          ) : (
                                            <img 
                                              src={p.fotoJuego}
                                              alt={p.juegoSlug} />
                                          )
                                        )
                                      }
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {p.fecha}
                                    </span>
                                  </td>
                                  <td className='entry_pay'>
                                    <span>
                                      {props.defaultData.moneda.signo} {p.costo}
                                    </span>
                                  </td>
                                  <td className='winner_award'>
                                    <span>
                                      {props.defaultData.moneda.signo} {p.premio}
                                    </span>
                                  </td>
                                  <td className='td-status'>
                                    <span className='t_status_icon'>
                                      {getResultImage(p.result)}
                                    </span>
                                    { !closed && p.tiempo && isShowRemainingTime(p) &&
                                      <div className="remaining_time">
                                        <span className="iconTimeRemaining pr-1">
                                          <FontAwesomeIcon
                                            icon={faHourglassHalf}
                                            size="lg" />
                                        </span>
                                        <span >
                                          {getRemainingTime(p)}
                                        </span>
                                      </div>
                                    }
                                  </td>
                                  <td className='tb-reentry'>
                                    <span>
                                      { canReentry(p) && 
                                        <button 
                                          className='button button_reentry'
                                          onClick={(e) => onReentryClick(e, p)}
                                          style={{
                                            marginLeft: '10px'
                                          }}>
                                          {
                                            (p.salaData.CostoReentry && parseFloat(p.salaData.CostoReentry) > 0) ? (
                                              (props.textosShow.reentrarpor || "Reentrar por") + " " + props.defaultData.moneda.signo + p.salaData.CostoReentry
                                            ) : (
                                              (props.textosShow.reentrar || "Reentrar")
                                            )
                                          }
                                        </button>
                                      }
                                      { (!p.reentry && p.tryAgain) && 
                                        <button 
                                          className='button button_reentry'
                                          onClick={(e) => onTryAgainClick(e, p)}
                                          style={{
                                            marginLeft: '10px'
                                          }}>
                                          {props.textosShow.reentrar || "Reentrar"}
                                        </button>
                                      }
                                    </span>
                                  </td>
                                  <div 
                                    className={ p.id == hoverEffectId ? 'effect effect-active' : 'effect' }
                                    >

                                    </div>
                                </tr>
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className='paginator'>
                        {
                          (activePage > 0) ?
                            <span className='link-button prev'
                                  onClick={() => handleChangePage(activePage - 1)}></span> :
                            null
                        }
                        {
                          (pages.length && pages[0] > 0) ?
                            <span className='link-button' 
                                  onClick={() => handleChangePage(0)}>
                              1
                            </span> :
                            null
                        }
                        {
                          (pages.length && pages[0] > 0) ?
                            <span>
                              ...
                            </span> :
                            null
                        }
                        {
                          pages.map(page =>
                            <span className='link-button'
                                  id={(page === activePage) ? 'page_active' : ''}
                                  onClick={() => handleChangePage(page)}
                                  key={page}>
                              {page + 1}
                            </span>
                          )
                        }
                        {
                          (pages.length && pages[pages.length - 1] < totalPages - 1) ?
                            <span>
                              ...
                            </span> :
                            null
                        }
                        {
                          (pages.length && pages[pages.length - 1] < totalPages - 1) ?
                            <span className='link-button' 
                                  onClick={() => handleChangePage(totalPages - 1)}>
                              {totalPages}
                            </span> :
                            null
                        }
                        {
                          (activePage < totalPages - 1) ?
                            <span className='link-button next'
                                  onClick={() => handleChangePage(activePage + 1)}></span> :
                            null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default GameHistory;