import React, { Component } from 'react';

class Fire extends Component {
  
  render() {
    return (
      <div className="fire"></div>
    );
  }
  
};

export default Fire;