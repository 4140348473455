import React, { Component } from 'react';

import $ from 'jquery';

class ToTop extends Component {
  
  componentDidMount() {
    document.addEventListener("scroll", this.onScroll, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll, false);
  }
  
  toTop() {
    $('body,html').animate({
      scrollTop: 0
    }, 800);
  }
  
  onScroll() {
    if ($(window).scrollTop() !== 0) {
      $('.toTop').fadeIn();
    } 
    else {
      $('.toTop').fadeOut();
    }
  }
  
  render() {
    return (
      <div className="toTop"
           onClick={this.toTop.bind()}>
        <span>
          {this.props.textosShow.irarriba || "Ir arriba"}
        </span>
      </div>
    );
  }
  
};

export default ToTop;