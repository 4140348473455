import React, { Component } from 'react';
import { Link, withRouter, } from "react-router-dom";
import $ from 'jquery';

import RegisterModal from './RegisterModal';
import RecoverModal from './RecoverModal';
import RecoverModalOk from './RecoverModalOk';
import Language from './Language';
import LogoBig from './LogoBig';
import Jackpot from './Jackpot';
import WithdrawModal from './WithdrawModal';
import AccountActivatedModal from './AccountActivatedModal';

import icon from '../../img/icon_small.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import GeneralJackpotsHeader from './GeneralJackpotsHeader';

import Util from '../utils/utils';
import MembershipUtil from '../utils/membership';

import commentIcon from '../../img/comment.png';
import ObjectivesLogo from '../../img/objective.png';
import ladderIcon from '../../img/ladder.png';
import castillitoIcon from '../../img/spin/castillito-thumb.png';
import boxRedIcon from '../../img/boxRedIcon.png';
import gem from '../../img/gem.png';
import ProfileAndLevel from './ProfileAndLevel';

class Header extends Component {

  constructor(props) {
    super(props);
    this.toggleBlog = this.toggleBlog.bind(this);
  }

  componentDidMount() {
    document.addEventListener("scroll", this.onScroll, false);
  };

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll, false);
  };

  openRegisterModal(e) {
    e.preventDefault();
    $("#register_modal_overlay").fadeIn("fast", function () {
      $("#register_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  openReferralModal(e) {
    e.preventDefault();
    $("#referral_modal_overlay").fadeIn("fast", function () {
      $("#referral_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  openAccountActivatedModal() {
    this.props.history.replace('/register_successful');
    $("#activated_modal_overlay").fadeIn("fast", function () {
      $("#activated_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  openRecoverModal(e) {
    e.preventDefault();
    $("#forgot_modal_overlay").fadeIn("fast", function () {
      $("#forgot_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  openWithdrawModal() {
    $("#withdraw_modal_overlay").fadeIn("fast", function () {
      $("#withdraw_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  showHowToLevelModal() {
    $("#how_to_level_modal_overlay").fadeIn("fast", function () {
      $("#how_to_level_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  toggleMenu() {
    $(".toggle-button").toggleClass('button-open');
    $('.menu-wrap').toggleClass('menu-show');
  };

  onScroll() {
    if ($(this).scrollTop() > 96) {
      $('.top-panel').addClass('top-fixed');
      $('.blog-panel').addClass('blog-fixed');
    }
    else {
      $('.top-panel').removeClass('top-fixed');
      $('.blog-panel').removeClass('blog-fixed');
    }
  };

  isIndex() {
    return (window.location.pathname === "/" || window.location.pathname === "/register_successful");
  };

  aceptarDesafio(desafio) {
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(clase.props.urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "aceptarDesafio",
        id: desafio.id,
        lan: lan
      }),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        window.location = clase.props.urlGlobal + "playGame/" + desafio.sala.slug + "/?gameMode=" + desafio.sala.id + "&partida=" + result.partida_id + "&game=" + desafio.sala.juego;
      }
      else {
        alert(result.msg);
      }
    });
  };

  rechazarDesafio(desafio) {
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(clase.props.urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "rechazarDesafio",
        id: desafio.id,
        lan: lan
      }),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        alert(result.msg);
      }
      else {
        alert(result.msg);
      }
    });
  };

  jugarDesafio(desafio) {
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(clase.props.urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "jugarDesafio",
        id: desafio.id,
        lan: lan
      }),
      headers: myHeaders
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        window.location = clase.props.urlGlobal + "playGame/" + desafio.sala.slug + "/?gameMode=" + desafio.sala.id + "&partida=" + desafio.partida_id + "&game=" + desafio.sala.juego;
      }
      else {
        alert(result.msg);
      }
    });
  };

  getMembershipText(membership) {
    let membershipSlug = 'None';
    if (membership) {
      membershipSlug = membership["membershipSlug"];
    }

    return `You are a ${this.props.textosShow[membershipSlug] || 'None'} membership user`;
  }

  toggleBlog() {
    this.props.toggleBlog();
  }

  render() {
    if (!this.props.loggedIn) {
      return (
        <header>
          <RegisterModal
            urlGlobal={this.props.urlGlobal}
            marketingData={this.props.marketingData}
            textosShow={this.props.textosShow}
            saveLoginStorage={this.props.saveLoginStorage.bind(this)}
            getDataUser={this.props.getDataUser.bind(this)}
            getHeadersToSend={this.props.getHeadersToSend}
            getLanActual={this.props.getLanActual}
            openAccountActivatedModal={this.openAccountActivatedModal.bind(this)}
            referral={this.props.referral}></RegisterModal>
          <RecoverModal
            urlGlobal={this.props.urlGlobal}
            textosShow={this.props.textosShow}
            getHeadersToSend={this.props.getHeadersToSend}
            getLanActual={this.props.getLanActual}></RecoverModal>
          <RecoverModalOk textosShow={this.props.textosShow}></RecoverModalOk>
          <div className='top_menu top-panel'>
            <div className='wrapper'>
              <Language
                idiomas={this.props.idiomas}
                lan={this.props.lan}
                selectLan={this.props.selectLan.bind(this)}
                urlGlobal={this.props.urlGlobal} />
              <Link to="/">
                <div className='game_icon'>
                  <img
                    src={icon}
                    alt="DuelCash" />
                </div>
              </Link>
              <div id='menu_icon'>
                <span
                  className="toggle-button"
                  onClick={this.toggleMenu.bind()}>
                  <div className="menu-bar menu-bar-top"></div>
                  <div className="menu-bar menu-bar-bottom"></div>
                </span>
              </div>
              <div className='menu-wrap'>
                <div className='profile'>
                  <form
                    className='login_panel pl-1'
                    id="loginForm">
                    <input
                      type="hidden"
                      name="action"
                      value="Login" />
                    <div id='login'>
                      <input
                        type="text"
                        placeholder="E-mail"
                        name="email" />
                    </div>
                    <div id='password'>
                      <input
                        type="password"
                        placeholder={this.props.textosShow.contrasenia || 'Contraseña'}
                        name="pass" />
                    </div>
                    <button
                      className='button login_button'
                      onClick={this.props.Login.bind()}>
                      {this.props.textosShow.iniciarsesion || 'Iniciar Sesión'}
                    </button>
                    <span>
                      {this.props.textosShow.o || 'O'}
                    </span>
                    <button
                      className='button register_button'
                      onClick={this.openRegisterModal.bind(this)}>
                      {this.props.textosShow.registrarme || 'Registrarme'}
                    </button>
                    <div className='forgot_password'>
                      <span
                        onClick={this.openRecoverModal.bind(this)}
                        className="link-button">
                        {this.props.textosShow.olvidastesclave || 'Olvidó su contraseña?'}
                      </span>
                    </div>
                  </form>
                  <GeneralJackpotsHeader
                    referralContests={this.props.userData.referralContests}
                    generalJackpots={this.props.userData.generalJackpots}
                    textosShow={this.props.textosShow}
                    loggedIn={this.props.loggedIn}
                    generaljackpotModalElement={this.props.generaljackpotModalElement}
                    referralContestModalElement={this.props.referralContestModalElement}
                  />
                </div>
              </div>
            </div>
          </div>
          {
            this.isIndex() ?
              <LogoBig></LogoBig>
              :
              null
          }
          {
            this.isIndex() ?
              <>
                <Jackpot textosShow={this.props.textosShow}>
                  <button
                    className='button register_button register-zoom shadow w-25 d-none d-lg-block mx-auto'
                    onClick={this.openRegisterModal.bind(this)}>
                    {this.props.textosShow.registrarme || 'Registrarme'}
                  </button>
                </Jackpot>
                <button
                  className='button register_button shadow register-zoom w-75 mt-4 d-block d-sm-none mx-auto'
                  onClick={this.openRegisterModal.bind(this)}>
                  {this.props.textosShow.registrarme || 'Registrarme'}
                </button>
                <button
                  className='button register_button shadow register-zoom w-50 mt-4 d-none d-sm-block d-lg-none mx-auto'
                  onClick={this.openRegisterModal.bind(this)}>
                  {this.props.textosShow.registrarme || 'Registrarme'}
                </button>
              </> : null
          }
        </header>
      );
    }
    else {
      return (
        <header>
          <AccountActivatedModal
            textosShow={this.props.textosShow}
            openTutorialStep1={this.props.openTutorialStep1.bind(this)}></AccountActivatedModal>
          <WithdrawModal
            urlGlobal={this.props.urlGlobal}
            textosShow={this.props.textosShow}
            saveLoginStorage={this.props.saveLoginStorage.bind(this)}
            getDataUser={this.props.getDataUser.bind(this)}
            defaultData={this.props.defaultData}
            userData={this.props.userData}
            getHeadersToSend={this.props.getHeadersToSend.bind(this)}
            getLanActual={this.props.getLanActual}></WithdrawModal>
          <div className='top_menu top-panel'>
            <div className='wrapper'>
              <Language
                idiomas={this.props.idiomas}
                lan={this.props.lan}
                selectLan={this.props.selectLan.bind(this)}
                urlGlobal={this.props.urlGlobal} />
              {!(this.props.userData.banned) && (this.props.userData.membership && this.props.userData.membership.membershipLevel > 0) &&
                <div className={`blog-toggle ${this.props.blogOpen ? 'blog-toggle-open' : ''}`}
                  onClick={this.toggleBlog}>
                  <img src={commentIcon} />
                  {this.props.unreadBlogCommentCnt > 0 &&
                    <span>{this.props.unreadBlogCommentCnt}</span>
                  }
                </div>}
              <Link to="/">
                <div className='game_icon'>
                  <img
                    src={icon}
                    alt="DuelCash" />
                </div>
              </Link>
              <div id='menu_icon'>
                <span
                  className="toggle-button"
                  onClick={this.toggleMenu.bind()}>
                  <div className="menu-bar menu-bar-top"></div>
                  <div className="menu-bar menu-bar-bottom"></div>
                </span>
              </div>
              <div className='menu-wrap'>
                <div className='profile'>
                  <div className='account_panel'>
                    <div className='balance'>
                      <div className='mt_block step_2'>
                        <div className='step'>
                          2
                        </div>
                        <h6>
                          {this.props.textosShow.paso || "Paso"} 2
                        </h6>
                        {this.props.textosShow.esteestudinero || "Este es tu dinero, recarga tu saldo para competir por efectivo"}
                        <div className='notif_buttons'>
                          <button
                            className="button green_button"
                            onClick={this.props.openTutorialStep3.bind(this)}>
                            Ok
                          </button>
                        </div>
                      </div>
                      <span data-tip data-for="deposit-tooltip" onMouseOver={() => this.setState({ isPopoverOpen: true })} onMouseOut={() => this.setState({ isPopoverOpen: false })}>
                        {this.props.defaultData.moneda.signo}{this.props.userData.money}
                      </span>
                      <ReactTooltip id="deposit-tooltip" className="text-center">
                        <div>
                          {this.props.textosShow.referralwon || "Referral Won"}
                        </div>
                        <div>
                          <b>
                            {this.props.defaultData.moneda.signo} {this.props.userData.referralEarnings}
                          </b>
                        </div>
                      </ReactTooltip>
                      <Link to="/deposit">
                        <button className='button deposit_button'>
                          <span className="d-none d-sm-block px-2">
                            {this.props.textosShow.depositar || 'Depositar'}
                          </span>
                          <span className="d-block d-sm-none px-2">
                            <FontAwesomeIcon icon={faShoppingCart} />
                          </span>
                        </button>
                      </Link>
                      {
                        this.props.userData.hasActiveMembership ? 
                        <>
                          <button className="button deposit_button" data-tip data-for="subscription-tooltip" >You are already a premium user</button>                      
                          <ReactTooltip id="subscription-tooltip" className="text-center">
                            <div>
                              {this.props.userData.membershipExpDate}
                            </div>
                          </ReactTooltip>
                        </> : <Link to="/subscription">
                            <button className='button subscription_button'>
                              <span className="d-none d-sm-block px-2">
                                {this.props.textosShow.vip || 'VIP'}
                              </span>
                              <span className="d-block d-sm-none px-2">
                                <FontAwesomeIcon icon={faShoppingCart} />
                              </span>
                            </button>
                      </Link>
                      }
                    </div>
                    <div className='balance rewards'>
                      <div className='mt_block step_3'>
                        <div className='step'>
                          3
                        </div>
                        <h6>
                          {this.props.textosShow.paso || "Paso"} 3
                        </h6>
                        {this.props.textosShow.esteestusaldodegemas || "Este es su saldo de gemas, gane dinero jugando gratis (no se requiere depósito)"}
                        <div className='notif_buttons'>
                          <button
                            className="button green_button"
                            onClick={this.props.closeTutorialStep3.bind(this)}>
                            Ok
                          </button>
                        </div>
                      </div>
                      <Link to="/rewards">
                        {this.props.userData.gemas} {this.props.textosShow.gemas || 'Gemas'}
                      </Link>
                    </div>
                    <div className='notifications'>
                      <button className='duels main-item'>
                        {
                          (this.props.userData.desafios && this.props.userData.desafios.length > 0) ?
                            <span>
                              {this.props.userData.desafios.length}
                            </span> :
                            null
                        }
                      </button>
                      <ul className="sub-menu profile_menu">
                        <li>
                          {
                            this.props.userData.desafios ?
                              this.props.userData.desafios.map(desafio =>
                                <div
                                  className='notification_item challenge'
                                  key={desafio.id}>
                                  {
                                    (desafio.estado === 'P') ?
                                      <div>
                                        <span style={{
                                          color: "#fff",
                                          fontWeight: "bold",
                                          background: "none !important"
                                        }}>
                                          {desafio.desafiante}
                                        </span> {this.props.textosShow.tereto || 'te retó'}
                                        <br />
                                        {this.props.textosShow.aganar || 'a ganar'}

                                        {desafio.sala.modo === 'Ladder' &&
                                          <b className='challenge-ladder'>
                                            {` ${this.props.textosShow.ladder || 'ladder'}, `}
                                          </b>
                                        }

                                        { desafio.sala.modo === 'Bonanza' && 
                                          <b className='challenge-bonanza'>
                                            {` ${this.props.textosShow.bonanza || 'bonanza'}, `}
                                          </b> 
                                        }

                                        <b>
                                          {this.props.defaultData.moneda.signo}{desafio.sala.premio}
                                        </b>

                                        {this.props.textosShow.en || 'en'} ({desafio.sala.juego})
                                        <div className='notif_buttons'>
                                          <button
                                            className="button green_button"
                                            onClick={() => this.aceptarDesafio(desafio)}>
                                            {this.props.textosShow.aceptar || 'Aceptar'}
                                          </button>
                                          { (desafio.sala.modo !== 'Ladder' && desafio.sala.modo !== 'Bonanza') && 
                                            <button
                                              className="button bordered_button"
                                              onClick={() => this.rechazarDesafio(desafio)}>
                                              {this.props.textosShow.rechazar || 'Rechazar'}
                                            </button>
                                          }
                                        </div>
                                      </div> :
                                      null
                                  }
                                  {
                                    (desafio.estado === 'A') ?
                                      <div>
                                        <span style={{
                                          color: "#fff",
                                          fontWeight: "bold",
                                          background: "none !important"
                                        }}>
                                          {desafio.desafiado}
                                        </span> {this.props.textosShow.aceptotureto || 'aceptó tu reto'}
                                        <br />

                                        {desafio.sala.modo === 'Ladder' &&
                                          <b className='challenge-ladder'>
                                            {this.props.textosShow.ladder || 'ladder'} {this.props.textosShow.desafiar || 'Challenge'}
                                          </b>
                                        }

                                        {this.props.textosShow.en || 'en'} ({desafio.sala.juego})
                                        <div className='notif_buttons'>
                                          <button
                                            className="button green_button"
                                            onClick={() => this.jugarDesafio(desafio)}>
                                            {this.props.textosShow.jugar || 'Jugar'}
                                          </button>
                                        </div>
                                      </div> :
                                      null
                                  }
                                </div>
                              ) :
                              null
                          }
                          <div className='show_more'>
                            <button className="link-button">
                              {this.props.textosShow.vermas || 'Ver más'}
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <Link to="/objectives">
                      <div className='notifications' >
                        <button className='referral-contests'
                          data-for="objectiveTooltip"
                          data-tip>
                          {this.props.objectiveNotificationCount > 0 ? <span>{this.props.objectiveNotificationCount}</span> : ''}
                          <img
                            style={{ width: '35px' }}
                            src={ObjectivesLogo}
                          />
                        </button>
                        <ReactTooltip id="objectiveTooltip">
                          <p>
                            {this.props.textosShow.objectives || 'Objectives '}
                          </p>
                        </ReactTooltip>
                      </div>
                    </Link>
                    <Link to="/ladders">
                      <div className='notifications' >
                        <button className='referral-contests'
                          data-for="laddersTooltip"
                          data-tip>
                          {this.props.userData.ladderCnt > 0 ? <span>{this.props.userData.ladderCnt}</span> : ''}
                          <img
                            style={{ width: '35px' }}
                            src={ladderIcon}
                          />
                        </button>
                        <ReactTooltip id="laddersTooltip">
                          <p>
                            {this.props.textosShow.ladder || 'Ladders '}
                          </p>
                        </ReactTooltip>
                      </div>
                    </Link>
                    <Link to="/spins">
                      <div className='notifications' >
                        <button className='referral-contests'
                          data-for="spinTooltip"
                          data-tip>
                          {this.props.userData.spinCnt > 0 ? <span>{this.props.userData.spinCnt}</span> : ''}
                          <img
                            style={{ width: '35px' }}
                            src={castillitoIcon}
                          />
                        </button>
                        <ReactTooltip id="spinTooltip">
                          <p>
                            {this.props.textosShow.spintitle || 'Spin '}
                          </p>
                        </ReactTooltip>
                      </div>
                    </Link>
                    <Link to="/freerolls">
                      <div className='notifications'>
                        <button 
                          className='referral-contests' 
                          data-for="freerollsTooltip" 
                          data-tip>
                          {
                            this.props.userData.freerollsCount > 0 ? (
                              <span>
                                {this.props.userData.freerollsCount}
                              </span>
                            ) : (
                              null
                            )
                          }
                          <img 
                            height="30px"
                            alt=""
                            src={boxRedIcon} />
                        </button>
                        <ReactTooltip id="freerollsTooltip">
                          <p>
                            {this.props.textosShow.freerollstitle || 'Freerolls'}
                          </p>
                        </ReactTooltip>
                      </div>
                    </Link>
                    <GeneralJackpotsHeader
                      referralContests={this.props.userData.referralContests}
                      generalJackpots={this.props.userData.generalJackpots}
                      textosShow={this.props.textosShow}
                      loggedIn={this.props.loggedIn}
                      generaljackpotModalElement={this.props.generaljackpotModalElement}
                      referralContestModalElement={this.props.referralContestModalElement}
                    />
                    <div className='nickname'>
                      <button className='main-item'>
                        <img
                          src={MembershipUtil.getMembershipIcon(this.props.userData.membership)}
                          alt={this.props.userData.name} 
                          data-for="membership-button-header"
                          data-tip />
                        <ProfileAndLevel 
                          className='mr-1' 
                          textosShow={this.props.textosShow}
                          userData={this.props.userData}
                          mini={true}
                        />
                        <p>{this.props.userData.name}</p>
                      </button>
                      <ReactTooltip id="membership-button-header">
                        <p>
                          {this.getMembershipText(this.props.userData.membership)}
                        </p>
                      </ReactTooltip>
                      <ul className="sub-menu profile_menu">
                        <li>
                          <div className='profile_data d-flex flex-columns'>
                            <a href="/profile">
                              <ProfileAndLevel  
                                textosShow={this.props.textosShow}
                                userData={this.props.userData}
                              />
                            </a>
                            <div style={{ textAlign: "left" }}>
                              <a href="/profile">
                                <h5>
                                  {this.props.userData.name}
                                </h5>
                                <span style={{ color: "white", float: "left" }} data-test="email-menu">{this.props.userData.email}</span>
                              </a>
                              <br/>
                              <Link to="#" className="highlight" onClick={this.showHowToLevelModal.bind(this)}>
                                  {this.props.textosShow.howincreselevel || 'How Increase Level?'}
                              </Link>
                            </div>
                          </div>
                          <hr />
                          { this.props.userData.freeUser ||
                            <span
                              className="link-button"
                              onClick={this.openWithdrawModal.bind(this)}>
                              {this.props.textosShow.retiro || 'Retiro'}
                            </span>
                          }
                          <Link to="/referral" className="highlight">
                            {this.props.textosShow.new || 'New'} {this.props.textosShow.referidos || 'Referidos'}
                          </Link>
                          <Link to="/membership" className="highlight">
                            {this.props.textosShow.membership || 'Membership'}
                          </Link>
                          <Link to="/objectives" >
                            {this.props.textosShow.objectives || 'Objectives'}
                          </Link>
                          {/* <Link to="/friends" >
                            {this.props.textosShow.Friends || 'Friends'}
                          </Link> */}
                          <Link to="/history">
                            {this.props.textosShow.historialdejuegos || 'Historial de Juegos'}
                          </Link>
                          <Link to="/transactions">
                            {this.props.textosShow.historicotransacciones || 'Historial de Transacciones'}
                          </Link>
                          <Link to="/support">
                            {this.props.textosShow.soporte || 'Soporte'}
                          </Link>
                          <Link to="/promoCode">
                            {this.props.textosShow.promocode || 'Promo Code'}
                          </Link>
                          <hr />
                          <Link to="/perfil">
                            {this.props.textosShow.perfilyajustes || 'Perfil y Ajustes'}
                          </Link>
                          {this.props.userData.canWinGemsValidatingEmail && (
                            <>
                              <hr />
                              <Link to="/validate_email">
                                {this.props.textosShow.ganargemasvalidandoemail || 'Win Gems'}
                                <img src={gem} alt="gem" className='menu-gems-icon' />
                              </Link>
                            </>
                          )}
                          {/* <a href="/profile" >
                            {this.props.textosShow.Profile || 'Profile'}
                          </a> */}
                          <hr />
                          <span
                            onClick={this.props.Logout.bind(this)}
                            className="link-button">
                            {this.props.textosShow.cerrarsesion || 'Cerrar Sesión'}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            this.isIndex() ? <LogoBig></LogoBig> : null
          }
          {
            this.isIndex() ? <Jackpot textosShow={this.props.textosShow}></Jackpot> : null
          }
        </header>
      );
    };
  };
};

export default withRouter(Header);
