import React, { Component } from 'react';

class Language extends Component{

  constructor(props) {
    super(props);
    this.state = {
      contHide: "hide"
    };
    this.selectLan = this.selectLan.bind(this);
  }

  toggleContent() {
    let contHide = (this.state.contHide === "hide") ? "show" : "hide";
    this.setState({
      contHide: contHide
    });
  }

  selectLan(ind) {
    this.props.selectLan({ind});
  }

  render() {
    return(
      <div className='lang'>
        <button className='main-item d-flex'>
          <img src={this.props.lan.img} 
               alt={this.props.lan.nombre} />
          <span className="d-none d-md-block">
            {this.props.lan.nombre}
          </span>
        </button>
        <ul className="sub-menu lang_menu">
          <li>
            {
              this.props.idiomas.map(item =>
                <span key={item.ind}
                        onClick={this.selectLan.bind(this, item.ind)}
                        className="link-button">
                  <img src={item.img} 
                       alt={item.nombre} />
                  
                  {item.nombre}
                </span>
              )
            }
          </li>
        </ul>
      </div>
    );
  }
}

export default Language;