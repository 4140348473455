import React, { Component } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Row, Col, } from 'reactstrap';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import $ from 'jquery';

import Ellipse1 from '../../img/profile/Ellipse-1.png';
import logo from '../../img/logo_big.png';

import Icon1 from '../../img/profile/icon1.png';
import Icon3 from '../../img/profile/icon3.png';
import Crowns from '../../img/profile/crowns.png';

import EditIcon from '../../img/profile/edit.png';

import ArrowRight from '../../img/profile/arrow-right.png';
import moment from 'moment';
import ProfilePopUp from "../components/ProfilePopUp";
import NewsAndUpdatePopup from "../components/NewsAndUpdatePopup";
import defaultImage from '../../img/no-image.png';

export default class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: null,
            cliente: {},
            friends: [],
            wonGames: [],
            recentDefeats: [],
            clienteData: null,
            newsData: null
        };
    };

    getUserById(userId) {
        let data = {
            action: "getUserById",
            "clienteId": userId,
            lan: this.props.getLanActual()
        }
        let myHeaders = this.props.getHeadersToSend();
        let clase = this;
        fetch(this.props.urlGlobal + "api/user", {
            method: "POST",
            body: JSON.stringify(data),
            headers: myHeaders
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.success) {
                clase.setState({
                    clienteData: result.user
                });
            }
            else {
            }
        });
    }

    /**
     * Get cliente details and recent friends
     * 
     * @param {*} userId 
     */
    getRecentFriendRequests(userId) {
        this.props.showLoadingIndicator(true)
        let className = this;
        fetch(this.props.urlGlobal + "api/friendRequest", {
            method: "POST",
            body: JSON.stringify({
                action: "getRecentFriendRequests",
                lan: this.props.getLanActual(),
                clienteId: userId
            }),
            headers: this.props.getHeadersToSend()
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.success) {
                className.props.showLoadingIndicator(false)
                console.log("result", result)
                className.setState({ cliente: result.cliente })
                className.setState({ friends: result.requests })
            }
            else {
            }
        });
    };

    /**
     * Get last on games
     * 
     * @param {*} userId 
     */
    getLastWonGames(userId) {
        this.props.showLoadingIndicator(true)
        let className = this;
        fetch(this.props.urlGlobal + "api/friendRequest", {
            method: "POST",
            body: JSON.stringify({
                action: "getLastWonGames",
                lan: this.props.getLanActual(),
                clienteId: userId
            }),
            headers: this.props.getHeadersToSend()
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.success) {
                className.props.showLoadingIndicator(false)
                console.log("result", result)
                className.setState({ wonGames: result.data })
            }
            else {
            }
        });
    };

    /**
     * Get recent defeats
     * 
     * @param {*} userId 
     */
    getRecentDefeats(userId) {
        this.props.showLoadingIndicator(true)
        let className = this;
        fetch(this.props.urlGlobal + "api/friendRequest", {
            method: "POST",
            body: JSON.stringify({
                action: "getRecentDefeats",
                lan: this.props.getLanActual(),
                clienteId: userId
            }),
            headers: this.props.getHeadersToSend()
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.success) {
                className.props.showLoadingIndicator(false)
                console.log("result", result)
                className.setState({ recentDefeats: result.data })
            }
            else {
            }
        });
    };

    getHonourSummary(userId) {
        this.props.showLoadingIndicator(true)
        let className = this;
        fetch(this.props.urlGlobal + "api/friendRequest", {
            method: "POST",
            body: JSON.stringify({
                action: "getHonourSummary",
                lan: this.props.getLanActual(),
                clienteId: userId
            }),
            headers: this.props.getHeadersToSend()
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.success) {
                className.props.showLoadingIndicator(false)
                console.log("result", result)
                className.setState({ honourSummary: result.data })
            }
            else {
            }
        });
    };

    getAwards(userId) {
        this.props.showLoadingIndicator(true)
        let className = this;
        fetch(this.props.urlGlobal + "api/friendRequest", {
            method: "POST",
            body: JSON.stringify({
                action: "getAwards",
                lan: this.props.getLanActual(),
                clienteId: userId
            }),
            headers: this.props.getHeadersToSend()
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.success) {
                className.props.showLoadingIndicator(false)
                console.log("result", result)
                className.setState({ awards: result.data })
            }
            else {
            }
        });
    };

    getNewsAndUpdates() {
        this.props.showLoadingIndicator(true)
        let className = this;
        fetch(this.props.urlGlobal + "api/newsAndUpdates", {
            method: "POST",
            body: JSON.stringify({
                action: "getNewsAndUpdates",
                lan: this.props.getLanActual()
            }),
            headers: this.props.getHeadersToSend()
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.success) {
                className.props.showLoadingIndicator(false)
                console.log("result", result)
                className.setState({ newsAndUpdates: result.data })
            }
            else {
            }
        });
    };

    componentDidMount() {
        let url = window.location.href;
        let urlParmsArray = url.split("/");
        if (urlParmsArray[urlParmsArray.length - 2] === 'Profile' || urlParmsArray[urlParmsArray.length - 2] === 'profile') {
            this.getRecentFriendRequests(urlParmsArray[urlParmsArray.length - 1]);
            this.getLastWonGames(urlParmsArray[urlParmsArray.length - 1]);
            this.getRecentDefeats(urlParmsArray[urlParmsArray.length - 1]);
            this.getUserById(urlParmsArray[urlParmsArray.length - 1]);
            this.getHonourSummary(urlParmsArray[urlParmsArray.length - 1]);
            this.getAwards(urlParmsArray[urlParmsArray.length - 1]);
        } else {
            this.getRecentFriendRequests(this.props.userData.id);
            this.getLastWonGames(this.props.userData.id);
            this.getRecentDefeats(this.props.userData.id);
            this.getUserById(this.props.userData.id);
            this.getHonourSummary(this.props.userData.id);
            this.getAwards(this.props.userData.id);
        }
        this.getNewsAndUpdates();
        if (!this.state.completedCrop || !this.state.previewCanvasRef.current || !this.state.imgRef.current) {
            return;
        }
    }

    openFriendModal() {
        $("#alert_modal_overlay7").fadeIn("fast", function () {
            $("#alert_modal").fadeIn("fast", function () {
                document.body.style.overflow = "hidden";
            });
        });
    };

    openNewsAndUpdateModal(id) {
        this.props.showLoadingIndicator(true)
        let className = this;
        fetch(this.props.urlGlobal + "api/newsAndUpdates", {
            method: "POST",
            body: JSON.stringify({
                action: "getNewsAndUpdateById",
                lan: this.props.getLanActual(),
                id: id
            }),
            headers: this.props.getHeadersToSend()
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            if (result.success) {
                className.props.showLoadingIndicator(false)
                console.log("result", result)
                className.setState({ newsData: result.data })
                $("#alert_modal_overlay6").fadeIn("fast", function () {
                    $("#alert_modal").fadeIn("fast", function () {
                        document.body.style.overflow = "hidden";
                    });
                });
            }
            else {
            }
        });
    };

    render() {
        if (!this.props.loggedIn) {
            return (<Redirect to={'/'} />);
          }
        return (
            <div className='content' id="membership">
                <ProfilePopUp
                    userId={this.props.userData.id}
                    urlGlobal={this.props.urlGlobal}
                    getHeadersToSend={this.props.getHeadersToSend}
                    showLoadingIndicator={this.props.showLoadingIndicator}
                    userData={this.state.userData}
                    newsData={this.state.newsData}
                ></ProfilePopUp>
                <NewsAndUpdatePopup
                    userId={this.props.userData.id}
                    urlGlobal={this.props.urlGlobal}
                    getHeadersToSend={this.props.getHeadersToSend}
                    showLoadingIndicator={this.props.showLoadingIndicator}
                    userData={this.state.userData}
                ></NewsAndUpdatePopup>
                <div className='wrapper'>
                    <div className='page_content'>
                        <div className='c_center'>
                            <div className='c_top bg_main' >
                                <div className='c_bottom'>
                                    <Eagle></Eagle>
                                    <Leaves></Leaves>
                                    <div className='game_image'>
                                        <img style={{ width: 'inherit', marginTop: '30px' }} src={logo} />
                                    </div>
                                    <div>
                                        <h4 style={{ color: "#C09262", marginTop: "-20px" }}>{this.props.textosShow.profile || "Profile"}</h4>
                                        <br></br>
                                    </div>
                                    <div className="profile-header">
                                        <div className="header-inner">
                                            <br></br>
                                            <div className="profile-image-bg" style={{ padding: "5px" }}>
                                                <img src={this.state.cliente.photo} onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} style={{ width: "105px", height: "107px" }} />
                                                {this.props.userData.id === this.state.cliente.id ?
                                                    <div style={{ marginTop: "-35px", marginLeft: "-80px" }}>
                                                        <button onClick={() => this.openFriendModal()} className="btn btn-primary" style={{ background: "none", outline: "none", border: "none", fontSize: "15px" }}>
                                                            <img src={EditIcon} style={{ width: "25px" }} />
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <br></br>
                                            <div className="profile-name-bg" style={{ height: `${this.props.userData.id !== this.state.cliente.id ? "100px" : "60px"}` }}>
                                                <p style={{ color: "white" }}>{this.state.cliente.name}</p>
                                                <div style={{ marginTop: "-15px" }}> <img style={{ marginTop: "-2px" }} src={Ellipse1} /> {this.props.textosShow.online || "Online"}</div>
                                                {this.props.userData.id !== this.state.cliente.id ?
                                                    // <span style={{ color: "white", fontWeight: "bold" }}
                                                    //     className="link-button"
                                                    //     onClick={() => this.props.showChallengeModal(this.state.cliente.id)}>
                                                    //     {this.props.textosShow.desafiar || "Desafiar"}
                                                    // </span>

                                                    <div className='friendOnlineSearch friendSearchOnlineBackground' onClick={() => this.props.showChallengeModal(this.state.cliente.id)} style={{ height: "35px", width: "180px", marginRight: "22px", cursor: "pointer", marginTop: "0px" }}>
                                                        {this.props.textosShow.desafiar || "Desafiar"}
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <br></br>
                                            <div className="profile-description-bg">
                                                <Row style={{ paddingLeft: '36px', textAlign: 'initial', color: '#8589A1', fontFamily: 'Albertus', fontSize: '14px' }}>
                                                    <Col xs='3'>
                                                        {this.props.textosShow.favouritegame || "Favourite Game"}
                                                    </Col>
                                                    <Col xs='3' style={{ color: '#ffffff' }}>
                                                        {this.state.cliente.favorito_juego}
                                                    </Col>
                                                    <Col xs='3'>
                                                        {this.props.textosShow.points || "Points"}
                                                    </Col>
                                                    <Col xs='3' style={{ color: '#ffffff' }}>
                                                        {this.state.cliente.points}
                                                    </Col>
                                                </Row>
                                                <Row style={{ paddingLeft: '36px', textAlign: 'initial', color: '#8589A1', fontFamily: 'Albertus', fontSize: '14px' }}>
                                                    <Col xs='3'>
                                                        {this.props.textosShow.membership || "Membership"}
                                                    </Col>
                                                    <Col xs='3' style={{ color: '#ffffff' }}>
                                                        {this.state.cliente.membership}
                                                    </Col>
                                                    <Col xs='3'>
                                                        {this.props.textosShow.membersince || "Member Since"}
                                                    </Col>
                                                    <Col xs='3' style={{ color: '#ffffff' }}>
                                                        {moment(this.state.cliente.fechaReg).format("YYYY-MMM-DD")}
                                                    </Col>
                                                </Row>
                                                <Row style={{ paddingLeft: '36px', textAlign: 'initial', color: '#8589A1', fontFamily: 'Albertus', fontSize: '14px' }}>
                                                    <Col xs='3'>
                                                        {this.props.textosShow.country || "Country"}
                                                    </Col>
                                                    <Col xs='3' style={{ color: '#ffffff' }}>
                                                        {this.state.cliente.country}
                                                        <img style={{ marginLeft: '5px' }} src={this.state.cliente.countryFlag} />
                                                    </Col>
                                                    <Col xs='3'>
                                                        {this.props.textosShow.ranking || "Ranking"}
                                                    </Col>
                                                    <Col xs='3' style={{ color: '#ffffff' }}>
                                                        {this.state.cliente.ranking}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col xs="3" style={{ marginLeft: "-40px" }}>
                                            <div className="profile-left-side-bg-sec-1">
                                                <div style={{ paddingTop: "30px", color: '#8589A1', textTransform: "uppercase", fontWeight: "bold" }}>{this.props.textosShow.community || "Community"}</div>
                                                <div className="profile-rectangle" style={{ marginTop: "28px", color: "#8589A1" }}>
                                                    <a href="/friends" style={{ color: "#8589A1" }}><Row>
                                                        <Col xs="10">
                                                            {this.props.textosShow.getstartedinthecommunity || "Get started in the Community"}
                                                        </Col>
                                                        <Col xs="2" style={{ marginLeft: "-10px", marginTop: "-10px" }}>
                                                            <img src={ArrowRight} style={{ width: "40px" }} />
                                                        </Col>
                                                    </Row></a>
                                                </div>
                                                {/* <div className="profile-rectangle" style={{ marginTop: "10px", color: "#8589A1" }}>
                                                    <Row>
                                                        <Col xs="10">
                                                            {this.props.textosShow.seecertificate || "See Certificate"}
                                                        </Col>
                                                        <Col xs="2" style={{ marginLeft: "-10px" }}>
                                                            <img src={ArrowRight} />
                                                        </Col>
                                                    </Row>
                                                </div> */}
                                                <div style={{ paddingTop: "100px", color: '#8589A1', textTransform: "uppercase", fontWeight: "bold" }}>{this.props.textosShow.personal || "Personal"}</div>
                                                <div className="profile-rectangle" style={{ marginTop: "28px", color: "#8589A1" }}>
                                                    <Row>
                                                        <Col xs="10">
                                                            <a href="/perfil" style={{ color: "rgb(133, 137, 161)" }}> {this.props.textosShow.myprofile || "My Profile"}</a>
                                                        </Col>
                                                        <Col xs="2" style={{ marginLeft: "-10px", marginTop: "-10px" }}>
                                                            <img src={ArrowRight} style={{ width: "40px" }} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="profile-rectangle" style={{ marginTop: "10px", color: "#8589A1" }}>
                                                    <Row>
                                                        <Col xs="10">
                                                            <a onClick={() => this.openFriendModal()} style={{ color: "rgb(133, 137, 161)" }}> {this.props.textosShow.editprofile || "Edit Profile"}</a>
                                                        </Col>
                                                        <Col xs="2" style={{ marginLeft: "-10px", marginTop: "-10px" }}>
                                                            <img src={ArrowRight} style={{ width: "40px" }} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="profile-rectangle" style={{ marginTop: "10px", color: "#8589A1" }}>
                                                    <Row>
                                                        <Col xs="10">
                                                            {this.props.textosShow.mymessages || "My Messages"}
                                                        </Col>
                                                        <Col xs="2" style={{ marginLeft: "-10px", marginTop: "-10px" }}>
                                                            <img src={ArrowRight} style={{ width: "40px" }} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                {/* <div className="profile-rectangle" style={{ marginTop: "10px", color: "#8589A1" }}>
                                                    <Row>
                                                        <Col xs="10">
                                                            {this.props.textosShow.sendcall || "Send Call"}
                                                        </Col>
                                                        <Col xs="2" style={{ marginLeft: "-10px" }}>
                                                            <img src={ArrowRight} />
                                                        </Col>
                                                    </Row>
                                                </div> */}
                                                <div className="profile-rectangle" style={{ marginTop: "10px", color: "#8589A1" }}>
                                                    <Row>
                                                        <Col xs="10">
                                                            <a href="/friends" style={{ color: "rgb(133, 137, 161)" }}> {this.props.textosShow.myfriendsandenemeies || "My friends and enemies"}</a>
                                                        </Col>
                                                        <Col xs="2" style={{ marginLeft: "-10px", marginTop: "-10px" }}>
                                                            <img src={ArrowRight} style={{ width: "40px" }} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="profile-left-side-bg-sec-2">
                                                <div style={{ paddingTop: "50px", color: '#8589A1', textTransform: "uppercase", fontWeight: "bold" }}>{this.props.textosShow.lastgamewon || "Last Game Won"}</div>
                                                <div className="won-games-section">
                                                    {this.state.wonGames && this.state.wonGames.length > 0 ?
                                                        this.state.wonGames.map((item, key) =>
                                                            <div className="profile-rectangle-2" style={{ marginTop: "10px", color: "#8589A1" }}>
                                                                <a href={"/partida/" + item.slug + "/" + item.sala + "/" + item.id} style={{ textDecoration: "none" }}> <img src={item.juego_img} style={{ width: "50px" }} /> <span style={{ color: "#8589A1" }}>{item.juego_name}</span></a>
                                                            </div>
                                                        )
                                                        :
                                                        <div style={{ textAlign: "center", paddingTop: "40px" }}>No Data Available</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="profile-left-side-bg-sec-3">
                                                <div style={{ paddingTop: "50px", color: '#8589A1', textTransform: "uppercase", fontWeight: "bold" }}>{this.props.textosShow.recentdefeats || "Recent Defeats"}</div>
                                                <div className="won-games-section">
                                                    {this.state.recentDefeats && this.state.recentDefeats.length > 0 ?
                                                        this.state.recentDefeats.map((item, key) =>
                                                            <div className="profile-rectangle-3" style={{ marginTop: "20px", color: "#8589A1" }}>
                                                                <a href={"/partida/" + item.slug + "/" + item.sala + "/" + item.id} style={{ color: "#8589A1", textDecoration: "none" }}>
                                                                    <Row>
                                                                        <Col xs="7">
                                                                            <img src={item.cliente_img} onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} style={{ width: "40px", height: "40px", borderRadius: "100%" }} /> &nbsp;
                                                            {item.cliente_name}
                                                                        </Col>
                                                                        <Col xs="5" style={{ marginTop: '10px', textAlign: "right" }}>
                                                                            <img src={item.countryFlag} /> &nbsp;
                                                            {item.country}
                                                                        </Col>
                                                                    </Row>
                                                                </a>
                                                            </div>
                                                        )
                                                        :
                                                        <div style={{ textAlign: "center", paddingTop: "40px" }}>No Data Available</div>
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs="5">
                                            <div className="profile-center-side-bg-sec-1">
                                                <div style={{ paddingTop: "10px", color: '#8589A1', textTransform: "uppercase", fontWeight: "bold" }}>{this.props.textosShow.personaldata || "Personal Data"}</div>
                                                <Row style={{ marginTop: "50px", textAlign: "left" }}>
                                                    <Col xs="6" className="personal-info">
                                                        {this.props.textosShow.bestranking || "Best Ranking"} :
                                                    </Col>
                                                    <Col xs="6" className="personal-info-right"> {this.state.cliente.ranking}</Col>
                                                </Row>
                                                <Row style={{ marginTop: "10px", textAlign: "left" }}>
                                                    <Col xs="6" className="personal-info">
                                                        {this.props.textosShow.mostplayed || "Most Played"} :
                                                    </Col>
                                                    <Col xs="6" className="personal-info-right" style={{ marginTop: "0px" }}><img style={{ width: "30px" }} src={this.state.cliente.most_played_juego_foto} onError={(e) => { e.target.onerror = null; e.target.src = Icon3 }} />{this.state.cliente.most_played_juego}</Col>
                                                </Row>
                                                {/* <Row style={{ marginTop: "15px", textAlign: "left" }}>
                                                    <Col xs="6" className = "personal-info">
                                                        Visitors :
                                                    </Col>
                                                    <Col xs="6" className = "personal-info-right">19</Col>
                                                </Row> */}
                                                <Row style={{ marginTop: "10px", textAlign: "left" }}>
                                                    <Col xs="6" className="personal-info">
                                                        {this.props.textosShow.gameplayed || "Game Played"} :
                                                    </Col>
                                                    <Col xs="6" className="personal-info-right">{this.state.cliente.total_game_play}</Col>
                                                </Row>
                                                <Row style={{ marginTop: "15px", textAlign: "left" }}>
                                                    <Col xs="6" className="personal-info">
                                                        {this.props.textosShow.gemscollected || "Gems Collected"} :
                                                    </Col>
                                                    <Col xs="6" className="personal-info-right">{this.state.clienteData ? this.state.clienteData.gemas : 0}</Col>
                                                </Row>
                                                <Row style={{ marginTop: "15px", textAlign: "left" }}>
                                                    <Col xs="8" className="personal-info">
                                                        {this.props.textosShow.opentochallanges || "Open to challanges"} :
                                                    </Col>
                                                    <Col xs="4" className="personal-info-right">Yes</Col>
                                                </Row>
                                                <Row style={{ marginTop: "15px", textAlign: "left" }}>
                                                    <Col xs="6" className="personal-info">
                                                        {this.props.textosShow.profile || "I like it"} :
                                                    </Col>
                                                    <Col xs="6" className="personal-info-right">{this.state.cliente.id_like_to}</Col>
                                                </Row>
                                                {/* <Row style={{ marginTop: "15px", textAlign: "left" }}>
                                                    <Col xs="6" className = "personal-info">
                                                        Dislike :
                                                    </Col>
                                                    <Col xs="6" className = "personal-info-right">Accounts in my email</Col>
                                                </Row> */}
                                                <Row style={{ marginTop: "15px", textAlign: "left" }}>
                                                    <Col xs="6" className="personal-info">
                                                        {this.props.textosShow.favoritegame || "Favorite Game"} :
                                                    </Col>
                                                    <Col xs="6" className="personal-info-right" style={{ marginTop: "-5px" }}><img src={this.state.cliente.favorito_juego_foto} style={{ width: "30px" }} onError={(e) => { e.target.onerror = null; e.target.src = Icon3 }} /> {this.state.cliente.favorito_juego}</Col>
                                                </Row>
                                                {/* <Row style={{ marginTop: "15px", textAlign: "left" }}>
                                                    <Col xs="7" className = "personal-info">
                                                        Most boring game :
                                                    </Col>
                                                    <Col xs="5" className = "personal-info-right"><img src={Icon4} /> BB Bathtub</Col>
                                                </Row> */}
                                                <Row style={{ marginTop: "10px", textAlign: "left" }}>
                                                    <Col xs="6" className="personal-info">
                                                        {this.props.textosShow.play || "Play"} :
                                                    </Col>
                                                    <Col xs="6" className="personal-info-right">Just for fun</Col>
                                                </Row>
                                                {/* <Row style={{ marginTop: "15px", textAlign: "left" }}>
                                                    <Col xs="6" className = "personal-info">
                                                        Zodiac Sign :
                                                    </Col>
                                                    <Col xs="6" className = "personal-info-right">Sagittarus</Col>
                                                </Row> */}
                                            </div>
                                            {/* <div className="profile-center-side-bg-sec-2">
                                                <div style={{ paddingTop: "10px", color: '#8589A1', textTransform: "uppercase", fontWeight: "bold" }}> {this.props.textosShow.honoursummary || "Honour Summary"}</div>
                                                <Row style={{ marginTop: "72px", textAlign: "left", color: "white" }}>
                                                    <Col xs="6" style={{ paddingLeft: "130px" }}>
                                                        {this.state.honourSummary ? this.state.honourSummary.white : 0} white
                                                    </Col>
                                                    <Col xs="6" style={{ textAlign: "left", paddingLeft: "70px", color: 'white' }}> Diplomas</Col>
                                                </Row>
                                                <Row style={{ marginTop: "58px", textAlign: "left" }}>
                                                    <Col xs="6" style={{ paddingLeft: "130px", color: "white" }}>
                                                        {this.state.honourSummary ? this.state.honourSummary.cups : 0} Cups
                                                    </Col>
                                                    <Col xs="6" style={{ textAlign: "left", paddingLeft: "70px", color: 'white' }}>0 Searches</Col>
                                                </Row>
                                                <Row style={{ marginTop: "58px", textAlign: "center" }}>
                                                    <Col xs="12" style={{ color: "white" }}>
                                                        Emeraled <span style={{ color: "grey" }}>13,607 Jewels</span>
                                                    </Col>
                                                </Row>
                                            </div> */}
                                            <div className="profile-center-side-bg-sec-3">
                                                <div style={{ paddingTop: "5px", color: '#8589A1', textTransform: "uppercase", fontWeight: "bold" }}>{this.props.textosShow.friends || "Friends"}</div>
                                                <div style={{ maxHeight: "458px", minHeight: "458px" }}>
                                                    {this.state.friends && this.state.friends.length > 0 ?
                                                        this.state.friends.map((item, key) =>
                                                            <div className="profile-center-rectangle-2">
                                                                <Row style={{ marginTop: "-10px" }}>
                                                                    <Col xs="8">
                                                                        <img src={item.photo} onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} style={{ width: "40px", height: "40px", borderRadius: "100%", marginTop: "5px" }} /> &nbsp;
                                                            <span style={{ marginTop: "-30px" }}>{item.name}</span>
                                                                        <p style={{ marginLeft: "50px", marginTop: "-15px" }}>{item.membership} ({item.ranking || 0})</p>
                                                                    </Col>
                                                                    <Col xs="4" style={{ marginTop: '10px', textAlign: "right" }}>
                                                                        <img src={item.countryFlag} /> &nbsp;
                                                            {item.country}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        ) :
                                                        <div style={{ textAlign: "center", paddingTop: "40px" }}>No Data Available</div>
                                                    }
                                                </div>
                                                <div><a style={{ color: "#8589A1" }} href="/Friends"> {this.props.textosShow.viewmorefriends || "View more friends"}</a></div>
                                            </div>
                                        </Col>
                                        <Col xs="4">
                                            <div className="profile-right-side-bg-sec-1">
                                                <div style={{ paddingTop: "5px", color: '#8589A1', textTransform: "uppercase", fontWeight: "bold" }}>{this.props.textosShow.friends || "News And Updates"}</div>
                                                <div style={{ maxHeight: "458px", minHeight: "458px" }}>
                                                    {this.state.newsAndUpdates && this.state.newsAndUpdates.length > 0 ?
                                                        this.state.newsAndUpdates.map((item, key) =>
                                                            <div className="profile-right-rectangle-1">
                                                                <Row onClick={() => this.openNewsAndUpdateModal(item.id)} style={{ cursor: "pointer" }}>
                                                                    <Col xs="8">
                                                                        {item.title.substring(0, 24)}
                                                                        {/* <p innerHtml={item.description.substring(0, 10)}></p> */}
                                                                        <p>{item.fecha}</p>
                                                                    </Col>
                                                                    <Col xs="4" style={{ marginTop: '0px', textAlign: "right", }}>
                                                                        <img src={item.image} style={{ width: "40px", height: "40px" }} />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        ) :
                                                        <div style={{ textAlign: "center", paddingTop: "40px" }}>No Data Available</div>
                                                    }
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="profile-right-side-bg-sec-2" style={{ marginTop: "-23px" }}>
                                                <div style={{ marginTop: "-10px", marginBottom: "25px", color: '#8589A1', textTransform: "uppercase", fontWeight: "bold" }}>{this.props.textosShow.awards || "Awards"}</div>
                                                <div className="award-section">
                                                    {/* <Row style={{ textAlign: "left" }}>
                                                        {this.state.awards && this.state.friends.length > 0 ?
                                                            this.state.awards.map((item, key) =>
                                                                <Col xs="3" style={{ color: '#8589A1', display: "flex" }}>
                                                                    <div className="profile-right-section-award-bg">
                                                                        <img src={AwardIcon1} title={item.juego_name} />
                                                                    </div>
                                                                </Col>
                                                            ) :
                                                            null
                                                        }
                                                    </Row> */}
                                                    {this.state.awards && this.state.awards.length > 0 ?
                                                        this.state.awards.map((item, key) =>
                                                            <div className="profile-right-rectangle-2" style={{ marginTop: "10px", color: "#8589A1" }}>
                                                                <Row>
                                                                    <Col xs="7">
                                                                        <img src={item.juego_img} style={{ width: "40px", height: "40px" }} /> &nbsp;
                                                            {item.juego_name}
                                                                    </Col>
                                                                    <Col xs="5" style={{ marginTop: '5px', textAlign: "right" }}>
                                                                        <img src={Crowns} style={{ width: "30px", height: "30px" }} /> &nbsp;
                                                                        {item.importe}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                        :
                                                        <div style={{ textAlign: "center", paddingTop: "40px" }}>No Data Available</div>
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
