import { useMemo } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const EmailVerificationSendForm = ({ textosShow, sendEmailVerification, userData, text }) => {
  const MailSendSchema = useMemo(() => Yup.object().shape({
    email: Yup.string()
      .min(2, textosShow.formatoinvalido)
      .max(100, textosShow.formatoinvalido)
      .required(textosShow.notempty)
      .email(textosShow.emailcorrecto),
    confirmlogin: Yup.bool()
      .when('email', ([email], schema) => {
        return schema.test({
          test: confirmlogin => {
            console.log(email, userData.email, confirmlogin)
            return email === userData.email || confirmlogin
          },
          message: textosShow.notempty
        })
      })
  }), [textosShow]);

  return (
    <Formik
      initialValues={{
        email: userData.email,
        confirmlogin: false
      }}
      validationSchema={MailSendSchema}
      onSubmit={sendEmailVerification}
    >
      {({ errors, touched, handleChange, handleBlur, values, submitForm }) => (
        <>
          {text && (
            <div className="row pb-3">
              <div className="col-12" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <section id="content1">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>
                          {textosShow.email || "Email"}:
                        </h5>
                      </td>
                      <td className=''>
                        <div className='table_content'>
                          <div className={!touched.email || !errors.email ? 'input' : 'input error'}>
                            {
                              touched.email && errors.email &&
                              <div className='error_reason'>
                                {errors.email}
                              </div>
                            }
                            <input type="text"
                              name="email"
                              className='w-100'
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          </div>
          <div className="row justify-content-md-center pt-3">
            <div className="col-12">
              {values.email !== userData.email && (
                <>
                  <div className='table_content px-4'>
                    <div className={`d-flex justify-content-md-center pt-3 ${!touched.confirmlogin || !errors.confirmlogin ? 'input' : 'input error'}`}>
                      {
                        touched.confirmlogin && errors.confirmlogin &&
                        <div className='error_reason'>
                          {errors.confirmlogin}
                        </div>
                      }
                      <div className='checkbox mr-2'>
                        <Field id="confirmlogin" type="checkbox" name="confirmlogin" />
                        <label htmlFor="confirmlogin"></label>
                      </div>
                      <b>
                        {textosShow.nextloginwillbewithnewemail || "Next login will be with new email"}
                      </b>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-12 text-center">
              <button type="button" className='button button_regular' onClick={submitForm}>
                {values.email === userData.email ?
                  (textosShow.sendvalidationcode || "Send code")
                  :
                  (textosShow.sendvalidationcodeandchangemail || "Send code and change email")
                }
              </button>
            </div>
          </div>
        </>
      )}
    </Formik>
  )
}

export default EmailVerificationSendForm