import React, { useEffect, useRef, useState } from 'react';

import $ from 'jquery';
import SmokeCenter from '../components/SmokeCenter';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Card, Col, Container, Row } from 'reactstrap';
import trumpet from '../../img/trumpet.png';
import pay_3 from '../../img/pay_3.png';
import coin_falling from '../../img/coin_falling.json';
import profile from '../../img/profile.json';
import Lottie from 'react-lottie-player';
import { useIsVisible } from 'react-is-visible';
import FaqReferralModal from '../components/FaqReferralModal';

const Referral = ({ urlGlobal, getLanActual, getHeadersToSend, textosShow, userData, defaultData }) => {
  const refLottieReferer = useRef()
  const isVisibleReferer = useIsVisible(refLottieReferer)
  const refLottieWon = useRef()
  const isVisibleWon = useIsVisible(refLottieWon)
  const [titulo, setTitulo] = useState(null);
  const [texto, setTexto] = useState(null);
  const [copied, setCopied] = useState(null);

  const getDescription = () => {
    fetch(urlGlobal + "api/page", {
      method: "POST",
      body: JSON.stringify({
        action: "getPage",
        page: "sistemadereferidos",
        lan: getLanActual()
      }),
      headers: getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        setTitulo(result.page.titulo);
        setTexto(result.page.texto);
      }
    });
  };

  const showFaqModal = () => {
    $("#faq_referral_modal_overlay").fadeIn("fast", function () {
      $("#faq_referral_modal").fadeIn("fast", function () {
        document.body.style.overflow = "hidden";
      });
    });
  };

  const onCopied = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  }

  useEffect(() => {
    getDescription();
  }, []);

  return (
    <>
      <FaqReferralModal
          urlGlobal={urlGlobal}
          getLanActual={getLanActual}
          getHeadersToSend={getHeadersToSend} />
      <div
        className='content'
        id='referral'>
        <div className='wrapper'>
          <SmokeCenter />
          <div className='page_content'>
            <div className='c_center'>
              <div className='c_top'>
                <div className='c_bottom'>
                  <Eagle />
                  <Leaves />
                  <h4>
                    {textosShow.sistemadereferidos || "Sistema de referidos"}
                  </h4>
                  <div className='faq_help'>
                    <button
                      className='faq_info'
                      onClick={showFaqModal}>
                      FAQ
                    </button>
                  </div>
                  <Container fluid className='page_block mt-4'>
                    <Row>
                      <Col xs="12" md="6" className="my-4">
                        <Card className="bg-semitransparent h-100 shadow">
                          <Container fluid className="h-100 py-3">
                            <Row className="h-100 align-items-center">
                              <Col xs="12" md="4" className="text-center text-md-left">
                                <img className="my-2" src={trumpet} alt="Trumpet" />
                              </Col>
                              <Col xs="12" md="8" className="text-center text-md-left">
                                <h3 className="my-4">
                                  {textosShow.sistemadereferidostellfriends || "Tell your friends about duelcash and increase your earnings"}
                                </h3>
                              </Col>
                            </Row>
                          </Container>
                        </Card>
                      </Col>
                      <Col xs="12" md="6" className="my-4">
                        <Card className="bg-semitransparent h-100 shadow">
                          <Container fluid className="h-100 py-3">
                            <Row className="h-100 align-items-center">
                              <Col xs="12" md="4" className="text-center text-md-left">
                                <img src={pay_3} alt="Pay 3" />
                              </Col>
                              <Col xs="12" md="8" className="text-center text-md-left">
                                <h3 className="m-0">
                                  {textosShow.sistemadereferidosdiscount || "Get 10% commission for each bet from each of your referrals! it's simple, the more your referrals play, the more you earn!"}
                                </h3>
                              </Col>
                            </Row>
                          </Container>
                        </Card>
                      </Col>
                      <Col xs="12" className="my-4">
                        <Card className="bg-semitransparent h-100 shadow">
                          <Container fluid className="h-100 py-3">
                            <Row className="h-100 align-items-center">
                              <Col className="d-block d-sm-flex align-items-center justify-content-between">
                                <h3 className="ml-sm-2 my-0 h-100 text-center">
                                  {textosShow.referidossharelink || "Share Link"}:
                                </h3>
                                <div className="ml-sm-2 mr-sm-auto my-3 my-sm-0 text-center text-success referral-link h-100 text-green-gradient">
                                  {`${window.location.origin}/?ref=${userData.id}`}
                                </div>
                                <div className="ml-sm-2 referral-link text-white text-center text-sm-left">
                                  <CopyToClipboard text={`${window.location.origin}/?ref=${userData.id}`}
                                    onCopy={onCopied}>

                                    <button className="pulse cursor-pointer rounded press copy-link-button">
                                      <FontAwesomeIcon icon={faCopy} />
                                    </button>
                                  </CopyToClipboard>
                                  <div className={`position-absolute copied ${copied ? 'd-block' : 'd-none'}`}>
                                    {textosShow.copied || "Copied!"}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Card>
                      </Col>
                      <Col xs="12" sm="6" className="my-4">
                        <Card className="bg-semitransparent h-100 shadow">
                          <Container fluid className="h-100 py-3">
                            <Row className="h-100 align-items-center">
                              <Col xs="12" sm="4" className="text-center text-sm-left">
                                <Lottie
                                  loop={false}
                                  animationData={profile}
                                  play={isVisibleReferer}
                                  style={{ width: 104, height: 108 }}
                                  className="p-2 w-100" />
                              </Col>
                              <Col xs="12" sm="8" className="text-center text-sm-left">
                                <h3 className="m-0" ref={refLottieReferer}>
                                  {textosShow.referidostotales || "Referidos totales"}:
                                </h3>
                                <h2>
                                  {userData.referralCount}
                                </h2>
                              </Col>
                            </Row>
                          </Container>
                        </Card>
                      </Col>
                      <Col xs="12" sm="6" className="my-4">
                        <Card className="bg-semitransparent h-100 shadow">
                          <Container fluid className="h-100 py-3">
                            <Row className="h-100 align-items-center">
                              <Col xs="12" sm="4" className="text-center text-sm-left">
                                <Lottie
                                  loop={false}
                                  animationData={coin_falling}
                                  play={isVisibleWon}
                                  style={{ width: 104, height: 108 }}
                                  className="p-2 w-100" />
                              </Col>
                              <Col xs="12" sm="8" className="text-center text-sm-left">
                                <h3 className="m-0" ref={refLottieWon}>
                                  {textosShow.totalganado || "Total ganado"}:
                                </h3>
                                <h2>
                                  {defaultData.moneda.signo} {userData.referralEarnings}
                                </h2>
                              </Col>
                            </Row>
                          </Container>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                  {
                    (titulo && texto) ? (
                      <>
                        <h5>
                          {titulo}
                        </h5>
                        <div dangerouslySetInnerHTML={{ __html: texto }}></div>
                      </>
                    ) : (
                      null
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referral;
