import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useRef, useState } from "react";
import StripePaymentElement from "./components/StripePaymentElement";
import PaypalButton from "./components/PaypalButton";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import stripeIcon from "../img/visa_master.png";
import paypalIcon from "../img/paypal.png";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const paymentMethodsIcon = {
  stripe: stripeIcon,
  paypal: paypalIcon,
};

const MobileCheckoutApp = () => {
  const [clientSecret, setClientSecret] = useState(null);
  const [total, setTotal] = useState(0);
  const [textos, setTextos] = useState({});
  const containerRef = useRef(null);
  const [showPaypal, setShowPaypal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("card");

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      theme: "night",
      variables: {
        colorText: "#fff",
        colorPrimary: "#958063",
        fontSizeBase: "16px",
        fontWeightNormal: "900",
      },
      fontFamily: "Open Sans, Arial, sans-serif",
      rules: {
        ".Input::placeholder": {
          color: "#958063",
        },
        ".Dropdown": {
          color: "#fff",
        },
        ".DropdownItem": {
          color: "#fff",
        },
        ".Label": {
          color: "#958063",
        },
      },
    },
  };

  const urlGlobal = process.env.REACT_APP_BACKEND_ENDPOINT;
  const postMessage = (data, targetOrigin) => {
    const fn = window.postMessageNew
      ? window.postMessageNew
      : window.ReactNativeWebView
      ? window.ReactNativeWebView.postMessage
      : window.postMessage;
    try {
      fn(JSON.stringify(data), targetOrigin);
    } catch (error) {
      window.postMessage(JSON.stringify(data), targetOrigin);
    }
  };

  //const logapp = (message) => postMessage({ type: 'log', message }, "*");
  //console.log = (message) => postMessage({type: 'log', message}, "*");

  useEffect(() => {
    function onParentMessage(event) {
      event = event.detail || event;
      if (event.origin === "") {
        // Events from app
        const data = event && event.data && JSON.parse(event.data);
        if (data.type === "data") {
          if (data.clientSecret) {
            setClientSecret(data.clientSecret);
          }
          if (data.total) {
            setTotal(data.total);
          }
          if (data.textos) {
            setTextos(data.textos);
          }
        } else if (data.type === "paymentMethods") {
          if(data.paymentMethods && data.paymentMethods.length > 0) {
            const showPaypal = data.paymentMethods.find((method) => method.id === "paypal");
            setShowPaypal(showPaypal);
          }
        }
      }
    }

    // subscribe for recive parent data messages
    window.addEventListener("messageRN", onParentMessage);
    window.addEventListener("message", onParentMessage);

    postMessage({ type: "loaded" });

    return () => {
      // unsubscribe for recive parent data messages
      window.removeEventListener("messageRN", onParentMessage);
      window.removeEventListener("message", onParentMessage);
    };
  }, [setClientSecret, setTotal, setTextos]);

  useEffect(() => {
    if (!containerRef.current) return;

    const interval = setInterval(() => {
      postMessage({
        type: "height",
        height: containerRef.current.scrollHeight,
      });
    }, 500);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [containerRef]);

  const onSuccess = (paymentId, payed) => {
    // send message to parent
    postMessage(
      { type: "payment", payed, success: true, paymentId, err: null },
      "*"
    );
  };

  const onError = (err) => {
    // send message to parent
    postMessage({ type: "payment", success: false, paymentId: null, err }, "*");
  };

  const onCancel = (data) => {
    // send message to parent
    // postMessage(
    //   { type: "payment", success: false, paymentId: null, err: data },
    //   "*"
    // );
    postMessage({ type: "modal_closed" });
  };

  const onCreateOrder = () => {
    postMessage({ type: "modal_opened" });
  };

  const onReady = () => {
    postMessage({ type: "ready" });
  };

  return (
    <div
      className="h-100 w-100 pb-4 px-4 text-light text-center d-flex flex-column justify-content-center"
      ref={containerRef}
    >
      <Nav
        tabs
        fill
        card
        role="tablist"
        style={{
          display: showPaypal ? "" : "none",
        }}
        className="pt-0 mt-0"
      >
        <NavItem>
          <NavLink
            active={selectedTab == "card"}
            onClick={() => setSelectedTab("card")}
          >
            <img src={paymentMethodsIcon.stripe} alt="Credit Card" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={selectedTab == "paypal"}
            onClick={() => setSelectedTab("paypal")}
          >
            <img src={paymentMethodsIcon.paypal} alt="Paypal" />
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={showPaypal ? selectedTab : "card"}>
        <TabPane tabId="paypal" className="pt-4">
          <PaypalButton
            total={total}
            depositState={{}}
            onSuccess={(id) => onSuccess(id, "paypal")}
            onError={onError}
            onCancel={onCancel}
            onCreateOrder={onCreateOrder}
          />
        </TabPane>
        <TabPane tabId="card">
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <StripePaymentElement
                urlGlobal={urlGlobal}
                textosShow={textos}
                onSuccess={(id) => onSuccess(id, "stripe")}
                isSetup={false}
                onReady={onReady}
                onError={onError}
              />
            </Elements>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default MobileCheckoutApp;
