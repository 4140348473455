import React, { Component } from 'react';

import $ from 'jquery';

import SuccessIcon from '../../img/p_success_icon.png';

class RecoverModalOk extends Component {
  
  constructor(props) {
    super(props);
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  }
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }
  
  close() {
    $("#forgot_modal_success").fadeOut("fast", function() {
      $("#forgot_modal_success_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  }
  
  render() {
    return (
      <div className="modalx-overlay close_mw"
           id="forgot_modal_success_overlay">
        <div className='modal_window'
             id="forgot_modal_success">
          <button id="close_modal"
                  className="link-button close_mw"
                  onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                <div className="activate_icon">
                  <img src={SuccessIcon}
                       alt={this.props.textosShow.exito || 'Éxito'} />
                </div>
                <h5>
                  {this.props.textosShow.exito || 'Éxito'}
                </h5>
                <div className='rec_text'>
                  {this.props.textosShow.contrasenarecuperada || 'Su contraseña ha sido recuperada exitosamente!'}
                  <br />
                  {this.props.textosShow.detallesenviados || 'Los detalles han sido enviados a su correo electrónico.'}
                </div>
                <div className='modal_button'>
                  <button className='button button_regular'
                          onClick={this.close.bind(this)}>
                    {this.props.textosShow.cerrar || 'Cerrar'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default RecoverModalOk;