import React from 'react';

const Jackpot = ({ textosShow, children }) => (
  <div className='jackpot text-shadow'>
    {textosShow.megajackpot || "Mega Jackpot"}
    <div className='text-shadow'>
      {textosShow.numerojackpot || "1,790,567.00"}
    </div>
    {children}
  </div>
);

export default Jackpot;