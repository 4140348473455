import React, { useState } from 'react';
import { ModalIFrame } from './ModalIFrame';

export default function PaymentWallButton({ urlGlobal, getHeadersToSend, total, onSuccess, onError, onCancel }) {
  const [error, setError] = useState('');
  const [paymentId, setPaymentId] = useState(null);
  const [url, setUrl] = useState(false);

  const onClick = () => {
    const myHeaders = getHeadersToSend();
    fetch(urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "createPaymentWallCheckout",
        importe: total
      }),
      headers: myHeaders
    }).then((response) => {
      return response.json();
    }).then((result) => {
      if (result.success) {
        setPaymentId(result.checkout_id);
        setUrl(result.checkout_url);
      } else {
        onError(result.msg);
      }
    });
  };  

  const handleClose = () => {
    setUrl(false)
    onCancel();
  }

  return (
    <div>
      {
        error ? (
          <p>
            {error}
          </p>
        ) : (
          <>
          <button
            onClick={onClick}
            style={{
              backgroundColor: "rgb(0, 0, 0)",
              color: "rgb(255, 255, 255)",
              height: "55px",
              width: "100%",
              borderRadius: "10px",
              fontSize: "18px",
              lineHeight: "55px"
            }}>
            Buy With Credit Card
          </button>
          {url && (<ModalIFrame src={url} onModalClose={handleClose} onChargeSuccess={() => onSuccess(paymentId)} onChargeFailure={onError} />)}
          </>
        )
      }
    </div>
  );
};
