import React, { Component } from 'react';

import $ from 'jquery';

class RulesModal extends Component{

  constructor(props) {
    super(props);
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }
  
  close() {
    $("#rules_modal").fadeOut("fast", function() {
      $("#rules_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  }

  render() {
    return (
      <div className="modalx-overlay close_mw"
           id="rules_modal_overlay">
        <div className='modal_window' 
             id="rules_modal">
          <button id="close_modal"
                    className="link-button close_mw"
                    onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                {
                  this.props.titulo ? (
                    <h5>
                      {this.props.titulo}
                    </h5>
                  ) : null
                }
                {
                  this.props.texto ? (
                    <div className='modal_form'>
                      <div className='terms_text'>
                        <div className='terms_scroll'>
                          <div dangerouslySetInnerHTML={{__html: this.props.texto}}></div>
                        </div>
                      </div>
                    </div>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RulesModal;