import React, { Component } from 'react';

import $ from 'jquery';
import { withRouter } from 'react-router';

import coinIcon from '../../img/coin_icon_big.png';

class BonanzaModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      bonanza: null,
      gameSlug: ""
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  };
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  };
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  };
  
  keyDown(e) {
    if (e.keyCode === 27) {
      this.close();
    }
  };
  
  close() {
    $("#bonanza_modal").fadeOut("fast", function() {
      $("#bonanza_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  };
  
  getBonanzaRewardTypeTitle() {

    if (!this.state.bonanza || !this.state.bonanza.bonanzaRewardType || !this.state.bonanza.bonanzaRewardMinutes) {
      return ''
    }

    var rewardTypeSlug = 'dailypremios';
    var minutes = '';

    switch (this.state.bonanza.bonanzaRewardType) {
      case 'Daily':
        rewardTypeSlug = 'dailypremios';
        break;

      case 'Hourly':
        rewardTypeSlug = 'hourlypremios';
        break;

      case 'Minutes':
        minutes = this.state.bonanza.bonanzaRewardMinutes;
        rewardTypeSlug = 'minutespremios';
        break;
    }
    
    var defaultTitle = (this.state.bonanza.bonanzaRewardType + " Premios");
    return minutes + ' ' + (this.props.textosShow[rewardTypeSlug] || defaultTitle);
  }

  play() {
    let clase = this;
    if (clase.props.loggedIn) {
      fetch(clase.props.urlGlobal + "api/juego", {
        method: "POST",
        body: JSON.stringify({
          action: "setBonanzaPlayer",
          lan: clase.props.getLanActual(),
          id: clase.state.bonanza.id
        }),
        headers: clase.props.getHeadersToSend()
      }).then(function(response) {
        return response.json();
      }).then(function(result) {
        if (!result.success) {
          alert(result.msg);
        }
        else {
          if (clase.state.bonanza.bonanzaStatus == 0 && result.partida_id != null) {
            window.location = clase.props.urlGlobal + "playGame/" + clase.state.bonanza.slug 
                            + "/?gameMode=" + clase.state.bonanza.id 
                            + "&partida=" + result.partida_id 
                            + "&game=" + clase.state.gameSlug
                            + "&bonanza_player_id=" + result.bonanza_player_id
                            + "&bonanza_entrada_id=" + result.bonanza_entrada_id;
          } else if (clase.state.bonanza.bonanzaStatus == 1) {
            clase.props.history.replace("/bonanza/" + clase.state.bonanza.id);
          }
          
          clase.close();
        }
      });
    }
    else{
      alert(clase.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta.");
    }
  };

  getLoopReward () {
    if (this.state.bonanza.bonanzaDailyRewards.length > this.state.bonanza.bonanzaFinalRewards.length) {
      return this.state.bonanza.bonanzaDailyRewards;
    } else {
      return this.state.bonanza.bonanzaFinalRewards;
    }
  }

  render() {
    return (
      <div 
        className="modalx-overlay close_mw"
        id="bonanza_modal_overlay">
        <div 
          className='modal_window' 
          id="bonanza_modal">
          <button 
            id="close_modal" 
            className="link-button close_mw"
            onClick={this.close.bind()}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                {
                  this.state.bonanza ? (
                    <div>
                      <h5>
                        {this.getBonanzaRewardTypeTitle()}
                      </h5>
                      <div className='premio'>
                        <img
                          src={coinIcon}
                          />
                        <div>
                          {this.props.defaultData.moneda.signo} {Number(this.state.bonanza.totalBDRs).toFixed(2)}
                        </div>
                      </div>

                      <h5>
                        {this.props.textosShow.finalpremios || "Final Premios"}
                      </h5>
                      <div className='premio'>
                        <img
                          src={coinIcon}
                          />
                        <div>
                          {this.props.defaultData.moneda.signo} {Number(this.state.bonanza.totalBFRs).toFixed(2)}
                        </div>
                      </div>
                      
                      <table>
                        <thead>
                          <tr className='subtitle'>
                            <td>
                              <span>#</span>
                            </td>
                            <td style={{padding:0}}>
                              <center>
                                {/* <span>{this.props.textosShow.dailypremios || "Daily Premios"}</span> */}
                                <span>
                                  {this.getBonanzaRewardTypeTitle()}
                                </span>
                              </center>
                            </td>
                            <td style={{padding:0}}>
                              <center>
                                <span>{this.props.textosShow.finalpremios || "Final Premios"}</span>
                              </center>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          { 
                            this.getLoopReward() && this.getLoopReward().map((item, index) =>
                              <tr key={`item-${item.ind}`}>
                                <td>
                                  <span>
                                    #{item.ind}
                                  </span>
                                </td>
                                <td className='entry_pay'>
                                  <div>
                                    <span>
                                      + {this.props.defaultData.moneda.signo} {this.state.bonanza.bonanzaDailyRewards[index] ? Number(this.state.bonanza.bonanzaDailyRewards[index].premio).toFixed(2) : 0}
                                    </span>
                                  </div>
                                </td>
                                <td className='entry_pay'>
                                  <div>
                                    <span>
                                      + {this.props.defaultData.moneda.signo} {this.state.bonanza.bonanzaFinalRewards[index] ? Number(this.state.bonanza.bonanzaFinalRewards[index].premio).toFixed(2) : 0}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                      <div className='vs_player'>
                        <h6>
                          Bonanza Game
                        </h6>
                      </div>
                      <div className='modal_button'>
                        <button 
                          className='button button_regular'
                          onClick={this.play.bind(this)}
                          >
                          {this.props.textosShow.inscribirse || "Inscribirse"}
                        </button>
                      </div>
                    </div>
                  ) : (
                    null
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default BonanzaModal;
