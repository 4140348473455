import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassEnd, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Row } from 'reactstrap';

import $ from 'jquery';

import SmokeCenter from '../components/SmokeCenter';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';

import winIcon from '../../img/win_icon.png';
import waitIcon from '../../img/wait_icon.png';
import warningIcon from '../../img/warn_icon.png';
import crownRed from '../../img/crown-red.png';
import crown from '../../img/crown.png';
import ReactTooltip from 'react-tooltip';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import boxRedIcon from '../../img/boxRedIcon.png';
import { Link } from 'react-router-dom';
import moveRemainingTime from '../utils/moveRemaingTime';

class Generaljackpot extends Component {

  constructor(props) {
    super(props);

    this.state = {
      generaljackpot: null,
      copied: false,
      helpPage: {}
    };
  };

  componentDidMount() {
    this.setState({
      referralContest: null
    });
    if (this.props.match.params.generaljackpotId) {
      this.getDataGeneraljackpot();
      if (process.env.REACT_APP_GAME_AUTOREFRESH_TIMEOUT) {
        this.timerGetData = setInterval(() => {
          this.getDataGeneraljackpot();
        }, process.env.REACT_APP_GAME_AUTOREFRESH_TIMEOUT);
      }
    }

    //mover el tiempo de cada sala
    this.timerTime = setInterval(() => {
      this.moveTime();
    }, 1000);
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.match.params.generaljackpotId !== nextProps.match.params.generaljackpotId) {
      this.props = nextProps;
      this.componentDidMount();
    }
    return true;
  }

  componentWillUnmount() {
    clearInterval(this.timerGetData);
    clearInterval(this.timerTime);
  };

  getDataGeneraljackpot() {
    let clase = this;
    fetch(clase.props.urlGlobal + "api/generaljackpot", {
      method: "POST",
      body: JSON.stringify({
        action: "getPosiciones",
        id: clase.props.match.params.generaljackpotId,
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        clase.setState({
          generaljackpot: result.generaljackpot
        });
        clase.getGeneralJackpotPage(result.generaljackpot);
      }
    });
  };

  getGeneralJackpotPage(generaljackpot) {
    let clase = this;
    clase.setState({
      loading: true
    });
    fetch(clase.props.urlGlobal + "api/page", {
      method: "POST",
      body: JSON.stringify({
        action: "getPage",
        page: "generaljackpot_" + generaljackpot.type,
        lan: clase.props.getLanActual()
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (result.success) {
        clase.setState({
          helpPage: {
            titulo: result.page.titulo,
            texto: result.page.texto
          }
        });
      }
    });
  }

  moveTime() {
    let generaljackpot = this.state.generaljackpot;
    if (generaljackpot) {
      generaljackpot.tiempo = moveRemainingTime(
        generaljackpot.tiempo
      );
      generaljackpot.tiempoToStart = moveRemainingTime(
        generaljackpot.tiempoToStart
      );

      this.setState({
        generaljackpot: generaljackpot
      });
    }
  };

  showAceptGeneralJackpot(generaljackpot) {
    if (this.props.loggedIn) {
      if (generaljackpot !== null && !generaljackpot.type) {
        this.props.referralContestModalElement.current.setState({
          referralContest: generaljackpot
        });
        $(".mt_block.step_4").fadeOut(0);
        $(".mt_block.step_5").fadeOut(0);
        $("#referral_contest_modal_overlay").fadeIn("fast", function () {
          $("#referral_contest_modal").fadeIn("fast", function () {
            document.body.style.overflow = "hidden";
          });
        });
      } else if (generaljackpot !== null && generaljackpot.type) {
        this.props.generaljackpotModalElement.current.setState({
          generaljackpot: generaljackpot
        });
        $(".mt_block.step_4").fadeOut(0);
        $(".mt_block.step_5").fadeOut(0);
        $("#generaljackpot_modal_overlay").fadeIn("fast", function () {
          $("#generaljackpot_modal").fadeIn("fast", function () {
            document.body.style.overflow = "hidden";
          });
        });
      }
      else {
        alert(this.props.textosShow.estamodalidadnoestaaundisponible || "Esta modalidad no esta aún disponible");
      }
    }
    else {
      alert(this.props.textosShow.paraaccederajugardebeestarregistradoconunacuenta || "Para acceder a jugar debe estar registrado con una cuenta");
    }
  };

  render() {
    if (!this.props.loggedIn || (this.state.generaljackpot && !this.state.generaljackpot.registered)) {
      return (<Redirect to={'/general-jackpots'} />);
    }
    
    return (
      <div>
        <div
          className={`content lg ${this.state.generaljackpot ? this.state.generaljackpot.type.endsWith('per_game') ? 'orange' : 'red' : 'invisible'}`}
          id='generaljackpot'>
          <div className='wrapper'>
            <SmokeCenter></SmokeCenter>
            <div className='page_content'>
              <div className='c_center'>
                <div className='c_top'>
                  <div className='c_bottom'>
                    <Eagle></Eagle>
                    <Leaves></Leaves>
                    <div className='page_block'>
                      {
                        this.state.generaljackpot ? (
                          <div class="cloth game-ui">
                            <Container className='px-5 pt-5'>
                              <Row>
                                <Col>
                                  <div className="tour_info reamaining text-white w-100 text-center">
                                    {
                                      !this.state.generaljackpot.closed ? 
                                        this.state.generaljackpot.tiempoToStart.timeRemain <= 0 ? (
                                          <span>
                                            {this.props.textosShow.tiemporestante || "Tiempo restante"}:
                                            {
                                              this.state.generaljackpot.tiempo.highlight ? (
                                                <span className="iconTimeRemaining pr-1 ml-2">
                                                  <FontAwesomeIcon
                                                    icon={faHourglassEnd}
                                                    size="lg" />
                                                </span>
                                              ) : (
                                                <span className="iconTimeRemaining pr-1 ml-2">
                                                  <FontAwesomeIcon
                                                    icon={faHourglassHalf}
                                                    size="lg" />
                                                </span>
                                              )
                                            }
                                            <h4 className="remaining_time pt-3">
                                              {this.state.generaljackpot.tiempo.dias}d {this.state.generaljackpot.tiempo.horas}:{this.state.generaljackpot.tiempo.minutos}:{this.state.generaljackpot.tiempo.segundos}
                                            </h4>
                                          </span>
                                        ) : (
                                          <span>
                                            <h3 className="">
                                              {this.props.textosShow.tiempoparaempezardetalle || "Tiempo para empezar detalle"} 
                                            </h3>
                                            <h4 className="remaining_time pt-3">
                                              {this.state.generaljackpot.tiempoToStart.dias}d {this.state.generaljackpot.tiempoToStart.horas}:{this.state.generaljackpot.tiempoToStart.minutos}:{this.state.generaljackpot.tiempoToStart.segundos}
                                            </h4>
                                          </span>
                                      ) : (
                                        <h4 className="remaining_time pt-3">
                                          {this.props.textosShow.termino || "Finalizado"}
                                        </h4>
                                      )
                                    }
                                  </div>
                                </Col>
                              </Row>
                              <div className='game_main'>
                                <div className='game_icon_main'>
                                  {this.state.generaljackpot.juego && (
                                    <>
                                      <Link to={`/game/${this.state.generaljackpot.juego.slug}`}>
                                        <img src={this.state.generaljackpot.juego.logo} />
                                      </Link>
                                      <span className="d-flex justify-content-center">
                                        <Link to={`/game/${this.state.generaljackpot.juego.slug}`}>
                                          <h3>{this.props.textosShow.viewgamerooms || "View Game Rooms"}</h3>
                                        </Link>
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className='premio mt-5'>
                                <h5>
                                  {this.props.textosShow.premiototal || "Premio Total"}
                                </h5>
                                <div>
                                  {this.props.defaultData.moneda.signo} {this.state.generaljackpot.dinero}
                                </div>

                                {!this.state.generaljackpot.registered ? (
                                  <button className='button button_regular mt-3' onClick={this.showAceptGeneralJackpot.bind(this, this.state.generaljackpot)}>
                                    {this.props.textosShow.inscribirse || "Inscribirse"}
                                  </button>
                                ) : (null)
                                }
                              </div>
                              <div className="bg-semitransparent shadow card p-4"
                                style={this.state.generaljackpot.registered ? { marginTop: '-50px', zIndex: 3 } : { marginTop: '20px', zIndex: 3 }}>
                                {
                                  this.state.helpPage.titulo ? (
                                    <h3 className=" text-center">
                                      {this.state.helpPage.titulo}
                                    </h3>
                                  ) : (null)
                                }

                                {
                                  this.state.helpPage.texto ? (
                                    <div dangerouslySetInnerHTML={{ __html: this.state.helpPage.texto }} />
                                  ) : (null)
                                }
                              </div>
                            </Container>
                          </div>
                        ) : (
                          null
                        )
                      }
                      {
                        this.state.generaljackpot && this.state.generaljackpot.tiempoToStart.timeRemain <= 0 ? (
                          <div className='game_ui_list'>
                            <table>
                              <tbody>
                                {
                                  this.state.generaljackpot.players.map((player, index) =>
                                    <tr
                                      className={(player.jugador.id === this.props.userData.id) ? 'tr_winner' : ''}
                                      key={player.posicion}>
                                      <td className='dark_td td_num'>
                                        <span>
                                          {player.posicion}
                                        </span>
                                      </td>
                                      <td className='td_win p-0'>
                                        <div className="d-flex justify-content-between align-items-center">
                                        {
                                          player.jugador.id ? (
                                            <div className='select pl-2 d-flex align-items-center'>
                                              <button
                                                className='main-item'
                                                style={{
                                                  color: '#ffffff'
                                                }}>
                                                <div className='country'>
                                                  <img
                                                    src={player.jugador.pais}
                                                    alt="" />
                                                </div>
                                                {player.jugador.nick}
                                                {
                                                  player.jugador.blocked ? (
                                                    <>
                                                      <span
                                                        data-tip
                                                        data-for={`blocked-tooltip-${player.jugador.id}`}
                                                        className="iconInfo pl-1">
                                                        <FontAwesomeIcon icon={faLock} />
                                                      </span>
                                                      <ReactTooltip id={`blocked-tooltip-${player.jugador.id}`}>
                                                        <p>
                                                          {this.props.textosShow.usuariobloqueado || "Usuario Bloqueado"}
                                                        </p>
                                                      </ReactTooltip>
                                                    </>
                                                  ) : (
                                                    null
                                                  )
                                                }
                                              </button>
                                              <ul className="sub-menu lang_menu">
                                                <li>
                                                  <span
                                                    className="link-button"
                                                    onClick={() => this.props.showPerfilUserModal(player.jugador.id)}>
                                                    {this.props.textosShow.verperfil || "Ver perfil"}
                                                  </span>
                                                  {
                                                    (player.jugador.id !== this.props.userData.id) ? (
                                                      <span
                                                        className="link-button"
                                                        onClick={() => this.props.showChallengeModal(player.jugador.id)}>
                                                        {this.props.textosShow.desafiar || "Desafiar"}
                                                      </span>
                                                    ) : (
                                                      null
                                                    )
                                                  }
                                                </li>
                                              </ul>
                                            </div>
                                          ) : (
                                            null
                                          )
                                        }
                                        {
                                          (player.posicion === 1) ? (
                                            <div
                                              className="emblem d-flex justify-content-between align-items-center"
                                              style={{
                                                color: '#a1e970',
                                                float: 'right'
                                              }}>
                                              {this.props.textosShow.theking || "The King"}
                                              <img
                                                className="mx-1"
                                                src={crown}
                                                alt="" />
                                            </div>
                                          ) : (
                                            null
                                          )
                                        }
                                        </div>
                                      </td>
                                      <td className='dark_td td_pts'>
                                        {
                                          player.jugador.id ? (
                                            <span>
                                              {player.puntos} {this.props.textosShow['generaljackpot_puntos_' + this.state.generaljackpot.type] || ''}
                                            </span>
                                          ) : (
                                            null
                                          )
                                        }
                                      </td>
                                      <td className='td_award'>
                                        <span className={`${player.cumplioRequisitosMinimos == 1 ? '' : 'f-blur-1'}`}>
                                          {this.props.defaultData.moneda.signo} {player.dinero}
                                          <small className="tour_add">
                                            + {player.gemas}
                                          </small>
                                        </span>
                                      </td>
                                    </tr>
                                  )
                                }
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          null
                        )
                      }
                      <div className='game_ui_warn'>
                        <span>
                          <img
                            src={warningIcon}
                            alt="Warning" />
                          {this.props.textosShow.importantenuestrosistemaalineamatchconoponentesdelmismonivel || "Importante! Nuestro sistema alinea match con openentes del mismo nivel"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default Generaljackpot;
