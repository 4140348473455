import React, { useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Papyrus from './Papyrus';
import EmailVerificationValidateForm from './EmailVerificationValidateForm';
import EmailVerificationSendForm from './EmailVerificationSendForm';

/**
 * Component for forcing the user to validate his email address before being able to use the children component.
 */
const EmailVerification = ({ children, getDataUser, userData, mandatory, urlGlobal, getHeadersToSend, force = false, textosShow, getLanActual, showLoadingIndicator, loggedIn, openAlertModal }) => {
  const [skipValidation, setSkipValidation] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [timestamp, setTimestamp] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const [lastEmail, setLastEmail] = useState(userData.email);

  const shouldShowValidationScreen = useMemo(() => {
    return (userData && !userData.hasEmailVerified && !skipValidation) || force;
  }, [userData, skipValidation, force]);

  useEffect(() => {
    if (shouldShowValidationScreen && !text) {
      getPageDescription();
    }
  }, [shouldShowValidationScreen, text, mandatory]);

  const getPageDescription = () => {
    showLoadingIndicator && showLoadingIndicator(true);
    fetch(urlGlobal + "api/page", {
      method: "POST",
      body: JSON.stringify({
        action: "getPage",
        page: mandatory ? "emailconfirmationmandatory" : "emailconfirmation",
        lan: getLanActual()
      }),
      headers: getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      showLoadingIndicator(false);
      if (result.success) {
        setText(result.page.texto);
        setTitle(result.page.titulo);
      }
    });
  };

  const sendEmailVerification = (values) => {
    showLoadingIndicator && showLoadingIndicator(true);
    setCodeSent(false);
    values?.email && setLastEmail(values?.email);
    fetch(urlGlobal + "api/register", {
      method: "POST",
      body: JSON.stringify({
        action: "SendMailValidationCode",
        email: values?.email || lastEmail
      }),
      headers: getHeadersToSend()
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      showLoadingIndicator(false);
      if (!result.success) {
        openAlertModal(textosShow[result.slug] || result.msg);
      } else {
        setCodeSent(true);
        setTimestamp(result.timestamp);
        setResendTimer(60);
      }
    });
  };

  if (!loggedIn) {
    return (<Redirect to={'/'} />);
  }

  return !shouldShowValidationScreen ? (
    children || <Redirect to={'/'} />
  ) : (
    <Papyrus>
      <>
        <div className="row pb-4">
          <div className="col-12">
            <h4>
              {title || "Email verification"}
            </h4>
          </div>
        </div>
        {!codeSent ? (
          <EmailVerificationSendForm
            sendEmailVerification={sendEmailVerification}
            textosShow={textosShow}
            userData={userData}
            text={text}
          />
        ) : (
          <EmailVerificationValidateForm
            resendTimer={resendTimer}
            setResendTimer={setResendTimer}
            sendEmailVerification={sendEmailVerification}
            timestamp={timestamp}
            getDataUser={getDataUser}
            userData={userData}
            urlGlobal={urlGlobal}
            getHeadersToSend={getHeadersToSend}
            textosShow={textosShow}
            showLoadingIndicator={showLoadingIndicator}
            openAlertModal={openAlertModal}
            lastEmail={lastEmail}
          />
        )}
        {mandatory || !children ? null : (
          <p className="text-center d-absolute pt-3">
            <a href='#' onClick={(e) => e.preventDefault() || setSkipValidation(true)}>
              {textosShow.skipvalidation || "Skip validation"}
            </a>
          </p>
        )}
      </>
    </Papyrus >
  );
}

export default EmailVerification;