import React, { Component } from 'react';
import { Link } from "react-router-dom";

import $ from 'jquery';

import facebookIcon from '../../img/facebook_soc.png';
import ReactPixel from 'react-facebook-pixel';

class RegisterModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.validateMsg = {
      empty: "%name% " + props.textosShow.notempty,
      email: props.textosShow.emailcorrecto
    };
    
    this.state = {
      redirectToReferrer: false,
      loadingShow: false,
      email: "",
      pass: "",
      optIn1: true,
      promoCode:"",
      successPromoCode:null,
      personaje: null,
      validate: {
        email: {
          valid: true,
          msg: ""
        },
        pass: {
          valid: true,
          msg: ""
        },
        promoCode: {
          valid: null,
          msg: "",
          msgSlug : ""
        }
      },
      error: "",
      personajes: []
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
  };
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(clase.props.urlGlobal + "api/personaje", {
      method: "POST",
      body: JSON.stringify({
        action: "getPersonajes",
        lan: lan
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.setState({
          personajes: result.personajes,
          personaje: result.personajes[0]
        });
      }
    });
  };
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  };
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  };
  
  close() {
    $("#register_modal").fadeOut("fast", function() {
      $("#register_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  };
  
  setEmail(e) {
    e.preventDefault();
    this.setState({
      email: e.target.value
    });
  };
  
  setPassword(e) {
    e.preventDefault();
    this.setState({
      pass: e.target.value
    });
  };

  setCheckOptIn1(e) {
    this.setState({
      optIn1: e.target.checked
    });
  };

  setPromoCode(e) {
    e.preventDefault();
    this.setState({
      promoCode: e.target.value === undefined || e.target.value === '' ? '' : e.target.value
    });

    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(clase.props.urlGlobal + "api/promoCodes", {
      method: "POST",
      body: JSON.stringify({
        action: "checkPromoCode",
        lan: lan,
        promoCode:e.target.value
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      let validate = clase.state.validate;
        validate.promoCode = {
          valid: e.target.value === undefined || e.target.value ==='' ? null : result.success,
          msg: result.msg,
          msgSlug : result.msgSlug
        };
        clase.setState({
          validate: validate
        });
        clase.setState({
          successPromoCode : result.data
        })
    });
  };
  
  validateEmail() {
    let valid = true;
    let msg = "";
    if (this.state.email === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, "Email");
    }
    else {
      if (!this.isEmail(this.state.email)) {
        valid = false;
        msg = this.validateMsg.email;
      }
    }
    let validate = this.state.validate;
    validate.email = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  };

  validatePass() {
    let valid = true;
    let msg = "";
    if (this.state.pass === "") {
      valid = false;
      msg = this.validateMsg.empty.replace(/%name%/, "Contraseña");
    }
    let validate = this.state.validate;
    validate.pass = {
      valid: valid,
      msg: msg
    };
    this.setState({
      validate: validate
    });
    return valid;
  };

  isEmail(mail) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(mail);
  };

  Register() {
    let clase = this;
    clase.setState({
      validate: {
        email: {
          valid: true,
          msg: ""
        },
        pass: {
          valid: true,
          msg: ""
        }
      },
      error: ""
    });
    if (clase.validateEmail() && clase.validatePass()) {
      clase.setState({
        loadingShow: true
      });
      fetch(clase.props.urlGlobal + "api/register", {
        method: "POST",
        body: JSON.stringify({
          action: "Register",
          terminos: 1,
          email: clase.state.email,
          pass: clase.state.pass,
          optIn1: clase.state.optIn1 ? 1 : 0,
          promoCode:clase.state.promoCode,
          foto: clase.state.personaje.img,
          referral: clase.props.referral,
          lan: clase.props.getLanActual().name,
          marketing_data: this.props.marketingData,
        })
      }).then(function(response) {
        return response.json();
      }).then(function(result) {
        if (result.success) {
          clase.props.saveLoginStorage(result.jwt);
          clase.setState({
            redirectToReferrer: true,
            loadingShow: false
          });
          clase.props.getDataUser();
          clase.close();
          clase.props.openAccountActivatedModal();
          ReactPixel.track("CompleteRegistration", {
            status: true
          });
        }
        else {
          clase.setState({
            loadingShow: false,
            error: result.msg
          });
          ReactPixel.track("CompleteRegistration", {
            status: false
          });
        }
      });
    }
  };
  
  selectPersonaje(personaje) {
    this.setState({
      personaje: personaje
    });
  };
  
  RegisterFB() {
    window.FB.login(function(){}, { scope: 'email' });
  };
  
  render() {
    return (
      <div 
        className="modalx-overlay close_mw"
        id="register_modal_overlay">
        <div 
          className='modal_window'
          id="register_modal">
          <button 
            id="close_modal"
            className="link-button close_mw"
            onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              <div className='modal_content'>
                {
                  this.state.personajes ? (
                    <>
                      <h5>
                        {this.props.textosShow.registro}
                      </h5>
                      <div className="modal_form">
                        <div className='soc_login'>
                          <span 
                            id='facebook_button'
                            className="link-button"
                            onClick={this.RegisterFB.bind(this)}>
                            <img 
                              src={facebookIcon}
                              alt="Facebook" />
                            {this.props.textosShow.entrarconfacebook}
                          </span>
                        </div>
                        <div className='or'>
                          <span>
                            {this.props.textosShow.o || 'O'}
                          </span>
                        </div>
                        <div className='input_title'>
                          {this.props.textosShow.tuemail || 'Tu Email'}
                        </div>
                        <div className={this.state.validate.email.valid && !this.state.error ? 'input' : 'input error'}>
                          {
                            !this.state.validate.email.valid ? (
                              <div className='error_reason'>
                                {this.state.validate.email.msg}
                              </div>
                            ) : (
                              null
                            )
                          }
                          {
                            this.state.error ? (
                              <div className='error_reason'>
                                {this.state.error}
                              </div>
                            ) : (
                              null
                            )
                          }
                          <input 
                            type="text"
                            name="email"
                            value={this.state.email}
                            onChange={this.setEmail.bind(this)} />
                        </div>

                        <div className='input_title'>
                          {this.props.textosShow.tucontrasena || 'Tu Contraseña'}
                        </div>
                        <div className={this.state.validate.pass.valid ? 'input' : 'input error'}>
                          {
                            !this.state.validate.pass.valid ? (
                              <div className='error_reason'>
                                {this.state.validate.pass.msg}
                              </div>
                            ) : (
                              null
                            )
                          }
                          <input 
                            type="password"
                            name="pass"
                            value={this.state.pass}
                            onChange={this.setPassword.bind(this)} />
                        </div>

                        <div className='input_title'>
                          {this.props.textosShow.tupromocode || 'Your Promo Code'}
                        </div>
                        <div className={this.state.validate.promoCode.valid === true ? 'input success' : this.state.validate.promoCode.valid === false ? 'input error' : ''}>
                          {
                            this.state.validate.promoCode.valid === false ? (
                              <div className='error_reason'>
                                {this.props.textosShow[this.state.validate.promoCode.msgSlug] || this.state.validate.promoCode.msg}
                              </div>
                            ) : this.state.validate.promoCode.valid === true ? (
                              <div className='success_reason'>
                                {this.props.textosShow[this.state.validate.promoCode.msgSlug] || this.state.validate.promoCode.msg}
                                &nbsp; (
                                  {this.state.successPromoCode?.type === 'cash' ? (this.props.textosShow.discountworth ? this.props.textosShow.discountworth + " " : "Discount worth ") + "$" + this.state.successPromoCode.amount : null}
                                  {this.state.successPromoCode?.type === 'gems' ? (this.props.textosShow.gemscredit ? this.props.textosShow.gemscredit + " " : "Gems credit ") + this.state.successPromoCode.gems : null}
                                  {this.state.successPromoCode?.type === 'membership' ? ((this.props.textosShow.avail ? this.props.textosShow.avail + " " : "Avail ") + this.state.successPromoCode.membership + (this.props.textosShow.membership ? " " + this.props.textosShow.membership : " membership")) : null}
                                  {this.state.successPromoCode?.type === 'entryFees' ? ((this.props.textosShow.avail ? this.props.textosShow.avail + " " : "Avail ") + this.state.successPromoCode.juego + (this.props.textosShow.entryfee ? " " + this.props.textosShow.entryfee : " entry fee")) : null}
                                )
                              </div>
                            ) : null
                          }
                          <input 
                            type="text"
                            name="promoCode"
                            value={this.state.promoCode}
                            onChange={this.setPromoCode.bind(this)} />
                        </div>

                        <div className='input_title d-flex align-items-center'>
                          <div className='checkbox'>
                            <input 
                              type="checkbox"
                              id="acceptOptin"
                              name="acceptOptin"
                              value="acceptOptin"
                              onChange={this.setCheckOptIn1.bind(this)}
                              defaultChecked={true} />
                            <label htmlFor="acceptOptin"></label>
                          </div>
                          <label htmlFor="acceptOptin" className='checkbox_text pl-3'>
                            {this.props.textosShow.dejeestaopcionactivadasidesearecibiralertasensucorreoelectronico || 'Deje esta opción activada si desea recibir alertas en su correo electrónico'}
                          </label>
                        </div>

                        <div className='personaje_selector'>
                          <div className='input_title'>
                            {this.props.textosShow.seleccionarpersonaje || "Seleccionar personaje"}
                          </div>
                          <div className='input'>
                            <div className='personaje_sel'>
                              {
                                this.state.personajes.map(personaje =>
                                  <div 
                                    className={(this.state.personaje.id === personaje.id) ? 'personaje_item gs_item selected' : 'personaje_item gs_item'}
                                    key={personaje.nombre}
                                    onClick={() => this.selectPersonaje(personaje)}>
                                    <img 
                                      src={personaje.img} 
                                      alt={personaje.nombre} />
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='modal_button'>
                        <button 
                          className='button button_regular'
                          onClick={this.Register.bind(this)}
                          disabled={this.state.loadingShow || this.state.validate.promoCode.valid === false}>
                          {this.props.textosShow.continuarregistro || "Continuar Registro"}
                        </button>
                      </div>
                      <div className='agreement'>
                        {this.props.textosShow.alregistrarseenelsitiowebautomaticamenteacepta || "Al registrarse en el sitio web, automáticamente acepta"}
                        <br />
                        <Link to="/terms_and_conditions">
                          {this.props.textosShow.terminosdelservicio || "Términos del servicio"}
                        </Link> {this.props.textosShow.y || "y"} <Link to="/private_policy">
                          {this.props.textosShow.politicadeprivacidad || "Política de privacidad"}
                        </Link>
                      </div>
                    </>
                  ) : (
                    null
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
};

export default RegisterModal;
