import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import Picker from 'emoji-picker-react';
import $, { data } from 'jquery';

import BlogCommentItem from './BlogCommentItem';

import Util from '../utils/utils';

import sendIcon from '../../img/send.png';
import emojiGreenIcon from '../../img/emoji-green.png';
import emojiWhiteIcon from '../../img/emoji-white.png';
import questionIcon from '../../img/question-mark.png';
import winBanGreenIcon from '../../img/win-ban-green.png';
import winBanWhiteIcon from '../../img/win-ban-white.png';

import BlogItem from './BlogItem';


const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => {
        elementRef.current.scrollIntoView({block: "end"});
    });
    return <div ref={elementRef} />;
};

export default function BlogBox({blogOpen, urlGlobal, getHeadersToSend, setUnreadBlogCommentCnt, increaseUnreadBlogCommentCnt, getRule, getBlogClienteBan, showPerfilUserModal, showChallengeModal, showLoadingIndicator, getLanActual, textosShow, userData, defaultData, wsConnect, socketData}) {

    const updateLastCommentPeriod = 400;

    const [blog, setBlog] = useState();
    const [blogCommentList, setBlogCommentList] = useState([]);
    const [lastReadCommentId, setLastReadCommentId] = useState(-1);
    const [lastCommentCheck, setLastCommentCheck] = useState(0);
    const [intervalId, setIntervalId] = useState(0);

    const [inputFocus, setInputFocus] = useState(false);
    const [blogSendText, setBlogSendText] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const [inputCursorPosition, setInputCursorPosition] = useState(0);
    const [inputError, setInputError] = useState();
    const [blogWinBan, setBlogWinBan] = useState(false);

    const [editBlogCommentId, setEditBlogCommentId] = useState(0);
    const [wsDeleteBlogCommentId, setWSDeleteBlogCommentId] = useState(null);

    const [isScrollBottom, setIsScrollBottom] = useState(false);
    const [isScrollTo, setIsScrollTo] = useState(false);

    useEffect(() => {
        inputWrapperSize();
        getTextAreaHeight();

        getLatestBlog();
    }, []);

    useEffect(() => {
        if (socketData) {
            parseSocketData();
        }
    }, [socketData]);

    useEffect(() => {
        if (blog) {
            getBlogCommentList();
        }
    }, [blog]);

    useEffect(() => {
        if (wsDeleteBlogCommentId != null) {
            deleteBlogCommentFromList(wsDeleteBlogCommentId);
            setWSDeleteBlogCommentId(null);
        }
    }, [wsDeleteBlogCommentId]);

    const inputWrapperSize = () => {
        const ta = $('.input-wrapper textarea').get(0);
        $('.input-wrapper textarea').on('keydown', function(el) {
            setTimeout(function () {
                if (ta.scrollHeight <= 180) {
                    el.currentTarget.style.cssText = 'height: auto;';
                    el.currentTarget.style.cssText = 'height: ' + ta.scrollHeight + 'px';
                } else {
                    el.currentTarget.style.cssText = 'height: 172px; overflow-y: auto';
                }
                
            })
        })
    }

    const getTextAreaHeight = () => {
        const ta = $('.input-wrapper textarea').get(0);
        $('.input-wrapper .emoji').css('height', ta.offsetHeight + 'px');
    }

    const showRulesModal = () => {
        $("#rules_modal_overlay").fadeIn("fast", function() {
          $("#rules_modal").fadeIn("fast", function() {
            document.body.style.overflow = "hidden";
          });
        });
      };

    const getBlogRule = () => {
        let clase = this;
        fetch(urlGlobal + "api/page", {
            method: "POST",
            body: JSON.stringify({
                action: "getPage",
                page: `reglas${'blog'}`,
                lan: getLanActual()
            }),
            headers: getHeadersToSend()
            }).then(function(response) {
                return response.json();
            }).then(function(result) {
                if (result.success) {
                    getRule({
                        titulo: result.page.titulo,
                        texto: result.page.texto
                    });

                    showRulesModal();
            }
        });
    }

    const getLatestBlog = () => {
        fetch(urlGlobal + "api/blog", {
            method: "POST",
            body: JSON.stringify({
              action: "getLatestBlog",
            }),
            headers: getHeadersToSend()
        }).then(function(response) {
            return response.json();
        }).then(function(result) {
            if (result.success) {
                setBlog(result.blog);
            }
        });
    }

    const getLastBlogInfo = () => {
        fetch(urlGlobal + "api/blog", {
            method: "POST",
            body: JSON.stringify({
              action: "getLastBlogInfo",
              blog_id: blog.id,
            }),
            headers: getHeadersToSend()
        }).then(function(response) {
            return response.json();
        }).then(function(result) {
            if (result.success) {
                setUnreadBlogCommentCnt(result.unread_comment_count);
                setLastReadCommentId(result.last_read_comment_id);
                scrollTo();
            }
        });
    }

    const updateBlogLastRead = (blogCommentId) => {
        fetch(urlGlobal + "api/blog", {
            method: "POST",
            body: JSON.stringify({
              action: "updateBlogLastRead",
              blog_id: blog.id,
              blog_comment_id: blogCommentId
            }),
            headers: getHeadersToSend()
        }).then(function(response) {
            return response.json();
        }).then(function(result) {
            
        });
    }

    const getBlogCommentList = () => {
        fetch(urlGlobal + "api/blog", {
            method: "POST",
            body: JSON.stringify({
              action: "getBlogCommentList",
              blog_id: blog.id,
            }),
            headers: getHeadersToSend()
        }).then(function(response) {
            return response.json();
        }).then(function(result) {
            if (result.success) {
                setBlogWinBan(result.blog_winner_ban);
                setBlogCommentList(result.blog_comment_list);
                getLastBlogInfo();
                showLoadingIndicator(false);
            }
        });
    }

    const updateBlogWinBan = (blogWinBan) => {
        fetch(urlGlobal + "api/blog", {
            method: "POST",
            body: JSON.stringify({
              action: "updateBlogWinBan",
              is_ban: blogWinBan,
            }),
            headers: getHeadersToSend()
        }).then(function(response) {
            return response.json();
        }).then(function(result) {
            if (result.success) {
                getBlogCommentList();
            }
        });
    }

    const storeBlogComment = (fakeId) => {
        if (!blog) {
            return;
        }

        fetch(urlGlobal + "api/blog", {
            method: "POST",
            body: JSON.stringify({
              action: "storeBlogComment",
              blog_id: blog.id,
              fake_id: fakeId,
              blog_comment: blogSendText
            }),
            headers: getHeadersToSend()
        }).then(function(response) {
            return response.json();
        }).then(function(result) {
            if (result.success) {
                setBlogCommentList(prev => prev.map(item => 
                    item.Id === result.fake_id ? {...item, Id: result.blog_comment_id, IsReal: true} : item
                ));
                
                setLastReadCommentId(result.blog_comment_id);
                setUnreadBlogCommentCnt(0);
                updateBlogLastRead(result.blog_comment_id);

                wsUpdateFakeId(result.fake_id, result.blog_comment_id);
            }
        });
    }

    const updateBlogComment = () => {
        if (!blog) {
            return;
        }

        fetch(urlGlobal + "api/blog", {
            method: "POST",
            body: JSON.stringify({
              action: "updateBlogComment",
              blog_comment_id: editBlogCommentId,
              blog_comment: blogSendText
            }),
            headers: getHeadersToSend()
        }).then(function(response) {
            return response.json();
        }).then(function(result) {
            if (result.success) {
            }
        });
    }

    const deleteBlogComment = (blogCommentId) => {
        if (!blog) {
            return;
        }

        fetch(urlGlobal + "api/blog", {
            method: "POST",
            body: JSON.stringify({
              action: "deleteBlogComment",
              blog_comment_id: blogCommentId,
            }),
            headers: getHeadersToSend()
        }).then(function(response) {
            return response.json();
        }).then(function(result) {
            if (result.success) {
            }
        });
    }


    const parseSocketData = () => {
        const socketObj = JSON.parse(socketData);

        if (socketObj && socketObj.type) {
            const type = socketObj.type;
            const action = socketObj.action;
            const data = socketObj.data;
            const clienteId = socketObj.cliente_id;

            if (data && type && action && clienteId != userData.id) {
                if (type === 'blog_comment') {
                    switch (action) {
                        case 'store':
                            setBlogCommentList(prev => (prev.filter(item => item.Id == data.blog_comment_id).length > 0) ? [...prev] : [...prev, data]);
                            break;
    
                        case 'update':
                            setBlogCommentList(prev => prev.map(item => 
                                item.Id === data.blog_comment_id ? {...item, BlogCommentContent: data.blog_comment_content} : item
                            ));
                            break;

                        case 'update_fake_id':
                            setBlogCommentList(prev => prev.map(item => 
                                item.Id === data.fake_id ? {...item, Id: data.real_id, IsReal: true} : item
                            ));

                            increaseUnreadBlogCommentCnt();
                            break;

                        case 'delete':
                            setWSDeleteBlogCommentId(data.blog_comment_id);
                            break;
                    }
                } else if (type === 'blog') {
                    console.log('========parseSocketData========', socketObj);
                    getLatestBlog();
                } else if (type === 'blog_cliente_ban') {
                    getBlogClienteBan(action, data);
                }
            }
        }
    }

    const wsStore = (blogComment) => {
        if (wsConnect) {
            const msg = {
                type: 'blog_comment',
                action: 'store',
                data: blogComment,
                cliente_id: userData.id
            }
            wsConnect.send(JSON.stringify(msg));
        }
    }

    const wsUpdate = () => {
        if (wsConnect) {
            const msg = {
                type: 'blog_comment',
                action: 'update',
                data: {
                    blog_comment_id: editBlogCommentId,
                    blog_comment_content: blogSendText
                },
                cliente_id: userData.id
            }
            wsConnect.send(JSON.stringify(msg));
        }
    }

    const wsUpdateFakeId = (fakeId, realId) => {
        if (wsConnect) {
            const msg = {
                type: 'blog_comment',
                action: 'update_fake_id',
                data: {
                    fake_id: fakeId,
                    real_id: realId
                },
                cliente_id: userData.id
            }
            wsConnect.send(JSON.stringify(msg));
        }
    }

    const wsDelete = (blogCommentId) => {
        if (wsConnect) {
            const msg = {
                type: 'blog_comment',
                action: 'delete',
                data: {
                    blog_comment_id: blogCommentId
                },
                cliente_id: userData.id
            }
            wsConnect.send(JSON.stringify(msg));
        }
    }

    const getBlogContainerClass = () => {
        return `d-flex-ver blog-panel ${blogOpen ? 'blog-panel-show' : 'blog-panel-hide'} ${$('.top-fixed').length > 0 ? 'blog-fixed' : ''}`;

    }

    const addToBlogList = (fakeId) => {
        if (!blog) {
            return;
        }

        const blogComment = {
            BlogId: blog ? blog.id : -1,
            BlogCommentContent: blogSendText,
            ClienteMembershipId: userData.membership.membershipLevel + 1,
            CreatedAt: Math.floor(new Date().getTime() / 1000), 
            Id: fakeId,
            IsReal: false,
            IdCliente: userData.id,
            UserFoto: userData.foto,
            PaisFoto: userData.paisFoto,
            UserName: userData.name
        }

        setBlogCommentList([...blogCommentList, blogComment]);

        wsStore(blogComment);
    }

    const updateToBlogList = () => {
        if (!blog) {
            return;
        }

        setBlogCommentList(prev => prev.map(item => 
            item.Id === editBlogCommentId ? {...item, BlogCommentContent: blogSendText} : item    
        ))

        wsUpdate();
    }

    const deleteBlogCommentFromList = (blogCommentId) => {
        const tempList = [...blogCommentList];
        let findIndex = null;
        blogCommentList.map((item, index) => {
            if (item.Id == blogCommentId) {
                findIndex = index;
            }
        })

        if (findIndex !== null) {
            tempList.splice(findIndex, 1);
            setBlogCommentList(tempList);

            var newId = lastReadCommentId;
            if ((lastReadCommentId == blogCommentId) && findIndex > 0) {
                newId = tempList[findIndex - 1].Id;
                setLastReadCommentId(newId);
            }

            setUnreadBlogCommentCnt(tempList.filter((item) => item.Id > newId).length);
        }
    }

    const scrollToBottom = () => {
        setIsScrollBottom(true);
        setTimeout(() => {
            setIsScrollBottom(false);
        }, 400);
    }

    const scrollTo = () => {
        setIsScrollTo(true);
        setTimeout(() => {
            setIsScrollTo(false);
        }, 400);
    }

    const onSendBtn = () => {

        if (!blogSendText || !blog) {
            return;
        }

        if (!checkValidation()) {
            return;
        }

        if (inputError) {
            setInputError(null);
        }

        if (editBlogCommentId > 0) {
            updateToBlogList();
            updateBlogComment();
            setEditBlogCommentId(0);
        } else {
            const fakeId = Util.genNewId();
            addToBlogList(fakeId);
            storeBlogComment(fakeId);
            scrollToBottom();
        }

        resetInput();
    }

    const checkValidation = () => {
        if (Util.validateEmail(blogSendText)) {
            setInputError('Email is not allowed');
            return false;
        }

        if (Util.validatePhoneNumber(blogSendText)) {
            setInputError('Phone number is not allowed');
            return false;
        }

        if (inputError) {
            setInputError(null);
        }
        
        return true;
    }

    const resetInput = () => {
        setBlogSendText('');
        setShowPicker(false);
        $('.input-wrapper textarea').css('height', 'auto');
    }

    const setInputCursor = (position) => {
        const textarea = $('.input-wrapper textarea').get(0);
        if (textarea && textarea.currentTarget) {
            textarea.currentTarget.selectionStart = position;
            textarea.currentTarget.selectionEnd = position;
        }
        
    }

    const onTextChange = (e) => {
        setInputCursorPosition(e.target.selectionStart);
        setBlogSendText(e.target.value);
        setInputError(null);
    }

    const onTextClick = (e) => {
        setInputCursorPosition(e.target.selectionStart);
    }

    const onEmojiClick = (event, emojiObject) => {
        setBlogSendText(Util.addStr(blogSendText, inputCursorPosition, emojiObject.emoji));
        setInputCursorPosition(inputCursorPosition + 2);
        setInputCursor(inputCursorPosition + 2);
    };

    const onEmojiIconClick = () => {
        setShowPicker(val => !val);
        setInputCursor(inputCursorPosition);
    }

    const onWinBanIconClick = () => {
        showLoadingIndicator(true);
        updateBlogWinBan(!blogWinBan);
    }

    const updateLastReadCommentId = (blogCommentId) => {
        if (lastReadCommentId < blogCommentId) {
            console.log('============setLastReadCommentId=============', lastReadCommentId, blogCommentId);
            setLastReadCommentId(blogCommentId);
            setUnreadBlogCommentCnt(blogCommentList.filter((item) => item.Id > blogCommentId).length);

            const curTime = new Date().getTime();

            if (intervalId && (curTime - lastCommentCheck) < updateLastCommentPeriod) {
                clearInterval(intervalId);
            }

            const newIntervalId = setInterval(() => {
                updateBlogLastRead(blogCommentId);
                clearInterval(newIntervalId);
                setIntervalId(0);
            }, [updateLastCommentPeriod]);

            setIntervalId(newIntervalId);
            setLastCommentCheck(curTime);
        }
    }

    const onEditBlogComment = (blogComment) => {
        setEditBlogCommentId(blogComment.Id);
        setBlogSendText(blogComment.BlogCommentContent);
    }

    const onDeleteBlogComment = (blogComment) => {
        deleteBlogCommentFromList(blogComment.Id);
        wsDelete(blogComment.Id);
        deleteBlogComment(blogComment.Id);
    }

    return (
        <div className={getBlogContainerClass()}>
            <div className='blog-list'>
                {   blog &&
                    <BlogItem 
                        item={blog}/>
                }

                { blog && blogCommentList &&
                    <div className='seperator mt-360'></div>
                }

                {   blogCommentList &&
                    blogCommentList.map((item) => {
                        return (
                            <>
                                <BlogCommentItem
                                    id={`blog_comment_item_${item.Id}`}
                                    item={item}
                                    userData={userData}
                                    defaultData={defaultData}
                                    textosShow={textosShow}
                                    lastReadCommentId={lastReadCommentId}
                                    showPerfilUserModal={showPerfilUserModal}
                                    showChallengeModal={showChallengeModal}
                                    updateLastReadCommentId={updateLastReadCommentId}
                                    onEditBlogComment={onEditBlogComment}
                                    onDeleteBlogComment={onDeleteBlogComment}/>
                                {item.Id === lastReadCommentId && isScrollTo && <AlwaysScrollToBottom />}
                            </>
                            
                        );
                    })
                }
                
                
                {isScrollBottom && <AlwaysScrollToBottom />}
                
            </div>

            { showPicker && 
                <div className='emoji-container'>
                    <Picker
                        pickerStyle={{ width: '100%' }}
                        onEmojiClick={onEmojiClick} />
                </div>
            }

            <div className='blog-panel-send-box'>
                <div className='d-flex send-input'>
                    <div className={`input-wrapper ${inputError ? 'input-error-border' : (inputFocus ? 'input-wrapper-border' : '')}`}>
                        <div className='input'>
                            <textarea 
                                rows={1} 
                                placeholder={textosShow.your_message || 'Your Message'}
                                value={blogSendText}
                                onClick={(e) => onTextClick(e)}
                                onChange={(e) => onTextChange(e)}
                                onFocus={() => setInputFocus(true)}
                                onBlur={() => setInputFocus(false)}/>
                            <div className='emoji'>
                                <img
                                    className="question-icon"
                                    src={questionIcon}
                                    onClick={() => {getBlogRule()}} />
                                <img
                                    className="win-ban-icon"
                                    src={blogWinBan ? winBanGreenIcon : winBanWhiteIcon}
                                    onClick={() => onWinBanIconClick()} />
                                <img
                                    className="emoji-icon"
                                    src={showPicker ? emojiGreenIcon : emojiWhiteIcon}
                                    onClick={() => onEmojiIconClick()} />
                                    
                            </div>
                        </div>
                    </div>
                    <div className='send-btn-wrapper'>
                        <button 
                            className='send-btn'
                            disabled={inputError || !blogSendText || blogSendText === ''}
                            onClick={() => onSendBtn()}>
                            <img
                                src={sendIcon}/>
                        </button>
                    </div>
                </div>
                {   inputError &&
                    <div className='input-error'>{inputError}</div>
                }
                
            </div>
        </div>
    )
}
