import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';

class Transacciones extends Component{

  constructor(props) {
    super(props);

    this.state = {
        tipos: [
          {
            id: "",
            value: this.props.textosShow.todas || "Todas",
            selected: true
          },
          {
            id: "deposito",
            value: this.props.textosShow.depositos || "Depósitos",
            selected: false
          },
          {
            id: "retiro",
            value: this.props.textosShow.depositos || "Retiros",
            selected: false
          },
          {
            id: "juego",
            value: this.props.textosShow.juegos || "Juegos",
            selected: false
          },
          {
            id: "premio",
            value: this.props.textosShow.premios || "Premios",
            selected: false
          },
          {
            id: "bonanza_reward",
            value: this.props.textosShow.bonanzareward || "Bonanza Reward",
            selected: false
          },
          {
            id: "spin_prize",
            value: this.props.textosShow.spinprize || "Spin Prize",
            selected: false
          }
        ],
        transacciones: [],
        total: 0,
        activePage: 0,
        totalPages: 0,
        pages: []
    };
    
    this.handleChangePage = this.handleChangePage.bind(this);

    this.urlGlobal = props.urlGlobal;
  }

  componentDidMount() {
    if (this.props.loggedIn) {
      this.getTransacciones(this.state.activePage);
    }
  }

  getTransacciones(page) {
    let myHeaders = this.props.getHeadersToSend();
    let lan = this.props.getLanActual();
    let clase = this;
    fetch(this.urlGlobal + "api/user", {
      method: "POST",
      body: JSON.stringify({
        action: "getTransacciones",
        tipo: clase.state.tipos.find(t => t.selected).id,
        lan: lan,
        page: page
      }),
      headers: myHeaders
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        let totalPages = Math.ceil(result.total / 5);
        let pages = [];
        let firstPage = (page > 4) ? page - 4 : 0;
        let lastPage = (page + 4 < totalPages) ? page + 4 : totalPages - 1;
        for (let i = firstPage; i <= lastPage; i++) {
          pages.push(i);
        }
        clase.setState({
          transacciones: result.transacciones,
          total: result.total,
          totalPages: totalPages,
          pages: pages
        });
      }
    });
  }

  handleChangePage(page) {
    this.setState({
      activePage: page
    });
    this.getTransacciones(page);
  }
  
  setTipo(tipo) {
    let tipos = this.state.tipos;
    tipos.find(t => t.selected).selected = false;
    tipos.find(t => t.id === tipo.id).selected = true;
    this.setState({
      tipos: tipos,
      activePage: 0
    });
    this.getTransacciones(this.state.activePage);
  };

  redirectToPartida(partida, generaljackpot) {
    if (partida) {
      this.props.history.replace(`/partida/${partida.juegoSlug}/${partida.sala}/${partida.id}`);
    }
    if(generaljackpot) {
      this.props.history.replace(`/${generaljackpot.slug}/${generaljackpot.id}`);
    }
  };

  render() {
    if (!this.props.loggedIn) {
      return (<Redirect to={'/'} />);
    }

    let transacciones = this.state.transacciones;
 
    return (
      <div>
        <div className='content' 
             id='game_history'>
          <div className='wrapper'>
            <div className='smoke_center'></div>
            <div className='page_content'>
              <div className='c_center'>
                <div className='c_top'>
                  <div className='c_bottom'>
                    <Eagle></Eagle>
                    <Leaves></Leaves>
                    <h4>
                      {this.props.textosShow.historicotransacciones || "Histórico de transacciones"}
                    </h4>
                    <div className='page_block'>
                      <div className='select'>
                        <button className='main-item'>
                          {
                            this.state.tipos.find(t => t.selected).value
                          }
                        </button>
                        <ul className="sub-menu lang_menu">
                          <li>
                            {
                              this.state.tipos.map(t =>
                                <span 
                                  className="link-button"
                                  key={t.id}
                                  onClick={() => this.setTipo(t)}>
                                  {t.value}
                                </span>
                              )
                            }
                          </li>
                        </ul>
                      </div>
                      <div className='table_list'>
                        <table>
                          <thead>
                            <tr className='subtitle'>
                              <td>
                                <span>
                                  {this.props.textosShow.fechayhora || "Fecha y hora"}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {this.props.textosShow.importe || "Importe"}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {this.props.textosShow.descripcion || "Descripción"}
                                </span>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              transacciones.map(t =>
                                <tr 
                                  key={t.id}
                                  onClick={() => this.redirectToPartida(t.partida, t.generaljackpot)}>
                                  <td>
                                    <span>
                                      {t.fecha}
                                    </span>
                                  </td>
                                  <td className={t.importe < 0 ? 'entry_pay' : 'winner_award'}>
                                    <span>
                                      {this.props.defaultData.moneda.signo}{t.importe}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {t.descripcion}
                                    </span>
                                  </td>
                                </tr>
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className='paginator'>
                        {
                          (this.state.activePage > 0) ?
                            <span 
                              className='link-button prev'
                              onClick={() => this.handleChangePage(this.state.activePage - 1)}></span> :
                            null
                        }
                        {
                          (this.state.pages.length && this.state.pages[0] > 0) ?
                            <span 
                              className='link-button' 
                              onClick={() => this.handleChangePage(0)}>
                              1
                            </span> :
                            null
                        }
                        {
                          (this.state.pages.length && this.state.pages[0] > 0) ?
                            <span>
                              ...
                            </span> :
                            null
                        }
                        {
                          this.state.pages.map(page =>
                            <span 
                              className='link-button'
                              id={(page === this.state.activePage) ? 'page_active' : ''}
                              onClick={() => this.handleChangePage(page)}
                              key={page}>
                              {page + 1}
                            </span>
                          )
                        }
                        {
                          (this.state.pages.length && this.state.pages[this.state.pages.length - 1] < this.state.totalPages - 1) ?
                            <span>
                              ...
                            </span> :
                            null
                        }
                        {
                          (this.state.pages.length && this.state.pages[this.state.pages.length - 1] < this.state.totalPages - 1) ?
                            <span 
                              className='link-button' 
                              onClick={() => this.handleChangePage(this.state.totalPages - 1)}>
                              {this.state.totalPages}
                            </span> :
                            null
                        }
                        {
                          (this.state.activePage < this.state.totalPages - 1) ?
                            <span 
                              className='link-button next'
                              onClick={() => this.handleChangePage(this.state.activePage + 1)}></span> :
                            null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Transacciones;
