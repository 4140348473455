import { useEffect, useRef } from 'react';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Row, Col, } from 'reactstrap';
import Gems from '../../img/stone2.json'
import Lottie from 'react-lottie-player';

function Notification(props) {
    function showToast() {
        for (var i = 0; i < props.objectiveNotificationData.length; i++) {
            toast.success(MyMsg(props.objectiveNotificationData[i]), {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: false,
                draggable: false,
                pauseOnFocusLoss: false,
                closeOnClick: false,
                toastId: props.objectiveNotificationData[i].id,
                transition: Bounce,
                style: ({
                    color: 'white',
                    backgroundColor: "black",
                    height: "135px",
                    border: '1px solid #7e7a7a'
                })
            })
        }
    }
    const MyMsg = (item) => (
        <Container>
            <Row>
                <Col xs="4">
                    <img style={{ width: '150px', height: '150px', marginTop: '-23px', marginRight: '-30px', float: 'right' }} src={item.img} />
                </Col>
                <Col xs="8">
                    <div style={{ fontWeight: 'bold', fontFamily: 'Albertus', fontSize: '13px' }}>
                        <p> {props.textosShow.play || "Play"} {item.play_number} {item.game_name} {props.textosShow.in || "in"} {item.time_duration} {item.time_type} {props.textosShow.towin || "to win"} <a style={{ color: '#f49b1f' }}>{item.gems} {props.textosShow.gems || "Gems"}</a></p>
                        <p>
                            <Lottie
                                loop={true}
                                animationData={Gems}
                                play={true}
                                style={{ width: "65px", height: "65px", marginTop: '-20px', marginLeft: '55px' }}
                                className="p-2 w-100" />  </p>
                    </div>
                </Col>
                <Col xs="4" style={{ marginTop: '-20px', display: 'grid' }}>
                </Col>
                <Col onClick={() => window.open("/game/" + item.game_slug, "_self")} xs="8" style={{ marginTop: '-55px', display: 'grid' }}>
                    <span style={{ textDecoration: 'underline', fontWeight: 'bold', fontFamily: 'Exocet', color: 'greenyellow', cursor: 'pointer' }}>{props.textosShow.playnow || "Play Now"}</span>
                </Col>
                <div className="sparks" style={{left: '50%', marginLeft: '-800px',  top: '60px', position: 'inherit', width: '0px'}}>
                    <div className="spark_1"></div>
                    <div className="spark_2"></div>
                    <div className="spark_3"></div>
                    <div className="spark_4 spark-big"></div>
                    <div className="spark_5 spark-big"></div>
                </div>
            </Row>
        </Container>
    )

    function logOut() {
        if (props.loggedIn === false) {
            toast.dismiss();
        }
    }
    useEffect(() => {
        logOut()
    }, [props.loggedIn])

    useEffect(() => {
        showToast()
    }, [props.objectiveNotificationData])
    return (
        <div className="App">
            <div>
                <ToastContainer />
            </div>
        </div>
    );
}

export default Notification;