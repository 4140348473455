import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import $ from 'jquery';

import SmokeCenter from '../components/SmokeCenter';
import Eagle from '../components/Eagle';
import Leaves from '../components/Leaves';
import NewTicketModal from '../components/NewTicketModal';
import ViewTicketModal from '../components/ViewTicketModal';
import MessageTicketModal from '../components/MessageTicketModal';

class Tickets extends Component {

  constructor(props) {
    super(props);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.onNewTicket = this.onNewTicket.bind(this);

    this.state = {
      tickets: [],
      total: 0,
      activePage: 0,
      totalPages: 0,
      pages: []
    };
    
    this.newTicketModalElement = React.createRef();
    this.messageTicketModalElement = React.createRef();
    this.ticketModalElement = React.createRef();
  };

  componentDidMount() {
    document.addEventListener("onNewTicket", this.onNewTicket, false);
    if (this.props.loggedIn) {
      this.getTickets(this.state.activePage);
    }
  };
  
  componentWillUnmount() {
    document.removeEventListener("onNewTicket", this.onNewTicket, false);
  };
  
  onNewTicket() {
    this.newTicketModalElement.current.setState({
      motivo: this.newTicketModalElement.current.state.motivos[0],
      mensaje: ""
    });
    this.getTickets(this.state.activePage);
  };

  getTickets(page) {
    let clase = this;
    fetch(clase.props.urlGlobal + "api/ticket", {
      method: "POST",
      body: JSON.stringify({
        action: "getTickets",
        lan: clase.props.getLanActual(),
        page: page
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        let totalPages = Math.ceil(result.total / 5);
        let pages = [];
        let firstPage = (page > 4) ? page - 4 : 0;
        let lastPage = (page + 4 < totalPages) ? page + 4 : totalPages - 1;
        for (let i = firstPage; i <= lastPage; i++) {
          pages.push(i);
        }
        clase.setState({
          tickets: result.tickets,
          total: result.total,
          totalPages: totalPages,
          pages: pages
        });
      }
    });
  };

  handleChangePage(page) {
    this.setState({
      activePage: page
    });
    this.getTickets(page);
  };
  
  newTicket() {
    $("#create_ticket_modal_overlay").fadeIn("fast", function() {
      $("#create_ticket_modal").fadeIn("fast", function() {
        document.body.style.overflow = "hidden";
      });
    });
  };

  newMessage() {
    this.messageTicketModalElement.current.setState({
      motivo: null,
      mensaje: "",
      ticket: this.ticketModalElement.current.state.ticket
    });
    $("#tickets_modal_overlay").fadeOut("fast", function() {
      $("#tickets_modal").fadeOut("fast", function() {
        $("#message_ticket_modal_overlay").fadeIn("fast", function() {
          $("#message_ticket_modal").fadeIn("fast", function() {
            document.body.style.overflow = "hidden";
          });
        });
      });
    });
  };
  
  showTicket(ticketId) {
    let clase = this;
    fetch(clase.props.urlGlobal + "api/ticket", {
      method: "POST",
      body: JSON.stringify({
        action: "getTicketData",
        lan: clase.props.getLanActual(),
        ticket: ticketId
      }),
      headers: clase.props.getHeadersToSend()
    }).then(function(response) {
      return response.json();
    }).then(function(result) {
      if (result.success) {
        clase.ticketModalElement.current.setState({
          ticket: result.ticket
        });
        $("#tickets_modal_overlay").fadeIn("fast", function() {
          $("#tickets_modal").fadeIn("fast", function() {
            document.body.style.overflow = "hidden";
          });
        });
      }
    });
  };

  render() {
    if (!this.props.loggedIn) {
      return (<Redirect to={'/'}/>);
    }
    
    const getClassNameByStatus = (status) => {
      if (status === 'sinrespuesta') return "closed-card";
      else if (status === 'respondido') return "read";
      else return "";
    };

    return(
      <div>
        <div 
          className='content' 
          id='support'>
          <div className='wrapper'>
            <SmokeCenter></SmokeCenter>
            <div className='page_content'>
              <div className='c_center'>
                <div className='c_top'>
                  <div className='c_bottom'>
                    <Eagle></Eagle>
                    <Leaves></Leaves>
                    <h4>
                      {this.props.textosShow.ticketsdesoporte || 'Tickets de Soporte'}
                    </h4>
                    <div className='faq_help'>
                      <button 
                        className='button button_regular' 
                        onClick={this.newTicket.bind(this)}>
                        {this.props.textosShow.nuevoticket || 'Nuevo Ticket'}
                      </button>
                    </div>
                    <div className='page_block'>
                      <div className='table_list'>
                        <table>
                          <thead>
                            <tr className='subtitle'>
                              <td>
                                <span>
                                  {this.props.textosShow.estado || 'Estado'}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {this.props.textosShow.fechayhora || 'Fecha y Hora'}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {this.props.textosShow.numerodeticket || 'Número de Ticket'}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {this.props.textosShow.motivo || 'Motivo'}
                                </span>
                              </td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.tickets.map(ticket =>
                                <tr 
                                  key={ticket.id}
                                  className={getClassNameByStatus(ticket.estado)}>
                                  <td className='ticket_status'>
                                    <span>
                                      {this.props.textosShow[ticket.estado]}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {ticket.fecha}
                                    </span>
                                  </td>
                                  <td className='ticket_num'>
                                    <span>
                                      {ticket.id}
                                    </span>
                                  </td>
                                  <td className='ticket_cat'>
                                    <span>
                                      {ticket.motivo}
                                    </span>
                                  </td>
                                  <td className='support_button'>
                                    <span>
                                      <button 
                                        className='button bordered_button' 
                                        onClick={() => this.showTicket(ticket.id)}>
                                        {this.props.textosShow.vermensaje || 'Ver Mensaje'}
                                      </button>
                                    </span>
                                  </td>
                                </tr>
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className='paginator'>
                        {
                          (this.state.activePage > 0) ? (
                            <span 
                              className='link-button prev'
                              onClick={() => this.handleChangePage(this.state.activePage - 1)}></span>
                          ) : (
                            null
                          )
                        }
                        {
                          (this.state.pages.length && this.state.pages[0] > 0) ? (
                            <span 
                              className='link-button' 
                              onClick={() => this.handleChangePage(0)}>
                              1
                            </span>
                          ) : (
                            null
                          )
                        }
                        {
                          (this.state.pages.length && this.state.pages[0] > 0) ? (
                            <span>
                              ...
                            </span>
                          ) : (
                            null
                          )
                        }
                        {
                          this.state.pages.map(page =>
                            <span 
                              className='link-button'
                              id={(page === this.state.activePage) ? 'page_active' : ''}
                              onClick={() => this.handleChangePage(page)}
                              key={page}>
                              {page + 1}
                            </span>
                          )
                        }
                        {
                          (this.state.pages.length && this.state.pages[this.state.pages.length - 1] < this.state.totalPages - 1) ? (
                            <span>
                              ...
                            </span>
                          ) : (
                            null
                          )
                        }
                        {
                          (this.state.pages.length && this.state.pages[this.state.pages.length - 1] < this.state.totalPages - 1) ? (
                            <span 
                              className='link-button' 
                              onClick={() => this.handleChangePage(this.state.totalPages - 1)}>
                              {this.state.totalPages}
                            </span>
                          ) : (
                            null
                          )
                        }
                        {
                          (this.state.activePage < this.state.totalPages - 1) ? (
                            <span 
                              className='link-button next'
                              onClick={() => this.handleChangePage(this.state.activePage + 1)}></span>
                           ) : (
                            null
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewTicketModal 
          urlGlobal={this.props.urlGlobal}
          textosShow={this.props.textosShow}
          getHeadersToSend={this.props.getHeadersToSend}
          getLanActual={this.props.getLanActual}
          ref={this.newTicketModalElement}></NewTicketModal>
        <MessageTicketModal
          urlGlobal={this.props.urlGlobal}
          textosShow={this.props.textosShow}
          getHeadersToSend={this.props.getHeadersToSend}
          getLanActual={this.props.getLanActual}
          ref={this.messageTicketModalElement}></MessageTicketModal>
        <ViewTicketModal 
          urlGlobal={this.props.urlGlobal}
          textosShow={this.props.textosShow}
          getHeadersToSend={this.props.getHeadersToSend}
          getLanActual={this.props.getLanActual}
          ref={this.ticketModalElement}
          onNewMessage={this.newMessage.bind(this)}></ViewTicketModal>
      </div>
    );
  };
};

export default Tickets;
