import React, { Component } from 'react';

import $ from 'jquery';

class ViewTicketModal extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      ticket: null
    };
    
    this.keyDown = this.keyDown.bind(this);
    this.close = this.close.bind(this);
    
    this.urlGlobal = props.urlGlobal;
  }
  
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }
  
  keyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }
  
  close() {
    $("#tickets_modal").fadeOut("fast", function() {
      $("#tickets_modal_overlay").fadeOut("fast", function() {
        document.body.style.overflow = "auto";
      });
    });
  }
  
  render() {
    return (
      <div 
        className="modalx-overlay close_mw"
        id="tickets_modal_overlay">
        <div 
          className='modal_window' 
          id="tickets_modal">
          <button 
            id="close_modal"
            className="link-button close_mw"
            onClick={this.close.bind(this)}></button>
          <div className='modal_top'>
            <div className='modal_bottom'>
              {
                this.state.ticket ?
                  <div className='modal_content'>
                    <div className="faq_help">
                      <button className="button button_regular">
                        {this.props.textosShow[this.state.ticket.estado]}
                      </button>
                    </div>
                    <h5>
                      {this.props.textosShow.ticket || "Ticket"} #{this.state.ticket.id}
                    </h5>
                    <div className='ticket_time'>
                      {this.props.textosShow.inicio || 'Inicio'}: {this.state.ticket.fechaIni} hs
                    </div>
                    <div className='modal_form'>
                      {
                        this.state.ticket ?
                          <div className='chat'>
                            {this.state.ticket.mensajes.map((mensaje) => (
                              <div key={mensaje.id} className={`chat_message ${mensaje.user.isClient || 'my_message'}`}>
                                <div className='cm_ava'>
                                  <img 
                                    src={mensaje.user.foto}
                                    alt="Avatar" />
                                </div>
                                <div className='cm_message'>
                                  <div className='message'>
                                    <div className='m_creator'>
                                      {mensaje.user.isClient ? (this.props.textosShow.miconsulta || 'Mi Consulta') : (this.props.textosShow.respuesta || 'Respuesta')}
                                    </div>
                                    {mensaje.mensaje}
                                    <div className='m_date'>
                                      {mensaje.fecha}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div> :
                          null
                      }
                      <button 
                        className='button button_regular' 
                        onClick={this.props.onNewMessage}>
                        {this.props.textosShow.nuevomensaje || 'Nuevo Ticket'}
                      </button>
                    </div>
                  </div> :
                  null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default ViewTicketModal;
  