import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Link } from 'react-router-dom';  
import ReactTooltip from 'react-tooltip';
import { Card } from 'reactstrap';
import gemIcon from '../../img/gem.png';
import shineImg from '../../img/race-game/bg-shine.png'
import prizeIcon from '../../img/race-game/icon-prize.png'
import questionIcon from '../../img/race-game/icon-question.png'
import Util from '../utils/utils';

export default function SpinGameCard({ item, curSpin, textosShow, defaultData,  gameComponent, onSpinClick}) {

  const getCosto = () => {
    if (!item) {
      return 0;
    }
    return item.costo;
  }

  const getButtonTitle = () => {
    return (textosShow.entrar || "Entrar");
  }

  return (
    <>
      <div 
        className={`spin-card-select ${(curSpin && item.id == curSpin.id )? 'selected' : ''}`}
        onClick={() => onSpinClick(item)}>
        <Card className='spin-card'>
          <div className='spin-players' data-tip data-for={`spin-players-tooltip-${item.id}`}>
            <span>{item.cantJugador}-Max</span>
          </div>
          <ReactTooltip id={`spin-players-tooltip-${item.id}`}>
              <p>
                {(textosShow.spinplayerstooltip || '{cantJugador} is a maximum number of players').replace('{cantJugador}', item.cantJugador)}
              </p>
          </ReactTooltip>
          <div className='spin-cost'>
            <span>{defaultData.moneda.signo}{Number(getCosto()).toFixed(2) || '0.00'}</span>
          </div>

          <div
            className='mt_block step_6'
            id={'deposit_now_' + item.id}>
            <div className='step'></div>
            {textosShow.necesitasrecargartucuentaparacompetir || "Necesitas recargar tu cuenta para competir"}
            <div className=''>
              <button className="button-deposit">
                <Link to="/deposit">
                  {textosShow.depositarahora || "Depositar ahora"}
                </Link>
              </button>
            </div>
          </div>
          <div
            className='mt_block step_6'
            id={'deposit_free_' + item.id}>
            <div className='step'></div>
            {textosShow.necesitasdepositarparajugaraljuegogratis || "Necesitas depositar para jugar al juego gratis"}
            <div className=''>
              <button className="button-deposit">
                <Link to="/deposit">
                  {textosShow.depositarahora || "Depositar ahora"}
                </Link>
              </button>
            </div>
          </div>

          <div className='spin-upto'>
            <div>{textosShow.spinupto || 'Up to'}</div>
            <div>{defaultData.moneda.signo}{Number(item.spinHighPrize).toFixed(2) || '0.00'}</div>
          </div>

        </Card>
      </div>
      
    </>
  )
}